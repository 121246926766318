import axios from "axios";
import { authUserLogoutThunk, logoutUserThunk } from "slices/thunk";
import { dispatchAction } from "index";
import { toast } from "react-toastify";

export const baseUrl =
  process.env.REACT_APP_BACKEND_URL || "https://devapi.signalhouse.io";

axios.defaults.baseURL = baseUrl;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// content type
const loginData = localStorage.getItem("loginData");
const apiKey =
  loginData && JSON.parse(loginData) ? JSON.parse(loginData).apiKey : null;
const authToken =
  loginData && JSON.parse(loginData) ? JSON.parse(loginData).authToken : null;

if (apiKey) axios.defaults.headers.common["apiKey"] = apiKey;
if (authToken) axios.defaults.headers.common["authToken"] = authToken;

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    if (
      response?.status === 401 ||
      response?.data?.errorCode === "SH-AUTH-TOKEN-NOT-FOUND"
    ) {
      dispatchAction(logoutUserThunk());
      dispatchAction(authUserLogoutThunk());
      toast.error("Unauthorized Access");
    }

    return response.data ? response.data : response;
  }

  // function (error) {
  //   // Any status codes that falls outside the range of 2xx cause this function to trigger
  //   let message;
  //   switch (error.status) {
  //     case 500:
  //       message = "Internal Server Error";
  //       break;
  //     case 401:
  //       message = "Invalid credentials";
  //       break;
  //     case 404:
  //       message = "Sorry! the data you are looking for could not be found";
  //       break;
  //     default:
  //       message = error.message || error;
  //   }
  //   return message;
  // }
);

const handleCatchErrors = (response?: any) => {
  if (
    response?.status === 401 ||
    response?.data?.errorCode === "SH-AUTH-TOKEN-NOT-FOUND"
  ) {
    dispatchAction(logoutUserThunk());
    dispatchAction(authUserLogoutThunk());
    toast.error("Unauthorized Access");
  }

  return response?.data ? response?.data : response;
};

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (apiKey: string, authToken: string) => {
  if (apiKey) axios.defaults.headers.common["apiKey"] = apiKey;
  if (authToken) axios.defaults.headers.common["authToken"] = authToken;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = (url: string, params: any) => {
    let response;

    let paramKeys: any = [];

    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };

  createNotion = (data: any) => {
    return axios.post("/user/notionIntegration", data);
  };

  getNumber = (url: string, params: any) => {
    let response;
    let queryString = "";

    if (params) {
      // Construct query string
      queryString = Object.keys(params)
        .map(
          (key: string) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        )
        .join("&");
    }

    // Append query string to URL
    const requestUrl = queryString ? `${url}?${queryString}` : url;
    // Make GET request
    response = axios.get(requestUrl);
    return response;
  };

  /**
   * post given data to url
   */
  create = (url: string, data: any, isFormData?: boolean) => {
    return axios.post(url, data, {
      headers: {
        "Content-Type": isFormData ? "multipart/form-data" : "application/json",
      },
    });
  };

  /**
   * Updates data
   */
  update = (url: string, data: any) => {
    return axios.patch(url, data);
  };

  put = (url: string, data: any) => {
    return axios.put(url, data);
  };

  /**
   * Delete
   */
  delete = (url: string, config?: any) => {
    return axios.delete(url, { ...config });
  };
}

const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser, handleCatchErrors };
