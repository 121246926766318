import React, { useState } from "react";
import { Card, Col } from "react-bootstrap";
import SimpleBar from "simplebar-react";

const Welcome = (props: any) => {
  interface CheckboxProps {
    id: string;
    label: string;
    checked?: boolean;
  }

  const Checkbox: React.FC<CheckboxProps> = ({
    id,
    label,
    checked = false,
  }) => {
    const [isChecked, setIsChecked] = useState(checked);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsChecked(e.target.checked);
    };

    return (
      <div className="form-check mb-2">
        <input
          className="form-check-input"
          type="radio"
          value=""
          id={id}
          checked={isChecked}
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      </div>
    );
  };

  return (
    <div>
      <Col xs={12}>
        <Card>
          <Card.Body className="px-0">
            <SimpleBar
              className="py-0"
              style={{ height: "360px", padding: "0px 19.2px" }}
            >
              <h4 className="list-group-item ps-0 mb-3 w-75">Welcome</h4>
              <p className="mb-4">
                Here you'll be able to build, configure, manage, and track your
                communications.
                <br />
                To get started, you may complete these steps in any order.
              </p>
              <div>
                <h5>Setup your account</h5>
                <Checkbox id="flexCheckDefault" label="Default checkbox" />
                <Checkbox
                  id="flexCheckChecked"
                  label="Checked checkbox"
                  checked
                />
              </div>
              <div className="mt-3">
                <h5>Integrate with API</h5>
                <Checkbox id="flexCheckDefault1" label="Default checkbox" />
                <Checkbox
                  id="flexCheckChecked1"
                  label="Checked checkbox"
                  checked
                />
                <Checkbox id="flexCheckDefault2" label="Default checkbox" />
                <Checkbox
                  id="flexCheckChecked2"
                  label="Checked checkbox"
                  checked
                />
              </div>
              <div className="mt-3">
                <a href="/">Visit Developer Docs</a>
              </div>
            </SimpleBar>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default Welcome;
