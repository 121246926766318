import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import Loader from "assets/images/spinner-dark.svg";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Row,
  Spinner,
  Form,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import {
  capitalizeString,
  customSelectTheme,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { releasedPhoneNumberListThunk } from "slices/thunk";
import Datatable from "../../Common/Datatable";
import Tooltip from "rc-tooltip";
import { useFormik } from "formik";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  brandListApi,
  campaignListApi,
  subgroupListApi,
  tagsListApi,
} from "helpers/apiroute_helper";
import TagsInput from "react-tagsinput";

const initialFilterValues = {
  signalHouseSID: "",
  phoneNumber: "",
  friendlyName: "",
  numberStatus: "",
  qTags: [],
  tenDLCStatus: "",
  brandId: "",
  campaignId: "",
  groupId: "",
  subGroupId: "",
};

const ReleaseNumber = (props: any) => {
  document.title = "Signal House Portal Released Phone Number";
  const dispatch = useDispatch<any>();

  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [subgroupTemp, setSubgroupTemp] = useState<any>({});

  const selectProfile = createSelector(
    (state: any) => state.Numbers,
    (state: any) => state.Groups,
    (numbers, groups) => ({
      loading: numbers.loading,
      releasedPhoneNumberObj: numbers.releasedPhoneNumberObj,
      error: numbers.error,
      groupDetails: groups?.AllGroups,
    })
  );

  const { loading, releasedPhoneNumberObj, groupDetails } =
    useSelector(selectProfile);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialFilterValues,
    onSubmit: (values) => {
      const temp = removeEmptyAndNullValues({
        ...values,
        qTags: values.qTags.length > 0 ? values.qTags : "",
      });
      handleGetData(currPage, rowsPerPage, temp);
    },
  });

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Number</span>,
      selector: (row: { phoneNumber: number | string }) => (
        <span className="text-primary">{row.phoneNumber}</span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Friendly Name</span>,
      selector: (row: { friendlyName: any }) => <span>{row.friendlyName}</span>,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Messaging Status</span>,
      sortable: true,
      selector: (cell: { tenDLCWhiteListStatus: any }) => {
        const status = cell.tenDLCWhiteListStatus?.toLowerCase() || "N/A";
        switch (status) {
          case "mt":
          case "false":
          case "in-process":
            return (
              <span className="d-flex align-items-center text-primary">
                <span className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(
                  cell.tenDLCWhiteListStatus.replaceAll("-", " ")
                )}
              </span>
            );
          case "ready":
            return (
              <span className="d-flex align-items-center text-secondary">
                <span className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(cell.tenDLCWhiteListStatus)}
              </span>
            );
          case "pending":
            return (
              <span className="d-flex align-items-center text-black">
                <span className="badge border border-2 border-white rounded-circle bg-black p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(cell.tenDLCWhiteListStatus)}
              </span>
            );
          default:
            return (
              <span className="d-flex align-items-center text-danger">
                <span className="badge border border-2 border-white rounded-circle bg-danger p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(status)}
              </span>
            );
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Number Status</span>,
      sortable: true,
      selector: (cell: { numberStatus: any }) => {
        switch (cell.numberStatus) {
          case "Inactive":
            return (
              <span
                className="badge bg-body-secondary border border-danger text-danger"
                style={{
                  width: "80px",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                Inactive
              </span>
            );
          case "Active":
            return (
              <span
                className="badge bg-body-secondary border border-success text-success"
                style={{
                  width: "80px",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                Active
              </span>
            );
          default:
            return (
              <span className="d-flex align-items-center text-danger">
                <span className="badge border border-2 border-white rounded-circle bg-danger p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(String(cell.numberStatus) || "N/A")}
              </span>
            );
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Tags</span>,
      selector: (row: { tags: any; i: number }) => (
        <div className="d-flex flex-wrap">
          {row.tags.map((tag: any, i: number) => (
            <span className="details-tag" key={i}>
              {tag}
            </span>
          ))}
        </div>
      ),
      grow: 2,
      wrap: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Capabilities</span>,
      selector: (row: {
        capabilities?: {
          voice?: boolean;
          sms?: boolean;
          mms?: boolean;
        };
      }) => {
        return (
          <div
            className="d-flex flex-row align-items-center flex-nowrap align-content-center"
            style={{
              gap: "20px",
            }}
          >
            {row?.capabilities?.voice && (
              <Tooltip placement="bottom" overlay={"Voice"}>
                <i className="bi bi-telephone fs-lg"></i>
              </Tooltip>
            )}
            {row?.capabilities?.sms && (
              <Tooltip placement="bottom" overlay={"SMS"}>
                <i className="bi bi-chat-left-text fs-lg"></i>
              </Tooltip>
            )}
            {row?.capabilities?.mms && (
              <Tooltip placement="bottom" overlay={"MMS"}>
                <i className="bi bi-file-image fs-lg"></i>
              </Tooltip>
            )}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Actions</span>,
      selector: (row: {
        phoneNumber?: number | string;
        signalHouseSID?: number | string;
      }) => {
        return (
          <Button
            className="btn  btn-sm"
            variant="outline-secondary"
            onClick={() =>
              props.router.navigate(
                `/mynumbers/${row?.phoneNumber}/${row?.signalHouseSID}`
              )
            }
          >
            Configure
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    handleGetData(currPage, rowsPerPage);
  }, []);

  useEffect(() => {
    if (groupDetails?.records?.[0]?.group_id) {
      validation.setFieldValue("groupId", groupDetails?.records?.[0]?.group_id);
    }
  }, [groupDetails?.records?.[0]?.group_id]);

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(releasedPhoneNumberListThunk(page, perPage, otherParams || {}));
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(
      page,
      rowsPerPage,
      removeEmptyAndNullValues({
        ...validation.values,
        qTags:
          validation.values.qTags.length > 0 ? validation.values.qTags : "",
      })
    );
  };

  const loadTagOptions = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let q: any = {
      isReleased: true,
    };
    if (searchQuery) {
      q.tagSearch = searchQuery;
    }
    let data: any = await tagsListApi(page, 10, q);

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records.map((tag: string) => ({
          value: tag,
          label: tag,
        })),
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };
  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(
      1,
      rows,
      removeEmptyAndNullValues({
        ...validation.values,
        qTags:
          validation.values.qTags.length > 0 ? validation.values.qTags : "",
      })
    );
  };

  const setDropdown = () => setOpen(!open);

  const loadOptions1 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await subgroupListApi(page, 10, {
      groupId: validation.values.groupId || "",
    });

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const loadOptions2 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await brandListApi(
      page,
      10,
      removeEmptyAndNullValues({
        groupId: validation.values.groupId || "",
        subGroupId: validation.values.subGroupId || "",
        brandId: searchQuery.toUpperCase() || "",
      })
    );

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const loadOptions3 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await campaignListApi(
      page,
      10,
      removeEmptyAndNullValues({
        groupId: validation.values.groupId || "",
        subGroupId: validation.values.subGroupId || "",
        brandId: validation.values.brandId || "",
        campaignId: searchQuery.toUpperCase() || "",
      })
    );

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Released Phone Number"
            isFilter={true}
            filterButton={
              <Dropdown
                align="end"
                className="filterDropDown"
                show={open}
                onToggle={setDropdown}
              >
                <Dropdown.Toggle variant="secondary">
                  <i className="bx bx-filter-alt me-1"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-md p-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      setDropdown();
                      validation.handleSubmit();
                    }}
                  >
                    <Row>
                      <Col sm={12} className="mb-3">
                        <Form.Label htmlFor="signalHouseSID">
                          Phone Number SID
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="phoneNumberSID"
                          placeholder="Search by Phone Number SID"
                          onChange={(e) => {
                            validation.setFieldValue(
                              "signalHouseSID",
                              e.target.value
                            );
                          }}
                          value={validation.values.signalHouseSID}
                        />
                      </Col>
                      <Col sm={12} className="mb-3">
                        <Form.Label htmlFor="phoneNumber">
                          Phone Number
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="phoneNumber"
                          placeholder="Search by Phone Number"
                          onChange={(e) => {
                            validation.setFieldValue(
                              "phoneNumber",
                              e.target.value
                            );
                          }}
                          value={validation.values.phoneNumber}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="subGroupId">Sub Group</Form.Label>
                        <AsyncPaginate
                          isDisabled={!validation.values.groupId}
                          key={validation.values.groupId}
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.subGroupId &&
                              validation?.errors?.subGroupId
                          )}
                          theme={customSelectTheme}
                          value={subgroupTemp || {}}
                          loadOptions={loadOptions1}
                          getOptionValue={(option: any) => option?.sub_group_id}
                          getOptionLabel={(option: any) =>
                            option?.sub_group_name
                          }
                          onChange={(option: any) => {
                            if (option) {
                              setSubgroupTemp(option);
                              validation.setFieldValue(
                                "subGroupId",
                                option?.sub_group_id
                              );
                              validation.setFieldValue("brandId", "");
                              validation.setFieldValue("campaignId", "");
                            } else {
                              setSubgroupTemp({});
                              validation.setFieldValue("subGroupId", "");
                            }
                          }}
                          isSearchable={true}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="brandId">Brand</Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.subGroupId ||
                            validation.values.groupId ||
                            "brandId"
                          }
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.brandId &&
                              validation?.errors?.brandId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.brandId
                              ? { brandId: validation.values.brandId }
                              : null || {}
                          }
                          loadOptions={loadOptions2}
                          getOptionValue={(option: any) => option?.brandId}
                          getOptionLabel={(option: any) => option?.brandId}
                          onChange={(option: any) => {
                            if (option) {
                              validation.setFieldValue(
                                "brandId",
                                option.brandId
                              );
                              validation.setFieldValue("campaignId", "");
                            } else {
                              validation.setFieldValue("brandId", "");
                            }
                          }}
                          isSearchable={true}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="campaignId">Campaign</Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.brandId ||
                            validation.values.subGroupId ||
                            validation.values.groupId ||
                            "campaignId"
                          }
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.campaignId &&
                              validation?.errors?.campaignId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.campaignId
                              ? { campaignId: validation.values.campaignId }
                              : null || {}
                          }
                          loadOptions={loadOptions3}
                          getOptionValue={(option: any) => option?.campaignId}
                          getOptionLabel={(option: any) => option?.campaignId}
                          onChange={(option: any) => {
                            if (option) {
                              validation.setFieldValue(
                                "campaignId",
                                option.campaignId
                              );
                            } else {
                              validation.setFieldValue("campaignId", "");
                            }
                          }}
                          isSearchable={true}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="numberStatus">
                          Number Status
                        </Form.Label>
                        <Select
                          name="numberStatus"
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.numberStatus &&
                              validation?.errors?.numberStatus
                          )}
                          theme={customSelectTheme}
                          options={[
                            { value: "Active", label: "Active" },
                            { value: "Inactive", label: "Inactive" },
                          ]}
                          placeholder="Select status"
                          value={{
                            label: validation.values.numberStatus,
                            value: validation.values.numberStatus,
                          }}
                          onChange={(e: any) => {
                            if (e) {
                              validation.setFieldValue("numberStatus", e.value);
                            } else {
                              validation.setFieldValue("numberStatus", "");
                            }
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="tenDLCStatus">
                          Messaging Status
                        </Form.Label>
                        <Select
                          name="tenDLCStatus"
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.tenDLCStatus &&
                              validation?.errors?.tenDLCStatus
                          )}
                          theme={customSelectTheme}
                          options={[
                            { value: "Pending", label: "Pending" },
                            { value: "Ready", label: "Ready" },
                            { value: "In-Progress", label: "In-Progress" },
                          ]}
                          placeholder="Select status"
                          value={{
                            label: validation.values.tenDLCStatus,
                            value: validation.values.tenDLCStatus,
                          }}
                          onChange={(e: any) => {
                            if (e) {
                              validation.setFieldValue("tenDLCStatus", e.value);
                            } else {
                              validation.setFieldValue("tenDLCStatus", "");
                            }
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="friendlyName">
                          Friendly Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="friendlyName"
                          placeholder="Enter Friendly Name"
                          onChange={(e) => {
                            validation.setFieldValue(
                              "friendlyName",
                              e.target.value
                            );
                          }}
                          value={validation.values.friendlyName}
                        />
                      </Col>
                      <Col sm={12} className="mb-3">
                        <Form.Label htmlFor="qTags">Tags</Form.Label>
                        <AsyncPaginate
                          isMulti
                          isClearable
                          styles={styleObj(
                            validation?.touched?.qTags &&
                              validation?.errors?.qTags
                          )}
                          theme={customSelectTheme}
                          value={validation.values.qTags.map((tag: string) => ({
                            value: tag,
                            label: tag,
                          }))}
                          loadOptions={loadTagOptions}
                          onChange={(selectedOptions: any) => {
                            const tags = selectedOptions
                              ? selectedOptions.map(
                                  (option: any) => option.value
                                )
                              : [];
                            validation.setFieldValue("qTags", tags);
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                    </Row>
                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        className="btn btn-secondary me-2"
                        type="button"
                        disabled={loading}
                        onClick={() => {
                          validation.setValues(initialFilterValues);
                          validation.resetForm();
                          validation.setFieldValue(
                            "groupId",
                            groupDetails?.records?.[0]?.group_id
                          );
                          setSubgroupTemp({});
                          setDropdown();
                          handleGetData(currPage, rowsPerPage);
                        }}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Clear
                      </Button>
                      <Button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {loading && <Spinner size="sm" animation="border" />}{" "}
                        Apply
                      </Button>
                    </div>
                  </Form>
                </Dropdown.Menu>
              </Dropdown>
            }
          />
          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body className="listing-table">
                {loading ? (
                  <div className={``}>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                      alt="loading"
                    />
                  </div>
                ) : (
                  <Datatable
                    data={
                      Array.isArray(releasedPhoneNumberObj?.records)
                        ? releasedPhoneNumberObj?.records
                        : []
                    }
                    columns={columns}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    currPage={currPage}
                    rowsPerPage={rowsPerPage}
                    totalRecords={releasedPhoneNumberObj?.totalRecords}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ReleaseNumber);
