import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import Loader from "assets/images/spinner-dark.svg";
import { useEffect, useState } from "react";
import { Card, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getSubgroupListThunk } from "slices/thunk";
import Datatable from "../../../../Common/Datatable";
import { capitalizeString, dateFormat } from "helpers/common";
import maintain from "assets/images/auth/maintenance.png";
import { Link } from "react-router-dom";

const SubGroupsList = (props: any) => {
  document.title = "Signal House Portal Sub Groups List";
  const dispatch = useDispatch<any>();

  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const selector = createSelector(
    (state: any) => state.Groups,
    (groups) => ({
      loading4: groups.loading4,
      subgroupsData: groups.subgroupsData,
      error: groups.error,
      getGroupsData: groups.AllGroups,
    })
  );

  const { loading4, subgroupsData, getGroupsData } = useSelector(selector);

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Name</span>,
      selector: (row: { sub_group_name: string }) => row.sub_group_name,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Company Name</span>,
      selector: (row: { sub_group_company_name: string }) =>
        row.sub_group_company_name,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      selector: (row: { status: string }) => {
        switch (row.status) {
          case "Active":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-success text-success"
                style={{
                  width: "80px",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                Active
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-danger text-danger"
                style={{
                  width: "80px",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                {capitalizeString(String(row.status) || "Inactive")}
              </span>
            );
        }
      },
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Created Date</span>,
      selector: (row: { created_datetime: string }) => (
        <span className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.created_datetime)}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Action</span>,
      cell: (row: { sub_group_id: string }) => {
        return (
          <>
            <i
              className="ri-eye-fill align-middle me-3 text-muted fs-xl"
              onClick={() =>
                props.router.navigate(
                  `/settings/groups/${props?.router?.params?.groupId}/subgroups/${row.sub_group_id}`
                )
              }
            />
            <i
              className="ri-pencil-fill align-middle text-muted fs-xl"
              onClick={() =>
                props.router.navigate(
                  `/settings/groups/${props?.router?.params?.groupId}/subgroups/${row.sub_group_id}/edit`
                )
              }
            />
            {/* <i className="ri-delete-bin-fill align-middle text-muted fs-xl" /> */}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (
      props?.router?.params?.groupId &&
      props?.router?.params?.groupId !== "undefined"
    ) {
      handleGetData(currPage, rowsPerPage);
    }
  }, [props?.router?.params?.groupId]);

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(
      getSubgroupListThunk(page, perPage, {
        groupId: props?.router?.params?.groupId,
        ...otherParams,
      })
    );
  };

  const handleRowClick = (row: { sub_group_id: string }) => {
    props.router.navigate(
      `/settings/groups/${props?.router?.params?.groupId}/subgroups/${row.sub_group_id}`
    );
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(page, rowsPerPage);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(1, rows);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={`${getGroupsData?.records?.[0]?.group_name || ""} ${
            getGroupsData?.records?.[0]?.group_id
              ? `(${getGroupsData?.records?.[0]?.group_id})`
              : ""
          }`}
          isCreate={
            props?.router?.params?.groupId &&
            props?.router?.params?.groupId !== "undefined"
              ? true
              : false
          }
          createLabel="Create Sub Group"
          createLink={`/settings/groups/${props?.router?.params?.groupId}/subgroups/create`}
        />

        <div className="position-relative">
          <Card className="wrapper">
            <Card.Body className="listing-table">
              {getGroupsData?.records?.[0]?.group_id &&
              props?.router?.params?.groupId !== "undefined" ? (
                loading4 ? (
                  <div className={``}>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                      alt="loading"
                    />
                  </div>
                ) : (
                  <Datatable
                    data={
                      Array.isArray(subgroupsData?.records)
                        ? subgroupsData?.records
                        : []
                    }
                    columns={columns}
                    handleRowClick={handleRowClick}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    currPage={currPage}
                    rowsPerPage={rowsPerPage}
                    totalRecords={subgroupsData?.totalRecords}
                  />
                )
              ) : (
                <div className="d-flex flex-column align-items-center justify-content-center text-center h-100">
                  <div className="">
                    <img src={maintain} alt="" height={100} />
                  </div>
                  <div className="mt-4 pt-3">
                    <h5>Please complete profile to see sub groups.</h5>
                    <Link to={"/settings/general"}>Go to profile page</Link>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(SubGroupsList);
