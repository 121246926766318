import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getSubgroupDetailsThunk } from "slices/thunk";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import Loader from "assets/images/spinner-dark.svg";
import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";

const SubGroupDetails = (props: any) => {
  document.title = "Signal House Portal Sub Group Details";

  const { subGroupId } = useParams();
  const dispatch = useDispatch<any>();

  const selectAccount = createSelector(
    (state: any) => state.Groups,
    (groups) => ({
      loading3: groups.loading3,
      subgroupDetails: groups.subgroupDetails,
      error: groups.error,
    })
  );

  const { loading3, subgroupDetails } = useSelector(selectAccount);

  useEffect(() => {
    subGroupId && dispatch(getSubgroupDetailsThunk(subGroupId));
  }, [subGroupId]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Sub Group"
            isBack={true}
            backClick={() => props.router?.navigate(-1)}
          />
          <div className="position-relative wrapper">
            {loading3 ? (
              <div className={``}>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                  alt="loading"
                />
              </div>
            ) : (
              <Card>
                <Card.Body className="">
                  {subgroupDetails?.data?.length > 0 ? (
                    <>
                      <div className="mb-1 ">
                        <h5>Group details</h5>
                      </div>
                      <Col lg={12} className="mt-3">
                        <Row className="border bg-light-gray rounded-3 p-3">
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Group Id:</h6>
                            <p className="mb-0">
                              {subgroupDetails?.data?.[0]?.group_id || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Sub Group Id:</h6>
                            <p className="mb-0">
                              {subgroupDetails?.data?.[0]?.sub_group_id || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Sub Group Name:</h6>
                            <p className="mb-0">
                              {subgroupDetails?.data?.[0]?.sub_group_name ||
                                "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Sub Group Description:</h6>
                            <p className="mb-0">
                              {subgroupDetails?.data?.[0]
                                ?.sub_group_description || "-"}
                            </p>
                          </Col>
                          <Col md={6}>
                            <h6 className="mb-1">Status:</h6>
                            {subgroupDetails?.data?.[0]?.status === "Active" ? (
                              <span className="badge bg-success-subtle text-success fs-sm">
                                Active
                              </span>
                            ) : (
                              <span className="badge bg-danger-subtle text-danger fs-sm">
                                Inactive
                              </span>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </>
                  ) : (
                    <p className="m-0">No Record Found</p>
                  )}
                </Card.Body>
              </Card>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(SubGroupDetails);
