import moment from "moment";

export const getInitials = function (string: string) {
  const names = string ? string.split(" ") : ["U"];
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const capitalizeString = (string: string) => {
  const words = string ? string.toLowerCase().split(" ") : [];

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }

  return words.join(" ");
};

export const humanizeString = (string: string) => {
  if (string) {
    const words = string.toLowerCase().split("_");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words.join(" ");
  }
  return "";
};

export const removeEmptyAndNullValues = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      delete obj[key];
    }
  });
  return obj;
};

export const getChangedValues = (initialValues: any, changedValues: any) => {
  const changedFields: any = {};

  for (const key in changedValues) {
    if (
      initialValues.hasOwnProperty(key) &&
      initialValues[key] !== changedValues[key]
    ) {
      changedFields[key] = changedValues[key];
    }
  }

  return changedFields;
};

export const removeKeyFromObj = (obj: any, keysToRemove: string[]): any => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => removeKeyFromObj(item, keysToRemove));
  }

  return Object.keys(obj).reduce((acc: any, key: string) => {
    if (!keysToRemove.includes(key)) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        acc[key] = removeKeyFromObj(obj[key], keysToRemove);
      } else {
        acc[key] = obj[key];
      }
    }
    return acc;
  }, {});
};

export const flattenObj = (ob: any) => {
  let result: any = {};

  for (const i in ob) {
    if (typeof ob[i] === "object" && ob[i] !== null && !Array.isArray(ob[i])) {
      const temp = flattenObj(ob[i]);
      for (const j in temp) {
        result[i + "." + j] = temp[j];
      }
    } else {
      result[i] = ob[i];
    }
  }

  return result;
};

export const cleanAndCapitalizeWithSpaces = (str: any) => {
  // ex. str => event.fld-val-list.reason_description
  str = str.charAt(0).toUpperCase() + str.slice(1);
  return str.replace(/[-._](\w)/g, (_: any, c: any) => " " + c.toUpperCase());
};

export const cleanAndCapitalize = (str: any) => {
  return str.replace(/[-._](\w)/g, (_: any, c: any) => c.toUpperCase());
};

export const camelToFlat = (camel: any) => {
  let flat = "";
  const camelCase = camel.replace(/([a-z])([A-Z])/g, "$1 $2").split(" ");
  camelCase.forEach((word: any) => {
    flat = flat + word.charAt(0).toUpperCase() + word.slice(1) + " ";
  });
  return flat;
};

export const dateFormat = (date: number | string, format?: string) => {
  return date ? moment(date).format(format || "MM/DD/YYYY") : "-";
};

export const getCurrentDateTime = (format?: string) => {
  return moment().format(format || "MM-DD-YYYY, HH:mm:ss");
};

export const customSelectStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: state.isDisabled
      ? "var(--tb-tertiary-bg)"
      : "var(--tb-secondary-bg)",
    color: state.isDisabled
      ? "var(--tb-tertiary-color)"
      : "var(--tb-body-color)",
    boxShadow: "none",
    border: state.isFocused
      ? "1px solid var(--tb-primary-border-subtle)"
      : "var(--tb-border-width) solid var(--tb-border-color-translucent)",
    outline: "none",
    "&:hover": {
      border: "1px solid var(--tb-primary-border-subtle)",
    },
  }),
  input: (base: any) => ({
    ...base,
    color: "var(--tb-body-color)",
  }),
  singleValue: (base: any) => ({
    ...base,
    color: "var(--tb-body-color)",
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    backgroundColor: "var(--tb-border-color-translucent)",
  }),
  menu: (base: any) => ({
    ...base,
    background: "var(--tb-secondary-bg)",
    zIndex: 6,
  }),
  option: (base: any) => {
    return {
      ...base,
    };
  },
  multiValue: (styles: any) => {
    return {
      ...styles,
      fontWeight: 600,
      backgroundColor: "var(--tb-primary)",
    };
  },
  multiValueLabel: (styles: any) => ({
    ...styles,
    backgroundColor: "var(--tb-primary)",
    color: "white",
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: "white",
    backgroundColor: "var(--tb-primary)",
    ":hover": {
      backgroundColor: "var(--tb-primary)",
      color: "white",
    },
  }),
};

export const styleObj = (error: any) => ({
  ...customSelectStyles,
  control: (base: any, state: any) => ({
    ...customSelectStyles.control(base, state),
    borderColor: error ? "var(--tb-form-invalid-border-color) !important" : "",
    "&:hover": {
      borderColor: error
        ? "var(--tb-form-invalid-border-color)"
        : "var(--tb-primary-border-subtle)",
    },
  }),
});

export const customSelectTheme = (theme: any) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#e41cfd1f",
    primary: "#e41cfd",
  },
});

export const last12Months = () => {
  const last12Months = [];

  for (let i = 0; i < 12; i++) {
    const month = moment().subtract(i, "months").month();
    const year = moment().subtract(i, "months").year();
    last12Months.push({
      label: moment().month(month).year(year).format("MMM YYYY"),
      value: moment().month(month).year(year).format("MM-YYYY"),
    });
  }

  return last12Months;
};
