import {
  verifiedCallerIDsListApi,
  createVerifiedCallerIDsApi,
  checkVerificationApi,
} from "helpers/apiroute_helper";
import {
  apiLoading,
  verifiedCallerDataSuccessful,
  verifiedCallerDataFailed,
  apiLoading2,
  verifiedCallerCreateSuccessful,
  verifiedCallerCreateFailed,
  checkVerificationSuccessful,
  checkVerificationFailed,
} from "./reducer";
import { toast } from "react-toastify";

export const getVerifiedCallerListThunk =
  (phoneNumber: string | undefined, friendlyName: string | undefined) =>
  async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await verifiedCallerIDsListApi(
        phoneNumber,
        friendlyName
      );

      if (data?.code) {
        dispatch(verifiedCallerDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(verifiedCallerDataSuccessful(data));
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(verifiedCallerDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const createVerifiedCallerThunk =
  (caller: any, cb?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading2());

      let data: any = await createVerifiedCallerIDsApi({
        ...caller,
      });

      if (data?.code || data?.message) {
        dispatch(verifiedCallerCreateFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(verifiedCallerCreateSuccessful(data?.data?.data));
        toast.success(
          data?.status || data?.data?.message || "Caller Created successfully"
        );
        cb && cb();
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(verifiedCallerCreateFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const checkVerificationThunk =
  (caller: any, cb?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading2());

      let data: any = await checkVerificationApi({
        ...caller,
      });

      if (data?.code || data?.message) {
        dispatch(checkVerificationFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(checkVerificationSuccessful(data));
        dispatch(verifiedCallerCreateSuccessful({}));
        toast.success(data?.data?.message || "OTP verified successfully");
        dispatch(getVerifiedCallerListThunk("", ""));
        cb && cb();
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(checkVerificationFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };
