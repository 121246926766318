import React from "react";
import { Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import SimpleBar from "simplebar-react";

const StartMessaging: React.FC = () => {
  const selectAccount = createSelector(
    (state: any) => state.AuthUser,
    (authUser) => ({
      stepsData: authUser.stepsData,
      error: authUser.error,
    })
  );

  const { stepsData } = useSelector(selectAccount);
  const count = Object.values(stepsData).filter((item) => item === true).length;

  return (
    <div>
      <Card>
        <Card.Body className="px-0">
          <SimpleBar
            className="py-0"
            style={{ height: "225px", padding: "0px 19.2px" }}
          >
            <h4 className="list-group-item ps-0">Overview Completion Guide</h4>
            <p className="mt-2 mb-1">
              Unlock the full potential of your system with this comprehensive
              step-by-step guide.
            </p>
            <p className="mt-2">
              {count}/{Object.values(stepsData)?.length} Completed
            </p>
            <div className="form-check pe-none d-flex align-items-center mb-2">
              <Form.Check
                type="checkbox"
                id="buyFirstNumber"
                checked={stepsData?.buyFirstNumber}
                className="homeSteps"
              />
              <p className="form-check-label ms-1 fs-base fw-medium">
                Buy Your First Number
              </p>
            </div>
            <div className="form-check pe-none d-flex align-items-center mb-2">
              <Form.Check
                type="checkbox"
                id="completedProfile"
                checked={stepsData?.completedProfile}
                className="homeSteps"
              />
              <p className="form-check-label ms-1 fs-base fw-medium">
                Complete Profile
              </p>
            </div>
            <div className="form-check pe-none d-flex align-items-center mb-2">
              <Form.Check
                type="checkbox"
                id="makeCall"
                checked={stepsData?.makeCall}
                className="homeSteps"
              />
              <p className="form-check-label ms-1 fs-base fw-medium">
                Make A Call
              </p>
            </div>
            <div className="form-check pe-none d-flex align-items-center">
              <Form.Check
                type="checkbox"
                id="sendFirstSms"
                checked={stepsData?.sendFirstSms}
                className="homeSteps"
              />
              <p className="form-check-label ms-1 fs-base fw-medium">
                Send First Sms
              </p>
            </div>
          </SimpleBar>
        </Card.Body>
      </Card>
    </div>
  );
};

export default StartMessaging;
