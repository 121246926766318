import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import {
  Card,
  Button,
  Row,
  Col,
  Form,
  Spinner,
  Container,
  Dropdown,
  Tab,
  Nav,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useSearchParams } from "react-router-dom";
import Datatable from "../../Common/Datatable";
import { getLookupLogsThunk } from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import Loader from "assets/images/spinner-dark.svg";
import Flatpickr from "react-flatpickr";
import BreadCrumb from "Common/BreadCrumb";
import { dateFormat } from "helpers/common";

const filterInitialState = {
  signalHouseSID: "",
  phoneNumber: "",
  startDate: "",
  endDate: "",
  from: "",
  to: "",
  selectField: "",
  direction: false,
};

const EventsLog = (props: any) => {
  document.title = "Signal House Portal Messaging Logs";
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<any>();

  const [loading, setLoading] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const selectProfile = createSelector(
    (state: any) => state.OwnNumbers,
    (OwnNumbers) => ({
      loading: OwnNumbers.loading,
      loading2: OwnNumbers.loading,
    })
  );

  const serializeFormQuery = (params: any) => {
    return Object.keys(params)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
      )
      .join("&");
  };

  const detailData = useSelector(
    (state: any) => state?.Numbers?.phoneNumberLookupLogs
  );

  const { loading2 } = useSelector(selectProfile);

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Lookup Date</span>,
      selector: (row: { createdDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.createdDate)}
        </span>
      ),
      sortable: true,
      // width: "320px",
    },
    {
      name: <span className="font-weight-bold fs-sm">Phone Number</span>,
      selector: (row: { phoneNumber: number | string }) => (
        <span data-tag="allowRowEvents" className="text-primary">
          {row.phoneNumber}
        </span>
      ),
      sortable: true,
      // width: "320px",
    },
    {
      name: <span className="font-weight-bold fs-sm">Country</span>,
      selector: (row: { lookUpInstance: { country_name: string } }) =>
        row.lookUpInstance?.country_name,
      sortable: true,
      // width: "320px",
    },
    {
      name: <span className="font-weight-bold fs-sm">Carrier</span>,
      selector: (row: { lookUpInstance: { carrier: string } }) =>
        row.lookUpInstance?.carrier,
      sortable: true,
      // width: "320px",
    },
    {
      name: <span className="font-weight-bold fs-sm">Pricing</span>,
      selector: (row: { charges: string }) => `$${row.charges} USD`,
      sortable: true,
      // width: "450px",
    },
  ];

  useEffect(() => {
    setLoading(true);
    handleGetData(currPage, limit);
  }, []);

  const handleGetData = async (page: any, perPage: any, values?: any) => {
    setLoading(true);

    const startDate = values
      ? values.startDate
      : searchParams.get("startDate") ?? "";
    const endDate = values ? values.endDate : searchParams.get("endDate") ?? "";
    const phoneNumber = values
      ? values.phoneNumber
      : searchParams.get("phoneNumber") ?? "";

    const allParams = {
      phoneNumber,
      startDate,
      endDate,
    };
    const otherParams = Object.fromEntries(
      Object.entries(allParams).filter(
        ([_, value]) => value !== undefined && value !== ""
      )
    );
    await dispatch(
      getLookupLogsThunk({ page: page, limit: perPage }, otherParams || {})
    );
    setLoading(false);
  };

  const handleRowClick = (row: { row: any }) => {
    setSelectedRow(row);
    handleShow();
  };

  //pagination for data table
  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(page, limit);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setLimit(rows);
    handleGetData(1, rows);
  };

  return (
    <React.Fragment>
      <div className={"mb-4,page-content"}>
        <Container fluid>
          <BreadCrumb
            title={"Lookup Logs"}
            isFilter={true}
            filterButton={
              <Dropdown
                align="end"
                className="filterDropDown"
                show={dropdownOpen}
                onToggle={() => {
                  if (!datePickerModal) {
                    setDropdownOpen(!dropdownOpen);
                  }
                }}
              >
                <Dropdown.Toggle variant="secondary" className="ms-2">
                  <i className="bx bx-filter-alt me-1"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-md p-4">
                  <Formik
                    initialValues={{
                      startDate: searchParams.get("startDate") ?? "",
                      endDate: searchParams.get("endDate") ?? "",
                      phoneNumber: searchParams.get("phoneNumber") ?? "",
                    }}
                    enableReinitialize
                    onSubmit={(values) => {
                      const queryParams = serializeFormQuery(values);
                      setSearchParams(queryParams);
                      setDropdownOpen(false);
                      handleGetData(1, limit, values);
                    }}
                  >
                    {({
                      handleSubmit,
                      getFieldProps,
                      setFieldValue,
                      resetForm,
                      values,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col md={12} className="mb-3">
                            <Form.Label htmlFor="phoneNumber">
                              Phone Number
                            </Form.Label>
                            <div className="d-flex">
                              <input
                                type="number"
                                placeholder="Search by Phone Number"
                                className="form-control"
                                onChange={(e) =>
                                  setFieldValue("phoneNumber", e.target.value)
                                }
                                value={values.phoneNumber}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6} className="mb-3">
                            <Form.Label htmlFor="startDate">
                              Start Date & Time
                            </Form.Label>
                            <Flatpickr
                              className="form-control"
                              name="startDate"
                              placeholder="Start Date & Time"
                              options={{
                                enableTime: true,
                                onOpen: () => {
                                  setDatePickerModal(true);
                                },
                                onClose: () => {
                                  setDatePickerModal(false);
                                },
                                maxDate: new Date(values.endDate) || "",
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d H:i",
                                onChange: (
                                  value: any,
                                  dateStr: string,
                                  instance: any
                                ) => {
                                  const newValue = !!value
                                    ? new Date(value).toISOString()
                                    : value;

                                  setFieldValue("startDate", newValue);
                                  instance.close();
                                },
                              }}
                              value={values.startDate}
                            />
                          </Col>
                          <Col sm={6} className="mb-3">
                            <Form.Label htmlFor="endDate">
                              End Date & Time
                            </Form.Label>
                            <Flatpickr
                              className="form-control"
                              name="endDate"
                              placeholder="End Date & Time"
                              options={{
                                enableTime: true,
                                onOpen: () => {
                                  setDatePickerModal(true);
                                },
                                onClose: () => {
                                  setDatePickerModal(false);
                                },
                                minDate:
                                  new Date(getFieldProps("startDate").value) ||
                                  "",
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d H:i",
                                onChange: (
                                  value: any,
                                  dateStr: string,
                                  instance: any
                                ) => {
                                  const newValue = !!value
                                    ? new Date(value).toISOString()
                                    : value;
                                  setFieldValue("endDate", newValue);
                                  instance.close();
                                },
                              }}
                              value={values.endDate}
                            />
                          </Col>
                        </Row>
                        <div className="d-flex align-items-center justify-content-end">
                          <Button
                            className="btn btn-secondary me-2"
                            type="button"
                            disabled={loading2}
                            onClick={() => {
                              const queryParams =
                                serializeFormQuery(filterInitialState);
                              setSearchParams(queryParams);
                              resetForm();
                              setFieldValue("startDate", "");
                              setFieldValue("endDate", "");
                              setDropdownOpen(false);
                              handleGetData(
                                currPage,
                                limit,
                                filterInitialState
                              );
                            }}
                          >
                            Clear
                          </Button>
                          <Button
                            className="btn btn-primary"
                            type="submit"
                            disabled={loading2}
                          >
                            {loading2 && (
                              <Spinner size="sm" animation="border" />
                            )}{" "}
                            Apply
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Dropdown.Menu>
              </Dropdown>
            }
          />

          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body className="listing-table">
                {loading ? (
                  <div>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                    />
                  </div>
                ) : (
                  <Datatable
                    data={
                      Array.isArray(detailData?.records)
                        ? detailData?.records
                        : []
                    }
                    columns={columns}
                    handleRowClick={handleRowClick}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    currPage={currPage}
                    rowsPerPage={limit}
                    totalRecords={detailData?.totalRecords}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </Container>
        {selectedRow && (
          <Modal show={showModal} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tab.Container defaultActiveKey="rowData">
                <Nav variant="tabs">
                  {/* <Nav.Item>
                    <Nav.Link eventKey="rowData">Row Data</Nav.Link>
                  </Nav.Item> */}
                </Nav>
                <Row>
                  <Col lg={6}>
                    <div className="p-2">
                      <div className="d-flex gap-2 mt-2">
                        <div className="text-secondary">Country Prefix: </div>
                        <p>
                          {selectedRow?.lookUpInstance?.country_prefix ?? "-"}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="p-2">
                      <div className="d-flex gap-2 mt-2">
                        <div className="text-secondary">Country Code</div>
                        <p>
                          {selectedRow?.lookUpInstance?.country_code ?? "-"}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <div className="p-2">
                      <div className="d-flex gap-2 mt-2">
                        <div className="text-secondary">
                          International Format:{" "}
                        </div>
                        <p>
                          {selectedRow?.lookUpInstance?.international_format ??
                            "-"}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="p-2">
                      <div className="d-flex gap-2 mt-2">
                        <div className="text-secondary">Line Type</div>
                        <p>{selectedRow?.lookUpInstance?.line_type ?? "-"}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <div className="p-2">
                      <div className="d-flex gap-2 mt-2">
                        <div className="text-secondary">Local Format: </div>
                        <p>
                          {selectedRow?.lookUpInstance?.local_format ?? "-"}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="p-2">
                      <div className="d-flex gap-2 mt-2">
                        <div className="text-secondary">Location</div>
                        <p>{selectedRow?.lookUpInstance?.location ?? "-"}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <div className="p-2">
                      <div className="d-flex gap-2 mt-2">
                        <div className="text-secondary">Valid: </div>
                        <p>
                          {selectedRow?.lookUpInstance?.valid
                            ? "true"
                            : "False"}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab.Container>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </React.Fragment>
  );
};

export default EventsLog;
