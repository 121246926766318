import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  loading2: false,
  loading3: false,
  authUser: null,
  success: false,
  successMsg: "",
  error: false,
  errorMsg: "",
  historyRecords: {},
  stepsData: {},
};

const authUserSlice = createSlice({
  name: "auth-user",
  initialState,
  reducers: {
    apiLoading(state) {
      state.loading = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    apiLoading2(state) {
      state.loading2 = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    apiLoading3(state) {
      state.loading3 = true;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    authUserSuccessful(state, action) {
      state.loading = false;
      state.authUser = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    authUserFailed(state, action) {
      state.loading = false;
      state.authUser = null;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    updateAuthUserSuccessful(state, action) {
      state.loading = false;
      state.loading2 = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    updateAuthUserFailed(state, action) {
      state.loading = false;
      state.loading2 = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    authUserLogout(state) {
      state.loading = false;
      state.authUser = null;
      state.success = false;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    authHistorySuccessful(state, action) {
      state.loading3 = false;
      state.historyRecords = action.payload;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    authHistoryFailed(state, action) {
      state.loading3 = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    userStepsDataSuccessful(state, action) {
      state.loading = false;
      state.stepsData = action.payload;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    userStepsDataFailed(state, action) {
      state.loading = false;
      state.stepsData = {};
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
    updateUserStepsSuccessful(state, action) {
      state.loading = false;
      state.loading2 = false;
      state.success = true;
      state.successMsg = "";
      state.error = false;
      state.errorMsg = "";
    },
    updateUserStepsFailed(state, action) {
      state.loading = false;
      state.loading2 = false;
      state.success = false;
      state.successMsg = "";
      state.error = true;
      state.errorMsg = action.payload.message;
    },
  },
});

export const {
  apiLoading,
  apiLoading2,
  apiLoading3,
  authUserSuccessful,
  authUserFailed,
  updateAuthUserSuccessful,
  updateAuthUserFailed,
  authUserLogout,
  authHistorySuccessful,
  authHistoryFailed,
  userStepsDataSuccessful,
  userStepsDataFailed,
  updateUserStepsSuccessful,
  updateUserStepsFailed,
} = authUserSlice.actions;

export default authUserSlice.reducer;
