import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import UsageSummary from "./UsageSummary";
import Revenue from "./revenue";
import Widgets from "./widgets";
import DeliveryRate from "./DeliveryRate";
import LastDays from "./LastDays";
import { removeEmptyAndNullValues } from "helpers/common";
import {
  customSelectStyles,
  customSelectTheme,
  last12Months,
} from "helpers/common";
import Select from "react-select";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  getUsageSummaryThunk,
  getAnalyticsMmsV1Thunk,
  getAnalyticsSmsV1Thunk,
  getSmsStatusLogsThunk,
  getMmsStatusLogsThunk,
} from "slices/thunk";

const selectOpts = [...last12Months()];

const Dashbaord = () => {
  document.title = "Signal House Portal Dashboard";

  const dispatch = useDispatch<any>();
  const [loading, setLoading] = useState(true);
  const [analyticsData, setAnalyticsData] = useState<any>({});
  const [month, setMonth] = useState({
    startDate: moment().subtract(7, "days").startOf("day").format("MM-DD-YYYY"),
    endDate: moment().endOf("day").format("MM-DD-YYYY"),
  });

  const dateRangeArr = [
    {
      label: "24 hours",
      value: "24 hours",
    },
    {
      label: "7 days",
      value: "7 days",
    },
    {
      label: "30 days",
      value: "30 days",
    },
    {
      label: "120 days",
      value: "120 days",
    },
    {
      label: "Custom Month",
      value: "Custom",
    },
  ];

  const [customMonth, setCustomMonth] = useState(null);
  const [dateRangeTemp, setDateRangeTemp] = useState(dateRangeArr[1]);
  const [selectedRange, setSelectedRange] = useState(dateRangeTemp.label);


  const selectAnalytics = createSelector(
    (state: any) => state.Analytics,
    (analytics) => ({
      analyticsAllV1: analytics.analyticsAllV1,
    })
  );

  const { analyticsAllV1 } = useSelector(selectAnalytics);

  const fetchAnalyticsData = (params: any) => {
    const cb = () => {
      dispatch(getAnalyticsMmsV1Thunk(removeEmptyAndNullValues({ ...params })));
    };
    dispatch(
      getAnalyticsSmsV1Thunk(removeEmptyAndNullValues({ ...params }), cb)
    );
  };

  useEffect(() => {
    const params = {
      groupId: "GB6S7D",
      startDate: moment(month.startDate, "MM-DD-YYYY").format("MM-DD-YYYY"),
      endDate: moment(month.endDate, "MM-DD-YYYY").format("MM-DD-YYYY"),
    };
    fetchAnalyticsData({ ...params });
  }, [dispatch, month]);
  

  useEffect(() => {
    if (analyticsAllV1) {
      setAnalyticsData(analyticsAllV1);
      setLoading(false);
    }
  }, [analyticsAllV1]);

  useEffect(() => {
    if (month?.startDate && month?.endDate) {
      const formattedStartDate = moment(month.startDate, "MM-DD-YYYY").format(
        "MM-DD-YYYY"
      );
      const formattedEndDate = moment(month.endDate, "MM-DD-YYYY").format(
        "MM-DD-YYYY"
      );
      dispatch(getUsageSummaryThunk(formattedStartDate, formattedEndDate));
    }
  }, [dispatch, month]);

  useEffect(() => {
    setDateRangeTemp(dateRangeArr[1]);
    setSelectedRange(dateRangeArr[1].label);
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box d-flex justify-content-end gap-3">
            <Col sm={2} className="mb-3 ">
              <Select
                styles={customSelectStyles}
                theme={customSelectTheme}
                options={dateRangeArr}
                onChange={(e: any) => {
                  setDateRangeTemp(e);
                  setSelectedRange(e.label);

                  let startDate = "";
                  let endDate = moment().endOf("day").format("MM-DD-YYYY");

                  if (e?.value === "24 hours") {
                    startDate = moment()
                      .subtract(1, "days")
                      .startOf("day")
                      .format("MM-DD-YYYY");
                    setMonth({ startDate, endDate });
                  } else if (e?.value === "7 days") {
                    startDate = moment()
                      .subtract(7, "days")
                      .startOf("day")
                      .format("MM-DD-YYYY");
                    setMonth({ startDate, endDate });
                  } else if (e?.value === "30 days") {
                    startDate = moment()
                      .subtract(30, "days")
                      .startOf("day")
                      .format("MM-DD-YYYY");
                    setMonth({ startDate, endDate });
                  } else if (e?.value === "120 days") {
                    startDate = moment()
                      .subtract(120, "days")
                      .startOf("day")
                      .format("MM-DD-YYYY");
                    setMonth({ startDate, endDate });
                  } else if (e?.value === "Custom") {
                  }
                }}
                value={dateRangeTemp}
              />
            </Col>
            {dateRangeTemp?.value === "Custom" && (
              <Col sm={2} className="mb-3">
                <Select
                  styles={customSelectStyles}
                  theme={customSelectTheme}
                  options={selectOpts}
                  onChange={(selectedMonth: any) => {
                    if (selectedMonth?.value) {
                      const startDate = moment(
                        selectedMonth.value,
                        "MM-DD-YYYY"
                      )
                        .startOf("month")
                        .format("MM-DD-YYYY");
                      const endDate = moment(selectedMonth.value, "MM-DD-YYYY")
                        .endOf("month")
                        .format("MM-DD-YYYY");

                      setCustomMonth(selectedMonth);
                      setMonth({ startDate, endDate });
                    } else {
                      setMonth({ startDate: "", endDate: "" });
                    }
                  }}
                  value={customMonth}
                  placeholder="Select month"
                />
              </Col>
            )}
          </div>
          <Widgets month={month} selectedRange={selectedRange} />
          <UsageSummary />
          <Row>
            {/* <Revenue /> */}
            <LastDays
            />
            <DeliveryRate
            />
          </Row>
          {/* <Row> */}
          {/* <Events /> */}
          {/* <EmailSent /> */}
          {/* </Row> */}
          <Row>
            {/* <UsersActivity /> */}
            {/* <SummaryTable month={month} /> */}
            {/* <SyncStatus /> */}
            {/* <OpenDeals /> */}
            {/* <TasksList /> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashbaord;
