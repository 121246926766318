import React from "react";
import { Card, Col, OverlayTrigger, Popover, Container } from "react-bootstrap";
import Tooltip from "rc-tooltip";
import Datatable from "../../Common/Datatable";
import Loader from "assets/images/spinner-dark.svg";
import { capitalizeString, dateFormat } from "helpers/common";

const SmsAndMmsLogs = ({
  loading,
  rowsPerPage,
  currPage,
  logsData,
  handleRowClick,
  handlePageChange,
  handleRowsPerPageChange,
}: any) => {
  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Date</span>,
      selector: (row: { CreatedDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.CreatedDate)}
        </span>
      ),
      sortable: true,
      width: "220px",
    },
    {
      name: <span className="font-weight-bold fs-sm">Direction</span>,
      sortable: true,
      selector: (cell: { direction: any }) => (
        <span
          data-tag="allowRowEvents"
          className="badge bg-body-secondary border border-primary text-primary"
        >
          {capitalizeString(cell.direction) || "N/A"}
        </span>
      ),
    },
    {
      name: <span className="font-weight-bold fs-sm">From</span>,
      selector: (row: { sendersPhoneNumber: number | string }) =>
        row.sendersPhoneNumber,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">To</span>,
      selector: (row: { recieversPhoneNumber?: number | string }) => {
        let phoneNumbers = row?.recieversPhoneNumber?.toString();
        let phoneArray = phoneNumbers
          ? phoneNumbers.split(",").map((num) => num.trim())
          : [];
        // Format the output
        let formattedNumbers =
          phoneArray.length > 1
            ? `${phoneArray[0]} and ${phoneArray.length - 1} more`
            : phoneArray[0];

        // Tooltip content
        const tooltipContent = phoneArray.join(", ");

        return (
          <Tooltip placement="top" overlay={tooltipContent}>
            <span className="phone-numbers" style={{ cursor: "pointer" }}>
              {formattedNumbers}
            </span>
          </Tooltip>
        );
      },
      sortable: true,
    },

    {
      name: <span className="font-weight-bold fs-sm">Body</span>,
      selector: (row: { messageBody: string }) => (
        <React.Fragment>
          <div
            className="d-flex align-items-start mb-2"
            style={{ width: 26, height: 14 }}
          >
            <OverlayTrigger
              trigger="hover"
              rootClose
              placement="bottom"
              overlay={
                <Popover id="popover-positioned-bottom">
                  <Popover.Body>
                    <div>
                      <Col>
                        {row.messageBody}
                        <span className="text-danger">*</span>
                      </Col>
                    </div>
                  </Popover.Body>
                </Popover>
              }
            >
              <i className="ri-eye-fill align-middle text-secondary fs-lg cursor-pointer"></i>
            </OverlayTrigger>
          </div>
        </React.Fragment>
      ),
    },
    {
      name: <span className="font-weight-bold fs-sm">Segments</span>,
      selector: (row: { segments?: string }) => row?.segments ?? "-",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      sortable: true,
      selector: (cell: { latestStatus: any }) => {
        switch (cell.latestStatus?.toLowerCase()) {
          case "queued":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-secondary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "enqueued":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-secondary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "dequeued":
            return (
              <span className="d-flex align-items-center text-secondary">
                <span className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2">
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "delivered":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-secondary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "sent":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-black"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-black p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "mo_message_received":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-secondary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus.replace("mo_message_", ""))}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-primary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString("failed")}
              </span>
            );
        }
      },
    },
  ];

  return (
    <div className="position-relative">
      <div className="listing-table">
        {loading ? (
          <div>
            <img
              src={Loader}
              className={`position-absolute top-50 start-50 translate-middle`}
            />
          </div>
        ) : (
          <Datatable
            data={Array.isArray(logsData?.records) ? logsData?.records : []}
            columns={columns}
            handleRowClick={handleRowClick}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            currPage={currPage}
            rowsPerPage={rowsPerPage}
            totalRecords={logsData?.totalRecords}
          />
        )}
      </div>
    </div>
  );
};
export default SmsAndMmsLogs;
