import { Form } from "react-bootstrap";
import Radiobox from "Common/Radiobox";

export default function Step3({ validation3, setActiveStep }: any) {
  return (
    <div className="p-5 p-lg-3 p-xxl-5">
      <div className="text-center">
        <p className="text-muted fs-xl mb-3">2/3</p>
        <h5 className="fs-3xl">
          Do you have existing{" "}
          <span className="text-primary">Campaigns to Migrate?</span>
        </h5>
      </div>

      <div className="mt-5">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation3.handleSubmit();
            return false;
          }}
        >
          <div>
            {validation3.touched.isCampaignsToMigrate &&
            validation3.errors.isCampaignsToMigrate ? (
              <p className="text-danger mb-3">
                {validation3.errors.isCampaignsToMigrate}
              </p>
            ) : null}
          </div>

          <div className="d-flex flex-column align-items-center">
            <div className="mb-3">
              <Radiobox
                label="Yes!"
                id="Yes"
                name="isCampaignsToMigrate"
                value="Yes"
                checked={validation3?.values?.isCampaignsToMigrate === "Yes"}
                onChange={(e: { target: { value: string } }) =>
                  validation3?.setFieldValue(
                    "isCampaignsToMigrate",
                    e.target.value
                  )
                }
              />
            </div>
          </div>

          <div className="d-flex flex-column align-items-center">
            {validation3.values.isCampaignsToMigrate === "Yes" ? (
              <>
                <div className="mb-3 w-100">
                  <Form.Label>
                    Approximately how many campaigns?{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    id="campaignsNumber"
                    name="campaignsNumber"
                    placeholder="Enter number of campaigns"
                    onChange={validation3.handleChange}
                    onBlur={validation3.handleBlur}
                    value={validation3.values.campaignsNumber || ""}
                    isInvalid={
                      validation3.touched.campaignsNumber &&
                      validation3.errors.campaignsNumber
                        ? true
                        : false
                    }
                  />
                  {validation3.touched.campaignsNumber &&
                  validation3.errors.campaignsNumber ? (
                    <Form.Control.Feedback type="invalid">
                      {validation3.errors.campaignsNumber}
                    </Form.Control.Feedback>
                  ) : null}
                </div>
                <div className="mb-3 w-100">
                  <Form.Label>
                    Who is the current Connectivity Partner?{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="currentConnectivityPartner"
                    name="currentConnectivityPartner"
                    placeholder="Enter current connectivity partner"
                    onChange={validation3.handleChange}
                    onBlur={validation3.handleBlur}
                    value={validation3.values.currentConnectivityPartner || ""}
                    isInvalid={
                      validation3.touched.currentConnectivityPartner &&
                      validation3.errors.currentConnectivityPartner
                        ? true
                        : false
                    }
                  />
                  {validation3.touched.currentConnectivityPartner &&
                  validation3.errors.currentConnectivityPartner ? (
                    <Form.Control.Feedback type="invalid">
                      {validation3.errors.currentConnectivityPartner}
                    </Form.Control.Feedback>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>

          <div className="d-flex flex-column align-items-center mb-3">
            <Radiobox
              label="Nope"
              id="No"
              name="isCampaignsToMigrate"
              value="No"
              checked={validation3?.values?.isCampaignsToMigrate === "No"}
              onChange={(e: { target: { value: string } }) =>
                validation3?.setFieldValue(
                  "isCampaignsToMigrate",
                  e.target.value
                )
              }
            />
          </div>

          <div className="d-flex align-items-start justify-content-center gap-3 mt-4">
            <button
              type="button"
              className="btn btn-link text-decoration-none btn-label previestab"
              onClick={() => {
                setActiveStep(2);
              }}
            >
              <i className="ri-arrow-left-line label-icon align-middle fs-lg"></i>
              Prev
            </button>
            <button
              type="submit"
              className="btn btn-secondary btn-label right nexttab nexttab"
            >
              <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>
              Next
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
}
