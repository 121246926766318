interface RadioboxProps {
  id?: string;
  label?: string;
  name?: string;
  value?: string;
  checked?: boolean;
  onChange?: any;
  disabled?: boolean;
}

const Radiobox: React.FC<RadioboxProps> = ({
  id,
  label,
  name,
  value,
  checked = false,
  onChange,
  disabled = false,
}) => {
  return (
    <div className="form-check mb-2">
      <input
        className="form-check-input"
        type="radio"
        id={id}
        name={name}
        checked={checked}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default Radiobox;
