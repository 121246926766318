import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import Loader from "assets/images/spinner-dark.svg";
import {
  capitalizeString,
  customSelectTheme,
  dateFormat,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import Tooltip from "rc-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getFileThunk, getPortRequestListThunk } from "slices/thunk";
import Datatable from "../../Common/Datatable";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  brandListApi,
  campaignListApi,
  subgroupListApi,
} from "helpers/apiroute_helper";

const initialFilterValues = {
  portingId: "",
  startDate: "",
  endDate: "",
  brandId: "",
  campaignId: "",
  groupId: "",
  subGroupId: "",
};

const PortRequests = (props: any) => {
  document.title = "Signal House Portal Port Requests";
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [subgroupTemp, setSubgroupTemp] = useState<any>({});
  const [iconDisable, setIconDisable] = useState(false);

  const selector = createSelector(
    (state: any) => state.Ports,
    (state: any) => state.Groups,
    (ports, groups) => ({
      loading: ports.loading,
      portRequestsData: ports.portRequestsArr,
      error: ports.error,
      groupDetails: groups?.AllGroups,
    })
  );

  const { loading, portRequestsData, groupDetails } = useSelector(selector);

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Porting ID</span>,

      selector: (row: { portingId: string }) => (
        <Tooltip
          placement="top"
          overlay={<p className="mb-0">{row.portingId}</p>}
        >
          <div onClick={() => handleRowClick(row)}>{row.portingId}</div>
        </Tooltip>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Owners Name</span>,
      selector: (row: { ownersName: string }) => row.ownersName || "-",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Requested Date</span>,
      selector: (row: { createdDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.createdDate)}
        </span>
      ),
      sortable: true,
      width: "220px",
    },
    {
      name: <span className="font-weight-bold fs-sm">LOA</span>,
      selector: (row: { letterOfAuthorization: string }) =>
        row.letterOfAuthorization ? (
          <i
            className={`ri-eye-fill ${
              iconDisable ? "cursor-not-allowed" : "cursor-pointer"
            } fs-2xl text-secondary`}
            onClick={() =>
              iconDisable ? {} : handleViewLoa(row.letterOfAuthorization)
            }
          />
        ) : (
          "-"
        ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      sortable: true,
      selector: (cell: { portingStatus: string }) => {
        switch (cell.portingStatus.toLowerCase()) {
          case "pending":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-danger text-danger"
              >
                {capitalizeString(cell.portingStatus)}
              </span>
            );
          case "complete":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-success text-success"
              >
                {capitalizeString(cell.portingStatus)}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-warning text-warning"
              >
                {capitalizeString(cell.portingStatus)}
              </span>
            );
        }
      },
    },
  ];

  const handleRowClick = (row: { portingId: string }) => {
    navigate(`/porting-status/${row.portingId}`);
  };

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: initialFilterValues,
    onSubmit: (values) => {
      const temp = removeEmptyAndNullValues({ ...values });
      handleGetData(currPage, rowsPerPage, temp);
    },
  });

  useEffect(() => {
    handleGetData(currPage, rowsPerPage);
  }, []);

  useEffect(() => {
    if (groupDetails?.records?.[0]?.group_id) {
      validation.setFieldValue("groupId", groupDetails?.records?.[0]?.group_id);
    }
  }, [groupDetails?.records?.[0]?.group_id]);

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(getPortRequestListThunk(page, perPage, otherParams || {}));
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(page, rowsPerPage);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(1, rows);
  };

  const setDropdown = () => setOpen(!open);

  const handleViewLoa = (loadId: string) => {
    setIconDisable(true);
    const cb = (img: any) => {
      const temp2 = URL.createObjectURL(img);
      window.open(temp2, "_blank");
    };
    dispatch(getFileThunk(loadId, cb)).then(() => {
      setIconDisable(false);
    });
  };

  const loadOptions1 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await subgroupListApi(page, 10, {
      groupId: validation.values.groupId || "",
    });

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const loadOptions2 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await brandListApi(
      page,
      10,
      removeEmptyAndNullValues({
        groupId: validation.values.groupId || "",
        subGroupId: validation.values.subGroupId || "",
        brandId: searchQuery.toUpperCase() || "",
      })
    );

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const loadOptions3 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await campaignListApi(
      page,
      10,
      removeEmptyAndNullValues({
        groupId: validation.values.groupId || "",
        subGroupId: validation.values.subGroupId || "",
        brandId: validation.values.brandId || "",
        campaignId: searchQuery.toUpperCase() || "",
      })
    );

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  return (
    <Container fluid>
      <BreadCrumb
        title="Porting Requests"
        isFilter={true}
        filterButton={
          <Dropdown
            align="end"
            className="filterDropDown"
            show={open}
            onToggle={setDropdown}
          >
            <Dropdown.Toggle variant="secondary">
              <i className="bx bx-filter-alt me-1"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-md p-4">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  setDropdown();
                  validation.handleSubmit();
                }}
              >
                <Row>
                  <Col sm={12} className="mb-3">
                    <Form.Label htmlFor="portingId">Porting ID</Form.Label>
                    <Form.Control
                      type="text"
                      name="portingId"
                      placeholder="Enter Porting ID"
                      onChange={(e) => {
                        validation.setFieldValue("portingId", e.target.value);
                      }}
                      value={validation.values.portingId}
                    />
                  </Col>

                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="startDate">
                      Start Date & Time
                    </Form.Label>
                    <Flatpickr
                      className="form-control"
                      name="startDate"
                      placeholder="Start Date & Time"
                      options={{
                        enableTime: true,
                        maxDate: validation.values.endDate || "",
                        altFormat: "F j, Y",
                        dateFormat: "Y-m-d H:i",
                        onChange: (value: any, dateStr: any, instance: any) => {
                          const newValue = value.length
                            ? new Date(value).toISOString()
                            : "";
                          validation.setFieldValue("startDate", newValue);
                          instance.close();
                        },
                      }}
                      value={validation.getFieldProps("startDate").value}
                    />
                  </Col>

                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="endDate">End Date & Time</Form.Label>
                    <Flatpickr
                      className="form-control"
                      name="endDate"
                      placeholder="End Date & Time"
                      options={{
                        enableTime: true,
                        minDate: validation.values.startDate || "",
                        altFormat: "F j, Y",
                        dateFormat: "Y-m-d H:i",
                        onChange: (value: any, dateStr: any, instance: any) => {
                          const newValue = value.length
                            ? new Date(value).toISOString()
                            : "";
                          validation.setFieldValue("endDate", newValue);
                          instance.close();
                        },
                      }}
                      value={validation.getFieldProps("endDate").value}
                    />
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="subGroupId">Sub Group</Form.Label>
                    <AsyncPaginate
                      isDisabled={!validation.values.groupId}
                      key={validation.values.groupId}
                      isClearable={true}
                      styles={styleObj(
                        validation?.touched?.subGroupId &&
                          validation?.errors?.subGroupId
                      )}
                      theme={customSelectTheme}
                      value={subgroupTemp || {}}
                      loadOptions={loadOptions1}
                      getOptionValue={(option: any) => option?.sub_group_id}
                      getOptionLabel={(option: any) => option?.sub_group_name}
                      onChange={(option: any) => {
                        if (option) {
                          setSubgroupTemp(option);
                          validation.setFieldValue(
                            "subGroupId",
                            option?.sub_group_id
                          );
                          validation.setFieldValue("brandId", "");
                          validation.setFieldValue("campaignId", "");
                        } else {
                          setSubgroupTemp({});
                          validation.setFieldValue("subGroupId", "");
                        }
                      }}
                      isSearchable={true}
                      additional={{
                        page: 1,
                      }}
                    />
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="brandId">Brand</Form.Label>
                    <AsyncPaginate
                      key={
                        validation.values.subGroupId ||
                        validation.values.groupId ||
                        "brandId"
                      }
                      isClearable={true}
                      styles={styleObj(
                        validation?.touched?.brandId &&
                          validation?.errors?.brandId
                      )}
                      theme={customSelectTheme}
                      value={
                        validation.values.brandId
                          ? { brandId: validation.values.brandId }
                          : null || {}
                      }
                      loadOptions={loadOptions2}
                      getOptionValue={(option: any) => option?.brandId}
                      getOptionLabel={(option: any) => option?.brandId}
                      onChange={(option: any) => {
                        if (option) {
                          validation.setFieldValue("brandId", option.brandId);
                          validation.setFieldValue("campaignId", "");
                        } else {
                          validation.setFieldValue("brandId", "");
                        }
                      }}
                      isSearchable={true}
                      additional={{
                        page: 1,
                      }}
                    />
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="campaignId">Campaign</Form.Label>
                    <AsyncPaginate
                      key={
                        validation.values.brandId ||
                        validation.values.subGroupId ||
                        validation.values.groupId ||
                        "campaignId"
                      }
                      isClearable={true}
                      styles={styleObj(
                        validation?.touched?.campaignId &&
                          validation?.errors?.campaignId
                      )}
                      theme={customSelectTheme}
                      value={
                        validation.values.campaignId
                          ? { campaignId: validation.values.campaignId }
                          : null || {}
                      }
                      loadOptions={loadOptions3}
                      getOptionValue={(option: any) => option?.campaignId}
                      getOptionLabel={(option: any) => option?.campaignId}
                      onChange={(option: any) => {
                        if (option) {
                          validation.setFieldValue(
                            "campaignId",
                            option.campaignId
                          );
                        } else {
                          validation.setFieldValue("campaignId", "");
                        }
                      }}
                      isSearchable={true}
                      additional={{
                        page: 1,
                      }}
                    />
                  </Col>
                </Row>
                <div className="d-flex align-items-center justify-content-end">
                  <Button
                    className="btn btn-secondary me-2"
                    type="button"
                    disabled={loading}
                    onClick={() => {
                      validation.setValues(initialFilterValues);
                      validation.resetForm();
                      validation.setFieldValue(
                        "groupId",
                        groupDetails?.records?.[0]?.group_id
                      );
                      setSubgroupTemp({});
                      handleGetData(currPage, rowsPerPage);
                    }}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Clear
                  </Button>
                  <Button
                    className="btn btn-primary"
                    type="submit"
                    disabled={loading}
                  >
                    {loading && <Spinner size="sm" animation="border" />} Apply
                  </Button>
                </div>
              </Form>
            </Dropdown.Menu>
          </Dropdown>
        }
        isCreate={true}
        createLabel="New Port Request"
        createLink="/port-numbers/new-porting-requests"
      />
      <div className="position-relative">
        <Card className="wrapper2">
          <Card.Body className="listing-table3">
            {loading ? (
              <div className={``}>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                  alt="loading"
                />
              </div>
            ) : (
              <Datatable
                data={
                  Array.isArray(portRequestsData?.records)
                    ? portRequestsData?.records
                    : []
                }
                columns={columns}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                currPage={currPage}
                rowsPerPage={rowsPerPage}
                totalRecords={portRequestsData?.totalRecords}
              />
            )}
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

export default withRouter(PortRequests);
