import React, { useEffect, useState } from "react";
import {
  Container,
  Form as BootstrapForm,
  Button,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { createSelector } from "reselect";
import Select, { components } from "react-select";
import Switch from "react-bootstrap/Switch";
import BreadCrumb from "Common/BreadCrumb";
import {
  getWebhookDetailsByIdThunk,
  createWebhookThunk,
  getWebhookEventsThunk,
  deleteWebhookThunk,
  editWebhookThunk,
} from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "Common/withRouter";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "assets/images/spinner-dark.svg";
import * as Yup from "yup";
import { styleObj } from "helpers/common";

const validationSchema = Yup.object().shape({
  webhookName: Yup.string().required("Webhook Name is required"),
  webhookUrl: Yup.string()
    .url("Invalid URL")
    .required("Webhook URL is required"),
  events: Yup.array()
    .min(1, "At least one event must be selected")
    .required("Events are required"),
  description: Yup.string()
    .min(40, "Description must be at least 40 words")
    .required("Description is required"),
});

const CreateNewWebhook = (props: any) => {
  document.title = "Signal House Portal Create Webhook";
  const dispatch = useDispatch<any>();
  const selector = createSelector(
    (state: any) => state.Webhooks,
    (Webhooks: any) => ({
      loading: Webhooks.loading,
    })
  );

  const { loading } = useSelector(selector);
  const navigate = useNavigate();
  const { webhookId } = useParams();
  const [webhookDetails, setWebhookDetails] = useState<any>({});
  const [showModal, setShowModal] = useState(false);
  const [initialValues, setInitialValues] = useState<any>({
    webhookName: "",
    webhookUrl: "",
    events: [],
    description: "",
  });
  const [status, setStatus] = useState<boolean>(false);
  const events = useSelector(
    (state: any) => state?.Webhooks?.webhookEvents?.events
  );
  const eventOptions = events ? transformEventOptions(events) : [];

  const handleDeleteWebhook = () => {
    dispatch(deleteWebhookThunk(webhookId || "")).then(() =>
      navigate("/settings/webhooks")
    );
  };

  const getWebhookDetails = async () => {
    if (!webhookId) {
      return;
    }
    const response = await dispatch(getWebhookDetailsByIdThunk(webhookId));
    setWebhookDetails({ ...response });
    if (response) {
      setInitialValues({
        webhookName: response.webhookName,
        webhookUrl: response.webhookUrl,
        events: [],
        description: response.description,
      });
      setStatus(response.disable);
    }
  };

  useEffect(() => {
    dispatch(getWebhookEventsThunk());
    getWebhookDetails();
  }, [dispatch, webhookId]);

  useEffect(() => {
    if (
      events &&
      webhookDetails?.events?.length &&
      initialValues?.events?.length === 0
    ) {
      const updatedEvents = [] as any;
      webhookDetails.events.map((data: any) => {
        let selectedEvent;
        eventOptions.map((eventOpt) => {
          selectedEvent = eventOpt.options.find(
            (x: any) => x.value === data.eventType
          );
          if (selectedEvent) {
            updatedEvents.push(selectedEvent);
          }
        });
      });

      setInitialValues((pre: any) => ({ ...pre, events: updatedEvents }));
    }
  }, [events, webhookDetails]);

  const handleSubmit = (values: any) => {
    const transformedValues = {
      ...values,
      events: values.events.map((event: any) => event.value),
    };

    if (webhookId) {
      dispatch(
        editWebhookThunk(webhookId, { disable: status, ...transformedValues })
      );
    } else {
      dispatch(createWebhookThunk(transformedValues));
    }
    navigate("/settings/webhooks");
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const confirmDelete = () => {
    handleDeleteWebhook();
    handleClose();
  };

  // Custom Option component
  const CustomOption = (props: any) => {
    return (
      <components.Option {...props}>
        <div>
          <div>{props.data.value}</div>
          <div style={{ fontSize: "0.8em", color: "#6c757d" }}>
            {props.data.description}
          </div>
        </div>
      </components.Option>
    );
  };

  // Custom SingleValue component
  const CustomSingleValue = (props: any) => {
    return (
      <components.SingleValue {...props}>
        <div>
          <div>{props.data.value}</div>
          <div style={{ fontSize: "0.8em", color: "#6c757d" }}>
            {props.data.description}
          </div>
        </div>
      </components.SingleValue>
    );
  };

  const characterCount = initialValues.description?.length;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={webhookId ? "Edit Webhook" : "Create New Webhook"}
            isBack={true}
            backClick={() => props.router.navigate("/settings/webhooks")}
          />

          <Card>
            <Card.Body style={{ minHeight: "500px" }}>
              {loading ? (
                <div className={``}>
                  <img
                    src={Loader}
                    alt="loading..."
                    className={`position-absolute top-50 start-50 translate-middle`}
                  />
                </div>
              ) : (
                <Formik
                  initialValues={initialValues}
                  enableReinitialize
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ setFieldValue, values, touched, errors }) => {
                    return (
                      <Form>
                        <BootstrapForm.Group
                          as={Row}
                          className="mb-3"
                          controlId="webhookName"
                        >
                          <BootstrapForm.Label column sm="2">
                            Webhook Name
                          </BootstrapForm.Label>
                          <Col sm="10">
                            <Field
                              name="webhookName"
                              className="form-control"
                              type="text"
                            />
                            <ErrorMessage
                              name="webhookName"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                        </BootstrapForm.Group>

                        <BootstrapForm.Group
                          as={Row}
                          className="mb-3"
                          controlId="webhookUrl"
                        >
                          <BootstrapForm.Label column sm="2">
                            Webhook URL
                          </BootstrapForm.Label>
                          <Col sm="10">
                            <Field
                              name="webhookUrl"
                              className="form-control"
                              type="text"
                            />
                            <ErrorMessage
                              name="webhookUrl"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                        </BootstrapForm.Group>

                        <BootstrapForm.Group
                          as={Row}
                          className="mb-3"
                          controlId="events"
                        >
                          <BootstrapForm.Label column sm="2">
                            Events
                          </BootstrapForm.Label>
                          <Col sm="10">
                            <Select
                              options={eventOptions}
                              name="events"
                              value={values.events}
                              isMulti
                              styles={styleObj(
                                touched?.events && errors?.events
                              )}
                              components={{
                                Option: CustomOption,
                                SingleValue: CustomSingleValue,
                              }}
                              onChange={(option: any) =>
                                setFieldValue("events", option)
                              }
                            />
                            <ErrorMessage
                              name="events"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                        </BootstrapForm.Group>

                        <BootstrapForm.Group
                          as={Row}
                          className="mb-3"
                          controlId="description"
                        >
                          <BootstrapForm.Label column sm="2">
                            Description
                          </BootstrapForm.Label>
                          <Col sm="10">
                            <Field
                              name="description"
                              as="textarea"
                              className="form-control"
                              rows="5"
                              onChange={(
                                e: React.ChangeEvent<HTMLTextAreaElement>
                              ) => {
                                const value = e.target.value;
                                setFieldValue("description", value);
                              }}
                            />
                            {/* <div className="text-end">
                              {characterCount} characters
                            </div> */}
                            <ErrorMessage
                              name="description"
                              component="div"
                              className="text-danger"
                            />
                          </Col>
                        </BootstrapForm.Group>

                        {webhookId && (
                          <BootstrapForm.Group
                            as={Row}
                            className="mb-3"
                            controlId="description"
                          >
                            <BootstrapForm.Label column sm="2">
                              Status Disable
                            </BootstrapForm.Label>
                            <Col sm="10" className="d-flex">
                              <div className="d-flex align-items-center">
                                <Switch
                                  id="mediaTypeSwitch"
                                  checked={status}
                                  onChange={() => {
                                    setStatus((pre) => !pre);
                                  }}
                                />
                              </div>
                            </Col>
                          </BootstrapForm.Group>
                        )}
                        <div className="d-flex">
                          <div>
                            {webhookId && (
                              <Button
                                variant="danger"
                                onClick={handleShow}
                                style={{ marginRight: "20px" }}
                              >
                                Delete
                              </Button>
                            )}

                            <Modal show={showModal} onHide={handleClose}>
                              <Modal.Header closeButton>
                                <Modal.Title>Confirm Delete</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <p>
                                  Are you sure you want to delete this webhook?
                                  This action is irrevocable.
                                </p>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                                <Button
                                  variant="danger"
                                  onClick={confirmDelete}
                                >
                                  Delete
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </div>

                          <div>
                            <Button variant="secondary" type="submit">
                              Submit
                            </Button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CreateNewWebhook);

// Helper function to transform event options
const transformEventOptions = (events: any) => {
  return Object.keys(events).map((key) => ({
    label: key,
    options: events[key].map((event: any) => ({
      value: event.eventType,
      label: event.eventType,
      description: event.description,
    })),
  }));
};
