import React, { useEffect, useState } from "react";
import {
  Container,
  Dropdown,
  Card,
  Modal,
  Button,
  Row,
  Col,
  Spinner,
  Form,
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
// import { createSelector } from "reselect";
import { findTemplateThunk, deleteTemplateThunk } from "slices/thunk";
import Datatable from "../../Common/Datatable";
import {
  // capitalizeString,
  removeEmptyAndNullValues,
} from "helpers/common";
import Loader from "assets/images/spinner-dark.svg";
import withRouter from "Common/withRouter";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

const ListMessagingTamplets = (props: any) => {
  document.title = "Signal House Portal Messaage Tamplets List";
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const MessagingData = useSelector((state: any) => state.Messages);
  const loading = useSelector((state: any) => state.loading);

  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    dispatch(
      findTemplateThunk({ page: currentPage, pageSize, templateName: "" })
    );
  }, [currentPage, pageSize]);

  const handleRowClick = (row: { templateId: string }) => {
    props.router.navigate(`/message-template/${row.templateId}`);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Created Date</span>,
      selector: (row: { CreatedDate: string }) => (
        <span className="d-flex align-items-center">
          <i className="bi bi-calendar3 me-2 fs-lg text-secondary" />
          {row.CreatedDate}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Template Name</span>,
      selector: (row: { templateName: string }) => row.templateName,
      sortable: true,
    },
    // {
    //   name: <span className="font-weight-bold fs-sm">Template Message</span>,
    //   selector: (row: { templateMessage: string }) => capitalizeString(row.templateMessage),
    //   sortable: true,
    // },
    {
      name: <span className="font-weight-bold fs-sm">Action</span>,
      cell: (cell: { templateId: string }) => {
        return (
          <>
            <i
              className="ri-pencil-fill align-middle text-muted fs-xl"
              onClick={() => navigate(`/message-template/${cell.templateId}`)}
            />
          </>
        );
      },
    },
  ];

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      templateName: "",
    },

    onSubmit: (values) => {
      const temp = removeEmptyAndNullValues({ ...values });
      handleGetData(currentPage, pageSize, temp);
    },
  });

  // const handleFormSubmit = () => {
  //   const queryParams = {
  //     currentPage,
  //     pageSize,
  //     templateName: validation.values.templateName,
  //   };
  //   dispatch(findTemplateThunk(queryParams));
  // };

  const handleDeleteTemplate = (template_id: string) => {
    dispatch(deleteTemplateThunk(template_id));
  };

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(findTemplateThunk({ page, pageSize, ...otherParams }));
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setPageSize(rows);
    setCurrentPage(1);
  };

  const setDropdown = () => setOpen(!open);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Message Tamplets List"
            isFilter={true}
            filterButton={
              <Dropdown
                align="end"
                className="filterDropDown"
                show={open}
                onToggle={setDropdown}
              >
                <Dropdown.Toggle variant="secondary">
                  <i className="bx bx-filter-alt me-1"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-md p-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      setDropdown();
                      validation.handleSubmit();
                    }}
                  >
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Form.Label htmlFor="templateName">
                            Search Via Template Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="templateName"
                            value={validation.values.templateName}
                            onChange={validation.handleChange}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        className="btn btn-secondary me-2"
                        type="button"
                        disabled={loading}
                        onClick={() => {
                          validation.resetForm();
                          setDropdown();
                          handleGetData(currentPage, pageSize);
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading}
                      >
                        {loading && <Spinner size="sm" animation="border" />}{" "}
                        Apply
                      </Button>
                    </div>
                  </Form>
                </Dropdown.Menu>
              </Dropdown>
            }
            isCreate={true}
            createLabel="Create New Template"
            createLink="/message-template/create"
          />

          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body className="listing-table">
                {MessagingData.loading ? (
                  <div className={``}>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                    />
                  </div>
                ) : (
                  <Datatable
                    data={
                      Array.isArray(MessagingData?.templateList)
                        ? MessagingData?.templateList
                        : []
                    }
                    columns={columns}
                    handlePageChange={handlePageChange}
                    // handleRowClick={handleRowClick}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    currPage={currentPage}
                    rowsPerPage={pageSize}
                    totalRecords={MessagingData?.totalTemplates || 0}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ListMessagingTamplets);
