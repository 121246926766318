import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Card,
  Button,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Spinner,
  Container,
  Dropdown,
  Modal,
} from "react-bootstrap";
import Tooltip from "rc-tooltip";
import { useNavigate, useSearchParams } from "react-router-dom";
import Datatable from "../../Common/Datatable";
import {
  getMessagesThunk,
  getMmsLogsThunk,
  getPersonalMessagesLogsThunk,
  resetMessageLogsList,
} from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import Loader from "assets/images/spinner-dark.svg";
import Flatpickr from "react-flatpickr";
import BreadCrumb from "Common/BreadCrumb";
import {
  capitalizeString,
  dateFormat,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import { customSelectTheme } from "helpers/common";
import withRouter from "Common/withRouter";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  brandListApi,
  campaignListApi,
  subgroupListApi,
} from "helpers/apiroute_helper";
import commonModal from "../../Common/modals/Modal"

const phoneNumberWithPlus = /^\+\w+/;

enum LocationType {
  SMS_LOGS_PAGE = "/sms-logs",
  MMS_LOGS_PAGE = "/mms-logs",
  MY_NUMBERS_PAGE = "/mynumbers",
}

const filterInitialState = {
  signalHouseSID: "",
  startDate: "",
  endDate: "",
  from: "",
  to: "",
  selectField: "",
  brandId: "",
  campaignId: "",
  subGroupId: "",
};

const SmsAndMmsLogs = (props: any) => {
  document.title = "Signal House Portal Messaging Logs";

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const CURRENT_LOCATION = props.router?.location.pathname;
  const [searchParams, setSearchParams] = useSearchParams();

  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialFrom, setInitialFrom] = useState(
    searchParams.get("from") ?? ""
  );
  const [activeDir, setActiveDir] = useState("");
  const [subgroupTemp, setSubgroupTemp] = useState<any>({});
  const [brandTemp, setBrandTemp] = useState<any>({});
  const [campaignTemp, setCampaignTemp] = useState<any>({});
  const [showModal, setShowModal] = useState(false);

  const customSort = (rows: any, selector: any, direction: string) => {
    return rows.sort((rowA: any, rowB: any) => {
      const aField = selector(rowA);
      const bField = selector(rowB);

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };

  const selectProfile = createSelector(
    (state: any) => state.Messages,
    (state: any) => state.Groups,
    (messages, groups) => ({
      loading2: messages.loading,
      groupDetails: groups?.AllGroups,
    })
  );

  const { loading2, groupDetails } = useSelector(selectProfile);

  const detailData = useSelector((state: any) =>
    CURRENT_LOCATION === LocationType.SMS_LOGS_PAGE
      ? state?.Messages?.MessageLogs
      : CURRENT_LOCATION === LocationType.MMS_LOGS_PAGE
      ? state?.Messages?.MmsLogs
      : state.Messages?.MessageLogs
  );

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Date</span>,
      selector: (row: { CreatedDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.CreatedDate, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
      width: "220px",
    },
    {
      name: <span className="font-weight-bold fs-sm">Direction</span>,
      sortable: true,
      selector: (cell: { direction: any }) => {
        switch (cell.direction?.toLowerCase()) {
          case "mt":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-info text-info"
              >
                {capitalizeString(cell.direction)}
              </span>
            );
          case "enqueued":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-warning text-warning"
              >
                {capitalizeString(cell.direction)}
              </span>
            );
          case "inbound":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-primary text-primary"
              >
                {capitalizeString(cell.direction)}
              </span>
            );
          case "outbound-api":
          case "outbound":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-secondary text-secondary"
              >
                {capitalizeString(cell.direction.replace("-api", ""))}
              </span>
            );
          case "group-outbound":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-secondary text-secondary"
              >
                {capitalizeString(cell.direction.replace("-", " "))}
              </span>
            );
          case "failed":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-primary border border-primary text-primary"
              >
                {capitalizeString(cell.direction)}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-danger text-danger"
              >
                {capitalizeString(cell.direction) || "N/A"}
              </span>
            );
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">From</span>,
      selector: (row: { sendersPhoneNumber: number | string }) =>
        row.sendersPhoneNumber,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">To</span>,
      selector: (row: { recieversPhoneNumber?: number | string }) => {
        let phoneNumbers = row?.recieversPhoneNumber?.toString();
        let phoneArray = phoneNumbers
          ? phoneNumbers.split(",").map((num) => num.trim())
          : [];
    
        const maxInitialDisplay = 1;
        const hasMoreNumbers = phoneArray.length > maxInitialDisplay;
    
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {phoneArray.slice(0, maxInitialDisplay).map((num, index) => (
              <span key={index}>{num}</span>
            ))}
            {hasMoreNumbers && (
              <>
                <span
                  className="btn btn-link p-0"
                  style={{
                    fontSize: "0.85rem",
                    cursor: "pointer",
                    color: "black",
                  }}
                  onClick={() => setShowModal(true)}
                >
                  and {phoneArray.length - 1} more
                </span>
                {showModal && (
                  <>
                    {/* Adjusted backdrop to be more transparent */}
                    <div
                      style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.09)", 
                        zIndex: 1040,
                      }}
                      onClick={() => setShowModal(false)}
                    />
    
                    <Modal
                      show={showModal}
                      onHide={() => setShowModal(false)}
                      backdrop={false} 
                      style={{ zIndex: 1050 }}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Phone Numbers</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {phoneArray.map((num, index) => (
                          <div key={index}>{num}</div>
                        ))}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={() => setShowModal(false)}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </>
                )}
              </>
            )}
          </div>
        );
      },
      sortable: true,
    },
    
    {
      name: <span className="font-weight-bold fs-sm">Body</span>,
      selector: (row: { messageBody: string }) => (
        <React.Fragment>
          <div
            className="d-flex align-items-start mb-2"
            style={{ width: 26, height: 14 }}
          >
            <OverlayTrigger
              trigger="hover"
              rootClose
              placement="bottom"
              overlay={
                <Popover id="popover-positioned-bottom">
                  <Popover.Body>
                    <div className="mb-3">{row.messageBody}</div>
                  </Popover.Body>
                </Popover>
              }
            >
              <i className="ri-eye-fill align-middle text-secondary fs-lg cursor-pointer"></i>
            </OverlayTrigger>
          </div>
        </React.Fragment>
      ),
    },
    {
      name: <span className="font-weight-bold fs-sm">Segments</span>,
      selector: (row: { segments?: string }) => row?.segments ?? "-",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      sortable: true,
      selector: (cell: { latestStatus: any }) => {
        switch (cell.latestStatus?.toLowerCase()) {
          case "queued":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-info"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-info p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "enqueued":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center"
                style={{ color: "#787777" }}
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle p-1 me-2"
                  style={{ background: "#787777" }}
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "dequeued":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center "
                style={{ color: "#5c5c5c" }}
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle p-1 me-2"
                  style={{ background: "#787777" }}
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "created":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center "
                style={{ color: "#787777" }}
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle p-1 me-2"
                  style={{ background: "#a8a5a5" }}
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "delivered":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-secondary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "sent":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-black"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-black p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "mo_message_received":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-secondary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus.replace("mo_message_", ""))}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-primary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString("failed")}
              </span>
            );
        }
      },
    },
  ];

  useEffect(() => {
    dispatch(resetMessageLogsList());
  }, []);

  useEffect(() => {
    handleGetData(
      currPage,
      rowsPerPage,
      removeEmptyAndNullValues({ ...validation.values })
    );
  }, [CURRENT_LOCATION]);

  useEffect(() => {
    handleGetData(
      1,
      rowsPerPage,
      removeEmptyAndNullValues({ ...validation.values })
    );
  }, [activeDir]);

  useEffect(() => {
    if (
      CURRENT_LOCATION.split("/")[1] ===
      LocationType.MY_NUMBERS_PAGE.split("/")[1]
    ) {
      setInitialFrom(props.phoneNumber);
    }
  }, [CURRENT_LOCATION, props.phoneNumber]);

  const handleGetData = async (page: number, perPage: number, values?: any) => {
    setLoading(true);

    const signalHouseSID = values
      ? values.signalHouseSID
      : searchParams.get("signalHouseSID") ?? "";
    const startDate = values
      ? values.startDate
      : searchParams.get("startDate") ?? "";
    const endDate = values ? values.endDate : searchParams.get("endDate") ?? "";
    const from = values ? values.from : searchParams.get("from") ?? "";
    const to = values ? values.to : searchParams.get("to") ?? "";
    const selectField = values
      ? values.selectField
      : searchParams.get("selectField") ?? "";

    const formattedFrom = !from
      ? undefined
      : phoneNumberWithPlus.test(from)
      ? `%2B${from.split("+")[1]}`
      : from;
    const formattedTo = !to
      ? undefined
      : phoneNumberWithPlus.test(to)
      ? `%2B${to.split("+")[1]}`
      : to;

    let dir = activeDir || "All";

    const allParams = {
      signalHouseSID,
      startDate,
      endDate,
      from: formattedFrom,
      to: formattedTo,
      selectField,
      direction: dir === "All" ? "" : dir.toLowerCase(),
      subGroupId: values?.subGroupId || "",
      brandId: values?.brandId || "",
      campaignId: values?.campaignId || "",
    };

    const otherParams = Object.fromEntries(
      Object.entries(allParams).filter(
        ([_, value]) => value !== undefined && value !== ""
      )
    );

    try {
      await dispatch(
        CURRENT_LOCATION === LocationType.SMS_LOGS_PAGE
          ? getMessagesThunk(page, perPage, otherParams || {})
          : CURRENT_LOCATION === LocationType.MMS_LOGS_PAGE
          ? getMmsLogsThunk(page, perPage, otherParams || {})
          : getPersonalMessagesLogsThunk(
              { page: page, recordsPerPage: perPage },
              props.phoneNumber,
              otherParams || {}
            )
      );
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (row: { signalHouseSID: string }) => {
    if (CURRENT_LOCATION === LocationType.MMS_LOGS_PAGE) {
      navigate(`/mms-logs-details/${row.signalHouseSID}`);
    } else if (CURRENT_LOCATION === LocationType.SMS_LOGS_PAGE) {
      navigate(`/sms-logs-details/${row.signalHouseSID}`);
    } else {
      navigate(`/programmable-sms-logs-details/${row.signalHouseSID}`);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(
      page,
      rowsPerPage,
      removeEmptyAndNullValues({ ...validation.values })
    );
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(1, rows, removeEmptyAndNullValues({ ...validation.values }));
  };

  const serializeFormQuery = (params: any) => {
    return Object.keys(params)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
      )
      .join("&");
  };

  const loadOptions1 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await subgroupListApi(page, 10, {
      groupId: groupDetails?.records?.[0]?.group_id || "",
    });

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const loadOptions2 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await brandListApi(
      page,
      10,
      removeEmptyAndNullValues({
        groupId: groupDetails?.records?.[0]?.group_id || "",
        subGroupId: subgroupTemp?.sub_group_id || "",
        brandId: searchQuery.toUpperCase() || "",
      })
    );

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const loadOptions3 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await campaignListApi(
      page,
      10,
      removeEmptyAndNullValues({
        groupId: groupDetails?.records?.[0]?.group_id || "",
        subGroupId: subgroupTemp?.sub_group_id || "",
        brandId: brandTemp?.brandId || "",
        campaignId: searchQuery.toUpperCase() || "",
      })
    );

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      signalHouseSID: searchParams.get("signalHouseSID") ?? "",
      startDate: searchParams.get("startDate") ?? "",
      endDate: searchParams.get("endDate") ?? "",
      from:
        CURRENT_LOCATION.split("/")[1] ===
        LocationType.MY_NUMBERS_PAGE.split("/")[1]
          ? initialFrom
          : searchParams.get("from") ?? "",
      to: searchParams.get("to") ?? "",
      selectField: searchParams.get("selectField") ?? "",
      subGroupId: searchParams.get("subGroupId") ?? "",
      brandId: searchParams.get("brandId") ?? "",
      campaignId: searchParams.get("campaignId") ?? "",
    },
    onSubmit: (values) => {
      const queryParams = serializeFormQuery(values);
      setSearchParams(queryParams);
      setDropdownOpen(false);
      handleGetData(1, rowsPerPage, values);
    },
  });

  return (
    <React.Fragment>
      <div
        className={`mb-4 ${
          CURRENT_LOCATION.split("/")[1] !==
          LocationType.MY_NUMBERS_PAGE.split("/")[1]
            ? "page-content"
            : ""
        }`}
      >
        <Container fluid>
          <BreadCrumb
            title={
              CURRENT_LOCATION === LocationType.MMS_LOGS_PAGE
                ? "MMS Messaging Logs"
                : "Programmable Messaging Logs"
            }
            isFilter={true}
            filterButton={
              <>
                <Dropdown className="card-header-dropdown flex-shrink-0 cursor-pointer">
                  <Dropdown.Toggle
                    as="a"
                    className="text-reset arrow-none mb-0"
                  >
                    <h5 className="mb-0">
                      {activeDir || "All"}{" "}
                      <i className="mdi mdi-chevron-down ms-1" />
                    </h5>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-end">
                    {["All", "Inbound", "Outbound"]?.map((dt, i) => (
                      <li key={i}>
                        <Dropdown.Item onClick={() => setActiveDir(dt)}>
                          {dt}
                        </Dropdown.Item>
                      </li>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown
                  align="end"
                  className="filterDropDown"
                  show={dropdownOpen}
                  onToggle={() => {
                    if (!datePickerModal) {
                      setDropdownOpen(!dropdownOpen);
                    }
                  }}
                >
                  <Dropdown.Toggle variant="secondary" className="ms-2">
                    <i className="bx bx-filter-alt me-1"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu-md p-4">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                      }}
                    >
                      <div className="mb-3">
                        <Form.Label htmlFor="signalHouseSID">Search</Form.Label>
                        <div className="d-flex">
                          <input
                            type="text"
                            placeholder="Search by Message SID"
                            className="form-control"
                            {...validation.getFieldProps("signalHouseSID")}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        <Button className="btn btn-primary mb-3" type="submit">
                          Search
                        </Button>
                      </div>
                      <Row>
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="startDate">
                            Start Date & Time
                          </Form.Label>
                          <Flatpickr
                            className="form-control"
                            name="startDate"
                            placeholder="Start Date & Time"
                            options={{
                              enableTime: true,
                              onOpen: () => {
                                setDatePickerModal(true);
                              },
                              onClose: () => {
                                setDatePickerModal(false);
                              },
                              maxDate:
                                new Date(validation.values.endDate) || "",
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d H:i",
                              onChange: (
                                value: any,
                                dateStr: string,
                                instance: any
                              ) => {
                                const newValue = !!value
                                  ? new Date(value).toISOString()
                                  : value;

                                validation.setFieldValue("startDate", newValue);
                                instance.close();
                              },
                            }}
                            value={validation.values.startDate}
                          />
                        </Col>
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="endDate">
                            End Date & Time
                          </Form.Label>
                          <Flatpickr
                            className="form-control"
                            name="endDate"
                            placeholder="End Date & Time"
                            options={{
                              enableTime: true,
                              onOpen: () => {
                                setDatePickerModal(true);
                              },
                              onClose: () => {
                                setDatePickerModal(false);
                              },
                              minDate:
                                new Date(
                                  validation.getFieldProps("startDate").value
                                ) || "",
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d H:i",
                              onChange: (
                                value: any,
                                dateStr: string,
                                instance: any
                              ) => {
                                const newValue = !!value
                                  ? new Date(value).toISOString()
                                  : value;
                                validation.setFieldValue("endDate", newValue);
                                instance.close();
                              },
                            }}
                            value={validation.values.endDate}
                          />
                        </Col>
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="from">From</Form.Label>
                          <input
                            type="text"
                            placeholder="From"
                            className="form-control"
                            {...validation.getFieldProps("from")}
                          />
                        </Col>
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="to">To</Form.Label>
                          <input
                            type="text"
                            placeholder="To"
                            className="form-control"
                            {...validation.getFieldProps("to")}
                          />
                        </Col>
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="subGroupId">
                            Sub Group
                          </Form.Label>
                          <AsyncPaginate
                            isDisabled={
                              !groupDetails?.records?.[0]?.group_id || ""
                            }
                            key={groupDetails?.records?.[0]?.group_id || ""}
                            isClearable={true}
                            styles={styleObj(
                              validation.touched?.subGroupId &&
                                validation.errors?.subGroupId
                            )}
                            theme={customSelectTheme}
                            value={subgroupTemp || {}}
                            loadOptions={loadOptions1}
                            getOptionValue={(option: any) =>
                              option?.sub_group_id
                            }
                            getOptionLabel={(option: any) =>
                              option?.sub_group_name
                            }
                            onChange={(option: any) => {
                              if (option) {
                                setSubgroupTemp(option);
                                validation.setFieldValue(
                                  "subGroupId",
                                  option?.sub_group_id
                                );
                                setBrandTemp({});
                                validation.setFieldValue("brandId", "");
                                setCampaignTemp({});
                                validation.setFieldValue("campaignId", "");
                              } else {
                                setSubgroupTemp({});
                                validation.setFieldValue("subGroupId", "");
                              }
                            }}
                            isSearchable={true}
                            additional={{
                              page: 1,
                            }}
                          />
                        </Col>
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="brandId">Brand</Form.Label>
                          <AsyncPaginate
                            key={
                              validation.values.subGroupId ||
                              groupDetails?.records?.[0]?.group_id ||
                              "" ||
                              "brandId"
                            }
                            isClearable={true}
                            styles={styleObj(
                              validation.touched?.brandId &&
                                validation.errors?.brandId
                            )}
                            theme={customSelectTheme}
                            value={brandTemp || {}}
                            loadOptions={loadOptions2}
                            getOptionValue={(option: any) => option?.brandId}
                            getOptionLabel={(option: any) => option?.brandId}
                            onChange={(option: any) => {
                              if (option) {
                                setBrandTemp(option);
                                validation.setFieldValue(
                                  "brandId",
                                  option.brandId
                                );
                                setCampaignTemp({});
                                validation.setFieldValue("campaignId", "");
                              } else {
                                setBrandTemp({});
                                validation.setFieldValue("brandId", "");
                              }
                            }}
                            isSearchable={true}
                            additional={{
                              page: 1,
                            }}
                          />
                        </Col>
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="campaignId">Campaign</Form.Label>
                          <AsyncPaginate
                            key={
                              validation.values.brandId ||
                              validation.values.subGroupId ||
                              groupDetails?.records?.[0]?.group_id ||
                              "" ||
                              "campaignId"
                            }
                            isClearable={true}
                            styles={styleObj(
                              validation.touched?.campaignId &&
                                validation.errors?.campaignId
                            )}
                            theme={customSelectTheme}
                            value={campaignTemp || {}}
                            loadOptions={loadOptions3}
                            getOptionValue={(option: any) => option?.campaignId}
                            getOptionLabel={(option: any) => option?.campaignId}
                            onChange={(option: any) => {
                              if (option) {
                                setCampaignTemp(option);
                                validation.setFieldValue(
                                  "campaignId",
                                  option.campaignId
                                );
                              } else {
                                setCampaignTemp({});
                                validation.setFieldValue("campaignId", "");
                              }
                            }}
                            isSearchable={true}
                            additional={{
                              page: 1,
                            }}
                          />
                        </Col>
                      </Row>
                      <div className="d-flex align-items-center justify-content-end">
                        <Button
                          className="btn btn-secondary me-2"
                          type="button"
                          disabled={loading2}
                          onClick={() => {
                            const queryParams =
                              serializeFormQuery(filterInitialState);
                            setSearchParams(queryParams);
                            validation.resetForm();
                            validation.setFieldValue("startDate", "");
                            validation.setFieldValue("endDate", "");
                            setSubgroupTemp({});
                            setBrandTemp({});
                            setCampaignTemp({});
                            setDropdownOpen(false);
                            handleGetData(
                              currPage,
                              rowsPerPage,
                              filterInitialState
                            );
                          }}
                        >
                          Clear
                        </Button>
                        <Button
                          className="btn btn-primary"
                          type="submit"
                          disabled={loading2}
                        >
                          {loading2 && <Spinner size="sm" animation="border" />}{" "}
                          Apply
                        </Button>
                      </div>
                    </Form>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            }
          />

          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body className="listing-table">
                {loading ? (
                  <div>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                    />
                  </div>
                ) : (
                  <Datatable
                    data={
                      Array.isArray(detailData.records)
                        ? detailData.records
                        : []
                    }
                    columns={columns}
                    handleRowClick={handleRowClick}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    currPage={currPage}
                    rowsPerPage={rowsPerPage}
                    totalRecords={detailData?.totalRecords}
                    customSort={customSort}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(SmsAndMmsLogs);

