import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Nav,
  Row,
  Spinner,
  Tab,
} from "react-bootstrap";
import CountUp from "react-countup";
import Select from "react-select";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnalyticsMmsV1Thunk,
  getAnalyticsSmsV1Thunk,
  getSmsStatusLogsThunk,
  getMmsStatusLogsThunk,
} from "slices/thunk";
import Loader from "assets/images/spinner-dark.svg";
import {
  customSelectTheme,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import moment from "moment";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  activePhoneNumberApi,
  brandListApi,
  campaignListApi,
  subgroupListApi,
} from "helpers/apiroute_helper";
import * as Yup from "yup";
import StackedColumn2 from "./StackedColumn2";
import _ from "lodash";
import LineChart from "./LineChart";
import SmsAndMmsLogs from "./SmsAndMmsLogs";
import withRouter from "Common/withRouter";

const dateRangeArr = [
  {
    label: "24 hours",
    value: "24 hours",
  },
  {
    label: "7 days",
    value: "7 days",
  },
  {
    label: "30 days",
    value: "30 days",
  },
  {
    label: "120 days",
    value: "120 days",
  },
  {
    label: "Custom (full custom range)",
    value: "Custom",
  },
];

const selectedTypeArr = ["All", "SMS", "MMS"];
const selectedTypeArr2 = ["All", "Outbound Analytics", "Inbound Analytics"];

const Analytics = (props: any) => {
  document.title = "Signal House Portal Analytics";

  const dispatch = useDispatch<any>();

  const selectProfile = createSelector(
    (state: any) => state.Analytics,
    (state: any) => state.Groups,
    (analytics, groups) => ({
      loading: analytics.loading,
      loading2: analytics.loading2,
      analyticsAllV1: analytics.analyticsAllV1,
      smsStatusLogs: analytics.smsStatusLogs,
      mmsStatusLogs: analytics.mmsStatusLogs,
      error: analytics.error,
      groupDetails: groups?.AllGroups,
    })
  );

  const {
    loading,
    loading2,
    analyticsAllV1,
    smsStatusLogs,
    mmsStatusLogs,
    groupDetails,
  } = useSelector(selectProfile);

  const [selectedType, setSelectedType] = useState(selectedTypeArr[0]);
  const [selectedType2, setSelectedType2] = useState(selectedTypeArr2[1]);
  const [selectedSubType, setSelectedSubType] = useState("totalMessagesSent");
  const [selectedSubTypeLabel, setSelectedSubTypeLabel] = useState(
    "Total messages sent"
  );
  const [open, setOpen] = useState(false);
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [dateRangeTemp, setDateRangeTemp] = useState(dateRangeArr[1]);
  const [cardObj, setCardObj] = useState<any>({});
  const [dateGraphObj, setDateGraphObj] = useState<any>({});
  const [numberGraphObj, setNumberGraphObj] = useState<any>({});
  const [graphType1, setGraphType1] = useState("Column");
  const [graphType2, setGraphType2] = useState("Column");
  const [currPageSms, setCurrPageSms] = useState(1);
  const [rowsPerPageSms, setRowsPerPageSms] = useState(10);
  const [currPageMms, setCurrPageMms] = useState(1);
  const [rowsPerPageMms, setRowsPerPageMms] = useState(10);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      startDate: moment().subtract(7, "days").startOf("day").toISOString(),
      endDate: moment().endOf("day").format("MM-DD-YYYY"),
      groupId: "",
      subGroupId: "",
      brandId: "",
      campaignId: "",
      phoneNumber: "",
      tag: "",
    },
    validationSchema: Yup.object({
      groupId: Yup.string().required("Please Select Group"),
    }),
    onSubmit: (values) => {
      const temp = {
        ...values,
        endDate: values.endDate
          ? moment(values.endDate, "MM-DD-YYYY").endOf("day").toISOString()
          : "",
        statusFilter:
          selectedSubType === "failed" || selectedSubType === "failedRate"
            ? ["failed"]
            : ["delivered", "sent"],
      };
      setOpen(!open);
      handleGetData(temp);
    },
  });

  useEffect(() => {
    if (groupDetails?.records?.[0]?.group_id) {
      const startDate = moment()
        .subtract(7, "days")
        .startOf("day")
        .toISOString();
      const endDate = moment().endOf("day").format("MM-DD-YYYY");

      handleGetData({
        ...validation.values,
        groupId: groupDetails?.records?.[0]?.group_id,
        endDate: endDate
          ? moment(endDate, "MM-DD-YYYY").endOf("day").toISOString()
          : "",
        statusFilter:
          selectedSubType === "failed" || selectedSubType === "failedRate"
            ? ["failed"]
            : ["delivered", "sent"],
      });
      validation.setFieldValue("groupId", groupDetails?.records?.[0]?.group_id);
      validation.setFieldValue("startDate", startDate);
      validation.setFieldValue("endDate", endDate);
    }
  }, [groupDetails?.records?.[0]?.group_id]);

  useEffect(() => {
    if (Object.keys(analyticsAllV1)?.length) {
      getCardData();
      getDateGraphData();
      getNumberGraphData();
    }
  }, [analyticsAllV1]);

  useEffect(() => {
    if (Object.keys(analyticsAllV1)?.length) {
      getDateGraphData();
      getNumberGraphData();
    }
  }, [selectedType]);

  useEffect(() => {
    if (groupDetails?.records?.[0]?.group_id) {
      const params = {
        ...validation.values,
        groupId: groupDetails?.records?.[0]?.group_id,
        endDate: moment(validation.values.endDate, "MM-DD-YYYY")
          .endOf("day")
          .toISOString(),
        statusFilter:
          selectedSubType === "failed" || selectedSubType === "failedRate"
            ? ["failed"]
            : ["delivered", "sent"],
      };
      setCurrPageSms(1);
      setRowsPerPageSms(10);
      setCurrPageMms(1);
      setRowsPerPageMms(10);
      handleSmsGetData(1, 10, removeEmptyAndNullValues({ ...params }));
      handleMmsGetData(1, 10, removeEmptyAndNullValues({ ...params }));
    }
  }, [groupDetails?.records?.[0]?.group_id, selectedSubType]);

  const getCardData = () => {
    let date = moment(validation.values?.endDate, "MM-DD-YYYY")?.diff(
      moment(validation.values?.startDate, "MM-DD-YYYY"),
      "days"
    );
    let dateDiff =
      validation.values?.endDate && validation.values?.startDate
        ? isNaN(date)
          ? 1
          : date
        : 1;

    const smsTotal = Object.keys(analyticsAllV1.SMS).reduce(
      (acc: any, date: any) => {
        const dateData = analyticsAllV1.SMS[date];
        Object.keys(dateData).forEach((phoneNumber) => {
          const phoneData = dateData[phoneNumber];
          Object.keys(phoneData).forEach((key) => {
            acc[key] = (acc[key] || 0) + phoneData[key];
          });
        });
        return acc;
      },
      {}
    );

    const totalSmsSent =
      (smsTotal?.delivered || 0) +
      (smsTotal?.sent || 0) +
      (smsTotal?.failed || 0);

    smsTotal["deliveryRate"] =
      (100 * smsTotal?.delivered) / (totalSmsSent || 1);
    smsTotal["failedRate"] = (100 * smsTotal?.failed) / (totalSmsSent || 1);
    smsTotal["sentRate"] = (100 * smsTotal?.sent) / (totalSmsSent || 1);
    smsTotal["totalMessagesSent"] = totalSmsSent;
    smsTotal["averageMessagesSent"] = totalSmsSent / dateDiff;
    smsTotal["averageMessagesReceived"] = smsTotal?.totalInbound / dateDiff;
    smsTotal["averageSegmentsSent"] = smsTotal?.segmentSent / dateDiff;
    smsTotal["optOutRate"] =
      (100 * smsTotal?.totalOptOut) / (smsTotal?.totalInbound || 1);
    smsTotal["averageSegmentReceived"] = smsTotal?.segmentRecieved / dateDiff;
    smsTotal["responseRate"] = smsTotal?.delivered
      ? Number((smsTotal?.totalInbound / smsTotal?.delivered) * 100)
      : 0;

    const mmsTotal = Object.keys(analyticsAllV1.MMS).reduce(
      (acc: any, date: any) => {
        const dateData = analyticsAllV1.MMS[date];
        Object.keys(dateData).forEach((phoneNumber) => {
          const phoneData = dateData[phoneNumber];
          Object.keys(phoneData).forEach((key) => {
            acc[key] = (acc[key] || 0) + phoneData[key];
          });
        });
        return acc;
      },
      {}
    );

    const totalMmsSent =
      (mmsTotal?.delivered || 0) +
      (mmsTotal?.sent || 0) +
      (mmsTotal?.failed || 0);

    mmsTotal["deliveryRate"] =
      (100 * mmsTotal?.delivered) / (totalMmsSent || 1);
    mmsTotal["failedRate"] = (100 * mmsTotal?.failed) / (totalMmsSent || 1);
    mmsTotal["sentRate"] = (100 * mmsTotal?.sent) / (totalMmsSent || 1);
    mmsTotal["totalMessagesSent"] = totalMmsSent;
    mmsTotal["averageMessagesSent"] = totalMmsSent / dateDiff;
    mmsTotal["averageMessagesReceived"] = mmsTotal?.totalInbound / dateDiff;
    mmsTotal["averageSegmentsSent"] = mmsTotal?.segmentSent / dateDiff;
    mmsTotal["optOutRate"] =
      (100 * mmsTotal?.totalOptOut) / (mmsTotal?.totalInbound || 1);
    mmsTotal["averageSegmentReceived"] = mmsTotal?.segmentRecieved / dateDiff;
    mmsTotal["responseRate"] = mmsTotal?.delivered
      ? Number((mmsTotal?.totalInbound / mmsTotal?.delivered) * 100)
      : 0;

    const allTotal = Object.keys(smsTotal).reduce((acc: any, key: any) => {
      acc[key] = (smsTotal[key] || 0) + (mmsTotal[key] || 0);
      return acc;
    }, {});

    const totalAllSent =
      (allTotal?.delivered || 0) +
      (allTotal?.sent || 0) +
      (allTotal?.failed || 0);

    allTotal["deliveryRate"] =
      (100 * allTotal?.delivered) / (totalAllSent || 1);
    allTotal["failedRate"] = (100 * allTotal?.failed) / (totalAllSent || 1);
    allTotal["sentRate"] = (100 * allTotal?.sent) / (totalAllSent || 1);
    allTotal["totalMessagesSent"] = totalAllSent;
    allTotal["averageMessagesSent"] = totalAllSent / dateDiff;
    allTotal["averageMessagesReceived"] = allTotal?.totalInbound / dateDiff;
    allTotal["averageSegmentsSent"] = allTotal?.segmentSent / dateDiff;
    allTotal["optOutRate"] =
      (100 * allTotal?.totalOptOut) / (allTotal?.totalInbound || 1);
    allTotal["averageSegmentReceived"] = allTotal?.segmentRecieved / dateDiff;
    allTotal["responseRate"] = allTotal?.delivered
      ? Number((allTotal?.totalInbound / allTotal?.delivered) * 100)
      : 0;

    const tempObj = {
      SMS: { ...smsTotal },
      MMS: { ...mmsTotal },
      All: { ...allTotal },
    };
    setCardObj({ ...tempObj });
  };

  const getDateGraphData = () => {
    const mergeData = (data1: any, data2?: any) => {
      const mergedData = _.mergeWith({}, data1, data2, (objValue, srcValue) => {
        if (_.isObject(objValue) && _.isObject(srcValue)) {
          return _.mergeWith(
            {},
            objValue,
            srcValue,
            (innerObjValue, innerSrcValue) => {
              if (_.isNumber(innerObjValue) && _.isNumber(innerSrcValue)) {
                return innerObjValue + innerSrcValue;
              }
            }
          );
        }
      });

      // Merge phone number data for each date and calculate new fields
      Object.keys(mergedData).forEach((date) => {
        const phoneData = mergedData[date];
        mergedData[date] = Object.values(phoneData).reduce(
          (acc: any, curr: any) => {
            Object.keys(curr).forEach((key) => {
              acc[key] = (acc[key] || 0) + curr[key];
            });
            return acc;
          },
          {}
        );

        // Calculate new fields
        const totalSent =
          mergedData[date].delivered +
          mergedData[date].sent +
          mergedData[date].failed;

        mergedData[date].deliveryRate = totalSent
          ? Number((mergedData[date].delivered / totalSent) * 100)
          : 0;
        mergedData[date].failedRate = totalSent
          ? Number((mergedData[date].failed / totalSent) * 100)
          : 0;
        mergedData[date].sentRate = totalSent
          ? Number((mergedData[date].sent / totalSent) * 100)
          : 0;
        mergedData[date].totalMessagesSent = totalSent;
        mergedData[date].optOutRate = mergedData[date].totalInbound
          ? Number(
              (mergedData[date].totalOptOut / mergedData[date].totalInbound) *
                100
            )
          : 0;
        mergedData[date].responseRate = mergedData[date].delivered
          ? Number(
              (mergedData[date].totalInbound / mergedData[date].delivered) * 100
            )
          : 0;
      });

      return mergedData;
    };

    const result =
      selectedType === "All"
        ? mergeData(analyticsAllV1?.SMS, analyticsAllV1?.MMS)
        : selectedType === "SMS"
        ? mergeData(analyticsAllV1?.SMS)
        : selectedType === "MMS"
        ? mergeData(analyticsAllV1?.MMS)
        : {};

    setDateGraphObj(result);
  };

  const getNumberGraphData = () => {
    const mergeDataByPhoneNumber = (data1: any, data2?: any) => {
      const mergedData: any = {};

      // Helper function to merge data for a single phone number
      const mergePhoneData = (phoneData: any, date: any, phoneNumber: any) => {
        if (!mergedData[phoneNumber]) {
          mergedData[phoneNumber] = {};
        }
        // Ensure metrics is defined before using it
        const metrics = [
          "delivered",
          "sent",
          "dequeued",
          "enqueued",
          "created",
          "failed",
          "segmentSent",
          "totalInbound",
          "segmentRecieved",
          "totalOptOut",
          "averageResponseTime",
        ];

        metrics.forEach((metric) => {
          if (!mergedData[phoneNumber][metric]) {
            mergedData[phoneNumber][metric] = 0;
          }
          mergedData[phoneNumber][metric] += phoneData[metric] || 0;
        });
      };

      // Merge data from both input objects
      [data1, data2].forEach((data) => {
        data &&
          Object.keys(data).forEach((date) => {
            data[date] &&
              Object.keys(data[date]).forEach((phoneNumber) => {
                mergePhoneData(data[date][phoneNumber], date, phoneNumber);
              });
          });
      });

      // Calculate new fields for each phone number
      Object.keys(mergedData).forEach((phoneNumber) => {
        const totalSent =
          mergedData[phoneNumber].delivered +
          mergedData[phoneNumber].sent +
          mergedData[phoneNumber].failed;

        mergedData[phoneNumber].deliveryRate = totalSent
          ? Number((mergedData[phoneNumber].delivered / totalSent) * 100)
          : 0;
        mergedData[phoneNumber].failedRate = totalSent
          ? Number((mergedData[phoneNumber].failed / totalSent) * 100)
          : 0;
        mergedData[phoneNumber].sentRate = totalSent
          ? Number((mergedData[phoneNumber].sent / totalSent) * 100)
          : 0;
        mergedData[phoneNumber].totalMessagesSent = totalSent;
        mergedData[phoneNumber].optOutRate = mergedData[phoneNumber]
          .totalInbound
          ? Number(
              (mergedData[phoneNumber].totalOptOut /
                mergedData[phoneNumber].totalInbound) *
                100
            )
          : 0;
        mergedData[phoneNumber].responseRate = mergedData[phoneNumber].delivered
          ? Number(
              (mergedData[phoneNumber].totalInbound /
                mergedData[phoneNumber].delivered) *
                100
            )
          : 0;
      });

      return mergedData;
    };

    const result =
      selectedType === "All"
        ? mergeDataByPhoneNumber(analyticsAllV1?.SMS, analyticsAllV1?.MMS)
        : selectedType === "SMS"
        ? mergeDataByPhoneNumber(analyticsAllV1?.SMS)
        : selectedType === "MMS"
        ? mergeDataByPhoneNumber(analyticsAllV1?.MMS)
        : {};

    setNumberGraphObj(result);
  };

  const handleGetData = (params?: any) => {
    const cb = () => {
      dispatch(getAnalyticsMmsV1Thunk(removeEmptyAndNullValues({ ...params })));
      setCurrPageSms(1);
      setRowsPerPageSms(10);
      setCurrPageMms(1);
      setRowsPerPageMms(10);
      handleSmsGetData(1, 10, removeEmptyAndNullValues({ ...params }));
      handleMmsGetData(1, 10, removeEmptyAndNullValues({ ...params }));
    };

    dispatch(
      getAnalyticsSmsV1Thunk(removeEmptyAndNullValues({ ...params }), cb)
    );
  };

  const loadOptions1 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await subgroupListApi(page, 10, {
      groupId: validation.values.groupId || "",
    });

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const loadOptions2 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await brandListApi(
      page,
      10,
      removeEmptyAndNullValues({
        groupId: validation.values.groupId || "",
        subGroupId: validation.values.subGroupId || "",
        brandId: searchQuery.toUpperCase() || "",
      })
    );

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const loadOptions3 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await campaignListApi(
      page,
      10,
      removeEmptyAndNullValues({
        groupId: validation.values.groupId || "",
        subGroupId: validation.values.subGroupId || "",
        brandId: validation.values.brandId || "",
        campaignId: searchQuery.toUpperCase() || "",
      })
    );

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const loadOptions4 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await activePhoneNumberApi(
      page,
      10,
      removeEmptyAndNullValues({
        groupId: validation.values.groupId || "",
        subGroupId: validation.values.subGroupId || "",
        brandId: validation.values.brandId || "",
        campaignId: validation.values.campaignId || "",
        phoneNumber: searchQuery || "",
      })
    );

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const handleSmsGetData = (
    page: number,
    perPage: number,
    otherParams?: any
  ) => {
    dispatch(
      getSmsStatusLogsThunk(
        page,
        perPage,
        removeEmptyAndNullValues({
          ...otherParams,
          direction:
            selectedSubType === "totalInbound" ||
            selectedSubType === "averageMessagesReceived" ||
            selectedSubType === "segmentReceived" ||
            selectedSubType === "averageSegmentReceived" ||
            selectedSubType === "responseRate" ||
            selectedSubType === "averageResponseTime"
              ? "inbound"
              : "outbound",
          hasOptOut:
            selectedSubType === "totalOptOut" ||
            selectedSubType === "optOutRate"
              ? "true"
              : "",
        })
      )
    );
  };

  const handleMmsGetData = (
    page: number,
    perPage: number,
    otherParams?: any
  ) => {
    dispatch(
      getMmsStatusLogsThunk(
        page,
        perPage,
        removeEmptyAndNullValues({
          ...otherParams,
          direction:
            selectedSubType === "totalInbound" ||
            selectedSubType === "averageMessagesReceived" ||
            selectedSubType === "segmentReceived" ||
            selectedSubType === "averageSegmentReceived" ||
            selectedSubType === "responseRate" ||
            selectedSubType === "averageResponseTime"
              ? "inbound"
              : "outbound",
          hasOptOut:
            selectedSubType === "totalOptOut" ||
            selectedSubType === "optOutRate"
              ? "true"
              : "",
        })
      )
    );
  };

  const handleSmsRowClick = (row: { signalHouseSID: string }) => {
    props.router.navigate(`/sms-logs-details/${row.signalHouseSID}`);
  };

  const handleMmsRowClick = (row: { signalHouseSID: string }) => {
    props.router.navigate(`/mms-logs-details/${row.signalHouseSID}`);
  };

  const handleSmsPageChange = (page: number) => {
    setCurrPageSms(page);
    handleSmsGetData(
      page,
      rowsPerPageSms,
      removeEmptyAndNullValues({
        ...validation.values,
        statusFilter:
          selectedSubType === "failed" || selectedSubType === "failedRate"
            ? ["failed"]
            : ["delivered", "sent"],
      })
    );
  };

  const handleMmsPageChange = (page: number) => {
    setCurrPageMms(page);
    handleMmsGetData(
      page,
      rowsPerPageMms,
      removeEmptyAndNullValues({
        ...validation.values,
        statusFilter:
          selectedSubType === "failed" || selectedSubType === "failedRate"
            ? ["failed"]
            : ["delivered", "sent"],
      })
    );
  };

  const handleSmsRowsPerPageChange = (rows: number) => {
    setCurrPageSms(1);
    setRowsPerPageSms(rows);
    handleSmsGetData(
      1,
      rows,
      removeEmptyAndNullValues({
        ...validation.values,
        statusFilter:
          selectedSubType === "failed" || selectedSubType === "failedRate"
            ? ["failed"]
            : ["delivered", "sent"],
      })
    );
  };

  const handleMmsRowsPerPageChange = (rows: number) => {
    setCurrPageMms(1);
    setRowsPerPageMms(rows);
    handleMmsGetData(
      1,
      rows,
      removeEmptyAndNullValues({
        ...validation.values,
        statusFilter:
          selectedSubType === "failed" || selectedSubType === "failedRate"
            ? ["failed"]
            : ["delivered", "sent"],
      })
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center flex-wrap">
                {selectedTypeArr
                  ?.filter((dt) => dt !== "All")
                  ?.map((dt, i) => (
                    <Form.Check
                      key={i}
                      type="checkbox"
                      id={dt}
                      label={<h5 className="mb-0">{dt}</h5>}
                      className="me-3"
                      checked={selectedType === dt || selectedType === "All"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          if (
                            selectedType === "All" ||
                            selectedType === "SMS" ||
                            selectedType === "MMS"
                          ) {
                            setSelectedType("All");
                          } else {
                            setSelectedType(dt);
                          }
                        } else {
                          const otherType = selectedTypeArr.find(
                            (t) => t !== "All" && t !== dt
                          );
                          setSelectedType(otherType || "All");
                        }
                      }}
                    />
                  ))}
              </div>
              <div className="d-flex align-items-center flex-wrap ms-4">
                {selectedTypeArr2
                  ?.filter((dt) => dt !== "All")
                  ?.map((dt, i) => (
                    <Form.Check
                      key={i}
                      type="checkbox"
                      id={dt}
                      label={<h5 className="mb-0">{dt}</h5>}
                      className="me-3"
                      checked={selectedType2 === dt || selectedType2 === "All"}
                      onChange={(e) => {
                        const setTypes = (type: any) => {
                          if (type === "Inbound Analytics") {
                            setSelectedSubType("totalInbound");
                            setSelectedSubTypeLabel("Total messages received");
                          } else {
                            setSelectedSubType("deliveryRate");
                            setSelectedSubTypeLabel("Delivered");
                          }
                        };

                        if (e.target.checked) {
                          if (
                            selectedType2 === "All" ||
                            selectedType2 === "Outbound Analytics" ||
                            selectedType2 === "Inbound Analytics"
                          ) {
                            setSelectedType2("All");
                            setTypes("All");
                          } else {
                            setSelectedType2(dt);
                            setTypes(dt);
                          }
                        } else {
                          const otherType = selectedTypeArr2.find(
                            (t) => t !== "All" && t !== dt
                          );
                          setSelectedType2(otherType || "All");
                          setTypes(otherType);
                        }
                      }}
                    />
                  ))}
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center flex-wrap">
                {validation.values.subGroupId && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Subgroup Id: {validation.values.subGroupId}
                  </span>
                )}
                {validation.values.brandId && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Brand Id: {validation.values.brandId}
                  </span>
                )}
                {validation.values.campaignId && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Campaign Id: {validation.values.campaignId}
                  </span>
                )}
                {validation.values.phoneNumber && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Phone Number: {validation.values.phoneNumber}
                  </span>
                )}
                {validation.values.tag && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Tag: {validation.values.tag}
                  </span>
                )}
                {dateRangeTemp?.value ? (
                  dateRangeTemp?.value === "Custom" ? (
                    <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                      Date Range: {validation.values.startDate} -{" "}
                      {validation.values.endDate}
                    </span>
                  ) : (
                    <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                      Date Range: {dateRangeTemp?.value}
                    </span>
                  )
                ) : null}
              </div>
              <Dropdown
                align="end"
                className="ms-2 filterDropDown"
                show={open}
                onToggle={() => {
                  if (!datePickerModal) {
                    setOpen(!open);
                  }
                }}
              >
                <Dropdown.Toggle variant="secondary">
                  <i className="bx bx-filter-alt me-1" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-md p-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                    }}
                  >
                    <Row>
                      <div>
                        {validation.touched.groupId &&
                        validation.errors.groupId ? (
                          <p className="text-danger">
                            {validation.errors.groupId}
                          </p>
                        ) : null}
                      </div>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="subGroupId">Sub Group</Form.Label>
                        <AsyncPaginate
                          isDisabled={!validation.values.groupId}
                          key={validation.values.groupId}
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.subGroupId &&
                              validation?.errors?.subGroupId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.subGroupId
                              ? {
                                  sub_group_id: validation.values.subGroupId,
                                  sub_group_name: validation.values.subGroupId,
                                }
                              : null
                          }
                          name="subGroupId"
                          loadOptions={loadOptions1}
                          getOptionValue={(option: any) => option?.sub_group_id}
                          getOptionLabel={(option: any) =>
                            option?.sub_group_name
                          }
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.sub_group_id !==
                                validation.values.subGroupId
                            ) {
                              validation.setValues({
                                ...validation.values,
                                subGroupId: option.sub_group_id,
                                brandId: "",
                                campaignId: "",
                                phoneNumber: "",
                              });
                            } else if (!option) {
                              validation.setFieldValue("subGroupId", "");
                            }
                          }}
                          isSearchable={true}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="brandId">Brand</Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.subGroupId ||
                            validation.values.groupId ||
                            "brandId"
                          }
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.brandId &&
                              validation?.errors?.brandId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.brandId
                              ? { brandId: validation.values.brandId }
                              : null || {}
                          }
                          loadOptions={loadOptions2}
                          getOptionValue={(option: any) => option?.brandId}
                          getOptionLabel={(option: any) => option?.brandId}
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.brandId === validation.values.brandId
                            ) {
                              return;
                            }
                            if (option) {
                              validation.setFieldValue(
                                "brandId",
                                option.brandId
                              );
                              validation.setFieldValue("campaignId", "");
                              validation.setFieldValue(
                                "subGroupId",
                                option.subGroupId?.[0]
                              );
                              validation.setFieldValue("phoneNumber", "");
                            } else {
                              validation.setFieldValue("brandId", "");
                            }
                          }}
                          isSearchable={true}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="campaignId">Campaign</Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.brandId ||
                            validation.values.subGroupId ||
                            validation.values.groupId ||
                            "campaignId"
                          }
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.campaignId &&
                              validation?.errors?.campaignId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.campaignId
                              ? { campaignId: validation.values.campaignId }
                              : null || {}
                          }
                          loadOptions={loadOptions3}
                          getOptionValue={(option: any) => option?.campaignId}
                          getOptionLabel={(option: any) => option?.campaignId}
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.campaignId === validation.values.campaignId
                            ) {
                              return;
                            }
                            if (option) {
                              validation.setFieldValue(
                                "campaignId",
                                option.campaignId
                              );

                              validation.setFieldValue(
                                "subGroupId",
                                option.subGroupId?.[0]
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.brandId
                              );
                              validation.setFieldValue("phoneNumber", "");
                            } else {
                              validation.setFieldValue("campaignId", "");
                            }
                          }}
                          isSearchable={true}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="phoneNumber">
                          Phone Number
                        </Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.campaignId ||
                            validation.values.brandId ||
                            validation.values.subGroupId ||
                            validation.values.groupId ||
                            "phoneNumber"
                          }
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.phoneNumber &&
                              validation?.errors?.phoneNumber
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.phoneNumber
                              ? { phoneNumber: validation.values.phoneNumber }
                              : null || {}
                          }
                          loadOptions={loadOptions4}
                          getOptionValue={(option: any) => option?.phoneNumber}
                          getOptionLabel={(option: any) => option?.phoneNumber}
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.phoneNumber ===
                                validation.values.phoneNumber
                            ) {
                              return;
                            }

                            if (option) {
                              validation.setFieldValue(
                                "phoneNumber",
                                option.phoneNumber
                              );
                              validation.setFieldValue(
                                "campaignId",
                                option.campaignId || ""
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.brandId || null
                              );
                              validation.setFieldValue(
                                "subGroupId",
                                option.subGroupId ? option.subGroupId[0] : ""
                              );
                            } else {
                              validation.setFieldValue("phoneNumber", "");
                            }
                          }}
                          isSearchable={true}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="tag">Tag</Form.Label>
                        <Form.Control
                          type="text"
                          name="tag"
                          onChange={(e) => {
                            validation.setFieldValue("tag", e.target.value);
                          }}
                          value={validation.values.tag}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="dateRange">Date Range</Form.Label>
                        <Select
                          styles={styleObj(false)}
                          theme={customSelectTheme}
                          options={dateRangeArr}
                          onChange={(e: any) => {
                            setDateRangeTemp(e);
                            let startDate = "";
                            let endDate = moment()
                              .endOf("day")
                              .format("MM-DD-YYYY");

                            if (e?.value === "24 hours") {
                              startDate = moment()
                                .subtract(1, "days")
                                .startOf("day")
                                .toISOString();
                            } else if (e?.value === "7 days") {
                              startDate = moment()
                                .subtract(7, "days")
                                .startOf("day")
                                .toISOString();
                            } else if (e?.value === "30 days") {
                              startDate = moment()
                                .subtract(30, "days")
                                .startOf("day")
                                .toISOString();
                            } else if (e?.value === "120 days") {
                              startDate = moment()
                                .subtract(120, "days")
                                .startOf("day")
                                .toISOString();
                            } else {
                              endDate = "";
                            }

                            validation.setFieldValue("startDate", startDate);
                            validation.setFieldValue("endDate", endDate);
                          }}
                          value={dateRangeTemp}
                        />
                      </Col>
                      {dateRangeTemp?.value === "Custom" ? (
                        <>
                          <Col sm={6} className="mb-3">
                            <Form.Label htmlFor="startDate">
                              Start Date
                            </Form.Label>
                            <Flatpickr
                              className="form-control"
                              name="startDate"
                              placeholder="Start Date"
                              options={{
                                enableTime: false,
                                onOpen: () => {
                                  setDatePickerModal(true);
                                },
                                onClose: () => {
                                  setDatePickerModal(false);
                                },
                                maxDate:
                                  new Date(validation.values.endDate) || "",
                                altFormat: "F j, Y",
                                dateFormat: "m-d-Y",
                                onChange: (
                                  value: any,
                                  dateStr: string,
                                  instance: any
                                ) => {
                                  validation.setFieldValue(
                                    "startDate",
                                    moment(value?.[0]).format("MM-DD-YYYY")
                                  );
                                  instance.close();
                                },
                              }}
                              value={validation.values.startDate || ""}
                            />
                          </Col>
                          <Col sm={6} className="mb-3">
                            <Form.Label htmlFor="endDate">End Date</Form.Label>
                            <Flatpickr
                              className="form-control"
                              name="endDate"
                              placeholder="End Date"
                              options={{
                                enableTime: false,
                                onOpen: () => setDatePickerModal(true),
                                onClose: () => setDatePickerModal(false),
                                minDate: validation.values.startDate || "",
                                altFormat: "F j, Y",
                                dateFormat: "m-d-Y",
                                onChange: (
                                  value: any,
                                  dateStr: any,
                                  instance: any
                                ) => {
                                  validation.setFieldValue(
                                    "endDate",
                                    moment(value?.[0]).format("MM-DD-YYYY")
                                  );
                                  instance.close();
                                },
                              }}
                              value={validation.values.endDate}
                            />
                          </Col>
                        </>
                      ) : null}
                    </Row>
                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        className="btn btn-secondary me-2"
                        type="button"
                        disabled={loading}
                        onClick={() => {
                          handleGetData({
                            ...validation.initialValues,
                            groupId: groupDetails?.records?.[0]?.group_id,
                            endDate: moment(
                              validation.initialValues.endDate,
                              "MM-DD-YYYY"
                            )
                              .endOf("day")
                              .toISOString(),
                            statusFilter:
                              selectedSubType === "failed" ||
                              selectedSubType === "failedRate"
                                ? ["failed"]
                                : ["delivered", "sent"],
                          });
                          validation.resetForm();
                          validation.setFieldValue(
                            "groupId",
                            groupDetails?.records?.[0]?.group_id
                          );
                          setDatePickerModal(false);
                          setDateRangeTemp(dateRangeArr[1]);
                          setOpen(!open);
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading}
                      >
                        {loading && <Spinner size="sm" animation="border" />}{" "}
                        Apply
                      </Button>
                    </div>
                  </Form>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          {loading ? (
            <div className="position-relative wrapper listing-table">
              <img
                src={Loader}
                className="position-absolute top-50 start-50 translate-middle"
              />
            </div>
          ) : (
            <>
              {(selectedType2 == "All" ||
                selectedType2 == "Outbound Analytics") && (
                <>
                  <h4 className="mb-3">Outbound Analytics</h4>
                  <Card>
                    <Card.Body>
                      <Row className="g-4">
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`custom-col border-end-sm analytics-card ${
                            selectedSubType === "deliveryRate" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("deliveryRate");
                            setSelectedSubTypeLabel("Delivrered");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-check-all fs-2xl" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">Delivery Rate</p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              suffix="%"
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]?.deliveryRate || 0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(
                                        cardObj?.[dt]?.deliveryRate || 0
                                      ).toFixed(2)}
                                      %
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]?.deliveryRate || 0
                              ).toFixed(2)}
                              %
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm-0 custom-col border-end-md analytics-card ${
                            selectedSubType === "totalMessagesSent"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("totalMessagesSent");
                            setSelectedSubTypeLabel("Total messages sent");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-check fs-3xl" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Total messages sent
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              start={0}
                              end={
                                cardObj?.[selectedType]?.totalMessagesSent || 0
                              }
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {cardObj?.[dt]?.totalMessagesSent || 0}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {cardObj?.[selectedType]?.totalMessagesSent || 0}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`custom-col border-end-sm analytics-card ${
                            selectedSubType === "averageMessagesSent"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("averageMessagesSent");
                            setSelectedSubTypeLabel("Average messages sent");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-chevron-bar-expand fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Average messages sent
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]?.averageMessagesSent ||
                                0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(
                                        cardObj?.[dt]?.averageMessagesSent || 0
                                      ).toFixed(2)}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]?.averageMessagesSent ||
                                0
                              ).toFixed(2)}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`custom-col border-end-sm analytics-card ${
                            selectedSubType === "segmentSent" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("segmentSent");
                            setSelectedSubTypeLabel("Total segments sent");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-clipboard2-check fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Total segments sent
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={0}
                              start={0}
                              end={cardObj?.[selectedType]?.segmentSent || 0}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {cardObj?.[dt]?.segmentSent || 0}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {cardObj?.[selectedType]?.segmentSent || 0}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`custom-col border-end-0 analytics-card ${
                            selectedSubType === "averageSegmentsSent"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("averageSegmentsSent");
                            setSelectedSubTypeLabel("Average segment sent");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-boxes fs-lg" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Average segment sent
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]?.averageSegmentsSent ||
                                0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(
                                        cardObj?.[dt]?.averageSegmentsSent || 0
                                      ).toFixed(2)}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]?.averageSegmentsSent ||
                                0
                              ).toFixed(2)}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>

                  <Card>
                    <Card.Body>
                      <Row>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm analytics-card ${
                            selectedSubType === "failed" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("failed");
                            setSelectedSubTypeLabel("Failed");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-primary border border-primary rounded-circle">
                                <i className="bi bi-x-circle-fill fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">Failed</p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={0}
                              start={0}
                              end={cardObj?.[selectedType]?.failed || 0}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}: {cardObj?.[dt]?.failed || 0}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {cardObj?.[selectedType]?.failed || 0}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm analytics-card ${
                            selectedSubType === "failedRate" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("failedRate");
                            setSelectedSubTypeLabel("Failure rate");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-primary border border-primary rounded-circle">
                                <i className="bi bi-x-circle-fill fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">Failure rate</p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              suffix="%"
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]?.failedRate || 0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(cardObj?.[dt]?.failedRate || 0).toFixed(
                                        2
                                      )}
                                      %
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]?.failedRate || 0
                              ).toFixed(2)}
                              %
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm analytics-card ${
                            selectedSubType === "totalOptOut" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("totalOptOut");
                            setSelectedSubTypeLabel("Total Opt Out");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-primary border border-primary rounded-circle">
                                <i className="bi bi-send-fill fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">Total Opt Out</p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={0}
                              start={0}
                              end={cardObj?.[selectedType]?.totalOptOut || 0}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {cardObj?.[dt]?.totalOptOut || 0}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {cardObj?.[selectedType]?.totalOptOut || 0}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-0 analytics-card ${
                            selectedSubType === "optOutRate" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("optOutRate");
                            setSelectedSubTypeLabel("Opt Out rate");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-primary border border-primary rounded-circle">
                                <i className="bi bi-send-fill fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">Opt Out rate</p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              suffix="%"
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]?.optOutRate || 0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(cardObj?.[dt]?.optOutRate || 0).toFixed(
                                        2
                                      )}
                                      %
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]?.optOutRate || 0
                              ).toFixed(2)}
                              %
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </>
              )}

              {(selectedType2 == "All" ||
                selectedType2 == "Inbound Analytics") && (
                <>
                  <h4
                    className={`${selectedType2 == "All" ? "mt-4" : ""} mb-3`}
                  >
                    Inbound Analytics
                  </h4>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm analytics-card ${
                            selectedSubType === "totalInbound" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("totalInbound");
                            setSelectedSubTypeLabel("Total messages received");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div
                                style={{
                                  transform: "rotate(180deg)",
                                }}
                                className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle"
                              >
                                <i className="bi bi-send-fill fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Total messages received
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={0}
                              start={0}
                              end={cardObj?.[selectedType]?.totalInbound || 0}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {cardObj?.[dt]?.totalInbound || 0}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {cardObj?.[selectedType]?.totalInbound || 0}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm-0 border-end-md analytics-card ${
                            selectedSubType === "averageMessagesReceived"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("averageMessagesReceived");
                            setSelectedSubTypeLabel(
                              "Average messages received"
                            );
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div
                                style={{
                                  transform: "rotate(180deg)",
                                }}
                                className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle"
                              >
                                <i className="bi bi-send-fill fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Average messages received
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]
                                  ?.averageMessagesReceived || 0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(
                                        cardObj?.[dt]
                                          ?.averageMessagesReceived || 0
                                      ).toFixed(2)}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]
                                  ?.averageMessagesReceived || 0
                              ).toFixed(2)}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm analytics-card ${
                            selectedSubType === "segmentReceived"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("segmentReceived");
                            setSelectedSubTypeLabel("Total segment received");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-stack fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Total segment received
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={0}
                              start={0}
                              end={
                                cardObj?.[selectedType]?.segmentReceived || 0
                              }
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {cardObj?.[dt]?.segmentReceived || 0}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {cardObj?.[selectedType]?.segmentReceived || 0}
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-0 analytics-card ${
                            selectedSubType === "averageSegmentReceived"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("averageSegmentReceived");
                            setSelectedSubTypeLabel(
                              "Average segments received"
                            );
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-stack fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Average segments received
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              suffix="%"
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]
                                  ?.averageSegmentReceived || 0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(
                                        cardObj?.[dt]?.averageSegmentReceived ||
                                        0
                                      ).toFixed(2)}
                                      %
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]
                                  ?.averageSegmentReceived || 0
                              ).toFixed(2)}
                              %
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>

                  <Card>
                    <Card.Body>
                      <Row>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm analytics-card ${
                            selectedSubType === "responseRate" ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("responseRate");
                            setSelectedSubTypeLabel("Response rate");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-send-fill fs-md" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">Response rate</p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              suffix="%"
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]?.responseRate || 0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(
                                        cardObj?.[dt]?.responseRate || 0
                                      ).toFixed(2)}
                                      %
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]?.responseRate || 0
                              ).toFixed(2)}
                              %
                            </p>
                          )}
                        </Col>
                        <Col
                          xl={3}
                          md={3}
                          sm={6}
                          className={`border-end-sm-0 border-end-md analytics-card ${
                            selectedSubType === "averageResponseTime"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedSubType("averageResponseTime");
                            setSelectedSubTypeLabel("Average time to response");
                          }}
                        >
                          <div className="d-flex align-items-center gap-2 mb-4">
                            <div className="avatar-xs flex-shrink-0">
                              <div className="avatar-title bg-body-secondary text-secondary border border-secondary rounded-circle">
                                <i className="bi bi-stopwatch-fill fs-lg" />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-0">
                                Average time to response
                              </p>
                            </div>
                          </div>
                          <h3>
                            <CountUp
                              decimals={2}
                              start={0}
                              end={(
                                cardObj?.[selectedType]?.averageResponseTime ||
                                0
                              ).toFixed(2)}
                            />
                          </h3>
                          {selectedType === "All" ? (
                            Object.keys(cardObj)?.length > 0 ? (
                              Object.keys(cardObj)?.map(
                                (dt, i) =>
                                  dt !== "All" && (
                                    <p className="text-muted mb-1" key={i}>
                                      {dt || ""}:{" "}
                                      {(
                                        cardObj?.[dt]?.averageResponseTime || 0
                                      ).toFixed(2)}
                                    </p>
                                  )
                              )
                            ) : null
                          ) : (
                            <p className="text-muted mb-1">
                              {selectedType || ""}:{" "}
                              {(
                                cardObj?.[selectedType]?.averageResponseTime ||
                                0
                              ).toFixed(2)}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </>
              )}

              <Card className="card-height-100">
                <Card.Header className="d-flex align-items-center justify-content-between">
                  <h6 className="card-title mb-2">{selectedSubTypeLabel}</h6>
                  <div>
                    <Button
                      variant={graphType1 === "Column" ? "secondary" : "light"}
                      className="ms-2 fs-2xl p-0"
                      onClick={() => setGraphType1("Column")}
                    >
                      <i className="ph ph-chart-bar m-2" />
                    </Button>
                    <Button
                      variant={graphType1 === "Bar" ? "secondary" : "light"}
                      className="ms-2 fs-2xl p-0"
                      onClick={() => setGraphType1("Bar")}
                    >
                      <i className="ph ph-chart-line m-2" />
                    </Button>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="w-100">
                    {graphType1 === "Column" ? (
                      <StackedColumn2
                        graphObj={dateGraphObj}
                        selectedSubType={selectedSubType}
                      />
                    ) : (
                      <LineChart
                        graphObj={dateGraphObj}
                        selectedSubType={selectedSubType}
                      />
                    )}
                  </div>
                </Card.Body>
              </Card>

              <Card className="card-height-100">
                <Card.Header className="d-flex align-items-center justify-content-between">
                  <h6 className="card-title mb-2">{selectedSubTypeLabel}</h6>
                  <div>
                    <Button
                      variant={graphType2 === "Column" ? "secondary" : "light"}
                      className="ms-2 fs-2xl p-0"
                      onClick={() => setGraphType2("Column")}
                    >
                      <i className="ph ph-chart-bar m-2" />
                    </Button>
                    <Button
                      variant={graphType2 === "Bar" ? "secondary" : "light"}
                      className="ms-2 fs-2xl p-0"
                      onClick={() => setGraphType2("Bar")}
                    >
                      <i className="ph ph-chart-line m-2" />
                    </Button>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="w-100">
                    {graphType2 === "Column" ? (
                      <StackedColumn2
                        graphObj={numberGraphObj}
                        selectedSubType={selectedSubType}
                      />
                    ) : (
                      <LineChart
                        graphObj={numberGraphObj}
                        selectedSubType={selectedSubType}
                      />
                    )}
                  </div>
                </Card.Body>
              </Card>

              <Card className="card-height-100">
                <Card.Body>
                  <Tab.Container
                    key={selectedType}
                    // activeKey={selectedType === "MMS" ? "mms" : "sms"}
                    defaultActiveKey={selectedType === "MMS" ? "mms" : "sms"}
                  >
                    <Nav
                      as="ul"
                      variant="tabs"
                      className="nav-tabs-custom nav-primary mb-4"
                    >
                      {(selectedType === "All" || selectedType === "SMS") && (
                        <Nav.Item as="li">
                          <Nav.Link eventKey="sms">SMS</Nav.Link>
                        </Nav.Item>
                      )}
                      {(selectedType === "All" || selectedType === "MMS") && (
                        <Nav.Item as="li">
                          <Nav.Link eventKey="mms">MMS</Nav.Link>
                        </Nav.Item>
                      )}
                    </Nav>
                    <Tab.Content>
                      {(selectedType === "All" || selectedType === "SMS") && (
                        <Tab.Pane eventKey="sms">
                          <SmsAndMmsLogs
                            key={selectedType}
                            loading={loading2}
                            rowsPerPage={rowsPerPageSms}
                            currPage={currPageSms}
                            logsData={smsStatusLogs}
                            handleRowClick={handleSmsRowClick}
                            handlePageChange={handleSmsPageChange}
                            handleRowsPerPageChange={handleSmsRowsPerPageChange}
                          />
                        </Tab.Pane>
                      )}
                      {(selectedType === "All" || selectedType === "MMS") && (
                        <Tab.Pane eventKey="mms">
                          <SmsAndMmsLogs
                            key={selectedType}
                            loading={loading2}
                            rowsPerPage={rowsPerPageMms}
                            currPage={currPageMms}
                            logsData={mmsStatusLogs}
                            handleRowClick={handleMmsRowClick}
                            handlePageChange={handleMmsPageChange}
                            handleRowsPerPageChange={handleMmsRowsPerPageChange}
                          />
                        </Tab.Pane>
                      )}
                    </Tab.Content>
                  </Tab.Container>
                </Card.Body>
              </Card>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Analytics);
