import {
  campaignListApi,
  usecaseFromBrandApi,
  termsFromUsecaseApi,
  termsAndConditionsApi,
  createCampaignApi,
  uploadCampaignMmsApi,
  campaignDetailsApi,
  getCampaignMnoMetadataApi,
  getCampaignMmsApi,
  campaignOperationStatusApi,
  campaignDcaElectedApi,
  updateCampaignApi,
  getCampaignAttachmentApi,
  deleteCampaignAttachmentApi,
  nudgeCampaignApi,
  resubmitCampaignApi,
  deactivateCampaignApi,
  campaignPartnerDetailsApi,
  cspWebhookEventsListApi,
  numProvListListApi,
} from "helpers/apiroute_helper";
import {
  apiLoading,
  apiLoading2,
  apiLoading3,
  apiLoading4,
  campaignsDataSuccessful,
  campaignsDataFailed,
  campaignsPartnerDataSuccessful,
  campaignsPartnerDataFailed,
  campaignUseCaseDataSuccessful,
  campaignUseCaseDataFailed,
  campaignTermsSuccessful,
  campaignTermsFailed,
  campaignTnCSuccessful,
  campaignTnCFailed,
  campaignCreateSuccessful,
  campaignCreateFailed,
  uploadCampaignMmsSuccessful,
  uploadCampaignMmsFailed,
  campaignDetailsSuccessful,
  campaignDetailsFailed,
  campaignMnoMetadataSuccessful,
  campaignMnoMetadataFailed,
  campaignMmsDataSuccessful,
  campaignMmsDataFailed,
  campaignOperationStatusSuccessful,
  campaignOperationStatusFailed,
  campaignDcaElectedSuccessful,
  campaignDcaElectedFailed,
  campaignUpdateSuccessful,
  campaignUpdateFailed,
  campaignAttachmentDataSuccessful,
  campaignAttachmentDataFailed,
  campaignAttachmentDeleteSuccessful,
  campaignAttachmentDeleteFailed,
  nudgeCampaignSuccessful,
  nudgeCampaignFailed,
  resubmitCampaignSuccessful,
  resubmitCampaignFailed,
  deactivateCampaignSuccessful,
  deactivateCampaignFailed,
  campaignsDataReset,
  cspWebhookEventsDataSuccessful,
  cspWebhookEventsDataFailed,
  currNumProvDataSuccessful,
  currNumProvDataFailed,
  prevNumProvDataSuccessful,
  prevNumProvDataFailed,
} from "./reducer";
import { toast } from "react-toastify";

export const getCampaignListThunk =
  (page?: number, recordsPerPage?: number, otherParams?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await campaignListApi(
        page,
        recordsPerPage,
        otherParams
      );

      if (data?.code || data?.errorCode) {
        dispatch(campaignsDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(campaignsDataSuccessful(data));
      }
    } catch (error: any) {
      dispatch(campaignsDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getCampaignPartnerListThunk =
  (page?: number, recordsPerPage?: number, otherParams?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading2());

    try {
      const data: any = await campaignListApi(
        page,
        recordsPerPage,
        otherParams
      );

      if (data?.code || data?.errorCode) {
        dispatch(campaignsPartnerDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(campaignsPartnerDataSuccessful(data));
      }
    } catch (error: any) {
      dispatch(campaignsPartnerDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const resetCampaignList = () => async (dispatch: any) => {
  dispatch(apiLoading());
  dispatch(campaignsDataReset());
};

export const getUsecaseFromBrandListThunk =
  (brandId: string) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await usecaseFromBrandApi(brandId);

      if (data?.code || data?.message) {
        dispatch(campaignUseCaseDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(campaignUseCaseDataSuccessful(data));
      }
    } catch (error: any) {
      dispatch(campaignUseCaseDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getTermsFromUsecaseListThunk =
  (brandId: string, usecaseId: string) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await termsFromUsecaseApi(brandId, usecaseId);

      if (data?.code || data?.message) {
        dispatch(campaignTermsFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(campaignTermsSuccessful(data));
      }
    } catch (error: any) {
      dispatch(campaignTermsFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getTermsAndConditionsThunk = () => async (dispatch: any) => {
  dispatch(apiLoading());

  try {
    const data: any = await termsAndConditionsApi();

    if (data?.code || data?.message) {
      dispatch(campaignTnCFailed(data));
      toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(campaignTnCSuccessful(data));
    }
  } catch (error: any) {
    dispatch(campaignTnCFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const createCampaignThunk =
  (campaign: any, history: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading());

      let data: any = await createCampaignApi({
        ...campaign,
      });

      if (data?.code || data?.errorCode) {
        dispatch(campaignCreateFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        const cb = () => history("/campaigns");
        if (data?.campaignId && campaign.mmsMediaUuid?.length > 0) {
          dispatch(
            uploadCampaignMmsThunk(data?.campaignId, campaign.mmsMediaUuid, cb)
          );
        } else {
          cb();
        }
        dispatch(campaignCreateSuccessful(data));
        toast.success("Campaign created successfully");
      }
    } catch (error: any) {
      dispatch(campaignCreateFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const uploadCampaignMmsThunk =
  (id: string, files: any, cb?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading());

      const promises = files?.map(async (dt: any) => {
        const formData = new FormData();
        formData.append("file", dt);
        return await uploadCampaignMmsApi(id, formData, true);
      });
      const results = await Promise.all(promises);

      if (results?.[0]?.uuid) {
        dispatch(
          uploadCampaignMmsSuccessful("Campaign MMS uploaded successfully")
        );
        toast.success("Campaign MMS uploaded successfully");
        cb && cb();
      } else {
        dispatch(uploadCampaignMmsFailed("Something went wrong"));
        toast.error("Something went wrong");
      }
    } catch (error: any) {
      dispatch(uploadCampaignMmsFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getCampaignDetailsThunk =
  (id: string, isPartner?: boolean) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = isPartner
        ? await campaignPartnerDetailsApi(id)
        : await campaignDetailsApi(id);

      if (data?.code || data?.message) {
        dispatch(campaignDetailsFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(campaignDetailsSuccessful(data));
      }
    } catch (error: any) {
      dispatch(campaignDetailsFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getCampaignMnoMetadataThunk =
  (id: string, isPartner?: boolean) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await getCampaignMnoMetadataApi(id, isPartner);

      if (data?.code || data?.message) {
        dispatch(campaignMnoMetadataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(campaignMnoMetadataSuccessful(data));
      }
    } catch (error: any) {
      dispatch(campaignMnoMetadataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getCampaignMmsThunk = (id: string) => async (dispatch: any) => {
  dispatch(apiLoading());

  try {
    const data: any = await getCampaignMmsApi(id);

    if (data?.code || data?.message) {
      dispatch(campaignMmsDataFailed(data));
      toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(campaignMmsDataSuccessful(data));
    }
  } catch (error: any) {
    dispatch(campaignMmsDataFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const getCampaignOperationStatusThunk =
  (id: string, isPartner?: boolean) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await campaignOperationStatusApi(id, isPartner);

      if (data?.code || data?.message) {
        dispatch(campaignOperationStatusFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(campaignOperationStatusSuccessful(data));
      }
    } catch (error: any) {
      dispatch(campaignOperationStatusFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getCampaignDcaElectedThunk =
  (id: string) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await campaignDcaElectedApi(id);

      if (data?.code || data?.message) {
        dispatch(campaignDcaElectedFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(campaignDcaElectedSuccessful(data));
      }
    } catch (error: any) {
      dispatch(campaignDcaElectedFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const updateCampaignThunk =
  (id: string, campaign: any, cb?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading2());

      let data: any = await updateCampaignApi(id, {
        ...campaign,
      });

      if (data?.code || data?.message) {
        dispatch(campaignUpdateFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(campaignUpdateSuccessful(data));
        toast.success("Campaign updated successfully");
        cb && cb();
      }
    } catch (error: any) {
      dispatch(campaignUpdateFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getCampaignAttachmentThunk =
  (id: string, cb?: any) => async (dispatch: any) => {
    dispatch(apiLoading2());
    try {
      const data: any = await getCampaignAttachmentApi(id);
      if (data?.code || data?.message) {
        dispatch(campaignAttachmentDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(campaignAttachmentDataSuccessful(data));
        cb &&
          cb(
            new File([data], `file.${data?.type?.split("/").pop()}`, {
              type: data?.type,
            })
          );
      }
    } catch (error: any) {
      dispatch(campaignAttachmentDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const deleteCampaignAttachmentThunk =
  (id: string, cpId: string, cb?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading2());
      let data: any = await deleteCampaignAttachmentApi(id);
      if (data?.code || data?.message) {
        dispatch(campaignAttachmentDeleteFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(campaignAttachmentDeleteSuccessful(data));
        toast.success("Campaign MMS deleted successfully");
        dispatch(getCampaignMmsThunk(cpId));
        cb && cb();
      }
    } catch (error: any) {
      dispatch(campaignAttachmentDeleteFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const nudgeCampaignThunk =
  (id: string, nudge: any, cb?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading2());

      let data: any = await nudgeCampaignApi(id, nudge);

      if (data?.code || data?.message) {
        dispatch(nudgeCampaignFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(nudgeCampaignSuccessful(data));
        toast.success("Message sent successfully");
        cb && cb();
      }
    } catch (error: any) {
      dispatch(nudgeCampaignFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const resubmitCampaignThunk =
  (id: string, mnos: any, cb?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading2());
      let data: any = await resubmitCampaignApi(id, {
        ...mnos,
      });
      if (data?.code || data?.message) {
        dispatch(resubmitCampaignFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(resubmitCampaignSuccessful(data));
        toast.success("Campaign resubmitted successfully");
        cb && cb();
      }
    } catch (error: any) {
      dispatch(resubmitCampaignFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const deactivateCampaignThunk =
  (id: string, cb: any, isPartner?: boolean) => async (dispatch: any) => {
    try {
      dispatch(apiLoading2());

      let data: any = await deactivateCampaignApi(id);

      if (data?.code || data?.message) {
        dispatch(deactivateCampaignFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(deactivateCampaignSuccessful(data));
        toast.success("Campaign deactivated successfully");
        dispatch(getCampaignDetailsThunk(id, isPartner));
        cb && cb();
      }
    } catch (error: any) {
      dispatch(deactivateCampaignFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getCspWebhookEventsListThunk =
  (page?: number, recordsPerPage?: number, otherParams?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading3());

    try {
      const data: any = await cspWebhookEventsListApi(
        page,
        recordsPerPage,
        otherParams
      );

      if (data?.code || data?.errorCode) {
        dispatch(cspWebhookEventsDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(cspWebhookEventsDataSuccessful(data));
      }
    } catch (error: any) {
      dispatch(cspWebhookEventsDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getCurrNumProvListThunk =
  (
    campaignId: string,
    page?: number,
    recordsPerPage?: number,
    otherParams?: any
  ) =>
  async (dispatch: any) => {
    dispatch(apiLoading4());

    try {
      const data: any = await numProvListListApi(
        campaignId,
        page,
        recordsPerPage,
        otherParams
      );

      if (data?.code || data?.errorCode) {
        dispatch(currNumProvDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(currNumProvDataSuccessful(data));
      }
    } catch (error: any) {
      dispatch(currNumProvDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getPrevNumProvListThunk =
  (
    campaignId: string,
    page?: number,
    recordsPerPage?: number,
    otherParams?: any
  ) =>
  async (dispatch: any) => {
    dispatch(apiLoading4());

    try {
      const data: any = await numProvListListApi(
        campaignId,
        page,
        recordsPerPage,
        otherParams
      );

      if (data?.code || data?.errorCode) {
        dispatch(prevNumProvDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(prevNumProvDataSuccessful(data));
      }
    } catch (error: any) {
      dispatch(prevNumProvDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };
