import {
  Col,
  Row,
  Button,
  Spinner,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import withRouter from "Common/withRouter";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { submitLoadingSubject } from "Common/modals/Modal";
import Loader from "assets/images/spinner-dark.svg";
import { Modal } from "react-bootstrap";
import {
  openModal,
  releasePhoneNumberThunk,
  getDetailsOfOwnNumber,
  setOwnNumberDetailInitialState,
  configureNumbers,
} from "slices/thunk";
import Tooltip from "rc-tooltip";
import { dateFormat } from "helpers/common";

const NumberProperties = ({ router }: any) => {
  const dispatch = useDispatch<any>();
  const { phoneNumber, sid } = useParams();

  const [details, setDetails] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [returnsEnabled, setReturnsEnabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pendingEnableState, setPendingEnableState] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const handleToggle = () => setShowAll(!showAll);

  const phoneNumberDetails = useSelector(
    (state: any) => state?.Numbers?.numberDetails
  );

  useEffect(() => {
    dispatch(setOwnNumberDetailInitialState());
  }, []);

  useEffect(() => {
    if (phoneNumberDetails) {
      setDetails(phoneNumberDetails);
      setReturnsEnabled(
        phoneNumberDetails.numberStatus === "Active" ? false : true
      );
    }
  }, [phoneNumberDetails]);

  useEffect(() => {
    getOwnPhoneNumber();
  }, [phoneNumber]);

  const getOwnPhoneNumber = () => {
    setLoading(true);
    dispatch(
      getDetailsOfOwnNumber({
        phoneNumber: phoneNumber,
      })
    ).then(() => setLoading(false));
  };

  const handleShow = () => {
    dispatch(
      openModal({
        path: "EditTags",
        data: {
          title: "Tags",
          detailsTags: details.tags,
          phoneNumber,
          getOwnPhoneNumber,
          footer: false,
          cancelBtn: true,
          buttonText: {
            submit: "Update",
          },
        },
      })
    );
  };

  const handleShowfriendlyName = () => {
    dispatch(
      openModal({
        path: "AddFriendlyName",
        data: {
          title: "Add Friendly Name",
          detailsfriendlyName: details.friendlyName,
          phoneNumber,
          getOwnPhoneNumber,
          footer: false,
          cancelBtn: true,
          buttonText: {
            submit: "Update",
          },
        },
      })
    );
  };

  const handleReleaseClick = () => {
    dispatch(
      openModal({
        modalbody: "Are you sure you want to release this number?",
        data: {
          title: "Release Number",
          footer: true,
          cancelBtn: true,
          buttonText: {
            submit: "Yes",
            cancel: "No",
          },
          onSubmit: ({ onClose }: any) => {
            const cb = () => {
              router.navigate("/mynumbers");
            };

            submitLoadingSubject.next(true);
            dispatch(
              releasePhoneNumberThunk(
                {
                  phoneNumbers: [`${phoneNumber}`],
                },
                cb
              )
            ).then(() => {
              submitLoadingSubject.next(false);
              onClose();
            });
          },
        },
      })
    );
  };

  const handleModalConfirm = () => {
    setReturnsEnabled(pendingEnableState);
    setShowModal(false);
    dispatch(
      configureNumbers({
        numberStatus: !pendingEnableState ? "Active" : "Inactive",
        phoneNumber: phoneNumber,
        sid: sid,
      })
    ).then(() => {
      dispatch(
        getDetailsOfOwnNumber({
          phoneNumber: phoneNumber,
        })
      );
    });
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };

  const handleEnableDisableClick = () => {
    setPendingEnableState(!returnsEnabled);
    setShowModal(true);
  };

  return (
    <div className="container-fluid">
      <div style={{ minHeight: "550px" }}>
        {loading ? (
          <div>
            <img
              src={Loader}
              className={`position-absolute top-50 start-50 translate-middle`}
            />
          </div>
        ) : (
          <>
            <div className="mb-1">
              <h5>Number Properties</h5>
            </div>
            <Col lg={12} className="my-3">
              <Row className="border bg-light-gray rounded-3 p-3">
                <Col md={6} className="mb-3">
                  <h6 className="mb-1">
                    Tags:
                    <i
                      className="bi bi-pencil cursor-pointer text-secondary ms-2"
                      onClick={handleShow}
                    />
                  </h6>
                  <div className="d-flex flex-wrap gap-2">
                    {details.tags?.map((tag: string, i: number) => (
                      <span className="badge bg-secondary text-white" key={i}>
                        {tag}
                      </span>
                    ))}
                  </div>
                </Col>
                <Col md={6} className="mb-3">
                  <h6 className="mb-1">
                    Friendly Name:
                    <i
                      className="bi bi-pencil cursor-pointer text-secondary ms-2"
                      onClick={handleShowfriendlyName}
                    />
                  </h6>
                  <p className="mb-0">{details.friendlyName || "N/A"}</p>
                </Col>
                <Col md={6} className="mb-3">
                  <h6 className="mb-1">Phone Number SID:</h6>
                  <p className="mb-0">{details?.signalHouseSID || "N/A"}</p>
                </Col>
                <Col md={6} className="mb-3">
                  <h6 className="mb-1">Capabilities:</h6>
                  <div className="d-flex flex-row gap-3">
                    {details?.capabilities?.voice && (
                      <Tooltip placement="bottom" overlay={"Voice"}>
                        <i className="bi bi-telephone fs-4 text-secondary"></i>
                      </Tooltip>
                    )}
                    {details?.capabilities?.sms && (
                      <Tooltip placement="bottom" overlay={"SMS"}>
                        <i className="bi bi-chat-left-text fs-4 text-secondary"></i>
                      </Tooltip>
                    )}
                    {details?.capabilities?.mms && (
                      <Tooltip placement="bottom" overlay={"MMS"}>
                        <i className="bi bi-file-image fs-4 text-secondary"></i>
                      </Tooltip>
                    )}
                    {/* {details?.capabilities?.fax && (
                      <Tooltip placement="bottom" overlay={"Fax"}>
                        <i className="bi bi-file-earmark-text fs-4 text-secondary"></i>
                      </Tooltip>
                    )} */}
                  </div>
                </Col>
                <Col md={6} className="mb-3">
                  <h6 className="mb-1">Purchase Date:</h6>
                  <p className="mb-0">
                    {details?.purchaseDate
                      ? dateFormat(details.purchaseDate)
                      : "N/A"}
                  </p>
                </Col>
                <Col md={6} className="mb-3">
                  <h6 className="mb-1">Renewal Date:</h6>
                  <p className="mb-0">
                    {details?.renewDate ? dateFormat(details.renewDate) : "N/A"}
                  </p>
                </Col>
                <Col md={6} className="mb-3">
                  <h6 className="mb-1">Brand Id:</h6>
                  <p className="mb-0">{details?.brandId || "N/A"}</p>
                </Col>
                <Col md={6}>
                  <h6 className="mb-1">Campaign Id:</h6>
                  <p className="mb-0">{details?.campaignId || "N/A"}</p>
                </Col>
                <Col md={6}>
                  <h6 className="mb-1">Group Id:</h6>
                  <p className="mb-0">{details?.groupId || "N/A"}</p>
                </Col>
                <Col md={6}>
                  <h6 className="mb-1">Sub Groups:</h6>
                  <div className="d-flex align-items-center">
                    {details?.subGroupId?.length > 0 ? (
                      <>
                        <p className="mb-0 mr-2">
                          {details.subGroupId.length} Sub Groups
                        </p>
                        <Dropdown>
                          <div style={{ position: "relative" }}>
                            <DropdownButton
                              id="dropdown-basic"
                              title={
                                <span className="text-secondary">
                                  Show Details
                                </span>
                              }
                              onClick={handleToggle}
                              variant="link text-secondary"
                              className="p-0"
                              style={{
                                paddingRight: "1.5rem", // Add space for the arrow
                              }}
                            >
                              {showAll ? (
                                details.subGroupId.map(
                                  (id: any, index: any) => (
                                    <Dropdown.Item key={index}>
                                      {id}
                                    </Dropdown.Item>
                                  )
                                )
                              ) : (
                                <Dropdown.Item>N/A</Dropdown.Item>
                              )}
                            </DropdownButton>
                          </div>
                        </Dropdown>
                      </>
                    ) : (
                      <p className="mb-0">N/A</p>
                    )}
                  </div>
                </Col>

                <Col md={6} className="mb-3 mt-2">
                  <h6 className="mb-1">Phone Number Type:</h6>
                  <p className="mb-0">Local</p>
                </Col>
              </Row>
            </Col>

            <Row>
              <Col xl={6} lg={6}>
                <div className="mb-1 mt-3">
                  <h5>Change Number Status</h5>
                </div>
                <Col lg={12} className="mt-3">
                  <div className="border bg-light-gray rounded-3 h-8 p-4">
                    <h6>Number Status</h6>
                    <p className="text-muted" style={{ marginBottom: "35px" }}>
                      Currently, the number is{" "}
                      {returnsEnabled ? "inactive" : "active"}. Press the button
                      to {returnsEnabled ? "activate" : "deactivate"} the
                      number.
                    </p>
                    <Button
                      variant={loading ? "danger" : ""}
                      className="btn btn-subtle-primary"
                      disabled={loading}
                      onClick={handleEnableDisableClick}
                    >
                      {loading && <Spinner size="sm" animation="border" />}{" "}
                      {returnsEnabled ? "Activate" : "Deactivate"}
                    </Button>
                    <Modal show={showModal} onHide={handleModalCancel}>
                      <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Are you sure you want to{" "}
                        {returnsEnabled ? "activate" : "deactivate"} this
                        number?
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="outline-primary"
                          onClick={handleModalCancel}
                        >
                          Cancel
                        </Button>
                        <Button variant="primary" onClick={handleModalConfirm}>
                          {returnsEnabled ? "Activate" : "Deactivate"}
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </Col>
              </Col>

              <Col xl={6} lg={6}>
                <div className="mb-1 mt-3">
                  <h5>Actions</h5>
                </div>
                <Col lg={12} className="mt-3">
                  <div className="border bg-light-gray rounded-3 h-8 p-4">
                    <h6 className="">Release phone number</h6>
                    <p className="text-muted">
                      Release this phone number from the account. After release,
                      phone number can be repurchased up to 10 days from the
                      time of release.
                    </p>
                    <Button
                      variant={loading ? "danger" : ""}
                      className="btn btn-subtle-danger"
                      disabled={loading}
                      onClick={handleReleaseClick}
                    >
                      {loading && <Spinner size="sm" animation="border" />}{" "}
                      Release phone number
                    </Button>
                  </div>
                </Col>
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(NumberProperties);
