import React from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

const NavSettingdata = () => {
  const selector = createSelector(
    (state: any) => state.Groups,
    (groups) => ({
      getGroupsData: groups.AllGroups,
    })
  );

  const { getGroupsData } = useSelector(selector);

  const menuItems: any = [
    {
      label: "Account",
      isHeader: true,
    },
    {
      id: "general",
      label: "General",
      icon: "ph-user",
      link: "/settings/general",
    },
    {
      id: "security",
      label: "Security",
      icon: "ph-lock-simple",
      link: "/settings/security",
    },
    {
      id: "users",
      label: "Users",
      icon: "ph-users",
      link: "/settings/users",
    },
    {
      id: "groups",
      label: "Sub Groups",
      icon: "ph-users-three",
      link: `/settings/groups/${getGroupsData?.records?.[0]?.group_id}/subgroups`,
    },
    // {
    //   id: "webhook",
    //   label: "Webhooks",
    //   icon: "ph-list-bullets",
    //   link: "/settings/webhooks",
    // },
    {
      id: "notifications",
      label: "Notifications",
      icon: "ph-bell",
      link: "/settings/notification-events",
    },
    {
      label: "Company",
      isHeader: true,
    },
    {
      id: "billing",
      label: "Billing",
      icon: "ph-money",
      link: "/settings/billing",
    },
    {
      id: "account-pricing",
      label: "Account Pricing",
      icon: "ph-money",
      link: "/settings/account-pricing",
    },
    {
      id: "usage-transactions",
      label: "Usage Transactions",
      icon: "ph-activity",
      link: "/settings/usage-transactions",
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};

export default NavSettingdata;
