import React from "react";

//import Scss
import "assets/scss/themes.scss";

//imoprt Route
import Route from "Routes/Index";
// import { createSelector } from "reselect";
// import { useDispatch, useSelector } from "react-redux";
// import { Alert, Button } from "react-bootstrap";
// import { hideToastThunk } from "slices/thunk";
import CustomModal from "./Common/modals/Modal";
import CustomBottomSheet from "./Common/bottomsheet/Bottomsheet";

const App = () => {
  // const dispatch = useDispatch<any>();
  // const selectToast = createSelector(
  //   (state: any) => state.Toast,
  //   (toast) => ({
  //     show: toast.show,
  //     toastVariant: toast.toastVariant,
  //     toastClass: toast.toastClass,
  //     toastIcon: toast.toastIcon,
  //     toastMsg: toast.toastMsg,
  //   })
  // );

  // const { show, toastVariant, toastClass, toastIcon, toastMsg } =
  //   useSelector(selectToast);

  return (
    <React.Fragment>
      {/* alert comment */}
      {/* <Alert
        // show={show}
        variant={toastVariant}
        // dismissible
        className={`alert-dismissible bg-${toastVariant}
        alert-label-icon fade show text-white
        ${toastClass} position-fixed start-50 translate-middle-x ${
          show ? "mt-3 opacity-100" : "mt-0 opacity-0"
        }`}
        style={{
          // transitionProperty: "margin-top",
          // transitionDuration: "0.2s",
          transition: "margin-top  0.5s ease 0s,opacity 1s ease 0s",
          zIndex: 1000,
        }}
      >
        <i className={`${toastIcon} label-icon`}></i>
        {toastMsg}
        <Button
          type="button"
          className="btn-close-white btn-close"
          onClick={() => dispatch(hideToastThunk())}
        ></Button>
      </Alert> */}
      <CustomModal />
      <CustomBottomSheet />
      <Route />
    </React.Fragment>
  );
};

export default App;
