import React, { useEffect, useState } from "react";
import {
  Container,
  Dropdown,
  Card,
  Button,
  Form,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import Datatable from "../../Common/Datatable";
import {
  capitalizeString,
  dateFormat,
  removeEmptyAndNullValues,
  humanizeString,
  customSelectTheme,
  styleObj,
} from "helpers/common";
import Loader from "assets/images/spinner-dark.svg";
import withRouter from "Common/withRouter";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Select from "react-select";
import {
  getCountryListThunk,
  getEntityTypeListThunk,
  getBrandListThunk,
  deleteBrandThunk,
  openModal,
} from "slices/thunk";
import { submitLoadingSubject } from "Common/modals/Modal";
import { AsyncPaginate } from "react-select-async-paginate";
import { brandListApi } from "helpers/apiroute_helper";

const BrandsList = (props: any) => {
  document.title = "Signal House Portal Brands";
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);

  const selectProfile = createSelector(
    (state: any) => state.Brands,
    (state: any) => state.Enums,
    (brands, enums) => ({
      loading: brands.loading,
      brandsData: brands.brandsData,
      countryObj: enums.countryObj,
      entityTypeArr: enums.entityTypeArr,
      error: brands.error,
    })
  );

  const { loading, brandsData, countryObj, entityTypeArr } =
    useSelector(selectProfile);

  const columns = [
    {
      name: (
        <span data-tag="allowRowEvents" className="font-weight-bold fs-sm">
          Brand ID
        </span>
      ),
      selector: (row: { brandId: string }) => (
        <span data-tag="allowRowEvents" className="text-primary">
          {row.brandId}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Country</span>,
      selector: (row: { country: string }) => row.country,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Name</span>,
      selector: (row: { displayName: string }) =>
        capitalizeString(row.displayName),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Entity Type</span>,
      selector: (row: { entityType: string }) => (
        <span
          data-tag="allowRowEvents"
          className="badge bg-body-secondary border border-info text-info"
        >
          {humanizeString(row.entityType)}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Create Date</span>,
      selector: (row: { createDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.createDate)}
        </span>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      sortable: true,
      selector: (cell: { brandIdentityStatus: string }) => {
        switch (cell.brandIdentityStatus.toLowerCase()) {
          case "Re-open":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-info"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-info p-1 me-2"
                >
                  <span></span>
                </span>
                {humanizeString(cell.brandIdentityStatus)}
              </span>
            );
          case "On-Hold":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-secondary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                {humanizeString(cell.brandIdentityStatus)}
              </span>
            );
          case "Closed":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-danger"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-danger p-1 me-2"
                >
                  <span></span>
                </span>
                {humanizeString(cell.brandIdentityStatus)}
              </span>
            );
          case "Inprogress":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-warning"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-warning p-1 me-2"
                >
                  <span></span>
                </span>
                {humanizeString(cell.brandIdentityStatus)}
              </span>
            );
          case "Open":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-primary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2"
                >
                  <span></span>
                </span>
                {humanizeString(cell.brandIdentityStatus)}
              </span>
            );
          case "New":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-success"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-success p-1 me-2"
                >
                  <span></span>
                </span>
                {humanizeString(cell.brandIdentityStatus)}
              </span>
            );
          case "verified":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-success"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-success p-1 me-2"
                >
                  <span></span>
                </span>
                {humanizeString(cell.brandIdentityStatus)}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-warning"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-warning p-1 me-2"
                >
                  <span></span>
                </span>
                {humanizeString(cell.brandIdentityStatus)}
              </span>
            );
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Action</span>,
      cell: (cell: { brandId: string }) => {
        return (
          <>
            <i
              className="ri-eye-fill align-middle me-3 text-muted fs-xl"
              onClick={() => navigate(`/brands/${cell.brandId}`)}
            />
            <i
              className="ri-pencil-fill align-middle me-3 text-muted fs-xl"
              onClick={() => navigate(`/brands/edit/${cell.brandId}`)}
            />
            <i
              className="ri-delete-bin-fill align-middle text-muted fs-xl"
              onClick={() => {
                dispatch(
                  openModal({
                    modalbody:
                      "Are you sure you want to delete this brand? This action is irrevocable.",
                    data: {
                      title: "Delete Brand",
                      footer: true,
                      cancelBtn: true,
                      buttonText: {
                        submit: "Delete",
                      },
                      onSubmit: ({ onClose }: any) => {
                        const cb = () => {
                          onClose();
                          handleGetData(currPage, rowsPerPage);
                        };
                        submitLoadingSubject.next(true);
                        dispatch(deleteBrandThunk(cell.brandId, cb)).then(
                          () => {
                            submitLoadingSubject.next(false);
                          }
                        );
                      },
                    },
                  })
                );
              }}
            />
          </>
        );
      },
    },
  ];

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      brandId: "",
      displayName: "",
      country: "",
      entityType: "",
      ein: "",
      universalEin: "",
      identityStatus: "",
      referenceId: "",
    },
    onSubmit: (values) => {
      const temp = removeEmptyAndNullValues({ ...values });
      handleGetData(currPage, rowsPerPage, temp);
    },
  });

  useEffect(() => {
    dispatch(getCountryListThunk());
    dispatch(getEntityTypeListThunk());
    handleGetData(currPage, rowsPerPage);
  }, []);

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(getBrandListThunk(page, perPage, otherParams || {}));
  };

  const handleRowClick = (row: { brandId: string }) => {
    props.router.navigate(`/brands/${row.brandId}`);
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(page, rowsPerPage);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(1, rows);
  };

  const setDropdown = () => setOpen(!open);

  const loadOptions2 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await brandListApi(
      page,
      10,
      removeEmptyAndNullValues({
        brandId: searchQuery.toUpperCase() || "",
      })
    );

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const loadOptions4 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await brandListApi(
      page,
      10,
      removeEmptyAndNullValues({
        brandId: validation.values.brandId || "",
        brandName: searchQuery.toUpperCase() || "",
      })
    );

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Brands"
            isFilter={true}
            filterButton={
              <Dropdown
                align="end"
                className="filterDropDown"
                show={open}
                onToggle={setDropdown}
              >
                <Dropdown.Toggle variant="secondary">
                  <i className="bx bx-filter-alt me-1"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-md p-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      setDropdown();
                      validation.handleSubmit();
                    }}
                  >
                    <Row>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="brandId">Brand ID</Form.Label>
                        <AsyncPaginate
                          key="brandId"
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.brandId &&
                              validation?.errors?.brandId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.brandId
                              ? { brandId: validation.values.brandId }
                              : null || {}
                          }
                          loadOptions={loadOptions2}
                          getOptionValue={(option: any) => option?.brandId}
                          getOptionLabel={(option: any) => option?.brandId}
                          onChange={(option: any) => {
                            if (option) {
                              validation.setFieldValue(
                                "brandId",
                                option.brandId
                              );
                            } else {
                              validation.setFieldValue("brandId", "");
                            }
                          }}
                          isSearchable={true}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="brandName">Brand Name</Form.Label>
                        <AsyncPaginate
                          key={validation.values.brandId || "brandName"}
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.brandName &&
                              validation?.errors?.brandName
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.brandName
                              ? { displayName: validation.values.brandName }
                              : null || {}
                          }
                          loadOptions={loadOptions4}
                          getOptionValue={(option: any) => option?.displayName}
                          getOptionLabel={(option: any) => option?.displayName}
                          onChange={(option: any) => {
                            if (option) {
                              validation.setFieldValue(
                                "brandName",
                                option.displayName
                              );
                            } else {
                              validation.setFieldValue("brandName", "");
                            }
                          }}
                          isSearchable={true}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="country">Country</Form.Label>
                        <Select
                          name="country"
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.country &&
                              validation?.errors?.country
                          )}
                          theme={customSelectTheme}
                          options={
                            typeof countryObj === "object" &&
                            countryObj !== null &&
                            Object.keys(countryObj)?.length > 0
                              ? Object.keys(countryObj)?.map((dt: string) => ({
                                  label: countryObj[dt],
                                  value: dt,
                                }))
                              : []
                          }
                          onChange={(e: { value: string }) => {
                            if (e) {
                              validation.setFieldValue("country", e.value);
                            } else {
                              validation.setFieldValue("country", "");
                            }
                          }}
                          value={{
                            label: countryObj?.[validation.values.country],
                            value: validation.values.country || "",
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="entityType">
                          Entity Type
                        </Form.Label>
                        <Select
                          name="entityType"
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.entityType &&
                              validation?.errors?.entityType
                          )}
                          theme={customSelectTheme}
                          options={
                            Array.isArray(entityTypeArr) &&
                            entityTypeArr?.length > 0
                              ? entityTypeArr?.map((dt: string) => ({
                                  label: humanizeString(dt),
                                  value: dt,
                                }))
                              : []
                          }
                          onChange={(e: { value: string }) => {
                            if (e) {
                              validation.setFieldValue("entityType", e.value);
                            } else {
                              validation.setFieldValue("entityType", "");
                            }
                          }}
                          value={{
                            label: humanizeString(
                              validation?.values?.entityType
                            ),
                            value: validation?.values?.entityType,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="ein">EIN/Tax Number</Form.Label>
                        <Form.Control
                          type="text"
                          name="ein"
                          onChange={(e) => {
                            validation.setFieldValue("ein", e.target.value);
                          }}
                          value={validation.values.ein}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="universalEin">
                          Universal EIN
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="universalEin"
                          onChange={(e) => {
                            validation.setFieldValue(
                              "universalEin",
                              e.target.value
                            );
                          }}
                          value={validation.values.universalEin}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="identityStatus">
                          Identity Status
                        </Form.Label>
                        <Select
                          name="identityStatus"
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.identityStatus &&
                              validation?.errors?.identityStatus
                          )}
                          theme={customSelectTheme}
                          options={[
                            {
                              label: humanizeString("SELF_DECLARED"),
                              value: "SELF_DECLARED",
                            },
                            {
                              label: humanizeString("UNVERIFIED"),
                              value: "UNVERIFIED",
                            },
                            {
                              label: humanizeString("VERIFIED"),
                              value: "VERIFIED",
                            },
                            {
                              label: humanizeString("VETTED_VERIFIED"),
                              value: "VETTED_VERIFIED",
                            },
                          ]}
                          onChange={(e: { value: string }) => {
                            if (e) {
                              validation.setFieldValue(
                                "identityStatus",
                                e.value
                              );
                            } else {
                              validation.setFieldValue("identityStatus", "");
                            }
                          }}
                          value={{
                            label: humanizeString(
                              validation.values.identityStatus
                            ),
                            value: validation.values.identityStatus,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="referenceId">
                          Reference ID
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="referenceId"
                          onChange={(e) => {
                            validation.setFieldValue(
                              "referenceId",
                              e.target.value
                            );
                          }}
                          value={validation.values.referenceId}
                        />
                      </Col>
                    </Row>
                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        className="btn btn-secondary me-2"
                        type="button"
                        disabled={loading}
                        onClick={() => {
                          validation.resetForm();
                          setDropdown();
                          handleGetData(currPage, rowsPerPage);
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading}
                      >
                        {loading && <Spinner size="sm" animation="border" />}{" "}
                        Apply
                      </Button>
                    </div>
                  </Form>
                </Dropdown.Menu>
              </Dropdown>
            }
            isCreate={true}
            createLabel="Add new brand"
            createLink="/brands/create"
          />

          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body className="listing-table">
                {loading ? (
                  <div className={``}>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                    />
                  </div>
                ) : (
                  <Datatable
                    data={
                      Array.isArray(brandsData?.records)
                        ? brandsData?.records
                        : []
                    }
                    columns={columns}
                    handleRowClick={handleRowClick}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    currPage={currPage}
                    rowsPerPage={rowsPerPage}
                    totalRecords={brandsData?.totalRecords}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(BrandsList);
