import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/Logout";
import Register from "pages/Authentication/Registration/Register";
import UserProfile from "pages/Authentication/user-profile";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import PasswordCreate from "pages/AuthenticationInner/PasswordCreate";
import VerifyEmail from "pages/Authentication/VerifyEmail";
import BrandsList from "pages/Brands/BrandsList";
import CreateBrand from "pages/Brands/CreateBrand";
import BrandDetails from "pages/Brands/BrandDetails";
import Home from "pages/Home/Home";
import BuyNumbers from "pages/Numbers/BuyNumbers";
import Dashboard from "pages/Dashboard/Dashboard";
import Analytics from "pages/Analytics";
import ApiKeys from "pages/ApiKeys";
// import ConfigureNumbers from "pages/Home/ConfigureNumbers";
import MyNumbers from "pages/Home/MyNumbers";
import OwnNumberDetails from "pages/Home/OwnNumberDetails";
import ReleaseNumber from "pages/Home/ReleasedNumber";
import VerifiedCallerList from "pages/PhoneNumbers/VerifiedCallerList";
import CampaignsList from "pages/Campaigns/CampaignsList";
import CreateCampaign from "pages/Campaigns/CreateCampaign";
import CampaignDetails from "pages/Campaigns/CampaignDetails";
// import BillingOverview from "pages/Billing/BillingOverview";
import AddFunds from "pages/Billing/AddFunds";
import AddPaymentMethod from "pages/Billing/AddPaymentMethod";
import PaymentHistory from "pages/Billing/PaymentHistory";
import SendSMS from "pages/Messaging/SendSMS";
import SendMMS from "pages/Messaging/SendMMS";
import ListMessagingTamplets from "pages/Messaging/ListMessagingTamplets";
import CreateMessageTemplate from "pages/Messaging/CreateMessageTemplate";
// import ActiveNumbers from "pages/Messaging/ActiveNumbers";
import Summary from "pages/UsageSummary/Summary";
import General from "pages/Authentication/Settings/General";
import Security from "pages/Authentication/Settings/Security";
import Users from "pages/Authentication/Settings/Users";
import NotificationEvents from "pages/Authentication/Settings/Notifications/NotificationEvents";
import Billing from "pages/Authentication/Settings/Billing";
import AccountPricing from "pages/Authentication/Settings/AccountPricing";
import UsageTransactions from "pages/Authentication/Settings/UsageTransactions";
import WebhooksList from "pages/Authentication/Settings/Webhooks/WebhooksList";
import WebhookDetails from "pages/Authentication/Settings/Webhooks/WebhookDetails";
import CreateNewWebhook from "pages/Authentication/Settings/CreateNewWebhook";
import PortNumbers from "pages/Numbers/PortNumbers";
import NewPortRequest from "pages/Numbers/NewPortRequest";
import PhoneNumberLookup from "pages/Numbers/PhoneNumberLookup";
import settingdata from "Layout/LayoutSettingsData";
import SmsAndMmsLogs from "pages/Messaging/SmsAndMmsLogs";
import SmsAndMmsLogsDetails from "pages/Messaging/SmsAndMmsLogsDetails";
import PortingStatusLogs from "pages/Numbers/PortingStatusLogs";
import NumberLookupTabs from "pages/Numbers/NumberLookupTabs";
import AllGroups from "pages/Authentication/Settings/Groups/AllGroups";
import GroupDetails from "pages/Authentication/Settings/Groups/GroupDetails";
import CreateGroup from "pages/Authentication/Settings/Groups/CreateGroup";
import SubGroupsList from "pages/Authentication/Settings/SubGroups/SubGroupsList";
import SubGroupDetails from "pages/Authentication/Settings/SubGroups/SubGroupDetails";
import CreateSubGroup from "pages/Authentication/Settings/SubGroups/CreateSubGroup";
import NotificationsList from "pages/Notifications/NotificationsList";
import NotificationDetails from "pages/Notifications/NotificationDetails";
import HelpCenterList from "pages/HelpCenter/HelpCenterList";
import HelpCenterSubPages from "pages/HelpCenter/HelpCenterSubPages";
import HelpCenterDetails from "pages/HelpCenter/HelpCenterDetails";

const publicRoutes = [
  { path: "/login", name: "Login", component: <Login /> },
  { path: "/logout", name: "Logout", component: <Logout /> },
  { path: "/register", name: "Register", component: <Register /> },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: <ForgotPassword />,
  },
  {
    path: "/create-password",
    name: "PasswordCreate",
    component: <PasswordCreate />,
  },
  {
    path: "/verifyEmail",
    name: "VerifyEmail",
    component: <VerifyEmail />,
  },
];

const authProtectedRoutes = [
  { path: "/", component: <Dashboard /> },
  { path: "/home", component: <Home /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/dashboard/add-funds", component: <AddFunds /> },
  { path: "/dashboard/add-payment-method", component: <AddPaymentMethod /> },
  { path: "/analytics", component: <Analytics /> },
  { path: "/api-keys", component: <ApiKeys /> },
  { path: "/mynumbers", component: <MyNumbers /> },
  { path: "/mynumbers/:phoneNumber/:sid", component: <OwnNumberDetails /> },
  { path: "/buynumbers", component: <BuyNumbers /> },
  { path: "/release-number", component: <ReleaseNumber /> },
  { path: "/brands", component: <BrandsList /> },
  { path: "/brands/create", component: <CreateBrand /> },
  { path: "/brands/edit/:brand_id", component: <CreateBrand /> },
  { path: "/brands/:id", component: <BrandDetails /> },
  { path: "/verified-caller-ids", component: <VerifiedCallerList /> },
  { path: "/campaigns", component: <CampaignsList /> },
  { path: "/campaigns/create", component: <CreateCampaign /> },
  { path: "/campaigns/:id", component: <CampaignDetails /> },
  { path: "/campaigns-partner/:id", component: <CampaignDetails /> },
  { path: "/add-funds", component: <AddFunds /> },
  { path: "/send-sms", component: <SendSMS /> },
  { path: "/send-mms", component: <SendMMS /> },
  { path: "/message-template", component: <ListMessagingTamplets /> },

  { path: "/sms-logs", component: <SmsAndMmsLogs /> },
  { path: "/mms-logs", component: <SmsAndMmsLogs /> },
  { path: "/sms-logs-details/:s_id", component: <SmsAndMmsLogsDetails /> },
  { path: "/mms-logs-details/:s_id", component: <SmsAndMmsLogsDetails /> },
  {
    path: "/programmable-sms-logs-details/:s_id",
    component: <SmsAndMmsLogsDetails />,
  },
  { path: "/number-logs-details/:s_id", component: <SmsAndMmsLogsDetails /> },

  { path: "/message-template/create", component: <CreateMessageTemplate /> },
  // { path: "/message-template", component: <CreateMessageTemplate /> },
  { path: "/port-numbers", component: <PortNumbers /> },
  { path: "/porting-status/:portingId", component: <PortingStatusLogs /> },
  {
    path: "/port-numbers/new-porting-requests",
    component: <NewPortRequest />,
  },
  { path: "/PhoneNumberLookup", component: <PhoneNumberLookup /> },
  { path: "/number-lookup", component: <NumberLookupTabs /> },
  {
    path: "/message-template/:tamplete_id",
    component: <CreateMessageTemplate />,
  },
  {
    path: "/webhooks",
    component: <WebhooksList />,
  },
  {
    path: "/webhooks-details/:webhookId",
    component: <WebhookDetails />,
  },
  {
    path: "/webhooks/new-webhook",
    component: <CreateNewWebhook />,
  },
  {
    path: "/webhooks/:webhookId",
    component: <CreateNewWebhook />,
  },
  {
    path: "/help-center",
    component: <HelpCenterList />,
  },
  {
    path: "/help-center/:pageId",
    component: <HelpCenterSubPages />,
  },
  {
    path: "/help-center/:pageId/:subpageId",
    component: <HelpCenterDetails />,
  },
  { path: "/settings", component: <UserProfile /> },
  {
    path: "/settings/general",
    isSubNavbar: true,
    subNavdata: settingdata,
    component: <General />,
  },
  {
    path: "/settings/security",
    isSubNavbar: true,
    subNavdata: settingdata,
    component: <Security />,
  },
  {
    path: "/settings/users",
    isSubNavbar: true,
    subNavdata: settingdata,
    component: <Users />,
  },
  {
    path: "/settings/notification-events",
    isSubNavbar: true,
    subNavdata: settingdata,
    component: <NotificationEvents />,
  },

  {
    path: "/settings/groups",
    isSubNavbar: true,
    subNavdata: settingdata,
    component: <AllGroups />,
  },
  {
    path: "/settings/groups/:groupId",
    isSubNavbar: true,
    subNavdata: settingdata,
    component: <GroupDetails />,
  },
  {
    path: "/settings/groups/create",
    isSubNavbar: true,
    subNavdata: settingdata,
    component: <CreateGroup />,
  },
  {
    path: "/settings/groups/:groupId/edit",
    isSubNavbar: true,
    subNavdata: settingdata,
    component: <CreateGroup />,
  },
  {
    path: "/settings/groups/:groupId/subgroups",
    isSubNavbar: true,
    subNavdata: settingdata,
    component: <SubGroupsList />,
  },
  {
    path: "/settings/groups/:groupId/subgroups/:subGroupId",
    isSubNavbar: true,
    subNavdata: settingdata,
    component: <SubGroupDetails />,
  },
  {
    path: "/settings/groups/:groupId/subgroups/create",
    isSubNavbar: true,
    subNavdata: settingdata,
    component: <CreateSubGroup />,
  },
  {
    path: "/settings/groups/:groupId/subgroups/:subGroupId/edit",
    isSubNavbar: true,
    subNavdata: settingdata,
    component: <CreateSubGroup />,
  },
  {
    path: "/settings/billing",
    isSubNavbar: true,
    subNavdata: settingdata,
    component: <Billing />,
  },
  {
    path: "/settings/billing/payment-history",
    isSubNavbar: true,
    subNavdata: settingdata,
    component: <PaymentHistory />,
  },
  {
    path: "/settings/billing/usage-summary",
    isSubNavbar: true,
    subNavdata: settingdata,
    component: <Summary />,
  },
  {
    path: "/settings/account-pricing",
    isSubNavbar: true,
    subNavdata: settingdata,
    component: <AccountPricing />,
  },
  {
    path: "/settings/usage-transactions",
    isSubNavbar: true,
    subNavdata: settingdata,
    component: <UsageTransactions />,
  },
  {
    path: "/all-notifications",
    component: <NotificationsList />,
  },
  {
    path: "/all-notifications/:id",
    component: <NotificationDetails />,
  },
  //
  // { path: "/ConfigureNumbers", component: <ConfigureNumbers /> },
  // { path: "/billing-overview", component: <BillingOverview /> },
  // { path: "/activeNumber", component: <ActiveNumbers /> },
];

export { authProtectedRoutes, publicRoutes };
