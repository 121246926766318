import React, { useEffect, useState } from "react";
import { Button, Card, Col } from "react-bootstrap";
import LineChart from "pages/Analytics/LineChart";
import StackedColumn from "./StackedColumn";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

const DeliveryRate = ({ month, analyticsData }: any) => {
  const [graphType, setGraphType] = useState("Column");
  const [deliveryRateGraphObj, setDeliveryRateGraphObj] = useState<any>({});

  const selectProfile = createSelector(
    (state: any) => state.Analytics,
    (analytics) => ({
      analyticsAllV1: analytics.analyticsAllV1,
    })
  );

  const { analyticsAllV1 } = useSelector(selectProfile);

  const getNumberGraphData = (data: any) => {
    const mergeDataByPhoneNumber = (data1: any, data2?: any) => {
      const mergedData: any = {};

      // Helper function to merge data for a single phone number
      const mergePhoneData = (phoneData: any, date: any, phoneNumber: any) => {
        if (!mergedData[phoneNumber]) {
          mergedData[phoneNumber] = {};
        }
        // Ensure metrics are defined before using them
        const metrics = [
          "delivered",
          "sent",
          "dequeued",
          "enqueued",
          "created",
          "failed",
          "segmentSent",
          "totalInbound",
          "segmentRecieved",
        ];

        metrics.forEach((metric) => {
          if (!mergedData[phoneNumber][metric]) {
            mergedData[phoneNumber][metric] = 0;
          }
          mergedData[phoneNumber][metric] += phoneData[metric] || 0;
        });
      };

      // Merge data from both input objects
      [data1, data2].forEach((data) => {
        data &&
          Object.keys(data).forEach((date) => {
            data[date] &&
              Object.keys(data[date]).forEach((phoneNumber) => {
                mergePhoneData(data[date][phoneNumber], date, phoneNumber);
              });
          });
      });

      // Calculate new fields for each phone number
      Object.keys(mergedData).forEach((phoneNumber) => {
        const totalSent =
          mergedData[phoneNumber].delivered + mergedData[phoneNumber].sent;
        mergedData[phoneNumber].deliveryRate = totalSent
          ? (100 * mergedData[phoneNumber]?.delivered) / (totalSent || 1)
          : 0;
        mergedData[phoneNumber].failedRate =
          totalSent > 0 ? 100 - mergedData[phoneNumber].deliveryRate : 0;
        mergedData[phoneNumber].totalMessagesSent = totalSent;
      });

      // Filter the data to get the latest 20 phone numbers
      const latestPhoneNumbers = Object.keys(mergedData).slice(-20);
      const filteredData = latestPhoneNumbers.reduce(
        (acc: any, phoneNumber: any) => {
          acc[phoneNumber] = mergedData[phoneNumber];
          return acc;
        },
        {}
      );

      setDeliveryRateGraphObj(filteredData);
    };

    const result = mergeDataByPhoneNumber(data?.SMS, data?.MMS);
  };

  useEffect(() => {
    // Check if the provided analyticsData prop has valid SMS and MMS data
    if (analyticsData && analyticsData.SMS && analyticsData.MMS) {
      getNumberGraphData(analyticsData);
    }
    // Fallback to the redux data if the prop is not provided
    else if (analyticsAllV1 && analyticsAllV1.SMS && analyticsAllV1.MMS) {
      getNumberGraphData(analyticsAllV1);
    }
  }, [analyticsData, analyticsAllV1]);

  return (
    <React.Fragment>
      <Col xl={7}>
        <Card className="card-height-100">
          <Card.Header className="d-flex align-items-center justify-content-between">
            <h6 className="card-title mb-2">Per Number Delivery Rate</h6>
            <div>
              <Button
                variant={graphType === "Column" ? "secondary" : "light"}
                className="ms-2 fs-2xl p-0"
                onClick={() => setGraphType("Column")}
              >
                <i className="ph ph-chart-bar m-2" />
              </Button>
              <Button
                variant={graphType === "Bar" ? "secondary" : "light"}
                className="ms-2 fs-2xl p-0"
                onClick={() => setGraphType("Bar")}
              >
                <i className="ph ph-chart-line m-2" />
              </Button>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="w-100">
              {graphType === "Column" ? (
                <StackedColumn graphObj={deliveryRateGraphObj } />
              ) : (
                <LineChart graphObj={deliveryRateGraphObj} />
              )}
            </div>
          </Card.Body>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default DeliveryRate;