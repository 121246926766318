import BreadCrumb from "Common/BreadCrumb";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Nav,
  Row,
  Tab,
  ProgressBar,
  Modal,
} from "react-bootstrap";
import withRouter from "Common/withRouter";
import {
  getVerticalListThunk,
  getStockExchangeListThunk,
  getCountryListThunk,
  getStateListThunk,
  getBrandAppealCategoryListThunk,
  getEntityTypeListThunk,
  getBrandRelationshipListThunk,
  createbrandThunk,
  getBrandDetailsThunk,
  updateBrandThunk,
  openModal,
} from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import Loader from "assets/images/spinner-dark.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import {
  customSelectTheme,
  humanizeString,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import { useParams } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { subgroupListApi } from "helpers/apiroute_helper";
import { FocusError } from "focus-formik-error";
import { City, State } from "country-state-city";

const CreateBrand = (props: any) => {
  document.title = "Signal House Portal Add Brand";

  const { brand_id } = useParams();
  const dispatch = useDispatch<any>();
  const inputRef = useRef<HTMLInputElement>(null);

  const [allState, setAllState] = useState<any>({});
  const [allCity, setAllCity] = useState<any>([]);
  const [activeTab, setactiveTab] = useState<number>(0);
  const [modal1, setModal1] = useState<boolean>(false);
  const [subgroupTemp, setSubgroupTemp] = useState<any>([]);

  const brandDetails = useSelector((state: any) => state.Brands?.brandDetails);
  const selectEnums = createSelector(
    (state: any) => state.Enums,
    (state: any) => state.Groups,
    (enums, groups) => ({
      loading: enums.loading,
      verticalObj: enums.verticalObj,
      stockExchangeArr: enums.stockExchangeArr,
      countryObj: enums.countryObj,
      stateObj: enums.stateObj,
      brandAppealCategoryArr: enums.brandAppealCategoryArr,
      entityTypeArr: enums.entityTypeArr,
      brandRelationshipArr: enums.brandRelationshipArr,
      error: enums.error,
      groupDetails: groups?.AllGroups,
    })
  );

  const {
    loading,
    verticalObj,
    stockExchangeArr,
    countryObj,
    stateObj,
    // brandAppealCategoryArr,
    entityTypeArr,
    brandRelationshipArr,
    groupDetails,
  } = useSelector(selectEnums);

  const validation1: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      entityType: "",
      displayName: "",
      companyName: "",
      ein: "",
      einIssuingCountry: "",
      altBusinessId: "",
      altBusinessIdType: "",
      firstName: "",
      lastName: "",
      mobilePhone: "",
      street: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      stockSymbol: "",
      stockExchange: "",
      website: "",
      vertical: "",
      referenceId: "",
      groupId: groupDetails?.records?.[0]?.group_id || "",
      subGroupId: [],
      // cspId: "",
      // brandId: "",
      // universalEin: "",
      // mock: false,
    },
    validationSchema: Yup.object({
      entityType: Yup.string().required("Please Select Type"),
      displayName: Yup.string().max(255).required("Please Enter Brand Name"),
      companyName: Yup.string()
        .min(2)
        .max(100)
        .when("entityType", ([entityType]) => {
          return entityType === "SOLE_PROPRIETOR"
            ? Yup.string().max(255).notRequired()
            : Yup.string().max(255).required("Please Enter Company Name");
        }),
      ein: Yup.string().when("entityType", ([entityType]) => {
        return entityType === "SOLE_PROPRIETOR"
          ? Yup.string()
              .max(21)
              .matches(
                /^\d{2}-\d{7}$/,
                "Invalid - It should be in XX-XXXXXXX format"
              )
              .notRequired()
          : Yup.string()
              .max(21)
              .matches(
                /^\d{2}-\d{7}$/,
                "Invalid - It should be in XX-XXXXXXX format"
              )
              .required("Please enter above field");
      }),
      // .when("einIssuingCountry", ([einIssuingCountry]) => {
      //   return einIssuingCountry === "US"
      //     ? Yup.string()
      //         .max(9)
      //         .matches(
      //           /^\d{2}-\d{7}$/,
      //           'Invalid EIN - EIN is a nine-digit number. The format is XX-XXXXXXX. The "-" symbol is also accepted.'
      //         )
      //     : Yup.string().max(21);
      // }),
      einIssuingCountry: Yup.string()
        .min(2)
        .max(2)
        .required("Please Select Country"),
      altBusinessId: Yup.string().max(50),
      altBusinessIdType: Yup.string(),
      firstName: Yup.string().when("entityType", ([entityType]) => {
        return entityType === "SOLE_PROPRIETOR"
          ? Yup.string().min(2).max(50).required("Please Enter First Name")
          : Yup.string().min(2).max(50).notRequired();
      }),
      lastName: Yup.string().when("entityType", ([entityType]) => {
        return entityType === "SOLE_PROPRIETOR"
          ? Yup.string().min(2).max(50).required("Please Enter Last Name")
          : Yup.string().min(2).max(50).notRequired();
      }),
      mobilePhone: Yup.string().when("entityType", ([entityType]) => {
        return entityType === "SOLE_PROPRIETOR"
          ? Yup.string().max(20).required("Please Enter Mobile Phone")
          : Yup.string().max(20).notRequired();
      }),
      street: Yup.string().min(3).max(100).required("Please Enter Street"),
      city: Yup.string().min(2).max(100).required("Please Enter City"),
      state: Yup.string().min(2).max(20).required("Please Enter State"),
      postalCode: Yup.string().when(
        "einIssuingCountry",
        ([einIssuingCountry]) => {
          return einIssuingCountry === "US"
            ? Yup.string()
                .matches(
                  /^\d{5}(-\d{4})?$/,
                  "Invalid US postal code. Must be ##### or #####-#### format"
                )
                .required("Please Enter Postal Code")
            : Yup.string().max(10).required("Please Enter Postal Code");
        }
      ),
      country: Yup.string().min(2).max(50).required("Please Select Country"),
      stockSymbol: Yup.string().when("entityType", ([entityType]) => {
        return entityType === "PUBLIC_PROFIT"
          ? Yup.string().max(10).required("Please Enter Stock Symbol")
          : Yup.string().max(10).notRequired();
      }),
      stockExchange: Yup.string().when("entityType", ([entityType]) => {
        return entityType === "PUBLIC_PROFIT"
          ? Yup.string().max(10).required("Please Enter Stock Exchange")
          : Yup.string().max(10).notRequired();
      }),
      website: Yup.string().max(100),
      vertical: Yup.string().when("entityType", ([entityType]) => {
        return entityType === "SOLE_PROPRIETOR"
          ? Yup.string().notRequired()
          : Yup.string().required("Please Select Vertical Type");
      }),
      referenceId: Yup.string().max(50).notRequired(),
      // tag: Yup.string(),
      // mock: Yup.boolean(),
      groupId: Yup.string().required("Please Select Group"),
      subGroupId: Yup.array()
        .min(1, "At least one subgroup must be selected")
        .required("Subgroups are required"),
    }),
    onSubmit: () => {
      setactiveTab(1);
    },
  });

  const validation2: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      brandRelationship: "",
    },
    validationSchema: Yup.object({
      brandRelationship: Yup.string().when("entityType", ([entityType]) => {
        return entityType === "SOLE_PROPRIETOR"
          ? Yup.string().notRequired()
          : Yup.string().required("Please Select Brand Relationship");
      }),
    }),
    onSubmit: () => {
      setactiveTab(2);
    },
  });

  const validation3: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      phone: "",
      email: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .min(10)
        .max(15)
        .required("Please Enter Support Phone Number"),
      email: Yup.string()
        .email("Invalid email address")
        .max(100)
        .required("Please Enter Support Email"),
    }),
    onSubmit: () => {
      setModal1(true);
    },
  });

  const handleSubmit = () => {
    if (brand_id) {
      //  Update Call Here
      dispatch(
        updateBrandThunk(
          brand_id,
          removeEmptyAndNullValues({
            ...validation1?.values,
            ...validation2?.values,
            ...validation3?.values,
          }),
          props.router.navigate
        )
      );
    } else {
      const cb = (brandId: string) => {
        dispatch(
          openModal({
            path: "CreateBrandSuccess",
            data: { footer: true, cancelBtn: true },
          })
        );
        brandId && props.router.navigate(`/brands/${brandId}`);
      };

      dispatch(
        createbrandThunk(
          removeEmptyAndNullValues({
            ...validation1?.values,
            ...validation2?.values,
            ...validation3?.values,
          }),
          cb
        )
      );
    }
  };

  useEffect(() => {
    dispatch(getVerticalListThunk());
    dispatch(getStockExchangeListThunk());
    dispatch(getCountryListThunk());
    dispatch(getStateListThunk());
    dispatch(getBrandAppealCategoryListThunk());
    dispatch(getEntityTypeListThunk());
    dispatch(getBrandRelationshipListThunk());
  }, []);

  useEffect(() => {
    if (validation1.values.country && validation1.values.country !== "US") {
      const temp = State.getStatesOfCountry(validation1.values.country);
      const temp2 = temp.reduce(
        (acc, cur) => ({ ...acc, [cur.isoCode]: cur.name }),
        {}
      );
      setAllState(temp2);
    } else {
      setAllState(stateObj);
    }
  }, [validation1.values.country]);

  useEffect(() => {
    if (validation1.values.state) {
      const temp = City.getCitiesOfState(
        validation1.values.country,
        validation1.values.state
      );
      setAllCity(temp);
    }
  }, [validation1.values.state]);

  useEffect(() => {
    if (brand_id) {
      dispatch(getBrandDetailsThunk(brand_id));
    }
  }, [brand_id]);

  useEffect(() => {
    if (brandDetails && brand_id && brandDetails?.brandId === brand_id) {
      const formattedValue = brandDetails?.ein
        ? brandDetails?.ein
            ?.replace(/[^0-9a-zA-Z]/g, "")
            ?.replace(/^(.{2})(.{0,7})/, "$1-$2")
            ?.slice(0, 10)
        : "";
      validation1.setFieldValue("entityType", brandDetails?.entityType);
      validation1.setFieldValue("displayName", brandDetails?.displayName);
      validation1.setFieldValue("companyName", brandDetails?.companyName);
      validation1.setFieldValue("ein", formattedValue);
      validation1.setFieldValue(
        "einIssuingCountry",
        brandDetails?.einIssuingCountry
      );
      validation1.setFieldValue("altBusinessId", brandDetails?.altBusinessId);
      validation1.setFieldValue(
        "altBusinessIdType",
        brandDetails?.altBusinessIdType
      );
      // firstName: "",
      // lastName: "",
      // mobilePhone: "",
      validation1.setFieldValue("street", brandDetails?.street);
      validation1.setFieldValue("city", brandDetails?.city);
      validation1.setFieldValue("state", brandDetails?.state);
      validation1.setFieldValue("postalCode", brandDetails?.postalCode);
      validation1.setFieldValue("country", brandDetails?.country);
      validation1.setFieldValue("stockSymbol", brandDetails?.stockSymbol);
      validation1.setFieldValue("stockExchange", brandDetails?.stockExchange);
      validation1.setFieldValue("website", brandDetails?.website);
      validation1.setFieldValue("vertical", brandDetails?.vertical);
      validation1.setFieldValue("referenceId", brandDetails?.referenceId);
      // groupId: groupDetails?.records?.[0]?.group_id || "",
      // subGroupId: [],
      validation1.setFieldValue("cspId", brandDetails?.cspId);
      validation1.setFieldValue("brandId", brandDetails?.brandId);
      validation1.setFieldValue("universalEin", brandDetails?.universalEin);
      validation2.setFieldValue(
        "brandRelationship",
        brandDetails?.brandRelationship
      );
      validation3.setFieldValue("phone", brandDetails?.phone);
      validation3.setFieldValue("email", brandDetails?.email);
    }
  }, [brandDetails]);

  useEffect(() => {
    console.log("********00000", inputRef.current);
    if (inputRef.current) {
      const autocomplete = new google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["geocode"], // Restrict the search to geographical location types
          // types: ["address"], // Restrict to address types
          // fields: ["address_components"], // Get only address components
          componentRestrictions: {
            country: validation1.values.country || undefined,
          },
        }
      );

      autocomplete.addListener("place_changed", () => {
        console.log("********11111");
        const place = autocomplete.getPlace();
        if (place.address_components) {
          const addressComponents = place.address_components;
          const addressDetails = {
            street: "",
            city: "",
            state: "",
            country: "",
            zipcode: "",
          };

          addressComponents?.forEach((component: any) => {
            const types = component.types;
            if (types.includes("locality")) {
              addressDetails.city = component.long_name;
            } else if (types.includes("administrative_area_level_1")) {
              addressDetails.state = component.short_name;
            } else if (types.includes("country")) {
              addressDetails.country = component.short_name;
            } else if (types.includes("postal_code")) {
              addressDetails.zipcode = component.long_name;
            } else if (
              types.includes("street_number") ||
              types.includes("route")
              // || types.includes("neighborhood")
            ) {
              addressDetails.street += ` ${component.long_name}`;
            }
          });

          validation1.setFieldValue(
            "street",
            addressDetails.street?.trim() || ""
          );
          validation1.setFieldValue("city", addressDetails.city || "");
          validation1.setFieldValue("state", addressDetails.state || "");
          validation1.setFieldValue("country", addressDetails.country || "");
          validation1.setFieldValue("postalCode", addressDetails.zipcode || "");
        }
      });
    }
  }, [inputRef.current, validation1.values.country]);

  const handlePincodeBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    validation1.handleBlur(event);

    const postalCode = validation1.values.postalCode;

    if (postalCode) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          componentRestrictions: {
            postalCode: postalCode,
          },
        },
        (results: any, status) => {
          if (status === google.maps.GeocoderStatus.OK && results[0]) {
            let city = "";
            let country = "";
            let state = "";

            for (let component of results[0].address_components) {
              if (component.types.includes("locality")) {
                city = component.long_name;
              }
              if (component.types.includes("country")) {
                country = component.short_name;
              }
              if (component.types.includes("administrative_area_level_1")) {
                state = component.short_name;
              }
            }

            if (city) validation1.setFieldValue("city", city);
            if (country) validation1.setFieldValue("country", country);
            if (state) validation1.setFieldValue("state", state);
          } else {
            console.error(
              "Geocode was not successful for the following reason: " + status
            );
          }
        }
      );
    }
  };

  const entityTypeOnSelectionChange = (value: string) => {
    validation1.setFieldValue("entityType", value);
    if (value === "GOVERNMENT") {
      validation1?.setFieldValue("country", "US");
      validation1?.setFieldValue("einIssuingCountry", "US");
    } else if (value === "SOLE_PROPRIETOR") {
      validation2?.setFieldValue("brandRelationship", "BASIC_ACCOUNT");
    }
  };

  const loadOptions4 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await subgroupListApi(page, 10, {
      groupId: groupDetails?.records?.[0]?.group_id,
    });

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: true,
        additional: {
          page: page,
        },
      };
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={brand_id ? "Edit Brand" : "Register Brand"}
            isBack={true}
            backClick={() => props.router.navigate("/brands")}
          />

          <div className="position-relative">
            <Card className="wrapper">
              {loading ? (
                <div className={``}>
                  <img
                    src={Loader}
                    className={`position-absolute top-50 start-50 translate-middle`}
                    alt="loading"
                  />
                </div>
              ) : (
                <Card.Body className="">
                  <Tab.Container activeKey={activeTab}>
                    <div id="custom-progress-bar" className="progress-nav mb-5">
                      <div>
                        <ProgressBar
                          now={activeTab * 50}
                          style={{ height: "1px" }}
                        ></ProgressBar>
                      </div>

                      <Nav
                        as="ul"
                        variant="pills"
                        className="progress-bar-tab custom-nav"
                      >
                        <Nav.Item as="li">
                          <Nav.Link
                            as="button"
                            eventKey="0"
                            onClick={() => setactiveTab(0)}
                            className="rounded-pill"
                          >
                            <span>1</span>
                            <div className="position-absolute progress-label start-0">
                              Brand Details
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link
                            as="button"
                            eventKey="1"
                            onClick={() => setactiveTab(1)}
                            className="rounded-pill"
                          >
                            <span>2</span>
                            <div className="position-absolute progress-label middleLabel">
                              Brand Relationship
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link
                            as="button"
                            eventKey="2"
                            onClick={() => setactiveTab(2)}
                            className="rounded-pill"
                          >
                            <span>3</span>
                            <div className="position-absolute progress-label end-0">
                              Contact Details
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>

                    <Tab.Content>
                      <Tab.Pane
                        eventKey="0"
                        id="pills-gen-info"
                        role="tabpanel"
                        aria-labelledby="pills-gen-info-tab"
                      >
                        <Form
                          action="#"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation1.handleSubmit();
                          }}
                        >
                          <FocusError formik={validation1} />
                          <div>
                            <div className="mb-4">
                              <div>
                                <h5 className="mb-1">Brand Details</h5>
                                <p className="text-muted">
                                  Fill all Information as below
                                </p>
                              </div>
                            </div>
                            <Row>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="subGroupId">
                                  Sub Group{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <AsyncPaginate
                                  // isMulti={true}
                                  isDisabled={!validation1.values.groupId}
                                  // closeMenuOnSelect={false}
                                  // hideSelectedOptions={false}
                                  menuPortalTarget={document.body}
                                  key={validation1.values.groupId}
                                  styles={styleObj(
                                    validation1?.touched?.subGroupId &&
                                      validation1?.errors?.subGroupId
                                  )}
                                  theme={customSelectTheme}
                                  components={{
                                    Option: (props: any) => {
                                      return (
                                        <div
                                          ref={props.innerRef}
                                          {...props.innerProps}
                                          className={`selectItemWithCheckbox`}
                                        >
                                          {props.children}
                                        </div>
                                      );
                                    },
                                  }}
                                  value={subgroupTemp || []}
                                  loadOptions={loadOptions4}
                                  getOptionValue={(option: any) =>
                                    option?.sub_group_id
                                  }
                                  getOptionLabel={(option: any) =>
                                    option?.sub_group_name
                                  }
                                  onChange={(option: any) => {
                                    setSubgroupTemp([option]);
                                    validation1.setFieldValue("subGroupId", [
                                      option?.sub_group_id,
                                    ]);
                                  }}
                                  isSearchable={true}
                                  additional={{
                                    page: 1,
                                  }}
                                />
                                {validation1?.touched?.subGroupId &&
                                validation1?.errors?.subGroupId ? (
                                  <Form.Control.Feedback
                                    type="invalid"
                                    className="d-block"
                                  >
                                    {validation1?.errors?.subGroupId}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="companyName">
                                  Legal Company Name{" "}
                                  {validation1.values.entityType ===
                                  "SOLE_PROPRIETOR" ? null : (
                                    <span className="text-danger">*</span>
                                  )}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="companyName"
                                  disabled={
                                    validation1.values.entityType ===
                                    "SOLE_PROPRIETOR"
                                  }
                                  onChange={(e) => {
                                    validation1.setFieldValue(
                                      "companyName",
                                      e.target.value
                                    );
                                    validation1.setFieldValue(
                                      "displayName",
                                      e.target.value
                                    );
                                  }}
                                  value={validation1.values.companyName}
                                  isInvalid={
                                    validation1.touched.companyName &&
                                    validation1.errors.companyName
                                      ? true
                                      : false
                                  }
                                />
                                {validation1.touched.companyName &&
                                validation1.errors.companyName ? (
                                  <Form.Control.Feedback type="invalid">
                                    {validation1.errors.companyName}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="displayName">
                                  DBA or Brand Name, if different from legal
                                  name <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="displayName"
                                  onChange={validation1?.handleChange}
                                  value={validation1.values?.displayName}
                                  isInvalid={
                                    validation1?.touched?.displayName &&
                                    validation1?.errors?.displayName
                                      ? true
                                      : false
                                  }
                                />
                                {validation1.touched?.displayName &&
                                validation1.errors?.displayName ? (
                                  <Form.Control.Feedback type="invalid">
                                    {validation1.errors?.displayName}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="entityType">
                                  What type of legal form is the organization?{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Select
                                  name="entityType"
                                  styles={styleObj(
                                    validation1.touched.entityType &&
                                      validation1.errors.entityType
                                  )}
                                  theme={customSelectTheme}
                                  options={
                                    Array.isArray(entityTypeArr) &&
                                    entityTypeArr?.length > 0
                                      ? entityTypeArr?.map((dt: string) => ({
                                          label: humanizeString(dt),
                                          value: dt,
                                        }))
                                      : []
                                  }
                                  onChange={(e: { value: string }) => {
                                    entityTypeOnSelectionChange(e.value);
                                  }}
                                  value={{
                                    label: humanizeString(
                                      validation1?.values?.entityType
                                    ),
                                    value: validation1?.values?.entityType,
                                  }}
                                />
                                {validation1.touched.entityType &&
                                validation1.errors.entityType ? (
                                  <Form.Control.Feedback
                                    type="invalid"
                                    className="d-block"
                                  >
                                    {validation1?.errors?.entityType}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="country">
                                  Country of Registration{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Select
                                  name="country"
                                  styles={styleObj(
                                    validation1.touched.country &&
                                      validation1.errors.country
                                  )}
                                  theme={customSelectTheme}
                                  isDisabled={
                                    validation1.values.entityType === "" ||
                                    validation1.values.entityType ===
                                      "GOVERNMENT"
                                  }
                                  options={
                                    typeof countryObj === "object" &&
                                    countryObj !== null &&
                                    Object.keys(countryObj)?.length > 0
                                      ? Object.keys(countryObj)?.map(
                                          (dt: string) => ({
                                            label: countryObj[dt],
                                            value: dt,
                                          })
                                        )
                                      : []
                                  }
                                  onChange={(e: { value: string }) => {
                                    validation1.setFieldValue(
                                      "country",
                                      e.value
                                    );
                                    validation1.setFieldValue(
                                      "einIssuingCountry",
                                      e.value
                                    );
                                    validation1.setFieldValue("street", "");
                                    validation1.setFieldValue("city", "");
                                    validation1.setFieldValue("state", "");
                                    validation1.setFieldValue("postalCode", "");
                                  }}
                                  value={
                                    typeof countryObj === "object" &&
                                    countryObj !== null &&
                                    Object.keys(countryObj)?.length > 0
                                      ? {
                                          label:
                                            countryObj[
                                              validation1.values.country
                                            ],
                                          value: validation1.values.country,
                                        }
                                      : ""
                                  }
                                />
                                {validation1.touched.country &&
                                validation1.errors.country ? (
                                  <Form.Control.Feedback
                                    type="invalid"
                                    className="d-block"
                                  >
                                    {validation1.errors.country}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="ein">
                                  {validation1.values.einIssuingCountry === "US"
                                    ? "EIN"
                                    : !validation1.values.einIssuingCountry
                                    ? "Tax Number/ID/EIN"
                                    : "Tax Number/ID"}{" "}
                                  {validation1.values.entityType ===
                                  "SOLE_PROPRIETOR" ? null : (
                                    <span className="text-danger">*</span>
                                  )}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="ein"
                                  disabled={
                                    !validation1.values.country ||
                                    validation1.values.entityType ===
                                      "SOLE_PROPRIETOR"
                                  }
                                  onChange={(e) => {
                                    const formattedValue = e.target.value
                                      .replace(/[^0-9a-zA-Z]/g, "")
                                      .replace(/^(.{2})(.{0,7})/, "$1-$2")
                                      .slice(0, 10);
                                    validation1.setFieldValue(
                                      "ein",
                                      formattedValue
                                    );
                                  }}
                                  value={validation1.values.ein}
                                  isInvalid={
                                    validation1.touched.ein &&
                                    validation1.errors.ein
                                      ? true
                                      : false
                                  }
                                />
                                {validation1.touched.ein &&
                                validation1.errors.ein ? (
                                  <Form.Control.Feedback type="invalid">
                                    {validation1.errors.ein}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="einIssuingCountry">
                                  {validation1.values.einIssuingCountry === "US"
                                    ? "EIN issuing Country"
                                    : !validation1.values.einIssuingCountry
                                    ? "Tax Number/ID/EIN issuing Country"
                                    : "Tax Number/ID issuing Country"}
                                </Form.Label>
                                <Select
                                  name="einIssuingCountry"
                                  styles={styleObj(
                                    validation1.touched.einIssuingCountry &&
                                      validation1.errors.einIssuingCountry
                                  )}
                                  theme={customSelectTheme}
                                  isDisabled={
                                    !validation1.values.country ||
                                    validation1.values.entityType ===
                                      "SOLE_PROPRIETOR" ||
                                    validation1.values.entityType ===
                                      "GOVERNMENT"
                                  }
                                  options={
                                    typeof countryObj === "object" &&
                                    countryObj !== null &&
                                    Object.keys(countryObj)?.length > 0
                                      ? Object.keys(countryObj)?.map(
                                          (dt: string) => ({
                                            label: countryObj[dt],
                                            value: dt,
                                          })
                                        )
                                      : []
                                  }
                                  onChange={(e: { value: string }) =>
                                    validation1.setFieldValue(
                                      "einIssuingCountry",
                                      e.value
                                    )
                                  }
                                  value={
                                    typeof countryObj === "object" &&
                                    countryObj !== null &&
                                    Object.keys(countryObj)?.length > 0
                                      ? {
                                          label:
                                            countryObj[
                                              validation1.values
                                                .einIssuingCountry
                                            ],
                                          value:
                                            validation1.values
                                              .einIssuingCountry,
                                        }
                                      : ""
                                  }
                                />
                                {validation1.touched.einIssuingCountry &&
                                validation1.errors.einIssuingCountry ? (
                                  <Form.Control.Feedback
                                    type="invalid"
                                    className="d-block"
                                  >
                                    {validation1.errors.einIssuingCountry}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="altBusinessIdType">
                                  DUNS or GIIN or LEI Number
                                </Form.Label>
                                <Select
                                  name="altBusinessIdType"
                                  styles={styleObj(
                                    validation1.touched.altBusinessIdType &&
                                      validation1.errors.altBusinessIdType
                                  )}
                                  theme={customSelectTheme}
                                  isDisabled={!validation1.values.country}
                                  options={[
                                    {
                                      label: "DUNS",
                                      value: "DUNS",
                                    },
                                    {
                                      label: "GIIN",
                                      value: "GIIN",
                                    },
                                    {
                                      label: "LEI",
                                      value: "LEI",
                                    },
                                  ]}
                                  onChange={(e: { value: string }) =>
                                    validation1.setFieldValue(
                                      "altBusinessIdType",
                                      e.value
                                    )
                                  }
                                  value={{
                                    label: validation1.values.altBusinessIdType,
                                    value: validation1.values.altBusinessIdType,
                                  }}
                                />
                                {validation1.touched.altBusinessIdType &&
                                validation1.errors.altBusinessIdType ? (
                                  <Form.Control.Feedback
                                    type="invalid"
                                    className="d-block"
                                  >
                                    {validation1.errors.altBusinessIdType}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="altBusinessId">
                                  {!validation1.values.altBusinessIdType
                                    ? "DUNS or GIIN or LEI "
                                    : `${validation1.values.altBusinessIdType} `}
                                  Number
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="altBusinessId"
                                  disabled={
                                    !validation1.values.altBusinessIdType
                                  }
                                  onChange={validation1.handleChange}
                                  value={validation1.values.altBusinessId}
                                  isInvalid={
                                    validation1.touched.altBusinessId &&
                                    validation1.errors.altBusinessId
                                      ? true
                                      : false
                                  }
                                />
                                {validation1.touched.altBusinessId &&
                                validation1.errors.altBusinessId ? (
                                  <Form.Control.Feedback type="invalid">
                                    {validation1.errors.altBusinessId}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="street">
                                  Address/Street{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  id="street"
                                  name="street"
                                  autoComplete="off"
                                  ref={inputRef}
                                  disabled={!validation1.values.country}
                                  placeholder="Search for address"
                                  onChange={validation1.handleChange}
                                  onBlur={validation1.handleBlur}
                                  value={validation1.values.street}
                                  isInvalid={
                                    validation1.touched.street &&
                                    validation1.errors.street
                                      ? true
                                      : false
                                  }
                                />
                                {validation1.touched.street &&
                                validation1.errors.street ? (
                                  <Form.Control.Feedback type="invalid">
                                    {validation1.errors.street}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="state">
                                  State/Region{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Select
                                  menuPortalTarget={document.body}
                                  name="state"
                                  styles={styleObj(
                                    validation1.touched.state &&
                                      validation1.errors.state
                                  )}
                                  theme={customSelectTheme}
                                  isDisabled={!validation1.values.country}
                                  isClearable={true}
                                  options={
                                    typeof allState === "object" &&
                                    allState !== null &&
                                    Object.keys(allState)?.length > 0
                                      ? Object.keys(allState)?.map(
                                          (dt: string) => ({
                                            label: allState[dt],
                                            value: dt,
                                          })
                                        )
                                      : []
                                  }
                                  onChange={(e: { value?: string }) => {
                                    if (e) {
                                      validation1.setFieldValue(
                                        "state",
                                        e.value ? e.value : ""
                                      );
                                      validation1.setFieldValue("city", "");
                                      validation1.setFieldValue(
                                        "postalCode",
                                        ""
                                      );
                                    } else {
                                      validation1.setFieldValue("state", "");
                                    }
                                  }}
                                  value={
                                    typeof allState === "object" &&
                                    allState !== null &&
                                    Object.keys(allState)?.length > 0
                                      ? {
                                          label:
                                            allState[validation1.values.state],
                                          value: validation1.values.state,
                                        }
                                      : ""
                                  }
                                />
                                {validation1.touched.state &&
                                validation1.errors.state ? (
                                  <Form.Control.Feedback
                                    type="invalid"
                                    className="d-block"
                                  >
                                    {validation1.errors.state}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="city">
                                  City <span className="text-danger">*</span>
                                </Form.Label>
                                <Select
                                  menuPortalTarget={document.body}
                                  name="city"
                                  styles={styleObj(
                                    validation1.touched.city &&
                                      validation1.errors.city
                                  )}
                                  theme={customSelectTheme}
                                  isDisabled={!validation1.values.state}
                                  isClearable={true}
                                  options={
                                    allCity?.length > 0
                                      ? allCity?.map(
                                          (dt: { name: string }) => ({
                                            label: dt?.name,
                                            value: dt?.name,
                                          })
                                        )
                                      : []
                                  }
                                  onChange={(e: { value?: string }) => {
                                    if (e) {
                                      validation1.setFieldValue(
                                        "city",
                                        e.value ? e.value : ""
                                      );
                                      validation1.setFieldValue(
                                        "postalCode",
                                        ""
                                      );
                                    } else {
                                      validation1.setFieldValue("city", "");
                                    }
                                  }}
                                  value={{
                                    label: validation1.values.city,
                                    value: validation1.values.city,
                                  }}
                                />
                                {validation1.touched.city &&
                                validation1.errors.city ? (
                                  <Form.Control.Feedback
                                    type="invalid"
                                    className="d-block"
                                  >
                                    {validation1.errors.city}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="postalCode">
                                  Postal Code/ZIP Code{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="postalCode"
                                  disabled={!validation1.values.country}
                                  onChange={validation1.handleChange}
                                  onBlur={handlePincodeBlur}
                                  value={validation1.values.postalCode}
                                  isInvalid={
                                    validation1.touched.postalCode &&
                                    validation1.errors.postalCode
                                      ? true
                                      : false
                                  }
                                />
                                {validation1.touched.postalCode &&
                                validation1.errors.postalCode ? (
                                  <Form.Control.Feedback type="invalid">
                                    {validation1.errors.postalCode}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="country">
                                  Country <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="country"
                                  disabled={true}
                                  value={
                                    typeof countryObj === "object" &&
                                    countryObj !== null &&
                                    Object.keys(countryObj)?.length > 0
                                      ? countryObj[validation1.values.country]
                                      : ""
                                  }
                                />
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="website">
                                  Website/Online Presence
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="website"
                                  disabled={!validation1.values.country}
                                  onChange={validation1.handleChange}
                                  value={validation1.values.website}
                                  isInvalid={
                                    validation1.touched.website &&
                                    validation1.errors.website
                                      ? true
                                      : false
                                  }
                                />
                                {validation1.touched.website &&
                                validation1.errors.website ? (
                                  <Form.Control.Feedback type="invalid">
                                    {validation1.errors.website}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="stockSymbol">
                                  Stock Symbol{" "}
                                  {validation1.values.entityType ===
                                  "PUBLIC_PROFIT" ? (
                                    <span className="text-danger">*</span>
                                  ) : null}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="stockSymbol"
                                  disabled={
                                    !validation1.values.country ||
                                    validation1.values.entityType !==
                                      "PUBLIC_PROFIT"
                                  }
                                  onChange={validation1.handleChange}
                                  value={validation1.values.stockSymbol}
                                  isInvalid={
                                    validation1.touched.stockSymbol &&
                                    validation1.errors.stockSymbol
                                      ? true
                                      : false
                                  }
                                />
                                {validation1.touched.stockSymbol &&
                                validation1.errors.stockSymbol ? (
                                  <Form.Control.Feedback type="invalid">
                                    {validation1.errors.stockSymbol}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="stockExchange">
                                  Stock Exchange{" "}
                                  {validation1.values.entityType ===
                                  "PUBLIC_PROFIT" ? (
                                    <span className="text-danger">*</span>
                                  ) : null}
                                </Form.Label>
                                <Select
                                  name="stockExchange"
                                  styles={styleObj(
                                    validation1.touched.stockExchange &&
                                      validation1.errors.stockExchange
                                  )}
                                  theme={customSelectTheme}
                                  isDisabled={
                                    !validation1.values.country ||
                                    validation1.values.entityType !==
                                      "PUBLIC_PROFIT"
                                  }
                                  options={
                                    Array.isArray(stockExchangeArr) &&
                                    stockExchangeArr?.length > 0
                                      ? stockExchangeArr?.map((dt: string) => ({
                                          label: dt,
                                          value: dt,
                                        }))
                                      : []
                                  }
                                  onChange={(e: { value: string }) =>
                                    validation1.setFieldValue(
                                      "stockExchange",
                                      e.value
                                    )
                                  }
                                  value={{
                                    label: validation1.values.stockExchange,
                                    value: validation1.values.stockExchange,
                                  }}
                                />
                                {validation1.touched.stockExchange &&
                                validation1.errors.stockExchange ? (
                                  <Form.Control.Feedback
                                    type="invalid"
                                    className="d-block"
                                  >
                                    {validation1.errors.stockExchange}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="vertical">
                                  Vertical Type{" "}
                                  {validation1.values.entityType ===
                                  "SOLE_PROPRIETOR" ? null : (
                                    <span className="text-danger">*</span>
                                  )}
                                </Form.Label>
                                <Select
                                  name="vertical"
                                  styles={styleObj(
                                    validation1.touched.vertical &&
                                      validation1.errors.vertical
                                  )}
                                  theme={customSelectTheme}
                                  isDisabled={!validation1.values.country}
                                  options={
                                    typeof verticalObj === "object" &&
                                    verticalObj !== null &&
                                    Object.keys(verticalObj)?.length > 0
                                      ? Object.keys(verticalObj)?.map(
                                          (dt: string) => ({
                                            label: verticalObj[dt]?.displayName,
                                            value: dt,
                                          })
                                        )
                                      : []
                                  }
                                  onChange={(e: { value: string }) =>
                                    validation1.setFieldValue(
                                      "vertical",
                                      e.value
                                    )
                                  }
                                  value={
                                    typeof verticalObj === "object" &&
                                    verticalObj !== null &&
                                    Object.keys(verticalObj)?.length > 0
                                      ? {
                                          label:
                                            verticalObj[
                                              validation1.values.vertical
                                            ]?.displayName,
                                          value: validation1.values.vertical,
                                        }
                                      : ""
                                  }
                                />
                                {validation1.touched.vertical &&
                                validation1.errors.vertical ? (
                                  <Form.Control.Feedback
                                    type="invalid"
                                    className="d-block"
                                  >
                                    {validation1.errors.vertical}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="referenceId">
                                  Reference ID
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="referenceId"
                                  disabled={!validation1.values.country}
                                  onChange={validation1.handleChange}
                                  value={validation1.values.referenceId}
                                  isInvalid={
                                    validation1.touched.referenceId &&
                                    validation1.errors.referenceId
                                      ? true
                                      : false
                                  }
                                />
                                {validation1.touched.referenceId &&
                                validation1.errors.referenceId ? (
                                  <Form.Control.Feedback type="invalid">
                                    {validation1.errors.referenceId}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="firstName">
                                  First Name{" "}
                                  {validation1.values.entityType ===
                                  "SOLE_PROPRIETOR" ? (
                                    <span className="text-danger">*</span>
                                  ) : null}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="firstName"
                                  disabled={
                                    validation1.values.entityType !==
                                      "SOLE_PROPRIETOR" ||
                                    !validation1.values.country
                                  }
                                  onChange={validation1.handleChange}
                                  value={validation1.values.firstName}
                                  isInvalid={
                                    validation1.touched.firstName &&
                                    validation1.errors.firstName
                                      ? true
                                      : false
                                  }
                                />
                                {validation1.touched.firstName &&
                                validation1.errors.firstName ? (
                                  <Form.Control.Feedback type="invalid">
                                    {validation1.errors.firstName}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="lastName">
                                  Last Name{" "}
                                  {validation1.values.entityType ===
                                  "SOLE_PROPRIETOR" ? (
                                    <span className="text-danger">*</span>
                                  ) : null}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="lastName"
                                  disabled={
                                    validation1.values.entityType !==
                                      "SOLE_PROPRIETOR" ||
                                    !validation1.values.country
                                  }
                                  onChange={validation1.handleChange}
                                  value={validation1.values.lastName}
                                  isInvalid={
                                    validation1.touched.lastName &&
                                    validation1.errors.lastName
                                      ? true
                                      : false
                                  }
                                />
                                {validation1.touched.lastName &&
                                validation1.errors.lastName ? (
                                  <Form.Control.Feedback type="invalid">
                                    {validation1.errors.lastName}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="mobilePhone">
                                  Mobile Phone{" "}
                                  {validation1.values.entityType ===
                                  "SOLE_PROPRIETOR" ? (
                                    <span className="text-danger">*</span>
                                  ) : null}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="mobilePhone"
                                  disabled={
                                    validation1.values.entityType !==
                                      "SOLE_PROPRIETOR" ||
                                    !validation1.values.country
                                  }
                                  onChange={validation1.handleChange}
                                  value={validation1.values.mobilePhone}
                                  isInvalid={
                                    validation1.touched.mobilePhone &&
                                    validation1.errors.mobilePhone
                                      ? true
                                      : false
                                  }
                                />
                                {validation1.touched.mobilePhone &&
                                validation1.errors.mobilePhone ? (
                                  <Form.Control.Feedback type="invalid">
                                    {validation1.errors.mobilePhone}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                            </Row>
                          </div>
                          <div className="d-flex align-items-start gap-3 mt-4">
                            <button
                              type="submit"
                              className="btn btn-secondary btn-label right m-auto nexttab nexttab"
                            >
                              <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>
                              Next
                            </button>
                          </div>
                        </Form>
                      </Tab.Pane>

                      <Tab.Pane
                        eventKey="1"
                        id="pills-info-desc"
                        role="tabpanel"
                        aria-labelledby="pills-info-desc-tab"
                      >
                        <Form
                          action="#"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation2.handleSubmit();
                          }}
                        >
                          <div>
                            <div className="mb-4">
                              <div>
                                <h5>Brand Relationship</h5>
                                {validation2.touched.brandRelationship &&
                                validation2.errors.brandRelationship ? (
                                  <p className="text-danger">
                                    {validation2.errors.brandRelationship}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            <Row className="d-flex justify-content-center">
                              {Array.isArray(brandRelationshipArr) &&
                              brandRelationshipArr?.length > 0
                                ? brandRelationshipArr?.map((dt, i) => (
                                    <Col
                                      lg={2}
                                      xs={6}
                                      key={i}
                                      onClick={() => {
                                        if (
                                          validation1.values.entityType !==
                                          "SOLE_PROPRIETOR"
                                        ) {
                                          validation2.setFieldValue(
                                            "brandRelationship",
                                            dt
                                          );
                                        }
                                      }}
                                    >
                                      <div className="pt-5">
                                        <Card
                                          className={`text-center cursor-pointer ${
                                            validation2?.values
                                              ?.brandRelationship === dt
                                              ? "rounded-4 border border-primary"
                                              : ""
                                          } ${
                                            validation1.values.entityType ===
                                              "SOLE_PROPRIETOR" &&
                                            validation2.values
                                              .brandRelationship ===
                                              "BASIC_ACCOUNT" &&
                                            dt !== "BASIC_ACCOUNT"
                                              ? "opacity-50 "
                                              : ""
                                          }`}
                                        >
                                          <Card.Header className="bg-secondary-subtle position-relative pb-3 pt-5 rounded-top-4">
                                            <div className="avatar-sm position-absolute top-0 start-50 translate-middle">
                                              <span className="avatar-title rounded-circle fs-3 border border-white border-2 bg-secondary">
                                                <i className="ph-users-three"></i>
                                              </span>
                                            </div>
                                            {humanizeString(dt)}
                                          </Card.Header>
                                          <Card.Body className="bg-body-tertiary rounded-bottom-4">
                                            {dt === "BASIC_ACCOUNT"
                                              ? "no business history with the CSP"
                                              : dt === "SMALL_ACCOUNT"
                                              ? "or some business history with the CSP"
                                              : dt === "MEDIUM_ACCOUNT"
                                              ? "with good standing with the CSP and solid business history"
                                              : dt === "LARGE_ACCOUNT"
                                              ? "with a dedicated account manager, highly trusted"
                                              : dt === "KEY_ACCOUNT"
                                              ? "with strategic value and a dedicated account team"
                                              : ""}
                                          </Card.Body>
                                        </Card>
                                      </div>
                                    </Col>
                                  ))
                                : null}
                            </Row>
                          </div>
                          <div className="d-flex align-items-start justify-content-center gap-3 mt-4">
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none btn-label previestab"
                              onClick={() => setactiveTab(0)}
                            >
                              <i className="ri-arrow-left-line label-icon align-middle fs-lg"></i>
                              Back to Details
                            </button>
                            <button
                              type="submit"
                              className="btn btn-secondary btn-label right nexttab nexttab"
                            >
                              <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>
                              Next
                            </button>
                          </div>
                        </Form>
                      </Tab.Pane>

                      <Tab.Pane
                        eventKey="2"
                        id="pills-success"
                        role="tabpanel"
                        aria-labelledby="pills-success-tab"
                      >
                        <Form
                          action="#"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation3.handleSubmit();
                          }}
                        >
                          <div>
                            <div className="mb-4">
                              <div>
                                <h5 className="mb-1">Brand Contact Details</h5>
                                <p className="text-muted">
                                  Fill all Information as below
                                </p>
                              </div>
                            </div>

                            <Row>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="email">
                                  Support Email Address{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="email"
                                  name="email"
                                  onChange={validation3.handleChange}
                                  onBlur={validation3.handleBlur}
                                  value={validation3.values.email}
                                  isInvalid={
                                    validation3.touched.email &&
                                    validation3.errors.email
                                      ? true
                                      : false
                                  }
                                />
                                {validation3.touched.email &&
                                validation3.errors.email ? (
                                  <Form.Control.Feedback type="invalid">
                                    {validation3.errors.email}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Form.Label htmlFor="phone">
                                  Mobile Phone{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="phone"
                                  onChange={validation3.handleChange}
                                  onBlur={validation3.handleBlur}
                                  value={validation3.values.phone}
                                  isInvalid={
                                    validation3.touched.phone &&
                                    validation3.errors.phone
                                      ? true
                                      : false
                                  }
                                />
                                {validation3.touched.phone &&
                                validation3.errors.phone ? (
                                  <Form.Control.Feedback type="invalid">
                                    {validation3.errors.phone}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Col>
                            </Row>
                          </div>
                          <div className="d-flex align-items-start justify-content-center gap-3 mt-4">
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none btn-label previestab"
                              onClick={() => setactiveTab(1)}
                            >
                              <i className="ri-arrow-left-line label-icon align-middle fs-lg"></i>
                              Back to Relationship
                            </button>
                            <button
                              type="submit"
                              className="btn btn-secondary btn-label right nexttab nexttab"
                            >
                              <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>
                              Next
                            </button>
                          </div>
                        </Form>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </Card.Body>
              )}
            </Card>
          </div>
        </Container>
      </div>

      <Modal centered show={modal1} onHide={() => setModal1(false)}>
        <Modal.Body className="p-4 text-center fs-5">
          <div>
            A one-time, non-refundable payment of $4 is required to register
            each BRAND. It will be added to your next balance.
          </div>
          <div>
            Click <b>I agree to confirm.</b>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="hstack gap-2 justify-content-end">
            <Button
              className="btn btn-ghost-danger"
              onClick={() => setModal1(false)}
            >
              <i className="bi bi-x-lg align-baseline me-1"></i> Close
            </Button>
            <Button
              variant="primary"
              id="add-btn"
              onClick={() => {
                setModal1(false);
                handleSubmit();
              }}
            >
              I Agree
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(CreateBrand);
