import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import CountUp from "react-countup";
import {
  capitalizeString,
  removeKeyFromObj,
} from "helpers/common";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import Loader from "assets/images/spinner-dark.svg";

const widgetsData = [
  {
    bgcolor: "bg-secondary-subtle",
    textcolor: "text-secondary",
    progressbar: "progress-bar bg-secondary",
  },
  {
    bgcolor: "bg-primary-subtle",
    textcolor: "text-primary",
    progressbar: "progress-bar",
  },
  {
    bgcolor: "bg-secondary-subtle",
    textcolor: "text-secondary",
    progressbar: "progress-bar bg-secondary",
  },
  {
    bgcolor: "bg-primary-subtle",
    textcolor: "text-primary",
    progressbar: "progress-bar",
  },

  {
    bgcolor: "bg-secondary-subtle",
    textcolor: "text-secondary",
    progressbar: "progress-bar bg-secondary",
  },
];

const generateRandomNumber = () => {
  const min = 20;
  const max = 100;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const getIcon = (data: string) => {
  switch (data) {
    case "Number":
      return "ph-file-text";
    case "SMS":
      return "ph-chat-circle-text";
    case "MMS":
      return " ph-image-light";
    case "brand":
      return "ph-storefront";
    case "campaign":
      return "ph-megaphone";
    default:
      return "ph-activity";
  }
};

const Widgets = ({ month }: any) => {

  const selectProfile = createSelector(
    (state: any) => state.UsageSummary,
    (state: any) => state.AuthUser,
    (state: any) => state.Billing,
    (state: any) => state.Analytics,
    (state: any) => state.Groups,
    (usageSummary, user, billing, analytics, groups) => ({
      loading: usageSummary.loading,
      usageSummaryObj: usageSummary.usageSummaryObj,
      error: usageSummary.error,
      authUser: user?.authUser,
      loading2: billing.loading,
      error2: billing.error,
      analyticsSmsV1: analytics.analyticsSmsV1,
      analyticsMmsV1: analytics.analyticsMmsV1,
      groupDetails: groups?.AllGroups,
    })
  );

  const {
    loading,
    usageSummaryObj,

    analyticsSmsV1,
    analyticsMmsV1,
    groupDetails,
  } = useSelector(selectProfile);
  const [summaryObj, setSummaryObj] = useState<any>({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedSubType, setSelectedSubType] = useState("totalMessagesSent");
  const [cardObj, setCardObj] = useState<any>({});

  useEffect(() => {
    if (Object.keys(usageSummaryObj).length > 0) {
      const temp = removeKeyFromObj(usageSummaryObj?.total, [
        "total",
        "transactions",
      ]);
      setSummaryObj(temp);
    }
  }, [usageSummaryObj]);

  let date = moment(month.endDate, "MM-DD-YYYY")?.diff(
    moment(month.startDate, "MM-DD-YYYY"),
    "days"
  );
  let dateDiff =
    moment(month.startDate, "MM-DD-YYYY") && moment(month.endDate, "MM-DD-YYYY")
      ? isNaN(date)
        ? 1
        : date
      : 1;

  const smsTotal = useMemo(() => {
    if (analyticsSmsV1.data) {
      return Object.keys(analyticsSmsV1.data).reduce((acc: any, date: any) => {
        const dateData = analyticsSmsV1.data[date];
        Object.keys(dateData).map((phoneNumber) => {
          const phoneData = dateData[phoneNumber];
          Object.keys(phoneData).map((key) => {
            acc[key] = (acc[key] || 0) + phoneData[key];
          });
        });
        return acc;
      }, {});
    }
    return {};
  }, [analyticsSmsV1]);
  const totalSmsSent = (smsTotal?.delivered || 0) + (smsTotal?.sent || 0);
  smsTotal["deliveryRate"] = (100 * smsTotal?.delivered) / (totalSmsSent || 1);
  smsTotal["failedRate"] =
    totalSmsSent > 0 ? 100 - smsTotal["deliveryRate"] : 0;
  smsTotal["totalMessagesSent"] = totalSmsSent;
  smsTotal["averageMessagesSent"] = totalSmsSent / dateDiff;
  smsTotal["averageMessagesReceived"] = smsTotal?.totalInbound / dateDiff;
  smsTotal["averageSegmentsSent"] = smsTotal?.segmentSent / dateDiff;

  const mmsTotal = useMemo(() => {
    if (analyticsMmsV1.data)
      return Object.keys(analyticsMmsV1.data).reduce((acc: any, date: any) => {
        const dateData = analyticsMmsV1.data[date];
        Object.keys(dateData).forEach((phoneNumber) => {
          const phoneData = dateData[phoneNumber];
          Object.keys(phoneData).forEach((key) => {
            acc[key] = (acc[key] || 0) + phoneData[key];
          });
        });
        return acc;
      }, {});
    return {};
  }, [analyticsMmsV1]);
  const totalMmsSent = (mmsTotal?.delivered || 0) + (mmsTotal?.sent || 0);
  mmsTotal["deliveryRate"] = (100 * mmsTotal?.delivered) / (totalMmsSent || 1);
  mmsTotal["failedRate"] =
    totalMmsSent > 0 ? 100 - mmsTotal["deliveryRate"] : 0;
  mmsTotal["totalMessagesSent"] = totalMmsSent;
  mmsTotal["averageMessagesSent"] = totalMmsSent / dateDiff;
  mmsTotal["averageMessagesReceived"] = mmsTotal?.totalInbound / dateDiff;
  mmsTotal["averageSegmentsSent"] = mmsTotal?.segmentSent / dateDiff;

  const totalMessagingData = useMemo(() => {
    const combinedTotals: any = {};
    if (smsTotal) {
      Object.keys(smsTotal).map((key) => {
        combinedTotals[key] = (combinedTotals[key] || 0) + smsTotal[key];
      });
    }
    if (mmsTotal) {
      Object.keys(mmsTotal).map((key) => {
        combinedTotals[key] = (combinedTotals[key] || 0) + mmsTotal[key];
      });
    }
    return combinedTotals;
  }, [smsTotal, mmsTotal]);

  useEffect(() => {
    if (groupDetails?.records?.[0]?.group_id && startDate && endDate) {
      const formattedStartDate = moment(startDate, "MM-DD-YYYY").format(
        "MM-DD-YYYY"
      );
      const formattedEndDate = moment(endDate, "MM-DD-YYYY").format(
        "MM-DD-YYYY"
      );

      const params = {
        groupId: groupDetails?.records?.[0]?.group_id,
        // statusFilter:
        //   selectedSubType === "deliveryRate"
        //     ? ["delivered"]
        //     : selectedSubType === "failed"
        //     ? ["failed"]
        //     : ["delivered", "sent"],
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      };
    }
  }, [
    groupDetails?.records?.[0]?.group_id,
    selectedSubType,
    startDate,
    endDate,
  ]);
  useEffect(() => {
    if (Object.keys(usageSummaryObj).length > 0) {
      const temp = removeKeyFromObj(usageSummaryObj?.total, [
        "total",
        "transactions",
      ]);
      setSummaryObj(temp);
    }
  }, [usageSummaryObj]);

  const orderedKeys = ["SMS", "MMS", "campaign"];

  const allTotal = Object.keys(smsTotal).reduce((acc: any, key: any) => {
    acc[key] = (smsTotal[key] || 0) + (mmsTotal[key] || 0);
    return acc;
  }, {});
  const totalAllSent = (allTotal?.delivered || 0) + (allTotal?.sent || 0);
  allTotal["deliveryRate"] = (100 * allTotal?.delivered) / (totalAllSent || 1);
  allTotal["failedRate"] =
    totalAllSent > 0 ? 100 - allTotal["deliveryRate"] : 0;
  allTotal["totalMessagesSent"] = totalAllSent;
  allTotal["averageMessagesSent"] = totalAllSent / dateDiff;
  allTotal["averageMessagesReceived"] = allTotal?.totalInbound / dateDiff;
  allTotal["averageSegmentsSent"] = allTotal?.segmentSent / dateDiff;

  useEffect(() => {
    const allTotal = Object?.keys(smsTotal)?.reduce((acc: any, key: any) => {
      acc[key] = (smsTotal[key] || 0) + (mmsTotal[key] || 0);
      return acc;
    }, {});

    const totalAllSent = (allTotal?.delivered || 0) + (allTotal?.sent || 0);
    allTotal["deliveryRate"] =
      (100 * allTotal?.delivered) / (totalAllSent || 1);
    allTotal["failedRate"] =
      totalAllSent > 0 ? 100 - allTotal["deliveryRate"] : 0;
    allTotal["totalMessagesSent"] = totalAllSent;

    const tempObj = {
      SMS: { ...smsTotal },
      MMS: { ...mmsTotal },
      All: { ...allTotal },
    };

    setCardObj(tempObj);
  }, [smsTotal, mmsTotal]);

  const dRate = cardObj?.All?.deliveryRate || 0;

  return (
    <React.Fragment>
      <Row>
        {loading ? (
          <div style={{ height: 60 }}>
            <img
              src={Loader}
              className="position-absolute top-50 start-50 translate-middle"
            />
          </div>
        ) : (
          <>
            <Col xxl sm={6} className="mb-4">
              <Card className="overflow-hidden mb-0 h-100">
                <Card.Body>
                  <div className="avatar-sm float-end">
                    <div
                      className={
                        "avatar-title bg-primary-subtle text-white fs-2xl rounded"
                      }
                    >
                      <i className="ri-question-answer-line text-primary "></i>
                    </div>
                  </div>
                  <p className="text-muted mb-3">Messaging</p>
                  <h4>
                    <span className="counter-value">
                      <CountUp
                        start={0}
                        end={
                          usageSummaryObj?.total?.SMS?.transactions +
                          usageSummaryObj?.total?.MMS?.transactions
                        }
                      />
                    </span>
                  </h4>
                  <p className="text-muted mb-1">
                    Outbound:{" "}
                    {usageSummaryObj?.total?.SMS?.outbound?.transactions +
                      usageSummaryObj?.total?.MMS?.outbound?.transactions}
                  </p>
                  <p className="text-muted mb-1">
                    Inbound:{" "}
                    {usageSummaryObj?.total?.SMS?.inbound?.transactions +
                      usageSummaryObj?.total?.MMS?.inbound?.transactions}
                  </p>
                </Card.Body>
                <div
                  className="progress progress-sm rounded-0 "
                  role="progressbar "
                >
                  <div
                    className="progress-bar bg-primary"
                    style={{ width: `${generateRandomNumber()}%` }}
                  ></div>
                </div>
              </Card>
            </Col>

            {orderedKeys.map(
              (dt1, i1) =>
                typeof summaryObj?.[dt1] === "object" &&
                !Array.isArray(summaryObj?.[dt1]) &&
                summaryObj?.[dt1] !== null && (
                  <Col xxl sm={6} key={i1} className="mb-4">
                    <Card className="overflow-hidden mb-0 h-100">
                      <Card.Body>
                        <div className="avatar-sm float-end">
                          <div
                            className={
                              "avatar-title " +
                              widgetsData[i1 % 5].bgcolor +
                              " " +
                              widgetsData[i1 % 5].textcolor +
                              " fs-3xl rounded"
                            }
                          >
                            <i className={getIcon(dt1)}></i>
                          </div>
                        </div>
                        <p className="text-muted mb-3">
                          {capitalizeString(dt1)}
                        </p>
                        <h4>
                          <span className="counter-value" data-target="368.24">
                            <CountUp
                              start={0}
                              end={usageSummaryObj?.total?.[dt1]?.transactions}
                            />
                          </span>
                        </h4>
                        {Object.keys(summaryObj?.[dt1])?.length > 0 &&
                          Object.keys(summaryObj?.[dt1]).map((dt2, i2) => (
                            <p className="text-muted mb-1" key={i2}>
                              {capitalizeString(dt2)}:{" "}
                              {usageSummaryObj?.total?.[dt1]?.[dt2]
                                ?.transactions || 0}
                            </p>
                          ))}
                      </Card.Body>
                      <div
                        className="progress progress-sm rounded-0"
                        role="progressbar"
                      >
                        <div
                          className={widgetsData[i1 % 5].progressbar}
                          style={{ width: `${generateRandomNumber()}%` }}
                        ></div>
                      </div>
                    </Card>
                  </Col>
                )
            )}

            <Col xxl sm={6} className="mb-4">
              <Card className="overflow-hidden mb-0 h-100">
                <Card.Body>
                  <div className="avatar-sm float-end">
                    <div
                      className={
                        "avatar-title bg-primary-subtle text-white fs-2xl rounded"
                      }
                    >
                      <i className=" ri-arrow-right-down-line text-primary "></i>
                    </div>
                  </div>
                  <p className="text-muted mb-3">Delivered</p>
                  <h4>
                    <span className="counter-value">
                      <CountUp suffix="%" decimals={2} start={0} end={dRate} />
                    </span>
                  </h4>{" "}
                  <p className="text-muted mb-1">
                    SMS: {(cardObj?.SMS?.deliveryRate || 0).toFixed(2)}%
                  </p>
                  <p className="text-muted mb-1">
                    MMS: {(cardObj?.MMS?.deliveryRate || 0).toFixed(2)}%
                  </p>
                </Card.Body>
                <div
                  className="progress progress-sm rounded-0 "
                  role="progressbar "
                >
                  <div
                    className="progress-bar bg-primary"
                    style={{ width: `${generateRandomNumber()}%` }}
                  ></div>
                </div>
              </Card>
            </Col>
          </>
        )}
      </Row>
    </React.Fragment>
  );
};

export default Widgets;
