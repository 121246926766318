import React, { createRef, useEffect, useState } from "react";
import {
  Container,
  Dropdown,
  Card,
  Modal,
  Button,
  Form,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  getVerifiedCallerListThunk,
  createVerifiedCallerThunk,
  checkVerificationThunk,
} from "slices/thunk";
import Datatable from "../../Common/Datatable";
import Loader from "assets/images/spinner-dark.svg";
import withRouter from "Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import { capitalizeString } from "helpers/common";

const VerifiedCallerList = (props: any) => {
  document.title = "Signal House Portal Verified Caller IDs";
  const dispatch = useDispatch<any>();
  const [modal1, setModal1] = useState<boolean>(false);
  const [modal2, setModal2] = useState<boolean>(false);
  const [filterNumber, setFilterNumber] = useState<string>("");
  const [filterFriendlyName, setFilterFriendlyName] = useState<string>("");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [open, setOpen] = useState(false);
  const otpInputRefs = Array.from({ length: 6 }, () => createRef<any>());

  const selectProfile = createSelector(
    (state: any) => state.VerifiedCaller,
    (caller) => ({
      loading: caller.loading,
      loading2: caller.loading2,
      callerArrObj: caller.callerArrObj,
      error: caller.error,
      createdCaller: caller.createdCaller,
    })
  );

  useEffect(() => {
    handleFilterClick();
  }, []);

  const { loading, loading2, callerArrObj, createdCaller } =
    useSelector(selectProfile);

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Phone Number</span>,
      selector: (row: any) => row?.token?.[0]?.to,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      sortable: true,
      selector: (cell: any) => {
        switch (cell?.token?.[0]?.status.toLowerCase()) {
          case "approved":
            return (
              <span className="badge bg-success-subtle text-success">
                {capitalizeString(cell?.token?.[0]?.status)}
              </span>
            );
          default:
            return (
              <span className="badge bg-warning-subtle text-warning">
                {capitalizeString(cell?.token?.[0]?.status)}
              </span>
            );
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Action</span>,
      selector: (cell: any) => {
        return (
          <button
            className="btn btn-secondary btn-sm"
            onClick={() =>
              handleRevalidateClick(cell?.verificationCheck?.[0]?.to)
            }
          >
            Revalidate
          </button>
        );
      },
    },
  ];

  const handleFilterClick = (
    fnum?: string | undefined,
    ffname?: string | undefined
  ) => {
    dispatch(getVerifiedCallerListThunk(fnum, ffname));
  };

  const handleRevalidateClick = (phoneNum: number | string | undefined) => {
    const cb = () => {
      setModal2(true);
      if (otpInputRefs[0]?.current) {
        otpInputRefs[0]?.current.focus();
      }
    };
    setFilterNumber("");
    setFilterFriendlyName("");
    dispatch(createVerifiedCallerThunk({ phoneNumber: phoneNum }, cb));
  };

  // phone number modal method
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      phone: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .required("Please Enter Phone number")
        .matches(
          /^[1-9]\d{1,3}[1-9]\d{6,14}$/,
          "Phone number must start with 1-4 digit country code followed by 7-15 digit phone number, numbers only"
        ),
    }),
    onSubmit: () => {
      const cb = () => {
        setModal1(false);
        validation.resetForm();
        setModal2(true);
        if (otpInputRefs[0]?.current) {
          otpInputRefs[0]?.current.focus();
        }
      };
      dispatch(
        createVerifiedCallerThunk({ phoneNumber: validation.values.phone }, cb)
      );
    },
  });
  // phone number modal method end

  // otp modal method
  const handleOtpSubmit = () => {
    const cb = () => {
      setModal2(false);
      setOtp(["", "", "", "", "", ""]);
    };

    dispatch(
      checkVerificationThunk(
        {
          serviceSID: createdCaller?.token?.[0]?.serviceSid,
          phoneNumber: createdCaller?.token?.[0]?.to,
          otp: otp?.join("").padStart(6, "0"),
        },
        cb
      )
    );
  };

  const handleInputChange = (index: number, value: any) => {
    if (isNaN(value)) return; // Only allow numeric input

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input box
    if (index < 5 && value !== "") {
      otpInputRefs[index + 1]?.current?.focus();
    }
  };

  const handleInputPaste = (e: any) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 6);

    // Validate and filter out non-numeric characters
    const numericData = pastedData.replace(/[^0-9]/g, "");

    const newOtp = [...otp];
    for (let i = 0; i < numericData.length; i++) {
      newOtp[i] = numericData[i];
    }

    setOtp(newOtp);

    // Move focus to the next input box after paste
    if (numericData.length > 0 && otpInputRefs[numericData.length]?.current) {
      otpInputRefs[numericData.length]?.current?.focus();
    }
  };

  const handleKeyDown = (index: number, e: any) => {
    // Handle backspace to move to the previous input box
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      otpInputRefs[index - 1]?.current?.focus();
    }
  };
  // otp modal method end

  const setDropdown = () => setOpen(!open);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Verified Caller IDs"
            isFilter={true}
            filterButton={
              <Dropdown
                align="end"
                className="filterDropDown"
                show={open}
                onToggle={setDropdown}
              >
                <Dropdown.Toggle variant="secondary">
                  <i className="bx bx-filter-alt me-1"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-md p-4">
                  <Row>
                    <Col sm={6} className="mb-3">
                      <Form.Label htmlFor="number">Number</Form.Label>
                      <Form.Control
                        type="text"
                        name="number"
                        disabled={loading || loading2}
                        onChange={(e) => {
                          setFilterNumber(e.target.value);
                        }}
                        value={filterNumber}
                      />
                    </Col>
                    <Col sm={6} className="mb-3">
                      <Form.Label htmlFor="friendlyName">
                        Friendly Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="friendlyName"
                        disabled={loading || loading2}
                        onChange={(e) => {
                          setFilterFriendlyName(e.target.value);
                        }}
                        value={filterFriendlyName}
                      />
                    </Col>
                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        className="btn btn-secondary me-2"
                        onClick={() => {
                          setDropdown();
                          setFilterNumber("");
                          setFilterFriendlyName("");
                          handleFilterClick();
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        className="btn btn-primary"
                        disabled={!filterNumber && !filterFriendlyName}
                        onClick={() => {
                          setDropdown();
                          handleFilterClick(filterNumber, filterFriendlyName);
                        }}
                      >
                        {loading && <Spinner size="sm" animation="border" />}{" "}
                        Apply
                      </Button>
                    </div>
                  </Row>
                </Dropdown.Menu>
              </Dropdown>
            }
            isCreate={true}
            createLabel="Add new caller ID"
            createClick={() => setModal1(true)}
          />
          <div className="">
            <Card className="wrapper">
              <Card.Body className="listing-table">
                <div className="mb-4">
                  Verity a number that you own to use it as a caller ID or as
                  the "To" number for outbound calls/messages from tne Sanadox
                  Number. The phone numbers you buy from Signal House, or port
                  to Signal House, can always be used as caller IDs.{" "}
                  <a href="#">Learn more about verified Caller IDs</a>
                </div>
                <div className="position-relative" style={{ height: "100px" }}>
                  {loading ? (
                    <div className={``}>
                      <img
                        src={Loader}
                        className={`position-absolute top-50 start-50 translate-middle`}
                        alt="loading"
                      />
                    </div>
                  ) : (
                    <Datatable
                      data={
                        Array.isArray(callerArrObj?.data)
                          ? callerArrObj?.data
                          : []
                      }
                      columns={columns}
                    />
                  )}
                </div>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
      <Modal
        centered
        show={modal1}
        onHide={() => {
          setModal1(false);
          validation.resetForm();
        }}
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
          }}
        >
          <Modal.Header closeButton>
            <h4 className="mb-0">Add a Caller ID</h4>
          </Modal.Header>
          <Modal.Body className="p-4">
            <p className="fs-md">
              The phone numbers you buy from Signal House, or port to Signal
              House, can always be used as Caller IDs.
            </p>
            <div className="mb-3">
              <Form.Label htmlFor="phone">
                Phone Number <span className="text-danger">*</span>
              </Form.Label>
              <br />
              <Form.Control
                type="number"
                id="phone"
                name="phone"
                placeholder="Enter phone number"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.phone || ""}
                isInvalid={
                  validation.touched.phone && validation.errors.phone
                    ? true
                    : false
                }
              />
              {validation.touched.phone && validation.errors.phone ? (
                <Form.Control.Feedback type="invalid">
                  {validation.errors.phone}
                </Form.Control.Feedback>
              ) : null}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="hstack gap-2 justify-content-end">
              <Button
                className="btn btn-ghost-danger"
                onClick={() => {
                  setModal1(false);
                  validation.resetForm();
                }}
              >
                <i className="bi bi-x-lg align-baseline me-1"></i> Cancel
              </Button>
              <Button
                id="add-btn"
                type="submit"
                variant="primary"
                disabled={!validation?.dirty || loading2}
              >
                {loading2 && <Spinner size="sm" animation="border" />} Verify
                Number
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        centered
        show={modal2}
        onHide={() => {
          setModal2(false);
          setOtp(["", "", "", "", "", ""]);
        }}
      >
        <Modal.Header closeButton>
          <h4 className="mb-0">Verify OTP</h4>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="mb-3">
            <Form.Label htmlFor="otp">
              OTP <span className="text-danger">*</span>
            </Form.Label>
            <Row>
              {otp.map((digit, index) => (
                <Col key={index}>
                  <Form.Control
                    className="text-center"
                    type="text"
                    name="companyName"
                    maxLength={1}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    onPaste={handleInputPaste}
                    ref={otpInputRefs[index]}
                    value={digit}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="hstack gap-2 justify-content-end">
            <Button
              className="btn btn-ghost-danger"
              onClick={() => {
                setModal2(false);
                setOtp(["", "", "", "", "", ""]);
              }}
            >
              <i className="bi bi-x-lg align-baseline me-1"></i> Cancel
            </Button>
            <Button
              id="add-btn"
              variant="primary"
              disabled={otp.includes("") || loading2}
              onClick={handleOtpSubmit}
            >
              {loading2 && <Spinner size="sm" animation="border" />} Verify OTP
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(VerifiedCallerList);
