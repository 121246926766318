import { useEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
import {
  authUserLogoutThunk,
  getAllGroupsThunk,
  getAuthUserThunk,
  getUserStepsThunk,
  getWebNotificationsListThunk,
  logoutUserThunk,
} from "slices/thunk";
import { createSelector } from "reselect";

const AuthProtected = (props: any) => {
  const dispatch = useDispatch<any>();
  const selectAccountAndLogin = createSelector(
    (state: any) => state.Login,
    (state: any) => state.AuthUser,
    (login, authUser) => ({
      loginData: login.loginData,
      user: authUser.authUser,
    })
  );
  const { loginData, user } = useSelector(selectAccountAndLogin);

  useEffect(() => {
    const obj = loginData && JSON.parse(loginData);
    if (obj.apiKey && obj.authToken) {
      setAuthorization(obj.apiKey, obj.authToken);
      dispatch(getAuthUserThunk());
      dispatch(getWebNotificationsListThunk());
      dispatch(getAllGroupsThunk({ page: 1, recordsPerPage: 10 }));
    } else if (!obj.apiKey && !obj.authToken) {
      dispatch(logoutUserThunk());
      dispatch(authUserLogoutThunk());
    }
  }, [loginData]);

  useEffect(() => {
    if (user) dispatch(getUserStepsThunk(user?.completedSteps));
  }, [user]);

  const obj = loginData && JSON.parse(loginData);
  if (!loginData || (!obj?.apiKey && !obj?.authToken)) {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props: any) => {
        return (
          <>
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
