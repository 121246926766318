import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { Form, Col, Button } from "react-bootstrap";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

interface FormData {
  apiKey: string;
  publicKey: string;
}

const ApiKeyCard: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    apiKey: "",
    publicKey: "",
  });

  const selectAccountAndLogin = createSelector(
    (state: any) => state.Login,
    (login) => ({
      loginData: login.loginData,
    })
  );

  const { loginData } = useSelector(selectAccountAndLogin);

  useEffect(() => {
    try {
      const obj = loginData && JSON.parse(loginData);
      obj?.apiKey && setFormData((prev) => ({ ...prev, apiKey: obj.apiKey }));
      obj?.authToken && setFormData((prev) => ({ ...prev, publicKey: obj.authToken }));
    } catch (error) {
      console.error("Error parsing loginData", error);
    }
  }, [loginData]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const copyText = (e: React.MouseEvent<HTMLButtonElement>, type: string) => {
    e.preventDefault();
    const textToCopy = document.getElementById(type) as HTMLInputElement;
    if (textToCopy) {
      textToCopy.select();
      textToCopy.setSelectionRange(0, 99999); /* For mobile devices */
      navigator.clipboard.writeText(textToCopy.value);
      toast.success("Text copied");
    }
  };

  return (
    <Col>
      <div className="card py-0" style={{ height: "398px", padding: "0px 19.2px" }}>
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="mb-3">API Keys</h4>
                <a href="/" className="ml-2">Manage Keys</a>
              </div>

              <Form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <Form.Label htmlFor="apiKey">API Key</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      type="text"
                      id="apiKey"
                      name="apiKey"
                      className="form-control pe-5 password-input"
                      placeholder="Enter API key"
                      value={formData.apiKey}
                      onChange={handleChange}
                      disabled
                    />
                    <Button
                      variant="link"
                      className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                      type="button"
                      onClick={(e) => copyText(e, "apiKey")}
                    >
                      <i className="bx bxs-copy align-middle"></i>
                    </Button>
                  </div>
                </div>
                <div className="mb-3">
                  <Form.Label htmlFor="publicKey">Public Key</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      type="password"
                      id="publicKey"
                      name="publicKey"
                      className="form-control pe-5 password-input"
                      placeholder="Enter public key"
                      value={formData.publicKey}
                      onChange={handleChange}
                      disabled
                    />
                    <Button
                      variant="link"
                      className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                      type="button"
                      onClick={(e) => copyText(e, "publicKey")}
                    >
                      <i className="bx bxs-copy align-middle"></i>
                    </Button>
                  </div>
                </div>
                {/* <Button type="submit">Submit</Button> */}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ApiKeyCard;
