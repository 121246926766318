import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  Container,
  Card,
  Button,
  Form,
  Row,
  Col,
  Tab,
  Nav,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import {
  PhoneNumberLookupThunk,
  getNumVerifyLookUpThunk,
  clearNumVerifyLookUpData,
} from "slices/thunk";
import Loader from "assets/images/spinner-dark.svg";
import { toast } from "react-toastify";
import { dateFormat } from "helpers/common";

interface OptionType {
  value: string;
  label: string;
}

interface FormData {
  apiKey: string;
  publicKey: string;
}

const featureOptions: OptionType[] = [
  { value: "fs1", label: "fs1" },
  { value: "fs23", label: "fs23" },
  { value: "fs2", label: "fs2" },
  { value: "fs5", label: "fs5" },
];

const PhoneNumberLookup = (props: any) => {
  document.title = "Signal House Portal Phone Number Lookup";
  const dispatch = useDispatch<any>();
  const [searchPhoneNumber, setSearchPhoneNumber] = useState<string>("");
  const [showDetailCard, setShowDetailCard] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const loading = useSelector((state: any) => state?.Numbers?.loading);

  const selectAccount = createSelector(
    (state: any) => state.Numbers,
    (state: any) => state.Login,
    (authUser, login) => ({
      stepsData: authUser.stepsData,
      user: authUser.authUser,
      loginData: login.loginData,
    })
  );

  const [formData, setFormData] = useState<FormData>({
    apiKey: "",
    publicKey: "",
  });

  const detailData = useSelector(
    (state: any) => state?.Numbers?.numVerifyLookUpObj
  );

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string().required("Phone number is mandatory"),
  });

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
      feature: null as OptionType | null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const payload = {
        feature: values.feature ? values.feature.value : "",
      };
      dispatch(PhoneNumberLookupThunk(payload));
      setShowDetailCard(false); // Hide card on new search
    },
  });

  const handleSearch = async () => {
    await formik.setFieldValue("phoneNumber", searchPhoneNumber);
    formik.setFieldTouched("phoneNumber", true);
    const errors = await formik.validateForm();

    if (Object.keys(errors).length === 0) {
      dispatch(getNumVerifyLookUpThunk(searchPhoneNumber));
      setShowDetailCard(true); // Show card on successful search
    }
    handleShow();
    setShowDetailCard(true); // Show card on successful search
  };

  const handleClear = () => {
    setSearchPhoneNumber("");
    formik.resetForm();
    dispatch(clearNumVerifyLookUpData());
    setShowDetailCard(false); // Hide card on clear
  };
  const copyToClipboard = (data: any) => {
    navigator.clipboard.writeText(JSON.stringify(data, null, 2));
    toast.success("JSON Data copied");
  };

  const copyText = (e: any, type: string) => {
    e && e.preventDefault();
    const textToCopy = document.getElementById(type) as HTMLInputElement;
    if (textToCopy) {
      const textArea = document.createElement("textarea");
      textArea.id = "copyDiv";
      textArea.style.position = "absolute";
      textArea.style.bottom = "0px";
      textArea.textContent = textToCopy.textContent;
      document.body.append(textArea);
      textArea.select();
      textArea.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(textArea?.value);
      toast.success("Text copied");
      const myDiv = document.getElementById("copyDiv") as HTMLInputElement;
      myDiv.remove();
    }
  };

  return (
    <React.Fragment>
      <Container fluid>
        <BreadCrumb title={"Create Lookup"} />

        <Card>
          <Card.Body>
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg={5} className="mb-5">
                  <Col sm={7}>
                    <div className="mb-3">
                      <Form.Label htmlFor="searchPhoneNumber">
                        Phone Number
                      </Form.Label>
                      <Form.Control
                        type="text"
                        id="searchPhoneNumber"
                        value={searchPhoneNumber}
                        onChange={(e: any) =>
                          setSearchPhoneNumber(e.target.value)
                        }
                        isInvalid={
                          formik.touched.phoneNumber &&
                          !!formik.errors.phoneNumber
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.phoneNumber}
                      </Form.Control.Feedback>
                    </div>
                  </Col>
                  <Row className="mt-2">
                    <div className="d-flex align-items-center">
                      <Button
                        className="btn btn-primary me-2"
                        type="button"
                        onClick={handleSearch}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Search
                      </Button>
                      <Button
                        className="btn btn-secondary"
                        type="button"
                        disabled={loading}
                        onClick={handleClear}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Clear
                      </Button>
                    </div>
                  </Row>
                </Col>
                <Col xl={7}>
                  <Card className="mb-4">
                    <Card.Header
                      style={{
                        background: "rgb(31, 48, 76)",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <h6 className="fs-md text-white">Request</h6>
                    </Card.Header>
                    <Card.Body
                      className="text-white position-relative"
                      style={{
                        background: "rgb(18, 28, 45)",
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                      }}
                    >
                      <div
                        className="position-absolute end-0 me-3 cursor-pointer"
                        onClick={(e) => copyText(e, "request")}
                      >
                        <i className="bx bx-copy align-middle me-2 fs-3xl" />
                      </div>
                      <div id="request">
                        <pre className="fs-sm">
                          {`curl -X 'POST' \\
                         ' '${
                           process.env.REACT_APP_BACKEND_URL ||
                           "https://devapi.signalhouse.io"
                         }/phoneNumber/numVerifyLookUp?phoneNumber=${searchPhoneNumber} \\
                          -H 'accept: application/json' \\
                          -H 'apiKey: ${formData.apiKey}' \\
                          -H 'authToken: ${formData.publicKey}' \\
                         -H 'Content-Type: application/json' \\
                         -d '{
                         "phoneNumber": "${searchPhoneNumber}",
                     }'`}
                        </pre>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>

        <Modal show={showModal} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Phone Number Lookup</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loading ? (
              <div>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                />
              </div>
            ) : showDetailCard && detailData ? (
              <Tab.Container defaultActiveKey="rowData">
                <Nav variant="tabs">
                  {/* <Nav.Item>
                      <Nav.Link eventKey="rowData">Row Data</Nav.Link>
                    </Nav.Item> */}
                  {/* <Nav.Item>
                      <Nav.Link eventKey="jsonData">JSON Data</Nav.Link>
                    </Nav.Item> */}
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="rowData">
                    <div className="p-2">
                      <Row>
                        <Col xl={6} className="mb-3">
                          <Row className="g-0">
                            <Col md={6}>
                              <h6 className="mb-0">Phone Number:</h6>
                            </Col>
                            <Col md={6}>{detailData.phoneNumber || "N/A"}</Col>
                          </Row>
                        </Col>
                        <Col xl={6} className="mb-3">
                          <Row className="g-0">
                            <Col md={6}>
                              <h6 className="mb-0">Charges:</h6>
                            </Col>
                            <Col md={6}>
                              {detailData.charges
                                ? `$${detailData.charges} USD`
                                : "N/A"}
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={6} className="mb-3">
                          <Row className="g-0">
                            <Col md={6}>
                              <h6 className="mb-0">Created Date:</h6>
                            </Col>
                            <Col md={6}>
                              {dateFormat(detailData.createdDate)}
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={6} className="mb-3">
                          <Row className="g-0">
                            <Col md={6}>
                              <h6 className="mb-0">Valid:</h6>
                            </Col>
                            <Col md={6}>
                              {detailData?.lookUpInstance?.valid
                                ? "Active"
                                : "Inactive"}
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={6} className="mb-3">
                          <Row className="g-0">
                            <Col md={6}>
                              <h6 className="mb-0">Local Format:</h6>
                            </Col>
                            <Col md={6}>
                              {detailData?.lookUpInstance?.local_format ||
                                "N/A"}
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={6} className="mb-3">
                          <Row className="g-0">
                            <Col md={6}>
                              <h6 className="mb-0">International Format:</h6>
                            </Col>
                            <Col md={6}>
                              {detailData?.lookUpInstance
                                ?.international_format || "N/A"}
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={6} className="mb-3">
                          <Row className="g-0">
                            <Col md={6}>
                              <h6 className="mb-0">Country Prefix:</h6>
                            </Col>
                            <Col md={6}>
                              {detailData?.lookUpInstance?.country_prefix ||
                                "N/A"}
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={6} className="mb-3">
                          <Row className="g-0">
                            <Col md={6}>
                              <h6 className="mb-0">Country Code:</h6>
                            </Col>
                            <Col md={6}>
                              {detailData?.lookUpInstance?.country_code ||
                                "N/A"}
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={6} className="mb-3">
                          <Row className="g-0">
                            <Col md={6}>
                              <h6 className="mb-0">Country Name:</h6>
                            </Col>
                            <Col md={6}>
                              {detailData?.lookUpInstance?.country_name ||
                                "N/A"}
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={6} className="mb-3">
                          <Row className="g-0">
                            <Col md={6}>
                              <h6 className="mb-0">Location:</h6>
                            </Col>
                            <Col md={6}>
                              {detailData?.lookUpInstance?.location || "N/A"}
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={6} className="mb-3">
                          <Row className="g-0">
                            <Col md={6}>
                              <h6 className="mb-0">Carrier:</h6>
                            </Col>
                            <Col md={6}>
                              {detailData?.lookUpInstance?.carrier || "N/A"}
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={6} className="mb-3">
                          <Row className="g-0">
                            <Col md={6}>
                              <h6 className="mb-0">Line Type:</h6>
                            </Col>
                            <Col md={6}>
                              {detailData?.lookUpInstance?.line_type || "N/A"}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Tab.Pane>

                  {/* <Tab.Pane eventKey="jsonData">
                      <Card.Body
                        className="text-white position-relative"
                        style={{
                          background: "rgb(18, 28, 45)",
                          borderBottomLeftRadius: 10,
                          borderBottomRightRadius: 10,
                          padding: 20,
                        }}
                      >
                        <div
                            className="position-absolute end-0 me-3 cursor-pointer"
                            onClick={(e) => copyText(e, "request")}
                          >
                            <i className="bx bx-copy align-middle me-2 fs-3xl" />
                          </div>
                          <div id="request">
                        <pre className="fs-sm">
                          {`curl -X 'GET' \\
      'https://devapi.signalhouse.io/phoneNumber/numVerifyLookUp?phoneNumber=${
        detailData.phoneNumber
      }' \\
      -H 'accept: application/json' \\
      -H 'apiKey: ${formData.apiKey}' \\
      -H 'authToken: ${formData.publicKey}' \\
      -H 'Content-Type: application/json' \\
      -d '{
      "phoneNumber": "${detailData.phoneNumber || ""}",
     "lookUpInstance": "{
      "createdDate": "${detailData.createdDate || ""}",
      "valid": "${detailData.lookUpInstance?.valid || ""}",
      "local_format": "${detailData.lookUpInstance?.local_format || ""}",
      "local_format": "${detailData.lookUpInstance?.international_format || ""}",
      "local_format": "${detailData.lookUpInstance?.country_prefix || ""}",
      "country_code": "${detailData.lookUpInstance?.country_code || ""}",
      "country_name": "${detailData.lookUpInstance?.country_name || ""}",
      "location": "${detailData.lookUpInstance?.location || ""}",
      "carrier": "${detailData.lookUpInstance?.carrier || ""}",
      "line_type": "${detailData.lookUpInstance?.line_type || ""}",
     }",
     "charges": "${detailData.charges || ""}",
     "createdDate": "${detailData.createdDate || ""}",
       }
    ",
    }'`}
                        </pre>
                        </div>
                      </Card.Body>
                    </Tab.Pane> */}
                </Tab.Content>
              </Tab.Container>
            ) : null}
          </Modal.Body>
        </Modal>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(PhoneNumberLookup);
