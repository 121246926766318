// @ts-nocheck

import React, { useEffect, useState, useCallback, useRef } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { createSelector } from "reselect";

import withRouter from "Common/withRouter";
import BreadCrumb from "Common/BreadCrumb";
// import Loader from "components/Loader";
import DLC from "./DLC";

import {
  GetConfiguredNumber,
  configureNumbers,
  getDetailsOfOwnNumber,
  getSubgroupDetailsThunk,
  tenDLCWhitelistThunk,
} from "slices/thunk";
import {
  customSelectTheme,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import {
  fetchCampaignDetailsApi,
  subgroupListApi,
} from "helpers/apiroute_helper";
import axios from "axios";

const selectAccount = createSelector(
  (state) => state.Groups,
  (state) => state.ConfigureNumbers,
  (state) => state.Numbers,
  (groups, configureNumbers, numbers) => ({
    groupDetails: groups?.AllGroups,
    subgroupDetails: groups.subgroupDetails,
    configNumberDetails: configureNumbers?.data,
    phoneNumberDetails: numbers?.numberDetails,
  })
);

const validationSchema = Yup.object().shape({
  groupId: Yup.string().required("Group is required"),
  subGroupId: Yup.array()
    .min(1, "At least one subgroup must be selected")
    .required("Subgroups are required"),
  urlOne_Message: Yup.string().url("Invalid URL").notRequired(),
  urlTwo_Message: Yup.string().url("Invalid URL").notRequired(),
});

const dlcValidationSchema = Yup.object().shape({
  brandId: Yup.string().when("isCnpCampaign", {
    is: false,
    then: () => Yup.string().required("Please select a brand"),
    otherwise: () => Yup.string().notRequired(),
  }),
  campaignId: Yup.string().required("Please select a campaign"),
});

const ConfigureNumbers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { phoneNumber, sid } = useParams();
  const hasSetValues = useRef(false);

  const {
    groupDetails,
    subgroupDetails,
    configNumberDetails,
    phoneNumberDetails,
  } = useSelector(selectAccount);

  const [loading, setLoading] = useState(false);
  const [subgroupTemp, setSubgroupTemp] = useState([]);
  const [isCnpCampaign, setIsCnpCampaign] = useState(false);
  const [brandTemp, setBrandTemp] = useState({});
  const [campaignTemp, setCampaignTemp] = useState({});
  const [isSubgroupChanged, setIsSubgroupChanged] = useState(false);
  const [campaignDetails, setCampaignDetails] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);

  const initialValues = {
    sid,
    phoneNumber,
    groupId: groupDetails?.records?.[0]?.group_id || "",
    subGroupId: [],
    aMessageComesIn_Message: "webhook",
    urlOne_Message: "",
    primaryHandlerFails_Message: "webhook",
    urlTwo_Message: "",
  };

  const dlcInitialValues = {
    brandId: "",
    campaignId: "",
    isSubgroupChanged: false,
    isCnpCampaign: false,
    usecase: "",
    description: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values, dlcFormik.values);
    },
  });

  const dlcFormik = useFormik({
    initialValues: dlcInitialValues,
    validationSchema: dlcValidationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,

    onSubmit: (values) => {
      onSubmit(formik.values, values);
    },
  });

  const onSubmit = useCallback(
    async (values) => {
      setIsSubmit(true);
      const dlcValues = dlcFormik.values;

      if (phoneNumberDetails?.brandId !== dlcValues?.brandId) {
        // Validate DLC formik first
        const dlcErrors = await dlcFormik.validateForm();

        if (Object.keys(dlcErrors).length > 0) {
          // Set focus on the first error field
          const firstErrorField = Object.keys(dlcErrors)[0];
          if (firstErrorField) {
            const errorElement = document.getElementById(firstErrorField);

            if (errorElement) {
              errorElement.focus();
            }
          }

          // If there are validation errors, set them and stop further processing
          dlcFormik.setErrors(dlcErrors);
          dlcFormik.setTouched(
            Object.keys(dlcErrors).reduce((acc, key) => {
              acc[key] = true;
              return acc;
            }, {})
          );
          return;
        }
      }

      const handleDLCSubmit = async () => {
        const whitelistPayload = {
          phoneNumber: String(phoneNumber),
          campaignId: dlcFormik?.values?.campaignId || "",
          usecase: dlcFormik?.values?.usecase || campaignDetails?.usecase || "",
          description:
            dlcFormik?.values?.description ||
            campaignDetails?.description ||
            "",
        };

        dispatch(
          tenDLCWhitelistThunk(removeEmptyAndNullValues(whitelistPayload))
        ).then(() => {
          setIsSubmit(false);
        });
      };

      // If DLC validation passes or brand ID hasn't changed, proceed with the rest of the logic

      const updateConfigAndDetails = () => {
        const updatedPayload = {
          sid,
          phoneNumber,
          groupId: values.groupId,
          subGroupId: values.subGroupId?.map((dt) => dt?.sub_group_id),
          messagingPrimaryUrl: values.urlOne_Message,
          messagingSecondaryUrl: values.urlTwo_Message,
          brandId: dlcValues?.isCnpCampaign ? null : dlcValues?.brandId,
          campaignId: dlcValues?.campaignId,
        };

        dispatch(configureNumbers(updatedPayload)).then(() => {
          setIsSubgroupChanged(false);
          // dispatch(getDetailsOfOwnNumber({ phoneNumber })).then(() => {
          if (
            (phoneNumberDetails?.campaignId &&
              phoneNumberDetails?.brandId !== dlcValues?.brandId) ||
            phoneNumberDetails?.campaignId !== dlcValues?.campaignId ||
            phoneNumberDetails?.subGroupId?.[0] !== dlcValues?.subGroupId?.[0]
          ) {
            handleDLCSubmit();
          } else {
            setIsSubmit(false);
          }
        });
      };

      updateConfigAndDetails();

      // if (
      //   phoneNumberDetails?.brandId !== brandTemp?.brandId ||
      //   phoneNumberDetails?.campaignId !== campaignTemp?.campaignId
      // ) {
      //   console.log(whitelistPayload, "whitelistPayload");
      // } else {
      //   updateConfigAndDetails();
      // }
    },
    [
      dispatch,
      phoneNumber,
      sid,
      isCnpCampaign,
      brandTemp,
      campaignTemp,
      phoneNumberDetails,
      campaignDetails,
      dlcFormik?.values,
      formik?.values,
      campaignDetails,
    ]
  );

  useEffect(() => {
    dlcFormik.validateForm();
  }, [dlcFormik.values]);

  useEffect(() => {
    if (phoneNumber) {
      setLoading(true);
      dispatch(GetConfiguredNumber({ phoneNumber })).finally(() => {
        setLoading(false);
      });
    }
  }, [dispatch, phoneNumber]);

  useEffect(() => {
    if (subgroupDetails?.data?.length > 0) {
      setSubgroupTemp([subgroupDetails?.data?.[0]]);
      formik.setFieldValue("subGroupId", [subgroupDetails?.data?.[0]]);
    }
  }, [subgroupDetails]);

  useEffect(() => {
    if (
      !hasSetValues.current &&
      configNumberDetails &&
      groupDetails?.records?.[0]?.group_id &&
      Object.keys(configNumberDetails)?.length > 0
    ) {
      formik.setValues({
        ...formik.values,
        groupId: groupDetails?.records?.[0]?.group_id || "",
        aMessageComesIn_Message:
          configNumberDetails?.aMessageComesIn?.[0]?.aMessageComesWith ||
          "webhook",
        urlOne_Message: configNumberDetails?.aMessageComesIn?.[0]?.url || "",
        primaryHandlerFails_Message:
          configNumberDetails?.primaryHandler?.[0]?.primaryHandlerFails ||
          "webhook",
        urlTwo_Message: configNumberDetails?.primaryHandler?.[0]?.url || "",
      });
      hasSetValues.current = true;
    }
  }, [configNumberDetails, groupDetails]);

  useEffect(() => {
    if (phoneNumberDetails?.brandId || phoneNumberDetails?.campaignId) {
      const isCnpCampaign =
        !phoneNumberDetails?.brandId && phoneNumberDetails?.campaignId;
      dlcFormik.setValues({
        ...dlcFormik.values,
        brandId: phoneNumberDetails?.brandId || "",
        campaignId: phoneNumberDetails?.campaignId || "",
        usecase: phoneNumberDetails?.usecase || "",
        description: phoneNumberDetails?.description || "",
        isCnpCampaign: isCnpCampaign,
      });
      setIsCnpCampaign(isCnpCampaign);
    }
  }, [phoneNumberDetails]);

  useEffect(() => {
    if (phoneNumberDetails?.subGroupId?.[0]) {
      dispatch(getSubgroupDetailsThunk(phoneNumberDetails?.subGroupId?.[0]));
    }
  }, [dispatch, phoneNumberDetails]);

  const loadOptions2 = useCallback(
    async (searchQuery, loadedOptions, { page }) => {
      const data = await subgroupListApi(page, 10, {
        groupId: groupDetails?.records?.[0]?.group_id,
      });

      if (!data?.code && !data?.errorCode) {
        return {
          options: data?.records,
          hasMore: data?.records?.length >= 1,
          additional: {
            page: page + 1,
          },
        };
      } else {
        return {
          options: [],
          hasMore: true,
          additional: {
            page: page,
          },
        };
      }
    },
    [groupDetails]
  );

  useEffect(() => {
    const fetchCampaignDetails = async () => {
      if (phoneNumberDetails?.campaignId && !campaignDetails) {
        try {
          const response = await fetchCampaignDetailsApi(
            phoneNumberDetails?.campaignId,
            phoneNumberDetails?.brandId
          );
          setCampaignDetails({ ...response });
        } catch (error) {
          console.error("Error fetching campaign details:", error);
        }
      }
    };

    fetchCampaignDetails();
  }, [phoneNumberDetails?.campaignId]);

  return (
    <div className="container-fluid">
      <BreadCrumb title="Configure Your Number" />
      {/* <Form onSubmit={formik.handleSubmit}> */}
      <Row>
        <div className="mb-1">
          <h5>
            Sub Group configuration for{" "}
            <span className="text-primary">
              {groupDetails?.records?.[0]?.group_name || "-"} (
              {groupDetails?.records?.[0]?.group_id || "-"})
            </span>
          </h5>
        </div>
        <Col lg={12} className="mb-4">
          <Row className="border bg-light-gray rounded-3 p-3">
            {formik.touched.groupId && formik.errors.groupId && (
              <Form.Control.Feedback
                type="invalid"
                className="d-block mt-0 mb-2"
              >
                {formik.errors.groupId}
              </Form.Control.Feedback>
            )}
            <Col lg={4}>
              <Form.Label htmlFor="subGroupId">
                Sub Group <span className="text-danger">*</span>
              </Form.Label>
              <AsyncPaginate
                isDisabled={!formik.values.groupId}
                menuPortalTarget={document.body}
                key={formik.values.groupId}
                styles={styleObj(
                  formik.touched.subGroupId && formik.errors.subGroupId
                )}
                theme={customSelectTheme}
                value={subgroupTemp || {}}
                loadOptions={loadOptions2}
                getOptionValue={(option) => option?.sub_group_id}
                getOptionLabel={(option) => option?.sub_group_name}
                onChange={(option) => {
                  setSubgroupTemp([option]);
                  formik.setFieldValue("subGroupId", [option]);
                  dlcFormik.setFieldValue("campaignId", "");
                  dlcFormik.setFieldValue("brandId", "");
                  dlcFormik.setFieldValue("isCnpCampaign", false);
                  dlcFormik.setTouched({
                    brandId: true,
                    campaignId: true,
                  });
                  if (
                    phoneNumberDetails?.brandId ||
                    phoneNumberDetails?.campaignId
                  ) {
                    const isChanged =
                      option?.sub_group_id !==
                      subgroupDetails?.data?.[0]?.sub_group_id;
                    setIsSubgroupChanged(isChanged);
                    dlcFormik.setFieldValue("isSubgroupChanged", isChanged);
                  }
                }}
                isSearchable={true}
                additional={{
                  page: 1,
                }}
              />
              {formik.touched.subGroupId && formik.errors.subGroupId && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {formik.errors.subGroupId}
                </Form.Control.Feedback>
              )}
            </Col>
          </Row>
        </Col>

        <div className="mt-3 mb-1">
          <h5>10DLC Provisioning</h5>
        </div>
        <Col lg={12} className="mb-4">
          <DLC
            validation={dlcFormik}
            validation2={formik}
            isSubmit={isSubmit}
          />
        </Col>

        <div className="mt-3 mb-1">
          <h5>Messaging Configuration</h5>
        </div>
        <Col lg={12} className="mb-4">
          <Row className="border bg-light-gray rounded-3 p-3">
            <Col lg={4} className="mb-3">
              <Form.Label htmlFor="aMessageComesIn_Message">
                A message comes in
              </Form.Label>
              <Select
                name="aMessageComesIn_Message"
                styles={styleObj(
                  formik?.touched?.aMessageComesIn_Message &&
                    formik?.errors?.aMessageComesIn_Message
                )}
                theme={customSelectTheme}
                options={[
                  {
                    label: "Webhook",
                    value: "webhook",
                  },
                ]}
                onChange={(e) => {
                  formik.setFieldValue("aMessageComesIn_Message", e?.value);
                }}
                value={{
                  label: "Webhook",
                  value: formik.values.aMessageComesIn_Message,
                }}
              />
            </Col>
            <Col lg={8} className="mb-3">
              <Form.Label htmlFor="urlOne_Message">URL</Form.Label>
              <Form.Control
                {...formik.getFieldProps("urlOne_Message")}
                className="form-control"
                placeholder="URL"
                data-choices
                data-choices-search-false
                id="urlOne_Message"
                style={{ width: "100%" }}
                isInvalid={
                  formik.touched.urlOne_Message && formik.errors.urlOne_Message
                }
              />
              {formik.touched.urlOne_Message &&
                formik.errors.urlOne_Message && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.urlOne_Message}
                  </Form.Control.Feedback>
                )}
            </Col>
            <Col lg={4} className="mb-3">
              <Form.Label htmlFor="primaryHandlerFails_Message">
                Primary handler fails
              </Form.Label>
              <Select
                name="primaryHandlerFails_Message"
                styles={styleObj(
                  formik?.touched?.primaryHandlerFails_Message &&
                    formik?.errors?.primaryHandlerFails_Message
                )}
                theme={customSelectTheme}
                options={[
                  {
                    label: "Webhook",
                    value: "webhook",
                  },
                ]}
                onChange={(e) => {
                  formik.setFieldValue("primaryHandlerFails_Message", e?.value);
                }}
                value={{
                  label: "Webhook",
                  value: formik.values.primaryHandlerFails_Message,
                }}
              />
            </Col>
            <Col lg={8} className="mb-3">
              <Form.Label htmlFor="urlTwo_Message">URL</Form.Label>
              <Form.Control
                {...formik.getFieldProps("urlTwo_Message")}
                className="form-control"
                placeholder="URL"
                data-choices
                data-choices-search-false
                id="urlTwo_Message"
                style={{ width: "100%" }}
                isInvalid={
                  formik.touched.urlTwo_Message && formik.errors.urlTwo_Message
                }
              />
              {formik.touched.urlTwo_Message &&
                formik.errors.urlTwo_Message && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.urlTwo_Message}
                  </Form.Control.Feedback>
                )}
            </Col>
          </Row>
        </Col>

        <Col lg={12}>
          <div className="hstack gap-2">
            <Button
              disabled={isSubmit}
              onClick={() => {
                formik.handleSubmit(formik.values);
              }}
              className="btn btn-primary"
              type="buttom"
            >
              Save Configuration
            </Button>
            <Button
              className="btn btn-primary"
              style={{ background: "none", color: "#e51cfd" }}
              onClick={() => navigate("/mynumbers")}
            >
              Return to Active Numbers
            </Button>
          </div>
        </Col>
      </Row>
      {/* </Form> */}
    </div>
  );
};

export default withRouter(ConfigureNumbers);
