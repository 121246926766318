import withRouter from "Common/withRouter";
import { brandListApi, campaignListApi } from "helpers/apiroute_helper";
import {
  customSelectTheme,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { AsyncPaginate } from "react-select-async-paginate";
import { createSelector } from "reselect";
// import { useEffect } from "react";

const DLC = ({ validation, validation2, isSubmit }: any) => {
  const selectProfile = createSelector(
    (state: any) => state.Groups,
    (groups) => ({
      groupDetails: groups?.AllGroups,
    })
  );

  const { groupDetails } = useSelector(selectProfile);

  const loadOptions2 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await brandListApi(
      page,
      10,
      removeEmptyAndNullValues({
        groupId: groupDetails?.records?.[0]?.group_id || "",
        subGroupId: validation2.values.subGroupId?.[0]?.sub_group_id || "",
        brandId: searchQuery.toUpperCase() || "",
      })
    );

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const loadOptions3 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await campaignListApi(
      page,
      10,
      removeEmptyAndNullValues({
        groupId: groupDetails?.records?.[0]?.group_id || "",
        subGroupId: validation2.values.subGroupId?.[0]?.sub_group_id || "",
        brandId: validation.values.brandId || "",
        campaignType:
          validation.values.brandId && !validation.values.isCnpCampaign
            ? "own"
            : validation.values.isCnpCampaign
            ? "partner"
            : "",
        campaignId: searchQuery.toUpperCase() || "",
      })
    );

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  return (
    <Row className="border bg-light-gray rounded-3 p-3">
      <div className="d-flex align-items-center mb-2">
        <h6 className="mb-0 me-2">CNP Campaign Provisioning</h6>
        <div className="form-check form-switch form-switch-md" dir="ltr">
          <Form.Check
            type="checkbox"
            role="switch"
            checked={validation.values.isCnpCampaign}
            onChange={async () => {
              validation.setFieldValue(
                "isCnpCampaign",
                !validation.values.isCnpCampaign
              );

              validation.setTouched({
                brandId: true,
                campaignId: true,
              });
              validation.setFieldValue("brandId", "");
              validation.setFieldValue("campaignId", "");
              await validation.validateForm();
            }}
          />
        </div>
      </div>
      {!validation.values.isCnpCampaign && (
        <Col sm={6} lg={4} xl={3}>
          <Form.Label htmlFor="brandId">Brand</Form.Label>
          <AsyncPaginate
            isClearable={true}
            key={`${validation2.values.subGroupId?.[0]?.sub_group_id}`}
            styles={styleObj(
              (validation?.touched?.brandId && validation?.errors?.brandId) ||
                (validation2?.touched?.brandId && validation2?.errors?.brandId)
            )}
            theme={customSelectTheme}
            value={
              validation.values.brandId
                ? { brandId: validation.values.brandId }
                : null
            }
            loadOptions={loadOptions2}
            getOptionValue={(option: any) => option?.brandId}
            getOptionLabel={(option: any) => option?.brandId}
            onChange={async (option: any) => {
              validation.setFieldValue("brandId", option ? option.brandId : "");
              validation.setFieldTouched("brandId", true);
              await validation.validateForm();
              validation.setFieldValue("campaignId", "");
            }}
            isSearchable={true}
            additional={{
              page: 1,
            }}
          />
          {validation?.touched?.brandId && validation?.errors?.brandId && (
            <Form.Control.Feedback type="invalid" className="d-block">
              {validation?.errors?.brandId}
            </Form.Control.Feedback>
          )}
        </Col>
      )}
      <Col sm={6} lg={4} xl={3}>
        <Form.Label htmlFor="campaignId">Campaign</Form.Label>
        <AsyncPaginate
          key={`${validation.values.isCnpCampaign}-${validation.values.brandId}`}
          isDisabled={
            validation.values.isCnpCampaign ? false : !validation.values.brandId
          }
          isClearable={true}
          styles={styleObj(
            (validation?.touched?.campaignId &&
              validation?.errors?.campaignId) ||
              (validation2?.touched?.campaignId &&
                validation2?.errors?.campaignId)
          )}
          theme={customSelectTheme}
          value={
            validation.values.campaignId
              ? { campaignId: validation.values.campaignId }
              : null
          }
          loadOptions={loadOptions3}
          getOptionValue={(option: any) => option?.campaignId}
          getOptionLabel={(option: any) => option?.campaignId}
          onChange={async (option: any) => {
            validation.setFieldValue(
              "campaignId",
              option ? option.campaignId : ""
            );

            validation.setFieldValue("usecase", option?.usecase || "");
            validation.setFieldValue("description", option?.description || "");
            validation.setFieldTouched("campaignId", true);
            await validation.validateForm();
          }}
          isSearchable={true}
          additional={{
            page: 1,
          }}
        />
        {validation?.touched?.campaignId && validation?.errors?.campaignId && (
          <Form.Control.Feedback type="invalid" className="d-block">
            {validation?.errors?.campaignId}
          </Form.Control.Feedback>
        )}
      </Col>
      <Col lsm={6} lg={4} xl={3} style={{ marginTop: "28px" }}>
        <div className="hstack gap-2">
          <Button
            className="btn btn-primary"
            form="Form2"
            type="button"
            disabled={isSubmit}
            onClick={async (e) => {
              e.preventDefault();
              await validation.setTouched({
                brandId: true,
                campaignId: true,
              });
              await validation.validateForm();
              if (validation.isValid) {
                validation.handleSubmit();
              }
            }}
          >
            Add
          </Button>
          <Button
            className="btn btn-secondary"
            type="button"
            onClick={() => {
              validation.resetForm();
            }}
          >
            Reset
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default withRouter(DLC);
