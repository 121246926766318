import getChartColorsArray from "Common/ChartsDynamicColor";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

const StackedColumn = ({ graphObj, selectedSubType }: any) => {
  const dataColors =
    selectedSubType === "deliveryRate"
      ? '["--tb-secondary", "--tb-primary"]'
      : selectedSubType === "averageMessagesSent" ||
        selectedSubType === "segmentSent" ||
        selectedSubType === "averageSegmentsSent" ||
        selectedSubType === "totalInbound" ||
        selectedSubType === "averageMessagesReceived" ||
        selectedSubType === "segmentReceived" ||
        selectedSubType === "totalOptOut"
      ? '["--tb-secondary"]'
      : selectedSubType === "failed"
      ? '["--tb-primary"]'
      : '["--tb-secondary", "--tb-primary", "--tb-black"]';

  const chartColors = getChartColorsArray(dataColors);
  const values = Object.keys(graphObj);
  const updatedValues = values.slice(-20);
  const totalData = updatedValues.reduce((sum, key) => sum + graphObj[key]?.[selectedSubType] || 0, 0); 
  const series = useMemo(() => {
    if (!graphObj) return [];

    // get the latest 20 dates
    const getDataForKey = (key: string) =>
      updatedValues.map((x) => graphObj[x][key] || 0);

    switch (selectedSubType) {
      case "deliveryRate":
        return [
          { name: "Delivery Rate", data: getDataForKey("deliveryRate") },
          { name: "Failed", data: getDataForKey("failedRate") },
        ];
      case "averageMessagesSent":
        return [
          {
            name: "Average Messages Sent",
            data: getDataForKey("totalMessagesSent"),
          },
        ];
      case "segmentSent":
        return [
          { name: "Total Segment Sent", data: getDataForKey("segmentSent") },
        ];
      case "averageSegmentsSent":
        return [
          {
            name: "Average Segments Sent",
            data: getDataForKey("segmentSent"),
          },
        ];
      case "totalInbound":
        return [
          {
            name: "Total Messages Received",
            data: getDataForKey("totalInbound"),
          },
        ];
      case "averageMessagesReceived":
        return [
          {
            name: "Average Messages Received",
            data: getDataForKey("totalInbound"),
          },
        ];
      case "segmentReceived":
        return [
          {
            name: "Total Segment Received",
            data: getDataForKey("segmentReceived"),
          },
        ];
      case "totalOptOut":
        return [
          {
            name: "Total Opt Out",
            data: getDataForKey("totalOptOut"),
          },
        ];
      case "failed":
        return [{ name: "Failed", data: getDataForKey("failed") }];
      default:
        return [
          { name: "Delivered", data: getDataForKey("delivered") },
          { name: "Failed", data: getDataForKey("failed") },
          { name: "Sent", data: getDataForKey("sent") },
        ];
    }
  }, [selectedSubType, graphObj]);

  const selectLayoutThemeType = createSelector(
    (state: any) => state.Layout,
    (layoutThemeType) => layoutThemeType.layoutThemeType
  );

  const layoutThemeType = useSelector(selectLayoutThemeType);

  useEffect(() => {
    document.getElementsByClassName("apex-charts");
  }, [layoutThemeType]);

  const options = {
    chart: {
      events: {
        updated: function (chartContext: any, config: any) {
        },
      },
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        borderRadiusApplication: "around",
        borderRadiusWhenStacked: "last",
        columnWidth: graphObj && Object.keys(graphObj).length < 5 ? "50%" :  "70%",
        barHeight: "70%",
        distributed: false,
        rangeBarOverlap: true,
        rangeBarGroupRows: false,
        hideZeroBarsWhenGrouped: false,
        isDumbbell: false,
        dumbbellColors: undefined,
        isFunnel: false,
        isFunnel3d: true,
        colors: {
          ranges: [
            {
              from: 0,
              to: 0,
              color: undefined,
            },
          ],
          backgroundBarColors: [],
          backgroundBarOpacity: 1,
          backgroundBarRadius: 0,
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    xaxis: {
      categories: [...updatedValues],
    },
    yaxis: {
      show: true,
      max: selectedSubType === "deliveryRate" ? 100 : undefined,
      labels: {
        formatter: (val: any) => {
          if (selectedSubType === "deliveryRate") {
            return val.toFixed(2) + "%";
          }
          if (totalData && selectedSubType !== "failed") {
            const percentage = ((val / totalData) * 100).toFixed(2);
            return `${val.toFixed(2)} (${percentage}%)`;
          }
          return val.toFixed(2);
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          // Show percentage in tooltip
          if (totalData && selectedSubType !== "failed") {
            const percentage = ((val / totalData) * 100).toFixed(2);
            return `${val.toFixed(2)} (${percentage}%)`; 
          }
          return val.toFixed(2);
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    colors: chartColors,
    dataLabels: {
      enabled: false,
    },
  };

  return (
    <Chart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="bar"
      height={350}
    />
  );
};

export default StackedColumn;
