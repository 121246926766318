import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import React, { useState } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import LookupLogs from "./LookupLogs";
import PhoneNumberLookup from "./PhoneNumberLookup";

const OwnNumberDetails: React.FC = (props: any) => {
  const [tag, setActiveTag] = useState<any>("Lookup");

  return (
    <div className="container-fluid page-content">
      <BreadCrumb title="Phone Number Lookup" />

      <Card>
        <Card.Body>
          <Tab.Container activeKey={tag} defaultActiveKey="Lookup">
            <Nav
              as="ul"
              variant="tabs"
              className="nav-justified nav-border-top nav-border-top-primary mb-3"
              role="tablist"
            >
              <Nav.Item onClick={() => setActiveTag("Lookup")} as="li">
                <Nav.Link eventKey="Lookup">
                  <i className="ri-home-5-line align-middle me-1"></i> Lookup
                </Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={() => setActiveTag("lookup-log")} as="li">
                <Nav.Link eventKey="lookup-log">
                  <i className="ri-calendar-event-line align-middle me-1"></i>{" "}
                  Lookup Logs
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="Lookup">
                <PhoneNumberLookup
                  setActiveTag={setActiveTag}
                  router={props.router}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="lookup-log">
                {tag === "lookup-log" && <LookupLogs />}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card.Body>
      </Card>
    </div>
  );
};

export default withRouter(OwnNumberDetails);
