import React, { useEffect, useState } from "react";
import {
  Container,
  Dropdown,
  Card,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Nav,
  Tab,
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  getCampaignListThunk,
  getCampaignPartnerListThunk,
  getConnectivityPartnersListThunk,
  getUsecaseListThunk,
} from "slices/thunk";
import Datatable from "../../Common/Datatable";
import {
  capitalizeString,
  removeEmptyAndNullValues,
  dateFormat,
  customSelectTheme,
  styleObj,
} from "helpers/common";
import Loader from "assets/images/spinner-dark.svg";
import withRouter from "Common/withRouter";
import Select from "react-select";
import { useFormik } from "formik";
import { AsyncPaginate } from "react-select-async-paginate";
import { brandListApi, campaignListApi } from "helpers/apiroute_helper";

const CampaignsList = (props: any) => {
  document.title = "Signal House Portal Campaigns";
  const dispatch = useDispatch<any>();

  const [currPage1, setCurrPage1] = useState(1);
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [currPage2, setCurrPage2] = useState(1);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [open, setOpen] = useState(false);

  const selectProfile = createSelector(
    (state: any) => state.Campaigns,
    (state: any) => state.Enums,
    (campaigns, enums) => ({
      loading: campaigns.loading,
      loading2: campaigns.loading,
      campaignsData: campaigns.campaignsData,
      campaignsPartnerData: campaigns.campaignsPartnerData,
      error: campaigns.error,
      usecaseObj: enums.usecaseObj,
      connectivityPartnersObj: enums.connectivityPartnersObj,
    })
  );

  useEffect(() => {
    handleGetData1(currPage1, rowsPerPage1);
    handleGetData2(currPage2, rowsPerPage2);
    dispatch(getUsecaseListThunk());
    dispatch(getConnectivityPartnersListThunk());
  }, []);

  const {
    loading,
    loading2,
    campaignsData,
    campaignsPartnerData,
    usecaseObj,
    connectivityPartnersObj,
  } = useSelector(selectProfile);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      campaignId: "",
      brandId: "",
      brandName: "",
      usecase: "",
      status: "",
      referenceId: "",
    },
    onSubmit: (values) => {
      const temp = removeEmptyAndNullValues({ ...values });
      handleGetData1(currPage1, rowsPerPage1, temp);
      handleGetData2(currPage2, rowsPerPage2, temp);
    },
  });

  const columns1 = [
    {
      name: <span className="font-weight-bold fs-sm">Campaign ID</span>,
      selector: (row: { campaignId: string }) => (
        <span data-tag="allowRowEvents" className="text-primary">
          {row.campaignId}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Brand ID</span>,
      selector: (row: { brandId: string }) => row.brandId,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Brand Name</span>,
      selector: (row: { brandName: string }) => capitalizeString(row.brandName),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Use Case</span>,
      selector: (row: { usecase: string }) => (
        <span
          data-tag="allowRowEvents"
          className="badge bg-body-secondary border border-info text-info"
        >
          {row.usecase}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Registered On</span>,
      selector: (row: { createDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.createDate)}
        </span>
      ),
      sortable: true,
      width: "220px",
    },
    {
      name: <span className="font-weight-bold fs-sm">TCR Status</span>,
      sortable: true,
      selector: (cell: { status: string }) => {
        switch (cell?.status?.toLowerCase()) {
          case "expired":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-danger"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-danger p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          case "deactivated":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-warning"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-warning p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-success"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-success p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Action</span>,
      cell: (cell: { campaignId: string; campaignType: string }) => {
        return (
          <>
            <i
              className="ri-eye-fill align-middle text-muted fs-xl"
              onClick={() =>
                props.router.navigate(`/campaigns/${cell.campaignId}`)
              }
            />
          </>
        );
      },
    },
  ];

  const columns2 = [
    {
      name: <span className="font-weight-bold fs-sm">Campaign ID</span>,
      selector: (row: { campaignId: string }) => (
        <span data-tag="allowRowEvents" className="text-primary">
          {row.campaignId}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Brand ID</span>,
      selector: (row: { brandId: string }) => row.brandId,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Use Case</span>,
      selector: (row: { usecase: string }) => (
        <span
          data-tag="allowRowEvents"
          className="badge bg-body-secondary border border-info text-info"
        >
          {row.usecase}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Downstream CNP</span>,
      selector: (row: { downstreamCnpId: string }) =>
        connectivityPartnersObj[row.downstreamCnpId] ||
        row.downstreamCnpId ||
        "-",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Shared With Me On</span>,
      selector: (row: { sharedWithMe: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.sharedWithMe)}
        </span>
      ),
      sortable: true,
      width: "175px",
    },
    {
      name: <span className="font-weight-bold fs-sm">Upstream CNP</span>,
      selector: (row: { upstreamCnpId: string }) =>
        connectivityPartnersObj[row.upstreamCnpId] || row.upstreamCnpId || "-",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Shared To On</span>,
      selector: (row: { sharedToOn: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.sharedToOn)}
        </span>
      ),
      sortable: true,
      width: "165px",
    },
    {
      name: <span className="font-weight-bold fs-sm">TCR Status</span>,
      sortable: true,
      selector: (cell: { status: string }) => {
        switch (cell?.status?.toLowerCase()) {
          case "expired":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-danger"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-danger p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          case "deactivated":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-warning"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-warning p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-success"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-success p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.status)}
              </span>
            );
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Action</span>,
      cell: (cell: { campaignId: string; campaignType: string }) => {
        return (
          <>
            <i
              className="ri-eye-fill align-middle text-muted fs-xl"
              onClick={() =>
                props.router.navigate(`/campaigns-partner/${cell.campaignId}`)
              }
            />
          </>
        );
      },
    },
  ];

  const handleGetData1 = (page: number, perPage: number, otherParams?: any) => {
    dispatch(
      getCampaignListThunk(page, perPage, {
        ...otherParams,
        campaignType: "own",
      })
    );
  };

  const handleGetData2 = (page: number, perPage: number, otherParams?: any) => {
    dispatch(
      getCampaignPartnerListThunk(page, perPage, {
        ...otherParams,
        campaignType: "partner",
      })
    );
  };

  const handleRowClick1 = (row: { campaignId: string }) => {
    props.router.navigate(`/campaigns/${row.campaignId}`);
  };

  const handleRowClick2 = (row: { campaignId: string }) => {
    props.router.navigate(`/campaigns-partner/${row.campaignId}`);
  };

  const handlePageChange1 = (page: number) => {
    setCurrPage1(page);
    handleGetData1(
      page,
      rowsPerPage1,
      removeEmptyAndNullValues({ ...validation.values })
    );
  };

  const handlePageChange2 = (page: number) => {
    setCurrPage2(page);
    handleGetData2(
      page,
      rowsPerPage2,
      removeEmptyAndNullValues({ ...validation.values })
    );
  };

  const handleRowsPerPageChange1 = (rows: number) => {
    setCurrPage1(1);
    setRowsPerPage1(rows);
    handleGetData1(1, rows, removeEmptyAndNullValues({ ...validation.values }));
  };

  const handleRowsPerPageChange2 = (rows: number) => {
    setCurrPage2(1);
    setRowsPerPage2(rows);
    handleGetData2(1, rows, removeEmptyAndNullValues({ ...validation.values }));
  };

  const setDropdown = () => setOpen(!open);

  const loadOptions2 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await campaignListApi(
      page,
      10,
      removeEmptyAndNullValues({
        brandId: validation.values.brandId || "",
        campaignId: searchQuery.toUpperCase() || "",
      })
    );

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const loadOptions3 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await brandListApi(
      page,
      10,
      removeEmptyAndNullValues({
        brandId: searchQuery.toUpperCase() || "",
      })
    );

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const loadOptions4 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await brandListApi(
      page,
      10,
      removeEmptyAndNullValues({
        brandId: validation.values.brandId || "",
        brandName: searchQuery.toUpperCase() || "",
      })
    );

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Campaigns"
            isFilter={true}
            filterButton={
              <Dropdown
                align="end"
                className="filterDropDown"
                show={open}
                onToggle={setDropdown}
              >
                <Dropdown.Toggle variant="secondary">
                  <i className="bx bx-filter-alt me-1"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-md p-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      setDropdown();
                      validation.handleSubmit();
                    }}
                  >
                    <Row>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="campaignId">
                          Campaign ID
                        </Form.Label>
                        <AsyncPaginate
                          key={validation.values.brandId || "campaignId"}
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.campaignId &&
                              validation?.errors?.campaignId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.campaignId
                              ? { campaignId: validation.values.campaignId }
                              : null || {}
                          }
                          loadOptions={loadOptions2}
                          getOptionValue={(option: any) => option?.campaignId}
                          getOptionLabel={(option: any) => option?.campaignId}
                          onChange={(option: any) => {
                            if (option) {
                              validation.setFieldValue(
                                "campaignId",
                                option.campaignId
                              );
                            } else {
                              validation.setFieldValue("campaignId", "");
                            }
                          }}
                          isSearchable={true}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="brandId">Brand ID</Form.Label>
                        <AsyncPaginate
                          key="brandId"
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.brandId &&
                              validation?.errors?.brandId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.brandId
                              ? { brandId: validation.values.brandId }
                              : null || {}
                          }
                          loadOptions={loadOptions3}
                          getOptionValue={(option: any) => option?.brandId}
                          getOptionLabel={(option: any) => option?.brandId}
                          onChange={(option: any) => {
                            if (option) {
                              validation.setFieldValue(
                                "brandId",
                                option.brandId
                              );
                              validation.setFieldValue("brandName", "");
                              validation.setFieldValue("campaignId", "");
                            } else {
                              validation.setFieldValue("brandId", "");
                            }
                          }}
                          isSearchable={true}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="brandName">Brand Name</Form.Label>
                        <AsyncPaginate
                          key={validation.values.brandId || "brandName"}
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.brandName &&
                              validation?.errors?.brandName
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.brandName
                              ? { displayName: validation.values.brandName }
                              : null || {}
                          }
                          loadOptions={loadOptions4}
                          getOptionValue={(option: any) => option?.displayName}
                          getOptionLabel={(option: any) => option?.displayName}
                          onChange={(option: any) => {
                            if (option) {
                              validation.setFieldValue(
                                "brandName",
                                option.displayName
                              );
                            } else {
                              validation.setFieldValue("brandName", "");
                            }
                          }}
                          isSearchable={true}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="usecase">Usecase</Form.Label>
                        <Select
                          name="usecase"
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.usecase &&
                              validation?.errors?.usecase
                          )}
                          theme={customSelectTheme}
                          options={
                            Object.keys(usecaseObj)?.length > 0
                              ? Object.keys(usecaseObj)?.map((dt) => ({
                                  label: usecaseObj?.[dt]?.displayName || dt,
                                  value: dt,
                                }))
                              : []
                          }
                          onChange={(e: { value: string }) => {
                            if (e) {
                              validation.setFieldValue(
                                "usecase",
                                e.value ? e.value : ""
                              );
                            } else {
                              validation.setFieldValue("usecase", "");
                            }
                          }}
                          value={{
                            label:
                              usecaseObj?.[validation.values.usecase]
                                ?.displayName,
                            value: validation.values.usecase,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="status">Status</Form.Label>
                        <Select
                          name="status"
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.status &&
                              validation?.errors?.status
                          )}
                          theme={customSelectTheme}
                          options={[
                            {
                              label: "ACTIVE",
                              value: "ACTIVE",
                            },
                            {
                              label: "EXPIRED",
                              value: "EXPIRED",
                            },
                          ]}
                          onChange={(e: { value: string }) => {
                            if (e) {
                              validation.setFieldValue("status", e.value);
                            } else {
                              validation.setFieldValue("status", "");
                            }
                          }}
                          value={{
                            label: validation.values.status,
                            value: validation.values.status,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="referenceId">
                          Reference ID
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="referenceId"
                          onChange={(e) => {
                            validation.setFieldValue(
                              "referenceId",
                              e.target.value
                            );
                          }}
                          value={validation.values.referenceId}
                        />
                      </Col>
                    </Row>
                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        className="btn btn-secondary me-2"
                        type="button"
                        disabled={loading}
                        onClick={() => {
                          validation.resetForm();
                          setDropdown();
                          handleGetData1(currPage1, rowsPerPage1);
                          handleGetData2(currPage2, rowsPerPage2);
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading}
                      >
                        {loading && <Spinner size="sm" animation="border" />}{" "}
                        Apply
                      </Button>
                    </div>
                  </Form>
                </Dropdown.Menu>
              </Dropdown>
            }
            isCreate={true}
            createLabel="Add new campaign"
            createLink="/campaigns/create"
          />
          <Tab.Container defaultActiveKey="campaign">
            <Nav
              as="ul"
              variant="tabs"
              className="nav-tabs-custom nav-primary mb-3"
            >
              <Nav.Item as="li">
                <Nav.Link eventKey="campaign">Campaigns</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="partner">CNP Campaigns</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="campaign">
                <div className="position-relative">
                  <Card className="wrapper2">
                    <Card.Body className="listing-table3">
                      {loading ? (
                        <div className={``}>
                          <img
                            src={Loader}
                            className={`position-absolute top-50 start-50 translate-middle`}
                            alt="loading"
                          />
                        </div>
                      ) : (
                        <Datatable
                          data={
                            Array.isArray(campaignsData?.records)
                              ? campaignsData?.records
                              : []
                          }
                          columns={columns1}
                          handleRowClick={handleRowClick1}
                          handlePageChange={handlePageChange1}
                          handleRowsPerPageChange={handleRowsPerPageChange1}
                          currPage={currPage1}
                          rowsPerPage={rowsPerPage1}
                          totalRecords={campaignsData?.totalRecords}
                        />
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="partner">
                <div className="position-relative">
                  <Card className="wrapper2">
                    <Card.Body className="listing-table3">
                      {loading2 ? (
                        <div className={``}>
                          <img
                            src={Loader}
                            className={`position-absolute top-50 start-50 translate-middle`}
                            alt="loading"
                          />
                        </div>
                      ) : (
                        <Datatable
                          data={
                            Array.isArray(campaignsPartnerData?.records)
                              ? campaignsPartnerData?.records
                              : []
                          }
                          columns={columns2}
                          handleRowClick={handleRowClick2}
                          handlePageChange={handlePageChange2}
                          handleRowsPerPageChange={handleRowsPerPageChange2}
                          currPage={currPage2}
                          rowsPerPage={rowsPerPage2}
                          totalRecords={campaignsPartnerData?.totalRecords}
                        />
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CampaignsList);
