import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./slices";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "rc-tooltip/assets/bootstrap.css";
import "react-tagsinput/react-tagsinput.css";
import "react-image-lightbox/style.css";
import "react-intl-tel-input/dist/main.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const store = configureStore({ reducer: rootReducer, devTools: true });

export const dispatchAction = (action: any) => {
  store.dispatch(action);
};

root.render(
  <React.Fragment>
    <Provider store={store}>
      <ToastContainer />
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </Provider>
  </React.Fragment>
);

reportWebVitals();
