import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import Loader from "assets/images/spinner-dark.svg";
import {
  capitalizeString,
  customSelectTheme,
  dateFormat,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Tab,
  Nav,
  Spinner,
} from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import Tooltip from "rc-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getFileThunk, getPortOutListThunk } from "slices/thunk";
import Datatable from "../../Common/Datatable";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  brandListApi,
  campaignListApi,
  subgroupListApi,
} from "helpers/apiroute_helper";

const initialFilterValues = {
  portingId: "",
  startDate: "",
  endDate: "",
  brandId: "",
  campaignId: "",
  groupId: "",
  subGroupId: "",
};

const PortOutRequests = (props: any) => {
  document.title = "Signal House Portal Port Requests";
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [prevSrc, setPrevSrc] = useState("");
  const [isOpenDocLightBox, setIsOpenDocLightBox] = useState(false);
  const [subgroupTemp, setSubgroupTemp] = useState<any>({});
  const [brandTemp, setBrandTemp] = useState<any>({});
  const [campaignTemp, setCampaignTemp] = useState<any>({});
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const selector = createSelector(
    (state: any) => state.Ports,
    (state: any) => state.Groups,
    (ports, groups) => ({
      loading: ports.loading,
      portOutRequestsArr: ports.portOutRequestsArr,
      error: ports.error,
      groupDetails: groups?.AllGroups,
    })
  );

  const { loading, portOutRequestsArr, groupDetails } = useSelector(selector);

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm"> Date</span>,
      selector: (row: { createdDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.createdDate)}
        </span>
      ),
      sortable: true,
      width: "220px",
    },
    {
      name: <span className="font-weight-bold fs-sm">Phone Number</span>,
      selector: (row: { nnsrResponse?: { tn?: string } }) =>
        row?.nnsrResponse?.tn || "-",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Reason</span>,
      sortable: true,
      selector: (cell: {
        nnsrResponse?: { reason?: string; contact_phone?: string };
      }) => {
        const reason = cell?.nnsrResponse?.reason || "";
        const contactPhone = cell?.nnsrResponse?.contact_phone || "-";

        let badgeClass;
        switch (reason.toLowerCase()) {
          case "rejected":
            badgeClass =
              "badge bg-body-body-secondary border border-danger text-danger"; // Red color for rejected
            break;
          case "approved":
            badgeClass =
              "badge bg-body-secondary border border-secondary text-secondary";
            break;
          case "deleted":
            badgeClass =
              "badge bg-body-primary border border-primary text-primary";
            break;
          default:
            badgeClass =
              "badge bg-body-secondary border border-warning text-warning"; // Default color
            break;
        }
        return (
          <div>
            <span data-tag="allowRowEvents" className={badgeClass}>
              {capitalizeString(reason)}
            </span>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Details</span>,
      selector: (row: any) => (
        <i
          data-tag="allowRowEvents"
          className=" ri-survey-fill align-middle text-secondary fs-lg cursor-pointer"
        ></i>
      ),
      sortable: false,
    },
  ];

  const handleRowClick = (row: { row: any }) => {
    setSelectedRow(row);
    handleShow();
  };

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: initialFilterValues,
    onSubmit: (values) => {
      const temp = removeEmptyAndNullValues({ ...values });
      handleGetData(currPage, rowsPerPage, temp);
    },
  });

  useEffect(() => {
    handleGetData(currPage, rowsPerPage);
  }, []);

  useEffect(() => {
    if (groupDetails?.records?.[0]?.group_id) {
      validation.setFieldValue("groupId", groupDetails?.records?.[0]?.group_id);
    }
  }, [groupDetails?.records?.[0]?.group_id]);

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(getPortOutListThunk(page, perPage, otherParams || {}));
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(page, rowsPerPage);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(1, rows);
  };

  const setDropdown = () => setOpen(!open);

  const handleViewLoa = (loadId: string) => {
    const cb = (img: any) => {
      const temp2 = URL.createObjectURL(img);
      setPrevSrc(temp2);
      setIsOpenDocLightBox(true);
    };
    dispatch(getFileThunk(loadId, cb));
  };

  const loadOptions1 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await subgroupListApi(page, 10, {
      groupId: validation.values.groupId || "",
    });

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const loadOptions2 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await brandListApi(
      page,
      10,
      removeEmptyAndNullValues({
        groupId: validation.values.groupId || "",
        subGroupId: validation.values.subGroupId || "",
        brandId: searchQuery.toUpperCase() || "",
      })
    );

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const loadOptions3 = async (
    searchQuery: string,
    loadedOptions: any,
    { page }: any
  ) => {
    let data: any = await campaignListApi(
      page,
      10,
      removeEmptyAndNullValues({
        groupId: validation.values.groupId || "",
        subGroupId: validation.values.subGroupId || "",
        brandId: validation.values.brandId || "",
        campaignId: searchQuery.toUpperCase() || "",
      })
    );

    if (!data?.code && !data?.errorCode) {
      return {
        options: data?.records,
        hasMore: data?.records?.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  return (
    <Container fluid>
      {isOpenDocLightBox && (
        <div
          className="position-fixed top-0 end-0 bottom-0 start-0"
          style={{
            zIndex: 1000,
            // backgroundColor: "rgba(0,0,0,0.5)",
            backgroundColor: "white",
          }}
        >
          <div
            className="position-absolute text-white cursor-pointer"
            style={{
              zIndex: 1000,
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              borderRadius: "50%",
              padding: "5px 10px",
              right: 10,
              top: 40,
            }}
          >
            <i
              className="ri-close-circle-fill cursor-pointer fs-5xl text-white"
              onClick={() => {
                setIsOpenDocLightBox(false);
                setPrevSrc("");
              }}
            />
          </div>
          <iframe
            id="iframe"
            title={prevSrc}
            src={prevSrc}
            height="100%"
            width="100%"
            style={{ background: "rgba(0, 0, 0, 0.6)" }}
          />
        </div>
      )}
      <BreadCrumb
        title="Port Out Requests"
        isFilter={true}
        filterButton={
          <Dropdown
            align="end"
            className="filterDropDown"
            show={open}
            onToggle={setDropdown}
          >
            <Dropdown.Toggle variant="secondary">
              <i className="bx bx-filter-alt me-1"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-md p-4">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  setDropdown();
                  validation.handleSubmit();
                }}
              >
                <Row>
                  <Col sm={12} className="mb-3">
                    <Form.Label htmlFor="portingId">Porting ID</Form.Label>
                    <Form.Control
                      type="text"
                      name="portingId"
                      placeholder="Enter Porting ID"
                      onChange={(e) => {
                        validation.setFieldValue("portingId", e.target.value);
                      }}
                      value={validation.values.portingId}
                    />
                  </Col>

                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="startDate">
                      Start Date & Time
                    </Form.Label>
                    <Flatpickr
                      className="form-control"
                      name="startDate"
                      placeholder="Start Date & Time"
                      options={{
                        enableTime: true,
                        maxDate: validation.values.endDate || "",
                        altFormat: "F j, Y",
                        dateFormat: "Y-m-d H:i",
                        onChange: (value: any, dateStr: any, instance: any) => {
                          const newValue = value.length
                            ? new Date(value).toISOString()
                            : "";
                          validation.setFieldValue("startDate", newValue);
                          instance.close();
                        },
                      }}
                      value={validation.getFieldProps("startDate").value}
                    />
                  </Col>

                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="endDate">End Date & Time</Form.Label>
                    <Flatpickr
                      className="form-control"
                      name="endDate"
                      placeholder="End Date & Time"
                      options={{
                        enableTime: true,
                        minDate: validation.values.startDate || "",
                        altFormat: "F j, Y",
                        dateFormat: "Y-m-d H:i",
                        onChange: (value: any, dateStr: any, instance: any) => {
                          const newValue = value.length
                            ? new Date(value).toISOString()
                            : "";
                          validation.setFieldValue("endDate", newValue);
                          instance.close();
                        },
                      }}
                      value={validation.getFieldProps("endDate").value}
                    />
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="subGroupId">Sub Group</Form.Label>
                    <AsyncPaginate
                      isDisabled={!validation.values.groupId}
                      key={validation.values.groupId}
                      isClearable={true}
                      styles={styleObj(
                        validation?.touched?.subGroupId &&
                          validation?.errors?.subGroupId
                      )}
                      theme={customSelectTheme}
                      value={subgroupTemp || {}}
                      loadOptions={loadOptions1}
                      getOptionValue={(option: any) => option?.sub_group_id}
                      getOptionLabel={(option: any) => option?.sub_group_name}
                      onChange={(option: any) => {
                        if (option) {
                          setSubgroupTemp(option);
                          validation.setFieldValue(
                            "subGroupId",
                            option?.sub_group_id
                          );
                          setBrandTemp({});
                          validation.setFieldValue("brandId", "");
                          setCampaignTemp({});
                          validation.setFieldValue("campaignId", "");
                        } else {
                          setSubgroupTemp({});
                          validation.setFieldValue("subGroupId", "");
                        }
                      }}
                      isSearchable={true}
                      additional={{
                        page: 1,
                      }}
                    />
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="brandId">Brand</Form.Label>
                    <AsyncPaginate
                      key={
                        validation.values.subGroupId ||
                        validation.values.groupId ||
                        "brandId"
                      }
                      isClearable={true}
                      styles={styleObj(
                        validation?.touched?.brandId &&
                          validation?.errors?.brandId
                      )}
                      theme={customSelectTheme}
                      value={brandTemp || {}}
                      loadOptions={loadOptions2}
                      getOptionValue={(option: any) => option?.brandId}
                      getOptionLabel={(option: any) => option?.brandId}
                      onChange={(option: any) => {
                        if (option) {
                          setBrandTemp(option);
                          validation.setFieldValue("brandId", option.brandId);
                          setCampaignTemp({});
                          validation.setFieldValue("campaignId", "");
                        } else {
                          setBrandTemp({});
                          validation.setFieldValue("brandId", "");
                        }
                      }}
                      isSearchable={true}
                      additional={{
                        page: 1,
                      }}
                    />
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Label htmlFor="campaignId">Campaign</Form.Label>
                    <AsyncPaginate
                      key={
                        validation.values.brandId ||
                        validation.values.subGroupId ||
                        validation.values.groupId ||
                        "campaignId"
                      }
                      isClearable={true}
                      styles={styleObj(
                        validation?.touched?.campaignId &&
                          validation?.errors?.campaignId
                      )}
                      theme={customSelectTheme}
                      value={campaignTemp || {}}
                      loadOptions={loadOptions3}
                      getOptionValue={(option: any) => option?.campaignId}
                      getOptionLabel={(option: any) => option?.campaignId}
                      onChange={(option: any) => {
                        if (option) {
                          setCampaignTemp(option);
                          validation.setFieldValue(
                            "campaignId",
                            option.campaignId
                          );
                        } else {
                          setCampaignTemp({});
                          validation.setFieldValue("campaignId", "");
                        }
                      }}
                      isSearchable={true}
                      additional={{
                        page: 1,
                      }}
                    />
                  </Col>
                </Row>
                <div className="d-flex align-items-center justify-content-end">
                  <Button
                    className="btn btn-secondary me-2"
                    type="button"
                    disabled={loading}
                    onClick={() => {
                      validation.setValues(initialFilterValues);
                      validation.resetForm();
                      validation.setFieldValue(
                        "groupId",
                        groupDetails?.records?.[0]?.group_id
                      );
                      setSubgroupTemp({});
                      setBrandTemp({});
                      setCampaignTemp({});
                      handleGetData(currPage, rowsPerPage);
                    }}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Clear
                  </Button>
                  <Button
                    className="btn btn-primary"
                    type="submit"
                    disabled={loading}
                  >
                    {loading && <Spinner size="sm" animation="border" />} Apply
                  </Button>
                </div>
              </Form>
            </Dropdown.Menu>
          </Dropdown>
        }
      />
      <div className="position-relative">
        <Card className="wrapper2">
          <Card.Body className="listing-table3">
            {loading ? (
              <div className={``}>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                  alt="loading"
                />
              </div>
            ) : (
              <Datatable
                data={
                  Array.isArray(portOutRequestsArr?.records)
                    ? portOutRequestsArr?.records
                    : []
                }
                columns={columns}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                handleRowClick={handleRowClick}
                currPage={currPage}
                rowsPerPage={rowsPerPage}
                totalRecords={portOutRequestsArr?.totalRecords}
              />
            )}
          </Card.Body>
        </Card>

        {selectedRow && (
          <Modal show={showModal} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col lg={6}>
                  <div className="p-2">
                    <div className="d-flex gap-2 mt-2">
                      <div className="text-secondary">Contact Name: </div>
                      <p>{selectedRow?.nnsrResponse?.contact_name ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="p-2">
                    <div className="d-flex gap-2 mt-2">
                      <div className="text-secondary">Contact Business: </div>
                      <p>
                        {selectedRow?.nnsrResponse?.contact_business ?? "-"}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="p-2">
                    <div className="d-flex gap-2 mt-2">
                      <div className="text-secondary">Contact Phone: </div>
                      <p>{selectedRow?.nnsrResponse?.contact_phone ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="p-2">
                    <div className="d-flex gap-2 mt-2">
                      <div className="text-secondary">Contact Email: </div>
                      <p>{selectedRow?.nnsrResponse?.contact_email ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="p-2">
                    <div className="d-flex gap-2 mt-2">
                      <div className="text-secondary">TID: </div>
                      <p>{selectedRow?.nnsrResponse?.tid ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="p-2">
                    <div className="d-flex gap-2 mt-2">
                      <div className="text-secondary">New NNID: </div>
                      <p>{selectedRow?.nnsrResponse?.new_nnid ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="p-2">
                    <div className="d-flex gap-2 mt-2">
                      <div className="text-secondary">Old NNID: </div>
                      <p>{selectedRow?.nnsrResponse?.old_nnid ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="p-2">
                    <div className="d-flex gap-2 mt-2">
                      <div className="text-secondary">PID: </div>
                      <p>{selectedRow?.nnsrResponse?.pid ?? "-"}</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="p-2">
                    <div className="d-flex gap-2 mt-2">
                      <div className="text-secondary">Message: </div>
                      <p>{selectedRow?.nnsrResponse?.message ?? "-"}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </Container>
  );
};

export default withRouter(PortOutRequests);
