import React, { useEffect, useState } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  getWebhookDetailsByIdThunk,
  getWebhookLogsThunk,
  resetWebhookDetailsById,
} from "slices/thunk";
import Datatable from "../../../../Common/Datatable";
import {
  camelToFlat,
  cleanAndCapitalize,
  dateFormat,
  flattenObj,
} from "helpers/common";
import Loader from "assets/images/spinner-dark.svg";
import withRouter from "Common/withRouter";
import { createSelector } from "reselect";

const WebhookDetails = (props: any) => {
  document.title = "Signal House Portal Webhook";
  const dispatch = useDispatch<any>();

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Event Type</span>,
      selector: (row: { eventType: string }) => (
        <span className="fw-semibold">{row.eventType}</span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      selector: (row: { requestData: any; reasonDescription: string }) => {
        let className = "badge";
        const capitalizeFirstLetter = (text: string) => {
          return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
        };
        switch (row.requestData?.reasonDescription?.toUpperCase()) {
          case "SUCCESS":
            className = "badge bg-secondary-subtle text-secondary fs-sm mb-0";
            break;
          case "UNSUCCESSFUL":
          case "PENDING":
            className = "badge bg-warning-subtle text-warning fs-sm mb-0";
            break;
          case "failed":
            className = "badge bg-primary-subtle text-primary fs-sm mb-0";
            break;
          default:
            className = "badge bg-primary-subtle text-primary fs-sm mb-0";
        }

        const formattedDescription = row.requestData?.reasonDescription
          ? capitalizeFirstLetter(row.requestData.reasonDescription)
          : "N/A";

        return (
          <span data-tag="allowRowEvents" className={className}>
            {formattedDescription}
          </span>
        );
      },
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Created On</span>,
      selector: (row: { createdAt: string }) => (
        <span className="d-flex align-items-center">
          <i className="bi bi-calendar3 me-2 fs-lg text-secondary" />
          {dateFormat(row.createdAt, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
    },
  ];

  const ExpandedComponent = ({ data }: any) => {
    const result = data.requestData ? flattenObj(data.requestData) : {};
    let flatObj: any = {};

    Object.keys(result)?.forEach((dt) => {
      const temp = cleanAndCapitalize(dt);
      flatObj[temp] = result[dt];
    });

    return (
      <div className="px-4">
        <div className="card-header border-bottom">
          <h6 className="card-title mb-2">Request Data</h6>
        </div>
        <div className="card-body">
          <Row>
            {Object.keys(flatObj)?.length > 0
              ? Object.keys(flatObj)?.map((dt, i) => {
                  if (dt.toLowerCase() === "reasondescription") {
                    return null;
                  }
                  return (
                    <Col xl={6} className="mb-2" key={i}>
                      <Row className="g-0">
                        <Col xs={4}>
                          <h6 className="mb-0 me-2">{camelToFlat(dt)}:</h6>
                        </Col>
                        <Col xs={8}>
                          <p className="mb-0">
                            {flatObj?.[dt]
                              ? typeof flatObj?.[dt] === "boolean"
                                ? "True"
                                : typeof flatObj?.[dt] === "string" &&
                                  dt?.includes("timestamp")
                                ? dateFormat(flatObj?.[dt])
                                : flatObj?.[dt]
                              : typeof flatObj?.[dt] === "boolean"
                              ? "False"
                              : typeof flatObj?.[dt] === "number"
                              ? flatObj?.[dt]
                              : "-"}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  );
                })
              : null}
          </Row>
        </div>
      </div>
    );
  };

  const selectProfile = createSelector(
    (state: any) => state.Webhooks,
    (webhooks) => ({
      loading: webhooks.loading4,
      loading2: webhooks.loading2,
      webhookDetails: webhooks.webhookDetails,
      webhookLogs: webhooks.webhookLogs,
      error: webhooks.error,
    })
  );

  const { loading, loading2, webhookDetails, webhookLogs } =
    useSelector(selectProfile);

  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (props?.router?.params?.webhookId) {
      dispatch(getWebhookDetailsByIdThunk(props?.router?.params?.webhookId));
      handleGetData(currPage, rowsPerPage);
    }
  }, [props?.router?.params?.webhookId]);

  const handleGetData = (page: number, perPage: number) => {
    dispatch(
      getWebhookLogsThunk(page, perPage, {
        webhookId: props?.router?.params?.webhookId,
      })
    );
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(page, rowsPerPage);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(1, rows);
  };
  useEffect(() => {
    if (webhookDetails?.webhookName) {
      document.title = `Webhook: ${webhookDetails?.webhookName}`;
    } else if (props?.router?.params?.webhookId) {
      document.title = `Webhook: ${props?.router?.params?.webhookId}`;
    }
  }, [webhookDetails?.webhookName, props?.router?.params?.webhookId]);
  useEffect(() => {
    dispatch(resetWebhookDetailsById());
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={`${webhookDetails?.webhookName || ""}`}
            isBack={true}
            backClick={() => props.router.navigate("/webhooks")}
          />
          <div className="position-relative wrapper">
            {loading ? (
              <div className={``}>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                  alt="loading"
                />
              </div>
            ) : (
              <>
                <Card>
                  <Card.Body className="">
                    <Row>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Webhook Id:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0">{webhookDetails?.webhookId}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Webhook Name:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {webhookDetails?.webhookName}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Webhook Url:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0">{webhookDetails?.webhookUrl}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Email Id:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {webhookDetails?.emailId}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Status:</h6>
                          </Col>
                          <Col md={8} className="mt-1 mt-sm-0">
                            {webhookDetails?.disable ? (
                              <p className="badge bg-danger-subtle text-danger fs-sm mb-0">
                                Disabled
                              </p>
                            ) : (
                              <p className="badge bg-success-subtle text-success fs-sm mb-0">
                                Enabled
                              </p>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Created On:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0">
                              {dateFormat(
                                webhookDetails?.createdDate,
                                "MM/DD/YYYY, hh:mm:ss A"
                              )}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={12} className="mb-2">
                        <Row className="g-0">
                          <Col md={2}>
                            <h6 className="mb-0">Events:</h6>
                          </Col>
                          <Col md={10}>
                            <div className="d-flex flex-wrap mt-1 mt-sm-0">
                              {webhookDetails?.events?.map(
                                (dt: { eventType: string }, i2: number) => {
                                  return (
                                    <span className="details-tag" key={i2}>
                                      {dt?.eventType}
                                    </span>
                                  );
                                }
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={12} className="">
                        <Row className="g-0">
                          <Col md={2}>
                            <h6 className="mb-0">Description:</h6>
                          </Col>
                          <Col md={10}>
                            <p className="mb-0 me-md-4">
                              {webhookDetails?.description}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Header className="d-sm-flex align-items-center justify-content-between">
                    <h6 className="card-title mb-2">Webhook Logs</h6>
                  </Card.Header>
                  <Card.Body>
                    {loading2 ? (
                      <div
                        className="position-relative"
                        style={{ height: 200 }}
                      >
                        <img
                          src={Loader}
                          className={`position-absolute top-50 start-50 translate-middle`}
                          alt="loading"
                        />
                      </div>
                    ) : (
                      <Datatable
                        data={
                          Array.isArray(webhookLogs?.records)
                            ? webhookLogs?.records
                            : []
                        }
                        columns={columns}
                        handlePageChange={handlePageChange}
                        handleRowsPerPageChange={handleRowsPerPageChange}
                        currPage={currPage}
                        rowsPerPage={rowsPerPage}
                        totalRecords={webhookLogs?.totalRecords}
                        expandableRows={true}
                        expandableRowsComponent={ExpandedComponent}
                      />
                    )}
                  </Card.Body>
                </Card>
              </>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(WebhookDetails);
