import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Link } from "react-router-dom";
import {
  getPaymentMethodThunk,
  deletePaymentMethodIdThunk,
  payWithPaymentMethodThunk,
  getAuthUserThunk,
} from "slices/thunk";
import * as Yup from "yup";
import { createSelector } from "reselect";
import { customSelectTheme, styleObj } from "helpers/common";
import Loader from "assets/images/spinner-dark.svg";

const AddFunds = (props: any) => {
  document.title = "Signal House Portal Add Funds";
  const dispatch = useDispatch<any>();

  const selectProfile = createSelector(
    (state: any) => state.Billing,
    (billing) => ({
      loading: billing.loading,
      loading2: billing.loading2,
      paymentMethods: billing.paymentMethods,
      error: billing.error,
    })
  );

  const { loading, loading2, paymentMethods } = useSelector(selectProfile);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: 0,
      paymentMethodId: "",
    },
    validationSchema: Yup.object({
      amount: Yup.number().min(20).max(1000000).required("Please Enter Amount"),
      paymentMethodId: Yup.string().required("Please Select Payment Method"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const cb = () => {
        resetForm();
        dispatch(getAuthUserThunk());
        window.location.href = "/dashboard";
      };
      dispatch(
        payWithPaymentMethodThunk(values?.paymentMethodId, +values?.amount, cb)
      );
    },
  });

  const [selectedPayMethod, setSelectedPayMethod] = useState<any>({});

  useEffect(() => {
    dispatch(getPaymentMethodThunk());
  }, []);

  useEffect(() => {
    if (validation.values.paymentMethodId) {
      const temp = paymentMethods?.find(
        (dt: { id?: string }) => dt?.id === validation.values.paymentMethodId
      );
      setSelectedPayMethod(temp);
    } else {
      setSelectedPayMethod({});
    }
  }, [validation.values.paymentMethodId]);

  const handleDelete = (e: any, id: string) => {
    if (!loading2) {
      e.preventDefault();
      e.stopPropagation();
      dispatch(deletePaymentMethodIdThunk(id));
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Add Funds"
            isBack={true}
            backClick={() => props.router.navigate("/dashboard")}
          />
          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body className="">
                <p>
                  How much would you like to add your account balance today?
                </p>
                <Row>
                  <Col lg={6}>
                    <Form
                      action="#"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                      }}
                    >
                      <div className="mb-3">
                        <Form.Label htmlFor="amount">
                          Payment amount <span className="text-danger">*</span>
                        </Form.Label>
                        <InputGroup>
                          <span className="input-group-text">$</span>
                          <Form.Control
                            type="number"
                            id="amount"
                            name="amount"
                            min={20}
                            max={1000000}
                            step={"any"}
                            inputMode={"decimal"}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.amount || ""}
                            isInvalid={
                              validation.touched.amount &&
                              validation.errors.amount
                                ? true
                                : false
                            }
                          />
                          {validation.touched.amount &&
                          validation.errors.amount ? (
                            <Form.Control.Feedback type="invalid">
                              {validation.errors.amount}
                            </Form.Control.Feedback>
                          ) : null}
                        </InputGroup>
                        <p className="text-muted mt-1">
                          Enter an amount between $20 and $10,000,00
                        </p>
                      </div>
                      <div className="mb-3">
                        <Form.Label htmlFor="paymentMethodId">
                          Select a payment method
                          <span className="text-danger">*</span>
                          {loading2 ? (
                            <div className={`d-inline`}>
                              <img src={Loader} alt="loading" height={50} />
                            </div>
                          ) : null}
                        </Form.Label>
                        <Select
                          name="paymentMethodId"
                          styles={styleObj(
                            validation.touched.paymentMethodId &&
                              validation.errors.paymentMethodId
                          )}
                          theme={customSelectTheme}
                          options={
                            paymentMethods?.length > 0
                              ? paymentMethods?.map(
                                  (dt: {
                                    id: string;
                                    card?: { brand?: string; last4?: string };
                                  }) => ({
                                    label: (
                                      <div className="d-flex align-items-center justify-content-between">
                                        <span className="d-flex align-items-center">
                                          <i
                                            className={`ri ri-${dt?.card?.brand}-fill fs-4xl me-2`}
                                          />
                                          {dt?.card?.last4
                                            ? // ? `**** **** **** ${dt?.card?.last4} ${dt?.billing_details?.name} ${dt?.billing_details?.email}`
                                              `**** **** **** ${dt?.card?.last4}`
                                            : ""}
                                        </span>
                                        <i
                                          className="ri-delete-bin-5-line text-danger fs-4 cursor-pointer"
                                          onClick={(e) =>
                                            handleDelete(e, dt?.id)
                                          }
                                        />
                                      </div>
                                    ),
                                    value: dt?.id,
                                  })
                                )
                              : []
                          }
                          onChange={(e: any) => {
                            validation.setFieldValue(
                              "paymentMethodId",
                              e.value
                            );
                          }}
                          value={{
                            label: (
                              <span className="d-flex align-items-center">
                                <i
                                  className={`ri ri-${selectedPayMethod?.card?.brand}-fill fs-4xl me-2`}
                                />
                                {selectedPayMethod?.card?.last4
                                  ? // ? `**** **** **** ${selectedPayMethod?.card?.last4} ${selectedPayMethod?.billing_details?.name} ${selectedPayMethod?.billing_details?.email}`
                                    `**** **** **** ${selectedPayMethod?.card?.last4}`
                                  : ""}
                              </span>
                            ),
                            value: validation.values.paymentMethodId,
                          }}
                        />
                        {validation.touched.paymentMethodId &&
                        validation.errors.paymentMethodId ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {validation.errors.paymentMethodId}
                          </Form.Control.Feedback>
                        ) : null}
                      </div>
                      <Link
                        to="/dashboard/add-payment-method"
                        className="mb-3 d-flex align-items-center"
                      >
                        <i className="bx bx-plus me-1"></i>Add a new payment
                        method
                      </Link>
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={loading}
                      >
                        {loading && <Spinner size="sm" animation="border" />}{" "}
                        Add to my balance
                      </Button>
                    </Form>
                  </Col>
                  {/* <Col lg={6}>
                    <h4 className="mb-3">Overview</h4>
                    <Row className="mb-2">
                      <Col md={5}>
                        <h6 className="mb-0">Current balance:</h6>
                      </Col>
                      <Col md={7}>
                        <p className="mb-0 me-md-4">$5.98</p>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <h6 className="mb-0">Payment amount:</h6>
                      </Col>
                      <Col md={7}>
                        <p className="mb-0 me-md-4">$0.00</p>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={5}>
                        <h6 className="mb-0">New balance:</h6>
                      </Col>
                      <Col md={7}>
                        <p className="mb-0 me-md-4">$5.98</p>
                      </Col>
                    </Row>
                  </Col> */}
                </Row>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AddFunds);
