import React from "react";
import { Card, Button } from "react-bootstrap";
import SimpleBar from "simplebar-react";

interface StartedWithVerifyProps {}

const StartedWithVerify: React.FC<StartedWithVerifyProps> = () => {
  return (
    <div>
      <Card>
        <Card.Body className="px-0">
          <SimpleBar
            className="py-0"
            style={{ height: "225px", padding: "0px 19.2px" }}
          >
            <h4 className="list-group-item ps-0">Get Started with verify</h4>
            <p className="mt-2 mb-3">
              Perform two fector authentication via SMS, Voice, Whatsapp and
              Calling.
            </p>
            <div
              style={{ display: "flex" }}
              className="flex mt-12 flex-nowrap flex-row gap-5"
            >
              <div className="flex">
                <Button
                  variant="primary"
                  className="w-30 mt-2"
                  id="new"
                  style={{ background: "none", color: "#e51cfd" }}
                >
                  Learn and build
                </Button>
              </div>
              <div className="flex">
                <Button
                  variant="secondry"
                  className="w-30 mt-2 ml-2 "
                  style={{ color: "#e51cfd" }}
                  id="new"
                >
                  Read the docs
                </Button>
              </div>
            </div>
          </SimpleBar>
        </Card.Body>
      </Card>
    </div>
  );
};

export default StartedWithVerify;
