//Include Both Helper File with needed methods

import { loginApi, verifyLoginOTP } from "helpers/apiroute_helper";
import {
  apiLoading,
  apiLoadingFalse,
  loginSuccess,
  logoutUserSuccess,
  loginFailed,
} from "./reducer";
import { removeLocal, setLocal } from "helpers/services/auth/auth";
import { toast } from "react-toastify";
import axios from "axios";
import { openModal } from "slices/thunk";

export const getLocationThunk = (cb: any) => async (dispatch: any) => {
  try {
    dispatch(apiLoading());

    axios
      .get(
        `https://api.ipapi.com/api/check?access_key=af910b6132a199b9dd3a099d4aceb0bf`
      )
      .then(function (response) {
        cb(response);
      });
  } catch (error: any) {
    console.log("error", error);
    dispatch(apiLoadingFalse());
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const loginUserThunk =
  (user: any, history: any, recaptchaRef?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading());

      let data: any = await loginApi({
        ...user,
      });

      if (data) {
        if (data?.twoFAEnabled) {
          dispatch(
            openModal({
              path: "Login2fa",
              data: {
                title: "Verify Number",
                footer: false,
                user,
                phone: data?.phone,
                closeButton: false,
                onCancel: () => {},
              },
            })
          );
        } else {
          var finallogin: any = JSON.stringify(data);
          setLocal("loginData", finallogin);
          finallogin = JSON.parse(finallogin);
          if (finallogin.apiKey && finallogin.authToken) {
            dispatch(loginSuccess(finallogin));
            // toast.success("User logged in successfully");
          } else {
            history("/login");
            dispatch(loginFailed(finallogin));
            toast.error(finallogin?.message);
          }
        }
      }
      recaptchaRef && recaptchaRef.current.reset();
    } catch (error: any) {
      console.log("error", error);
      dispatch(loginFailed(error));
      recaptchaRef && recaptchaRef.current.reset();
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const verifyLoginOTPThunk =
  (data: any, cb: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading());
      const response: any = await verifyLoginOTP({ ...data });

      if (response) {
        var finallogin: any = JSON.stringify(response);
        setLocal("loginData", finallogin);
        finallogin = JSON.parse(finallogin);
        if (finallogin.apiKey && finallogin.authToken) {
          dispatch(loginSuccess(finallogin));
          // toast.success("User logged in successfully");
          cb && cb();
        } else {
          dispatch(loginFailed(finallogin));
          toast.error(finallogin?.message);
        }
      }
    } catch (error: any) {
      console.error("error", error);
      dispatch(loginFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const logoutUserThunk = () => async (dispatch: any) => {
  try {
    removeLocal("loginData");
    dispatch(logoutUserSuccess());
  } catch (error) {
    dispatch(loginFailed(error));
    toast.error("Something went wrong");
  }
};

export const socialLoginThunk =
  (type: any, history: any) => async (dispatch: any) => {
    // try {
    //   let response;
    //   if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //     const fireBaseBackend = getFirebaseBackend();
    //     response = fireBaseBackend.socialLoginUser(type);
    //   }
    //   //  else {
    //   //   response = postSocialLogin(data);
    //   // }
    //   const socialdata = await response;
    //   if (socialdata) {
    //     localStorage.setItem("authUser", JSON.stringify(socialdata));
    //     dispatch(loginSuccess(socialdata));
    //     history("/dashboard");
    //   }
    // } catch (error) {
    //   dispatch(loginFailed(error));
    // }
  };
