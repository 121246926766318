import withRouter from "Common/withRouter";
import ApiKeyCard from "./components/ApiKeyCard";
import StartMessaging from "./components/StartMessaging";
import StartedWithVerify from "./components/StartedWithVerify";
import Welcome from "./components/Welcome";

const Home = (props: any) => {
  document.title = "Signal House Portal Home";

  return (
    <div className="container-fluid page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8 col-12">
            <Welcome />
          </div>
          <div className="col-lg-4 col-12">
            <ApiKeyCard />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-12">
            <StartMessaging />
          </div>
          <div className="col-lg-8 col-12">
            <StartedWithVerify />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Home);
