import { Form, Spinner } from "react-bootstrap";
import Radiobox from "Common/Radiobox";

export default function Step4({ validation4, setActiveStep, loading }: any) {
  return (
    <div className="p-5 p-lg-3 p-xxl-5">
      <div className="text-center">
        <p className="text-muted fs-xl mb-3">3/3</p>
        <h5 className="fs-3xl">
          Do you have existing{" "}
          <span className="text-primary">Numbers to Port?</span>
        </h5>
      </div>

      <div className="mt-5">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation4.handleSubmit();
            return false;
          }}
        >
          <div>
            {validation4.touched.isNumbersToPort &&
            validation4.errors.isNumbersToPort ? (
              <p className="text-danger mb-3">
                {validation4.errors.isNumbersToPort}
              </p>
            ) : null}
          </div>

          <div className="d-flex flex-column align-items-center">
            <div className="mb-3">
              <Radiobox
                label="Yes!"
                id="Yes"
                name="isNumbersToPort"
                value="Yes"
                checked={validation4?.values?.isNumbersToPort === "Yes"}
                onChange={(e: { target: { value: string } }) =>
                  validation4?.setFieldValue("isNumbersToPort", e.target.value)
                }
              />
            </div>

            {validation4.values.isNumbersToPort === "Yes" ? (
              <div className="d-flex flex-column align-items-center mb-3 w-100">
                <Form.Label>
                  Approximately how many numbers?{" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  id="numbers"
                  name="numbers"
                  placeholder="Enter approx number"
                  onChange={validation4.handleChange}
                  onBlur={validation4.handleBlur}
                  value={validation4.values.numbers || ""}
                  isInvalid={
                    validation4.touched.numbers && validation4.errors.numbers
                      ? true
                      : false
                  }
                />
                {validation4.touched.numbers && validation4.errors.numbers ? (
                  <Form.Control.Feedback type="invalid">
                    {validation4.errors.numbers}
                  </Form.Control.Feedback>
                ) : null}
              </div>
            ) : null}

            <div className="d-flex flex-column align-items-center mb-3">
              <Radiobox
                label="Nope"
                id="No"
                name="isNumbersToPort"
                value="No"
                checked={validation4?.values?.isNumbersToPort === "No"}
                onChange={(e: { target: { value: string } }) =>
                  validation4?.setFieldValue("isNumbersToPort", e.target.value)
                }
              />
            </div>
          </div>

          <div className="d-flex align-items-start justify-content-center gap-3 mt-4">
            <button
              type="button"
              className="btn btn-link text-decoration-none btn-label previestab"
              onClick={() => {
                setActiveStep(3);
              }}
            >
              <i className="ri-arrow-left-line label-icon align-middle fs-lg"></i>
              Prev
            </button>
            <button
              type="submit"
              className="btn btn-secondary btn-label right nexttab nexttab"
              disabled={loading}
            >
              <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>
              {loading && <Spinner size="sm" animation="border" />} Sign Up
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
}
