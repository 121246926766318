import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Tab,
  Modal,
} from "react-bootstrap";
import Switch from "react-bootstrap/Switch";
import BreadCrumb from "Common/BreadCrumb";
import {
  createTemplateThunk,
  deleteTemplateThunk,
  findTemplateDetailsThunk,
  editTemplateThunk,
  getMsgFileThunk,
  msgFileUploadThunk,
} from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "Common/withRouter";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "assets/images/spinner-dark.svg";

const CreateMessageTemplate = (props: any) => {
  document.title = "Signal House Portal Create Message Template";
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { tamplete_id } = useParams();

  const templateDetails = useSelector((state: any) => state.Messages);

  const [modal2, setModal2] = useState<boolean>(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [templateData, setTemplateData] = useState<any>({
    templateName: "",
    templateMessage: "",
    mediaType: "SMS",
    mms: {},
  });

  const wordCount = templateData.templateMessage.trim().split(/\s+/).length;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // let isMMS = false;
    // if (templateData.mms && templateData.mms?.path) {
    //   isMMS = true;
    // }
    if (tamplete_id) {
      dispatch(
        editTemplateThunk({
          templateId: tamplete_id,
          templateName: templateData.templateName,
          templateMessage: templateData.templateMessage,
          isMMS: templateData.mediaType === "SMS" ? false : true,
          mediaUrl: templateData.mms?.path,
        })
      ).then(() => navigate("/message-template"));
    } else {
      dispatch(
        createTemplateThunk({
          templateName: templateData.templateName,
          templateMessage: templateData.templateMessage,
          isMMS: templateData.mediaType === "SMS" ? false : true,
          mediaUrl: templateData.mms.path,
        })
      ).then(() => navigate("/message-template"));
    }
  };

  const uploadImage = (e: any) => {
    const cb = (files: any) => {
      getImage(files?.[0]?.path);
    };

    try {
      e.preventDefault();
      let files = e.target.files;

      const reader = new FileReader();
      if (!reader) return;
      reader.onload = () => {
        const formData = new FormData();
        formData.append("media file", files[0]);
        dispatch(msgFileUploadThunk(formData, true, cb));
      };
      reader.readAsDataURL(files[0]);
    } catch (error) {}
  };

  const handleDelete = () => {
    dispatch(deleteTemplateThunk(tamplete_id || "")).then(() =>
      navigate("/message-template")
    );
  };

  async function getImage(path: string) {
    await dispatch(
      getMsgFileThunk(path, (file: any, path: any) => {
        const f = URL.createObjectURL(file);
        // messageTemplateImage
        setTemplateData((pre: any) => {
          return {
            ...pre,
            mms: {
              file,
              path,
              f,
            },
          };
        });
        setSelectedFileName(file.name);
      })
    );
  }

  useEffect(() => {
    if (tamplete_id) {
      dispatch(findTemplateDetailsThunk({ templateId: tamplete_id }));
    }
  }, []);

  useEffect(() => {
    if (
      tamplete_id &&
      templateDetails.success &&
      !templateDetails.loading &&
      templateDetails.templateDetailsList[0]
    ) {
      const path = templateDetails.templateDetailsList[0].mediaUrl;

      if (path) {
        getImage(path);
      }

      setTemplateData(() => {
        return {
          templateName: templateDetails.templateDetailsList[0].templateName,
          templateMessage:
            templateDetails.templateDetailsList[0].templateMessage,
          mediaType: templateDetails.templateDetailsList[0].isMMS
            ? "MMS"
            : "SMS",
        };
      });
    }
  }, [templateDetails.loading, templateDetails.templateDetailsList]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={tamplete_id ? "Edit Template" : "Create Message Template"}
            isBack={true}
            backClick={() => props.router.navigate("/message-template")}
          />
          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body className="">
                {templateDetails.loading ? (
                  <div className={``}>
                    <img
                      src={Loader}
                      alt="loading..."
                      className={`position-absolute top-50 start-50 translate-middle`}
                    />
                  </div>
                ) : (
                  <Tab.Container defaultActiveKey={0}>
                    <Tab.Content>
                      <Tab.Pane
                        eventKey="0"
                        id="pills-gen-info"
                        role="tabpanel"
                        aria-labelledby="pills-gen-info-tab"
                      >
                        <Form onSubmit={handleSubmit}>
                          <div>
                            <div className="mb-4">
                              <div>
                                <h5 className="mb-1">Template Details</h5>
                                <p className="text-muted">
                                  Fill all Information as below
                                </p>
                              </div>
                            </div>
                            <Row>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Form.Label htmlFor="templateName">
                                    Template Name
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="templateName"
                                    value={templateData.templateName}
                                    onChange={(e) =>
                                      setTemplateData((pre: any) => {
                                        return {
                                          ...pre,
                                          templateName: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                </div>
                              </Col>
                              <Row>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Form.Label htmlFor="templateMessage">
                                      Template Message
                                    </Form.Label>
                                    <Form.Control
                                      as="textarea"
                                      name="templateMessage"
                                      value={templateData.templateMessage}
                                      onChange={(e) => {
                                        setTemplateData((pre: any) => {
                                          return {
                                            ...pre,
                                            templateMessage: e.target.value,
                                          };
                                        });
                                      }}
                                      rows={5}
                                      maxLength={500}
                                    />
                                    <div className="text-muted">
                                      {wordCount} words /{" "}
                                      {templateData.templateMessage.length}{" "}
                                      characters
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Col lg={6}>
                                <Col lg={3}>
                                  <div className="d-flex align-items-center mb-3">
                                    <Switch
                                      id="mediaTypeSwitch"
                                      checked={templateData.mediaType === "MMS"}
                                      onChange={() => {
                                        setTemplateData((pre: any) => {
                                          return {
                                            ...pre,
                                            mediaType:
                                              pre.mediaType === "MMS"
                                                ? "SMS"
                                                : "MMS",
                                          };
                                        });
                                      }}
                                    />
                                    <span className="ms-3">MMS</span>
                                  </div>
                                </Col>
                                {templateData.mediaType === "MMS" && (
                                  <div className="">
                                    <div className="switch-container"></div>
                                    <div className="">
                                      <Form.Label htmlFor="mediaContent">
                                        Media Content
                                      </Form.Label>
                                      {!templateData?.mms?.f && (
                                        <Form.Control
                                          type="file"
                                          id="message-template-file-upload"
                                          onChange={uploadImage}
                                          accept=".jpg, .jpeg, .png, .webp, .svg"
                                        />
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Col>

                              {templateData?.mms?.f && (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "8px",
                                      cursor: "pointer",
                                      color: "blue",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      setShowPreview(true);
                                    }}
                                  >
                                    <div>
                                      <i className="bi bi-image-fill"></i>
                                    </div>
                                    <div>{selectedFileName}</div>
                                    <div style={{ fontSize: "11px" }}>
                                      (click To Preview)
                                    </div>
                                  </div>
                                  <br />
                                  {!templateData?.mms?.f && (
                                    <Form.Group controlId="message-template-file-upload">
                                      <Form.Label column lg={6}>
                                        Or upload new media
                                      </Form.Label>
                                      <Col lg={6}>
                                        <Form.Control
                                          type="file"
                                          onChange={uploadImage}
                                          accept=".jpg, .jpeg, .png .webp, .svg"
                                        />
                                      </Col>
                                    </Form.Group>
                                  )}

                                  <Modal
                                    show={showPreview}
                                    onHide={() => {
                                      setShowPreview(false);
                                    }}
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>Preview</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {templateData?.mms?.f && (
                                        <img
                                          style={{
                                            width: "-webkit-fill-available",
                                          }}
                                          id="messageTemplateImage"
                                          src={templateData?.mms?.f}
                                          alt="Media"
                                        />
                                      )}
                                    </Modal.Body>
                                  </Modal>
                                </>
                              )}
                            </Row>
                          </div>

                          <div className="d-flex align-items-start gap-3 mt-4">
                            {tamplete_id && (
                              <Button
                                variant="danger"
                                //  onClick={handleDelete}
                                onClick={() => setModal2(true)}
                              >
                                Delete
                              </Button>
                            )}
                            <Button
                              type="submit"
                              className="btn btn-secondary btn-label right nexttab"
                            >
                              <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>
                              Submit
                            </Button>
                          </div>
                        </Form>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                )}
              </Card.Body>
            </Card>

            <Modal show={modal2} onHide={() => setModal2(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Apply for Vetting</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  Are you sure you want to delete this message template, this
                  step is not recoverable.
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setModal2(false)}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                  Delete Template
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CreateMessageTemplate);
