import React, { useEffect, useState } from "react";
import { Container, Card, Tab, Nav } from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { getUsageSummaryThunk, getUsageTransactionsThunk } from "slices/thunk";
import Datatable from "../../../Common/Datatable";
import Loader from "assets/images/spinner-dark.svg";
import { createSelector } from "reselect";
import moment from "moment";
import Tooltip from "rc-tooltip";
import Select from "react-select";
import {
  customSelectTheme,
  dateFormat,
  getCurrentDateTime,
  last12Months,
  removeKeyFromObj,
  styleObj,
} from "helpers/common";
import withRouter from "Common/withRouter";
import { useNavigate, useSearchParams } from "react-router-dom";

const selectOpts = [...last12Months()];

const UsageTransactions = (props: any) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const type2 = searchParams.get("type");

  const [summaryObj, setSummaryObj] = useState<any>({});
  const [month, setMonth] = useState(
    `${moment().format("MM")}-${moment().format("YYYY")}`
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [type, setType] = useState<string | null>("");

  const selectProfile = createSelector(
    (state: any) => state.UsageSummary,
    (usageSummary) => ({
      loading: usageSummary.loading,
      loading2: usageSummary.loading2,
      usageSummaryObj: usageSummary.usageSummaryObj,
      usageTransactionsObj: usageSummary.usageTransactionsObj,
    })
  );

  const { loading, loading2, usageSummaryObj, usageTransactionsObj } =
    useSelector(selectProfile);

  const capitalizeFirstLetter = (string: any) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Create Date</span>,
      selector: (row: { created: string }) => (
        <span className="d-flex align-items-center">
          <i className="bi bi-calendar3 me-2 fs-lg text-secondary" />
          {dateFormat(row.created)}
        </span>
      ),
      sortable: true,
      width: "220px",
    },
    {
      name: <span className="font-weight-bold fs-sm">Transaction ID</span>,
      selector: (row: { transactionId: string }) => (
        <Tooltip placement="top" overlay={<span>{row.transactionId}</span>}>
          <span>{row.transactionId}</span>
        </Tooltip>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">SID</span>,
      selector: (row: { sid: string }) => (
        <Tooltip placement="top" overlay={<span>{row.sid}</span>}>
          <span className="text-primary">{row.sid}</span>
        </Tooltip>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Service Type</span>,
      // selector: (row: { serviceType: string }) => row.serviceType,
      selector: (cell: { serviceType: string }) => {
        switch (cell.serviceType?.toLowerCase()) {
          case "mt":
            return (
              <span className="badge bg-body-secondary border border-info text-info">
                {cell.serviceType}
              </span>
            );
          case "number.local":
            return (
              <span className="badge bg-body-secondary border border-warning text-warning">
                {capitalizeFirstLetter(cell.serviceType.replace("Number.", ""))}
              </span>
            );
          case "number.lookup":
            return (
              <span className="badge bg-body-secondary border border-secondary text-secondary">
                {capitalizeFirstLetter(cell.serviceType.replace("Number.", ""))}
              </span>
            );
          case "number.tollfree":
            return (
              <span className="badge bg-body-secondary border border-info text-info">
                {capitalizeFirstLetter(
                  cell.serviceType
                    .replace("Number.", "")
                    .replace(/([A-Z])/g, " $1")
                    .toLowerCase()
                )}
              </span>
            );

          case "sms.inbound":
            return (
              <span className="badge bg-body-secondary border border-success text-success">
                {capitalizeFirstLetter(cell.serviceType.replace("SMS.", ""))}
              </span>
            );

          case "brand.create":
            return (
              <span className="badge bg-body-secondary border border-success text-success">
                {capitalizeFirstLetter(cell.serviceType.replace("brand.", ""))}
              </span>
            );
          case "mms.inbound":
            return (
              <span className="badge bg-body-secondary border border-primary text-primary">
                {capitalizeFirstLetter(cell.serviceType.replace("MMS.", ""))}
              </span>
            );

          case "sms.outbound":
            return (
              <span className="badge bg-body-secondary border border-warning text-warning">
                {capitalizeFirstLetter(cell.serviceType.replace("SMS.", ""))}
              </span>
            );

          case "mms.outbound":
            return (
              <span className="badge bg-body-secondary border border-warning text-warning">
                {capitalizeFirstLetter(cell.serviceType.replace("MMS.", ""))}
              </span>
            );
          case "campaign.marketing":
            return (
              <span className="badge bg-body-secondary border border-warning text-warning">
                {capitalizeFirstLetter(
                  cell.serviceType.toLowerCase().replace("campaign.", "")
                )}
              </span>
            );

          default:
            return (
              <span className="badge bg-danger-subtle text-danger fs-sm">
                {cell.serviceType || "N/A"}
              </span>
            );
        }
      },
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Quantity</span>,
      selector: (row: { quantity: number }) => row.quantity,
      sortable: true,
      width: "150px",
    },
    {
      name: <span className="font-weight-bold fs-sm">Amount</span>,
      selector: (row: { amount: number }) => `$${row.amount?.toFixed(4)} USD`,
      sortable: true,
    },
  ];

  useEffect(() => {
    type && startDate && endDate && handleGetData(currPage, rowsPerPage);
  }, [type]);

  useEffect(() => {
    if (month) {
      const monthTemp: any = month?.split("-");
      const startDate = moment([monthTemp?.[1], monthTemp?.[0] - 1]);
      const endDate = moment(startDate).add(1, "month").subtract(1, "day");
      const formattedStartDate = startDate.format("MM-DD-YYYY");
      const formattedEndDate = endDate.format("MM-DD-YYYY");
      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
      setType(type2);
      dispatch(getUsageSummaryThunk(formattedStartDate, formattedEndDate));
    }
  }, [month]);

  useEffect(() => {
    if (Object.keys(usageSummaryObj).length > 0) {
      const temp = removeKeyFromObj(usageSummaryObj?.total, [
        "total",
        "transactions",
      ]);
      setSummaryObj(temp);
      setSearchParams({
        type:
          type2 && Object.keys(temp)?.includes(type2)
            ? type2
            : Object.keys(temp)?.[0],
      });
      setType(
        type2 && Object.keys(temp)?.includes(type2)
          ? type2
          : Object.keys(temp)?.[0]
      );
    } else {
      setSummaryObj({});
      setSearchParams({ type: "" });
      setType("");
    }
  }, [usageSummaryObj]);

  const handleGetData = (page: number, perPage: number) => {
    dispatch(
      getUsageTransactionsThunk(
        type || "SMS",
        startDate,
        endDate,
        page,
        perPage
      )
    );
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(page, rowsPerPage);
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(1, rows);
  };

  const handleTabChange = (dt: string) => {
    setSearchParams({ type: dt });
    setType(dt);
    setCurrPage(1);
    setRowsPerPage(10);
  };

  const handleRowClick = (row: { sid: string }) => {
    // navigate(`/${type?.toLowerCase()}-logs-details/${row.sid}`);
  };

  return (
    <React.Fragment>
      <div className="page-content position-relative">
        <Container fluid>
          <BreadCrumb
            title="Usage Transactions"
            isFilter={true}
            filterButton={
              <div className="d-flex align-items-center">
                <p className="mb-0 me-2">As of {getCurrentDateTime()}</p>
                <Select
                  styles={styleObj(false)}
                  theme={customSelectTheme}
                  options={selectOpts}
                  onChange={(e: any) => {
                    setMonth(e.value);
                  }}
                  value={selectOpts?.find((dt) => dt?.value === month)}
                />
              </div>
            }
          />

          {loading ? (
            <div className={`wrapper`}>
              <img
                src={Loader}
                className={`position-absolute top-50 start-50 translate-middle`}
              />
            </div>
          ) : Object.keys(summaryObj)?.length > 0 ? (
            <Tab.Container defaultActiveKey={type || ""} activeKey={type || ""}>
              <Nav
                as="ul"
                variant="tabs"
                className="nav-tabs-custom nav-primary mb-3"
              >
                {Object.keys(summaryObj).map((dt, i) => (
                  <Nav.Item as="li" onClick={() => handleTabChange(dt)} key={i}>
                    <Nav.Link eventKey={dt}>{dt}</Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content>
                {Object.keys(summaryObj).map((dt, i) => (
                  <Tab.Pane eventKey={dt} key={i}>
                    <div className="position-relative">
                      <Card className="wrapper2">
                        <Card.Body className="listing-table3">
                          {loading2 ? (
                            <div className={``}>
                              <img
                                src={Loader}
                                className={`position-absolute top-50 start-50 translate-middle`}
                              />
                            </div>
                          ) : (
                            <Datatable
                              data={
                                type &&
                                Array.isArray(usageTransactionsObj?.records)
                                  ? usageTransactionsObj?.records
                                  : []
                              }
                              columns={columns}
                              handleRowClick={handleRowClick}
                              handlePageChange={handlePageChange}
                              handleRowsPerPageChange={handleRowsPerPageChange}
                              currPage={currPage}
                              rowsPerPage={rowsPerPage}
                              totalRecords={usageTransactionsObj?.totalRecords}
                            />
                          )}
                        </Card.Body>
                      </Card>
                    </div>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          ) : (
            <p className="mt-3">No Record Found</p>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UsageTransactions);
