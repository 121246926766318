import {
  getNotificationEventsApi,
  createNotificationEventsApi,
  getWebNotificationsListApi,
  updateWebNotificationApi,
  notificationsListApi,
} from "helpers/apiroute_helper";
import {
  apiLoading,
  apiLoading2,
  apiLoading3,
  apiLoading5,
  fetchNotificationsStart,
  fetchNotificationsSuccess,
  fetchNotificationsFailure,
  createNotificationEventsSuccessful,
  createNotificationEventsFailed,
  getNotificationEventsSuccessful,
  getNotificationEventsFailed,
  webNotificationsDataSuccessful,
  webNotificationsDataFailed,
  notificationsDataSuccessful,
  notificationsDataFailed,
} from "./reducer";
import { toast } from "react-toastify";

export const fetchNotificationsThunk = () => async (dispatch: any) => {
  try {
    dispatch(fetchNotificationsStart());

    const response = await getNotificationEventsApi();

    if (response.data) {
      dispatch(fetchNotificationsSuccess(response.data));
    } else {
      throw new Error("Failed to fetch notifications");
    }
  } catch (error: any) {
    dispatch(
      fetchNotificationsFailure(
        error.message || "An error occurred while fetching notifications"
      )
    );
    toast.error(error.message || "Failed to fetch notifications");
  }
};

export const getNotificationEventsThunk = () => async (dispatch: any) => {
  dispatch(apiLoading2());

  try {
    const data: any = await getNotificationEventsApi();
    if (data?.code || data?.errorCode) {
      dispatch(getNotificationEventsFailed(data));
      toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(getNotificationEventsSuccessful(data));
    }
  } catch (error: any) {
    console.log("error", error);
    dispatch(getNotificationEventsFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const CreateNotificationEventsThunk =
  (payload: any, cb?: any) => async (dispatch: any) => {
    try {
      dispatch(apiLoading3());
      let data: any = await createNotificationEventsApi({
        ...payload,
      });

      if (data?.code || data?.errorCode) {
        dispatch(createNotificationEventsFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(createNotificationEventsSuccessful(data));
        toast.success(data?.message || "Webhook created successfully");
        cb && cb();
      }
    } catch (error: any) {
      console.log("error", error);
      dispatch(createNotificationEventsFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getWebNotificationsListThunk = () => async (dispatch: any) => {
  try {
    const data: any = await getWebNotificationsListApi();

    if (data?.code || data?.errorCode) {
      dispatch(webNotificationsDataFailed(data));
      toast.error(data?.message || "Something went wrong");
    } else {
      dispatch(webNotificationsDataSuccessful(data));
    }
  } catch (error: any) {
    dispatch(webNotificationsDataFailed(error));
    toast.error(error?.response?.data?.message || "Something went wrong");
  }
};

export const updateWebNotificationThunk =
  (notification: any, cb?: any) => async (dispatch: any) => {
    dispatch(apiLoading());

    try {
      const data: any = await updateWebNotificationApi(notification);

      if (data?.code || data?.errorCode) {
        toast.error(data?.message || "Something went wrong");
      } else {
        cb && cb();
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

export const getNotificationsListThunk =
  (page?: number, recordsPerPage?: number, otherParams?: any) =>
  async (dispatch: any) => {
    dispatch(apiLoading5());

    try {
      const data: any = await notificationsListApi(
        page,
        recordsPerPage,
        otherParams
      );

      if (data?.code || data?.message) {
        dispatch(notificationsDataFailed(data));
        toast.error(data?.message || "Something went wrong");
      } else {
        dispatch(notificationsDataSuccessful(data));
      }
    } catch (error: any) {
      dispatch(notificationsDataFailed(error));
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };
