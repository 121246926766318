import React, { useEffect } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import Datatable from "../../Common/Datatable";
import { capitalizeString, dateFormat } from "helpers/common";
import { getNotificationsListThunk } from "slices/thunk";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import Loader from "assets/images/spinner-dark.svg";
import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import Tooltip from "rc-tooltip";

const NotificationDetails = (props: any) => {
  document.title = "Signal House Portal Notifications";
  const dispatch = useDispatch<any>();

  const selectProfile = createSelector(
    (state: any) => state.Notifications,
    (notifications) => ({
      loading: notifications.loading5,
      notificationDetails: notifications.notificationsData?.records?.[0],
      errors: notifications.errors,
    })
  );

  const { loading, notificationDetails } = useSelector(selectProfile);

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">ID</span>,
      selector: (row: { _id: string }) => row._id || "-",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Date</span>,
      selector: (row: { date: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.date)}
        </span>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      sortable: true,
      selector: (cell: { status: string }) => getStatusUi(cell.status),
    },
  ];

  useEffect(() => {
    props?.router?.params?.id &&
      dispatch(
        getNotificationsListThunk(1, 5, {
          notificationId: props?.router?.params?.id,
        })
      );
  }, [props?.router?.params?.id]);

  const getStatusUi = (type?: string) => {
    switch (type?.toLowerCase()) {
      case "notsent":
        return (
          <span className="badge bg-danger-subtle text-danger fs-sm mt-1">
            {capitalizeString(type)}
          </span>
        );
      case "unread":
        return (
          <span className="badge bg-warning-subtle text-warning fs-sm mt-1">
            {capitalizeString(type)}
          </span>
        );
      case "read":
        return (
          <span className="badge bg-success-subtle text-success fs-sm mt-1">
            {capitalizeString(type)}
          </span>
        );

      default:
        return (
          <span className="badge bg-info-subtle text-info fs-sm mt-1">
            {type ? capitalizeString(type) : "-"}
          </span>
        );
    }
  };

  const getAlertUi = (type?: string) => {
    switch (type?.toLowerCase()) {
      case "error":
        return (
          <span
            data-tag="allowRowEvents"
            className="d-flex align-items-center text-danger"
          >
            <span
              data-tag="allowRowEvents"
              className="badge border border-2 border-white rounded-circle bg-danger p-1 me-2"
            >
              <span></span>
            </span>
            {capitalizeString(type)}
          </span>
        );
      default:
        return (
          <span
            data-tag="allowRowEvents"
            className={`d-flex align-items-center text-${
              type?.toLowerCase() || "info"
            }`}
          >
            <span
              data-tag="allowRowEvents"
              className={`badge border border-2 border-white rounded-circle bg-${
                type?.toLowerCase() || "info"
              } p-1 me-2`}
            >
              <span></span>
            </span>
            {type ? capitalizeString(type) : "-"}
          </span>
        );
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Notification Details"
            isBack={true}
            backClick={() => {
              props.router?.navigate(-1);
            }}
          />
          <div className="position-relative wrapper">
            {loading ? (
              <div className={``}>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                  alt="loading"
                />
              </div>
            ) : (
              <>
                <Card>
                  <Card.Body>
                    <div className="mb-1">
                      <h5>Properties</h5>
                    </div>
                    <Col lg={12} className="my-3">
                      <Row className="border bg-light-gray  rounded-3 p-3">
                        <Col md={6} className="mb-3">
                          <h6 className="mb-1">Notification Id:</h6>
                          <p className="mb-0">
                            {notificationDetails?.notificationId || "-"}
                          </p>
                        </Col>
                        <Col md={6} className="mb-3">
                          <h6 className="mb-1">Created Date:</h6>
                          <p className="mb-0">
                            {dateFormat(notificationDetails?.createdDate) ||
                              "-"}
                          </p>
                        </Col>
                        <Col md={6} className="mb-3">
                          <h6 className="mb-1">Message:</h6>
                          <p className="mb-0">
                            {notificationDetails?.notificationMessage || "-"}
                          </p>
                        </Col>
                        <Col md={6} className="mb-3">
                          <h6 className="mb-1">Notification Type:</h6>
                          <div
                            className="d-flex flex-row align-items-center flex-nowrap align-content-center"
                            style={{
                              gap: "20px",
                            }}
                          >
                            {notificationDetails?.notificationType?.includes(
                              "web"
                            ) && (
                              <Tooltip placement="bottom" overlay={"Web"}>
                                <i className="bi bi-globe fs-xl text-secondary"></i>
                              </Tooltip>
                            )}
                            {notificationDetails?.notificationType?.includes(
                              "SMS"
                            ) && (
                              <Tooltip placement="bottom" overlay={"SMS"}>
                                <i className="bi bi-chat-dots fs-xl text-secondary"></i>
                              </Tooltip>
                            )}
                            {notificationDetails?.notificationType.includes(
                              "voice"
                            ) && (
                              <Tooltip placement="bottom" overlay={"Voice"}>
                                <i className="bi bi-soundwave fs-3xl text-secondary"></i>
                              </Tooltip>
                            )}
                            {notificationDetails?.notificationType?.includes(
                              "email"
                            ) && (
                              <Tooltip placement="bottom" overlay={"Email"}>
                                <i className="bi bi-envelope fs-xl text-secondary"></i>
                              </Tooltip>
                            )}
                          </div>
                        </Col>
                        <Col md={6} className="mb-3">
                          <h6 className="mb-1">Notification Web Status:</h6>
                          <p className="mb-0">
                            {getStatusUi(
                              notificationDetails?.NotificationWebStatus
                            )}
                          </p>
                        </Col>
                        <Col md={6} className="mb-3">
                          <h6 className="mb-1">Notification SMS Status:</h6>
                          <p className="mb-0">
                            {getStatusUi(
                              notificationDetails?.NotificationSMSStatus
                            )}
                          </p>
                        </Col>
                        <Col md={6} className="mb-3">
                          <h6 className="mb-1">Notification Voice Status:</h6>
                          <p className="mb-0">
                            {getStatusUi(
                              notificationDetails?.NotificationVoiceStatus
                            )}
                          </p>
                        </Col>
                        <Col md={6} className="mb-3">
                          <h6 className="mb-1">Notification Email Status:</h6>
                          <p className="mb-0">
                            {getStatusUi(
                              notificationDetails?.NotificationEmailStatus
                            )}
                          </p>
                        </Col>
                        <Col md={6} className="mb-3 mb-md-0">
                          <h6 className="mb-1">Notification Alert:</h6>
                          <p className="mb-0">
                            {getAlertUi(notificationDetails?.notificationAlert)}
                          </p>
                        </Col>
                        <Col md={6}>
                          <h6 className="mb-1">Notification Services:</h6>
                          <p className="mb-0">
                            {notificationDetails?.notificationServices || "-"}
                          </p>
                        </Col>
                      </Row>
                    </Col>

                    <div className="mb-1">
                      <h5>Web Notification History</h5>
                    </div>
                    <Col lg={12} className="my-3">
                      <Row className="border bg-light-gray rounded-3 p-3">
                        <Col md={12}>
                          <Datatable
                            data={
                              notificationDetails?.webNotificationHistory || []
                            }
                            columns={columns}
                            pagination={false}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <div className="mb-1">
                      <h5>SMS Notification History</h5>
                    </div>
                    <Col lg={12} className="my-3">
                      <Row className="border bg-light-gray rounded-3 p-3">
                        <Col md={12}>
                          <Datatable
                            data={
                              notificationDetails?.SMSNotificationHistory || []
                            }
                            columns={columns}
                            pagination={false}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <div className="mb-1">
                      <h5>Voice Notification History</h5>
                    </div>
                    <Col lg={12} className="my-3">
                      <Row className="border bg-light-gray rounded-3 p-3">
                        <Col md={12}>
                          <Datatable
                            data={
                              notificationDetails?.voiceNotificationHistory ||
                              []
                            }
                            columns={columns}
                            pagination={false}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <div>
                      <h5>Email Notification History</h5>
                    </div>
                    <Col lg={12} className="my-3">
                      <Row className="border bg-light-gray rounded-3 p-3">
                        <Col md={12}>
                          <Datatable
                            data={
                              notificationDetails?.emailNotificationHistory ||
                              []
                            }
                            columns={columns}
                            pagination={false}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Card.Body>
                </Card>
              </>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(NotificationDetails);
