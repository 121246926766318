import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { findGroupDetailThunk } from "slices/thunk";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import Loader from "assets/images/spinner-dark.svg";
import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";

const GroupDetails = (props: any) => {
  document.title = "Signal House Portal Group Details";

  const { groupId } = useParams();
  const dispatch = useDispatch<any>();

  const selectAccount = createSelector(
    (state: any) => state.Groups,
    (groups) => ({
      loading: groups.loading,
      groupDetails: groups.groupDetails,
      error: groups.error,
    })
  );

  const { loading, groupDetails } = useSelector(selectAccount);

  useEffect(() => {
    groupId && dispatch(findGroupDetailThunk({ groupId }));
  }, [groupId]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Group"
            isBack={true}
            backClick={() => props.router?.navigate(-1)}
          />
          <div className="position-relative wrapper">
            {loading ? (
              <div className={``}>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                  alt="loading"
                />
              </div>
            ) : (
              <Card>
                <Card.Body className="">
                  {groupDetails?.data?.length > 0 ? (
                    <>
                      <div className="mb-1 ">
                        <h5>Group details</h5>
                      </div>
                      <Col lg={12} className="my-3">
                        <Row className="border bg-light-gray rounded-3 p-3">
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Group Id:</h6>
                            <p className="mb-0">
                              {groupDetails?.data?.[0]?.group_id || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Status:</h6>
                            {groupDetails?.data?.[0]?.status === "Active" ? (
                              <span className="badge bg-success-subtle text-success fs-sm">
                                Active
                              </span>
                            ) : (
                              <span className="badge bg-danger-subtle text-danger fs-sm">
                                Inactive
                              </span>
                            )}
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Group Name:</h6>
                            <p className="mb-0">
                              {groupDetails?.data?.[0]?.group_name || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Group Company Name:</h6>
                            <p className="mb-0">
                              {groupDetails?.data?.[0]?.group_company_name ||
                                "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Group Description:</h6>
                            <p className="mb-0">
                              {groupDetails?.data?.[0]?.group_description ||
                                "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Address:</h6>
                            <p className="mb-0">
                              {`${
                                groupDetails?.data?.[0]?.address_line1
                                  ? `${groupDetails?.data?.[0]?.address_line1},`
                                  : ""
                              } ${
                                groupDetails?.data?.[0]?.address_line2
                                  ? `${groupDetails?.data?.[0]?.address_line2},`
                                  : ""
                              } ${
                                groupDetails?.data?.[0]?.city
                                  ? `${groupDetails?.data?.[0]?.city},`
                                  : ""
                              } ${
                                groupDetails?.data?.[0]?.state
                                  ? `${groupDetails?.data?.[0]?.state},`
                                  : ""
                              } ${
                                groupDetails?.data?.[0]?.country
                                  ? `${groupDetails?.data?.[0]?.country}`
                                  : ""
                              } ${
                                groupDetails?.data?.[0]?.postal_code
                                  ? `- ${groupDetails?.data?.[0]?.postal_code}`
                                  : ""
                              }`}
                            </p>
                          </Col>
                          <Col md={6}>
                            <h6 className="mb-1">Origin:</h6>
                            <p className="mb-0">
                              {groupDetails?.data?.[0]?.origin || "-"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <div className="mb-1 mt-3">
                        <h5>Group Contact Person Details</h5>
                      </div>
                      <Col lg={12} className="mt-3">
                        <Row className="border bg-light-gray rounded-3 p-3">
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">First Name:</h6>
                            <p className="mb-0">
                              {groupDetails?.data?.[0]
                                ?.group_contact_person_first_name || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3">
                            <h6 className="mb-1">Middle Name:</h6>
                            <p className="mb-0">
                              {groupDetails?.data?.[0]
                                ?.group_contact_person_middle_name || "-"}
                            </p>
                          </Col>
                          <Col md={6} className="mb-3 mb-md-0">
                            <h6 className="mb-1">Last Name:</h6>
                            <p className="mb-0">
                              {groupDetails?.data?.[0]
                                ?.group_contact_person_last_name || "-"}
                            </p>
                          </Col>
                          <Col md={6}>
                            <h6 className="mb-1">Phone Number:</h6>
                            <p className="mb-0">
                              {groupDetails?.data?.[0]
                                ?.group_contact_phone_number || "-"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  ) : (
                    <p className="m-0">No Record Found</p>
                  )}
                </Card.Body>
              </Card>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(GroupDetails);
