import BreadCrumb from "Common/BreadCrumb";
import Datatable from "Common/Datatable";
import withRouter from "Common/withRouter";
import { useFormik } from "formik";
import {
  customSelectTheme,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { createSelector } from "reselect";
import {
  fetchNumbersListThunk,
  openModal,
  purchaseNumberThunk,
  updateUserStepsThunk,
} from "slices/thunk";
import { City, Country, State } from "country-state-city";
import { submitLoadingSubject } from "Common/modals/Modal";
import Loader from "assets/images/spinner-dark.svg";
import Tooltip from "rc-tooltip";

// Options for capabilities dropdown
const capabilitiesOptions = [
  { label: "All", value: "all" },
  { label: "SMS", value: "sms" },
  { label: "MMS", value: "mms" },
  // { label: "Voice", value: "voice" },
];

const tfpOpt = ["800", "822", "833", "844", "855", "866", "877", "888"];

const BuyNumbers: React.FC = () => {
  document.title = "Signal House Portal Buy Numbers";
  const dispatch: any = useDispatch();

  const [open, setOpen] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [allCountry, setAllCountry] = useState<any>([]);
  const [allState, setAllState] = useState<any>([]);
  const [allCity, setAllCity] = useState<any>([]);
  const [isChecked, setIsChecked] = useState(false);

  const numberData = createSelector(
    (state: any) => state.Numbers,
    (state: any) => state.AuthUser,
    (numbers, authUser) => ({
      loading: numbers.loading,
      dataObj: numbers.dataObj,
      error: numbers.error,
      stepsData: authUser.stepsData,
      user: authUser.authUser,
    })
  );

  const { loading, dataObj, stepsData, user } = useSelector(numberData);

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Number</span>,
      selector: (row: { phoneNumber: number | string }) => (
        <span className="text-primary">{row?.phoneNumber}</span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Features</span>,
      selector: (row: {
        capabilities?: {
          voice?: boolean;
          sms?: boolean;
          mms?: boolean;
          fax?: boolean;
        };
      }) => {
        return (
          <div
            className="d-flex flex-row align-items-center flex-nowrap align-content-center"
            style={{
              gap: "20px",
            }}
          >
            {row?.capabilities?.voice && (
              <Tooltip placement="bottom" overlay={"Voice"}>
                <i className="bi bi-telephone fs-lg"></i>
              </Tooltip>
            )}
            {row?.capabilities?.sms && (
              <Tooltip placement="bottom" overlay={"SMS"}>
                <i className="bi bi-chat-left-text fs-lg"></i>
              </Tooltip>
            )}
            {row?.capabilities?.mms && (
              <Tooltip placement="bottom" overlay={"MMS"}>
                <i className="bi bi-file-image fs-lg"></i>
              </Tooltip>
            )}
            {row?.capabilities?.fax && (
              <Tooltip placement="bottom" overlay={"Fax"}>
                <i className="bi bi-file-earmark-text fs-lg"></i>
              </Tooltip>
            )}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Monthly Fee</span>,
      selector: (row: { monthlyCharges?: number }) =>
        `$${row?.monthlyCharges ? (+row?.monthlyCharges)?.toFixed(2) : 0}`,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-sm">Actions</span>,
      selector: (row: any) => {
        return (
          <Button
            className="btn  btn-sm"
            variant="outline-secondary"
            onClick={() => handleBuyNow(row)}
          >
            Buy Now
          </Button>
        );
      },
    },
  ];

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      country: "US",
      state: "",
      city: "",
      areaCode: "",
      capabilities: [],
      contains: "",
    },
    onSubmit: (values) => {
      handleGetData(currPage, rowsPerPage, getPayload(values));
    },
  });

  useEffect(() => {
    handleGetData(currPage, rowsPerPage);
    setAllCountry(Country.getAllCountries());
  }, []);

  useEffect(() => {
    if (validation.values.country) {
      const temp = State.getStatesOfCountry(validation.values.country);
      setAllState(temp);
    }
  }, [validation.values.country]);

  useEffect(() => {
    if (validation.values.state) {
      const temp = City.getCitiesOfState(
        validation.values.country,
        validation.values.state
      );
      setAllCity(temp);
    }
  }, [validation.values.state]);

  const getPayload = (values: any) => {
    let updatedPayload: any = {
      tfn: isChecked,
      inRegion: values.state,
      inLocality: values.city,
      areaCode: values.areaCode,
      contains: values.contains,
    };
    if (values.capabilities.find((x: string) => x === "sms")) {
      updatedPayload = { ...updatedPayload, smsEnabled: true };
    }
    if (values.capabilities.find((x: string) => x === "voice")) {
      updatedPayload = { ...updatedPayload, voiceEnabled: true };
    }
    if (values.capabilities.find((x: string) => x === "mms")) {
      updatedPayload = { ...updatedPayload, mmsEnabled: true };
    }
    return removeEmptyAndNullValues(updatedPayload);
  };

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    dispatch(fetchNumbersListThunk(page, perPage, otherParams || {}));
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(page, rowsPerPage, getPayload({ ...validation.values }));
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(1, rows, getPayload({ ...validation.values }));
  };

  const handleBuyNow = (item: { phoneNumber?: number | string }) => {
    dispatch(
      openModal({
        modalbody: "Are you sure you want to purchase this Number?",
        data: {
          title: "Buy Now",
          footer: true,
          cancelBtn: true,
          buttonText: {
            submit: "Yes",
            cancel: "No",
          },
          onSubmit: ({ onClose }: any) => {
            const cb = () => {
              onClose();
              // validation.resetForm();
              handleGetData(
                currPage,
                rowsPerPage,
                getPayload({
                  ...validation.values,
                })
              );
              !stepsData?.buyFirstNumber &&
                dispatch(
                  updateUserStepsThunk(
                    { buyFirstNumber: true },
                    user?.completedSteps
                  )
                );
            };

            submitLoadingSubject.next(true);
            dispatch(
              purchaseNumberThunk(
                { phoneNumbers: [`${item?.phoneNumber}`], tollFree: false },
                cb
              )
            ).then(() => {
              submitLoadingSubject.next(false);
            });
          },
        },
      })
    );
  };

  const setDropdown = () => setOpen(!open);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Buy Numbers" />

          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body>
                <div className="border-bottom pb-2">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      setDropdown();
                      validation.handleSubmit();
                    }}
                  >
                    <Row>
                      <Col sm={4}>
                        <div className="mb-3">
                          <Form.Label htmlFor="country">Country</Form.Label>
                          <Select
                            name="country"
                            styles={styleObj(
                              validation?.touched?.country &&
                                validation?.errors?.country
                            )}
                            theme={customSelectTheme}
                            options={
                              allCountry?.length > 0
                                ? allCountry?.map(
                                    (dt: {
                                      name: string;
                                      isoCode: string;
                                    }) => ({
                                      label: dt?.name,
                                      value: dt?.isoCode,
                                    })
                                  )
                                : []
                            }
                            onChange={(e: { value: string }) => {
                              validation.setFieldValue(
                                "country",
                                e.value ? e.value : ""
                              );
                              validation.setFieldValue("state", "");
                              validation.setFieldValue("city", "");
                              validation.setFieldValue("areaCode", "");
                            }}
                            value={{
                              label: allCountry?.find(
                                (dt: { isoCode: string }) =>
                                  dt?.isoCode === validation.values.country
                              )?.name,
                              value: validation.values.country,
                            }}
                          />
                        </div>
                      </Col>

                      <Col sm={4}>
                        <div className="mb-3">
                          <Form.Label htmlFor="state">States</Form.Label>
                          <Select
                            name="state"
                            styles={styleObj(
                              validation?.touched?.state &&
                                validation?.errors?.state
                            )}
                            theme={customSelectTheme}
                            isDisabled={!validation.values.country}
                            options={
                              allState?.length > 0
                                ? allState?.map(
                                    (dt: {
                                      name: string;
                                      isoCode: string;
                                    }) => ({
                                      label: dt?.name,
                                      value: dt?.isoCode,
                                    })
                                  )
                                : []
                            }
                            onChange={(e: { value: string }) => {
                              validation.setFieldValue("state", e.value);
                              validation.setFieldValue("city", "");
                              validation.setFieldValue("areaCode", "");
                            }}
                            value={{
                              label: allState?.find(
                                (dt: { isoCode: string }) =>
                                  dt?.isoCode === validation.values.state
                              )?.name,
                              value: validation.values.state,
                            }}
                          />
                        </div>
                      </Col>

                      <Col sm={4}>
                        <div className="mb-3">
                          <Form.Label htmlFor="city">City</Form.Label>
                          <Select
                            name="city"
                            styles={styleObj(
                              validation?.touched?.city &&
                                validation?.errors?.city
                            )}
                            theme={customSelectTheme}
                            isDisabled={!validation.values.state}
                            options={
                              allCity?.length > 0
                                ? allCity?.map((dt: { name: string }) => ({
                                    label: dt?.name,
                                    value: dt?.name,
                                  }))
                                : []
                            }
                            onChange={(e: { value: string }) => {
                              validation.setFieldValue("city", e.value);
                              validation.setFieldValue("areaCode", "");
                            }}
                            value={{
                              label: validation.values.city,
                              value: validation.values.city,
                            }}
                          />
                        </div>
                      </Col>

                      <Col sm={4}>
                        <div className="mb-3">
                          <Form.Label htmlFor="areaCode">
                            {isChecked ? "Toll Free Prefix" : "Area Code"}
                          </Form.Label>
                          {isChecked ? (
                            <Select
                              name="areaCode"
                              styles={styleObj(
                                validation?.touched?.areaCode &&
                                  validation?.errors?.areaCode
                              )}
                              theme={customSelectTheme}
                              options={
                                tfpOpt?.length > 0
                                  ? tfpOpt?.map((dt) => ({
                                      label: dt,
                                      value: dt,
                                    }))
                                  : []
                              }
                              onChange={(e: { value: string }) => {
                                validation.setFieldValue("areaCode", e.value);
                              }}
                              value={{
                                label: validation.values.areaCode,
                                value: validation.values.areaCode,
                              }}
                            />
                          ) : (
                            <Form.Control
                              type="text"
                              name="areaCode"
                              disabled={!validation.values.country}
                              onChange={(e) => {
                                validation.setFieldValue(
                                  "areaCode",
                                  e.target.value
                                );
                              }}
                              value={validation.values.areaCode}
                            />
                          )}
                        </div>
                      </Col>

                      <Col sm={4}>
                        <div className="mb-3">
                          <Form.Label htmlFor="contains">Contains</Form.Label>
                          <input
                            type="number"
                            placeholder="Choice Number"
                            className="form-control custom-button"
                            name="contains"
                            value={validation.values.contains || ""}
                            onChange={validation.handleChange}
                          />
                        </div>
                      </Col>

                      <Col sm={4}>
                        <div className="mb-3 mt-4">
                          <Dropdown
                            className="form-control form "
                            id="capabilities"
                            style={{ padding: "0px" }}
                          >
                            <Dropdown.Toggle
                              variant=""
                              id="dropdown-capabilities"
                              style={{
                                background: "rgba(0, 0, 0, 0)",
                                color: "#6868ab",
                                border: "1px solid #6868ab",
                                width: "100%",
                                textAlign: "start",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {validation.values.capabilities.length > 0
                                ? validation.values.capabilities.join(", ")
                                : "Advanced Search"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              style={{ width: "100%", padding: "12px" }}
                              id="capabilities"
                            >
                              {capabilitiesOptions.map((key) => (
                                <div className="form-check" key={key.value}>
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={key.value}
                                    checked={validation.values.capabilities.includes(
                                      key.value as never
                                    )}
                                    onChange={() => {
                                      const keyIndex =
                                        validation.values.capabilities.indexOf(
                                          key.value as never
                                        );
                                      if (key.value === "all") {
                                        const tempValues =
                                          validation.values.capabilities
                                            .length ===
                                          capabilitiesOptions.length
                                            ? []
                                            : capabilitiesOptions.map(
                                                (x) => x.value as never
                                              );
                                        validation.setFieldValue(
                                          "capabilities",
                                          tempValues
                                        );
                                      } else {
                                        if (keyIndex !== -1) {
                                          const updatedOptions = [
                                            ...validation.values.capabilities,
                                          ];
                                          updatedOptions.splice(keyIndex, 1);
                                          validation.setFieldValue(
                                            "capabilities",
                                            updatedOptions
                                          );
                                        } else {
                                          validation.setFieldValue(
                                            "capabilities",
                                            [
                                              ...validation.values.capabilities,
                                              key.value as never,
                                            ]
                                          );
                                        }
                                      }
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={key.value}
                                  >
                                    {key.label}
                                  </label>
                                </div>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </Col>
                      {/* <Col className="d-flex align-items-center">
                        <div
                          className="form-check form-switch form-switch-md"
                          dir="ltr"
                        >
                          <Form.Check
                            type="checkbox"
                            role="switch"
                            label={
                              <Form.Label>
                                {isChecked
                                  ? "Toll Free Numbers Enabled"
                                  : "Toll Free Numbers Disabled"}
                              </Form.Label>
                            }
                            checked={isChecked}
                            onChange={(e) => {
                              validation.setFieldValue("areaCode", "");
                              setIsChecked(e.target.checked);
                            }}
                            style={{ marginBottom: "20px" }}
                          />
                        </div>
                      </Col> */}
                      <Col className="mb-2">
                        <div className="d-flex align-items-center justify-content-end">
                          <Button
                            className="btn btn-secondary me-2"
                            type="button"
                            disabled={loading}
                            onClick={() => {
                              setIsChecked(false);
                              validation.resetForm();
                              setDropdown();
                              handleGetData(currPage, rowsPerPage);
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Clear
                          </Button>
                          <Button
                            className="btn btn-primary"
                            type="submit"
                            disabled={loading}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {loading && (
                              <Spinner size="sm" animation="border" />
                            )}{" "}
                            Apply
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Card.Body>

              <Card.Body className="listing-table">
                {loading ? (
                  <div className={``}>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                    />
                  </div>
                ) : (
                  <Datatable
                    data={
                      Array.isArray(dataObj?.records) ? dataObj?.records : []
                    }
                    columns={columns}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    currPage={currPage}
                    rowsPerPage={rowsPerPage}
                    totalRecords={dataObj?.totalRecords}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(BuyNumbers);
