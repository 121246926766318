import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { createSelector } from "reselect";
import getChartColorsArray from "Common/ChartsDynamicColor";
import { useSelector } from "react-redux";

const RevenueChart = ({ periodData, dataColors }: any) => {
  const RevenueChart = getChartColorsArray(dataColors);

  // chart re-render
  const selectLayoutThemeType = createSelector(
    (state: any) => state.Layout,
    (layoutThemeType) => layoutThemeType.layoutThemeType
  );

  const layoutThemeType = useSelector(selectLayoutThemeType);

  useEffect(() => {
    document.getElementsByClassName("apex-charts");
  }, [layoutThemeType]);

  const options = {
    chart: {
      type: "bar",
      height: 328,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 5,
      colors: "#000",
      lineCap: "round",
    },
    grid: {
      show: true,
      borderColor: "#000",

      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
        borderRadius: 5,
        lineCap: "round",
        borderRadiusOnAllStackedSeries: true,
      },
    },
    colors: RevenueChart,
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: false,
      textAnchor: "top",
    },
    yaxis: {
      labels: {
        show: true,
        formatter: function (y: any) {
          return y.toFixed(0) + "k";
        },
      },
    },
    legend: {
      show: false,
      position: "top",
      horizontalAlign: "right",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        rotate: -90,
      },
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        stroke: {
          width: 1,
        },
      },
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...periodData]}
        height="328"
        type="bar"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

const TrafficSourceChart = ({ periodData, dataColors }: any) => {
  const chartColumnColors = getChartColorsArray(dataColors);

  // chart re-render
  const selectLayoutThemeType = createSelector(
    (state: any) => state.Layout,
    (layoutThemeType) => layoutThemeType.layoutThemeType
  );

  const layoutThemeType = useSelector(selectLayoutThemeType);

  useEffect(() => {
    document.getElementsByClassName("apex-charts");
  }, [layoutThemeType]);

  const options = {
    chart: {
      height: 360,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    legend: {
      show: true,
      position: "top",
    },
    colors: chartColumnColors,
    xaxis: {
      categories: ["Feb", "Mar", "Apr", "May"],
    },
    yaxis: {
      show: false,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...periodData]}
        height="360"
        type="bar"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

const BudgetChart = ({ periodData, dataColors }: any) => {
  const areachartminiColors = getChartColorsArray(dataColors);

  // chart re-render
  const selectLayoutThemeType = createSelector(
    (state: any) => state.Layout,
    (layoutThemeType) => layoutThemeType.layoutThemeType
  );

  const layoutThemeType = useSelector(selectLayoutThemeType);

  useEffect(() => {
    document.getElementsByClassName("apex-charts");
  }, [layoutThemeType]);

  const options = {
    chart: {
      type: "line",
      height: 45,
      sparkline: {
        enabled: true,
      },
    },
    colors: areachartminiColors,
    stroke: {
      curve: "smooth",
      width: 1,
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function (seriesName: any) {
            return "";
          },
        },
      },
      marker: {
        show: false,
      },
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...periodData]}
        height="45"
        type="line"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

const PayoutsChart = ({ periodData, dataColors }: any) => {
  const areachartminiColors = getChartColorsArray(dataColors);

  // chart re-render
  const selectLayoutThemeType = createSelector(
    (state: any) => state.Layout,
    (layoutThemeType) => layoutThemeType.layoutThemeType
  );

  const layoutThemeType = useSelector(selectLayoutThemeType);

  useEffect(() => {
    document.getElementsByClassName("apex-charts");
  }, [layoutThemeType]);

  const options = {
    chart: {
      type: "line",
      height: 45,
      sparkline: {
        enabled: true,
      },
    },
    colors: areachartminiColors,
    stroke: {
      curve: "smooth",
      width: 1,
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function (seriesName: any) {
            return "";
          },
        },
      },
      marker: {
        show: false,
      },
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...periodData]}
        height="45"
        type="line"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

const BalanceOverviewChart = ({ periodData, dataColors }: any) => {
  const balanceOverviewColors = getChartColorsArray(dataColors);

  // chart re-render
  const selectLayoutThemeType = createSelector(
    (state: any) => state.Layout,
    (layoutThemeType) => layoutThemeType.layoutThemeType
  );
  const layoutThemeType = useSelector(selectLayoutThemeType);

  useEffect(() => {
    document.getElementsByClassName("apex-charts");
  }, [layoutThemeType]);

  const options = {
    chart: {
      height: 300,
      type: "line",
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 0,
        left: 0,
        blur: 3,
        color: balanceOverviewColors,
        opacity: 0.25,
      },
    },
    markers: {
      size: 0,
      strokeColors: balanceOverviewColors,
      strokeWidth: 2,
      strokeOpacity: 0.9,
      fillOpacity: 1,
      radius: 0,
      hover: {
        size: 5,
      },
    },
    grid: {
      show: true,
      padding: {
        top: -20,
        right: 0,
        bottom: 0,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        rotate: -90,
      },
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        stroke: {
          width: 1,
        },
      },
    },
    stroke: {
      width: [2, 2, 2],
      curve: "smooth",
    },
    colors: balanceOverviewColors,
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...periodData]}
        height="300"
        type="line"
        className="apex-charts ms-n3"
      />
    </React.Fragment>
  );
};

const EmailSendChart = ({ periodData, dataColors }: any) => {
  const emailSentColors = getChartColorsArray(dataColors);

  // chart re-render
  const selectLayoutThemeType = createSelector(
    (state: any) => state.Layout,
    (layoutThemeType) => layoutThemeType.layoutThemeType
  );
  const layoutThemeType = useSelector(selectLayoutThemeType);

  useEffect(() => {
    document.getElementsByClassName("apex-charts");
  }, [layoutThemeType]);

  const options = {
    chart: {
      height: 363,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        track: {
          background: emailSentColors,
          opacity: 0.15,
        },
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
            color: "#87888a",
          },
          total: {
            show: true,
            label: "Total",
            formatter: function (w: any) {
              return 1793;
            },
          },
        },
      },
    },
    legend: {
      show: true,
      position: "bottom",
    },
    labels: ["Sent", "Received", "Failed"],
    colors: emailSentColors,
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...periodData]}
        height="363"
        type="radialBar"
      />
    </React.Fragment>
  );
};

const UsersActivityChart = ({ periodData, dataColors }: any) => {
  const usersActivityColors = getChartColorsArray(dataColors);

  // chart re-render
  const selectLayoutThemeType = createSelector(
    (state: any) => state.Layout,
    (layoutThemeType) => layoutThemeType.layoutThemeType
  );
  const layoutThemeType = useSelector(selectLayoutThemeType);

  useEffect(() => {
    document.getElementsByClassName("apex-charts");
  }, [layoutThemeType]);

  const options = {
    chart: {
      type: "bar",
      height: 330,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "40%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["S", "M", "T", "W", "T", "F", "S"],
    },
    grid: {
      show: true,
      padding: {
        top: -18,
        right: 0,
        bottom: 0,
      },
    },
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
    colors: usersActivityColors,
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...periodData]}
        height="330"
        type="bar"
        className="apex-charts ms-n2"
      />
    </React.Fragment>
  );
};

const SyncStatusBreakdownChart = ({ periodData, dataColors }: any) => {
  const syncStatusBreakdownColors = getChartColorsArray(dataColors);

  // chart re-render
  const selectLayoutThemeType = createSelector(
    (state: any) => state.Layout,
    (layoutThemeType) => layoutThemeType.layoutThemeType
  );
  const layoutThemeType = useSelector(selectLayoutThemeType);

  useEffect(() => {
    document.getElementsByClassName("apex-charts");
  }, [layoutThemeType]);

  const options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnHight: "40%",
      },
    },
    grid: {
      show: true,
      padding: {
        top: -20,
        right: 0,
        bottom: -10,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: false,
    },
    colors: syncStatusBreakdownColors,
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...periodData]}
        height="350"
        type="bar"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export {
  RevenueChart,
  TrafficSourceChart,
  BudgetChart,
  PayoutsChart,
  BalanceOverviewChart,
  EmailSendChart,
  SyncStatusBreakdownChart,
  UsersActivityChart,
};
