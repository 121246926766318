import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentHistoryThunk } from "slices/thunk";
import getChartColorsArray from "Common/ChartsDynamicColor";
import Loader from "assets/images/spinner-dark.svg";
import { capitalizeString, removeKeyFromObj } from "helpers/common";
import { BudgetChart } from "./charts";
import { Link } from "react-router-dom";

const SummaryChart = ({
  periodData,
  dataColors,
  labels,
  trackBg,
  showPercentage,
}: any) => {
  const chartColors = getChartColorsArray(dataColors);
  const selectLayoutThemeType = createSelector(
    (state: any) => state.Layout,
    (layoutThemeType: any) => layoutThemeType.layoutThemeType
  );
  const layoutThemeType = useSelector(selectLayoutThemeType);
  const totalData = useMemo(
    () => periodData.reduce((a: number, b: number) => a + b, 0),
    [periodData]
  );

  useEffect(() => {
    document.getElementsByClassName("apex-charts");
  }, [layoutThemeType]);

  const chartData = useMemo(() => {
    if (!periodData) {
      return {};
    } else {
      return {
        chart: {
          height: 390,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            track: {
              background: trackBg,
              opacity: 0.15,
            },
            dataLabels: {
              name: {
                fontSize: "22px",
              },
              value: {
                fontSize: "14px",
                color: "#878a99",
                formatter: function (val: number) {
                  if (!showPercentage) {
                    const percentage = ((val / totalData) * 100).toFixed(2);
                    // return `${val} (${percentage}%)`;
                    return `${val} `;
                  }
                  return val;
                },
              },
              total: {
                show: true,
                label: "Total",
                fontSize: "14px",
                color: "#878a99",
                formatter: function (w: any) {
                  return w.globals.seriesTotals.reduce((a: any, b: any) => {
                    return parseFloat((a + b).toFixed(2));
                  }, 0);
                },
              },
            },
          },
        },
        legend: {
          show: true,
          position: "bottom",
        },
        labels: labels,
        colors: chartColors,
      };
    }
  }, [totalData]);

  return (
    <React.Fragment>
      <ReactApexChart
        id={Math.random().toString()}
        options={chartData}
        series={[...periodData]}
        type="radialBar"
        height="250"
      />
    </React.Fragment>
  );
};

const UsageSummary = ({ month, selectedRange }: any) => {
  const dispatch = useDispatch<any>();

  const selectProfile = createSelector(
    (state: any) => state.UsageSummary,
    (state: any) => state.AuthUser,
    (state: any) => state.Billing,
    (state: any) => state.Analytics,
    (state: any) => state.Groups,
    (usageSummary, user, billing, analytics, groups) => ({
      loading: usageSummary.loading,
      usageSummaryObj: usageSummary.usageSummaryObj,
      error: usageSummary.error,
      authUser: user?.authUser,
      loading2: billing.loading,
      paymentHistoryData: billing.paymentHistoryData,
      error2: billing.error,
      analyticsSmsV1: analytics.analyticsSmsV1,
      analyticsMmsV1: analytics.analyticsMmsV1,
      groupDetails: groups?.AllGroups,
    })
  );

  const {
    loading,
    usageSummaryObj,
    authUser,
    loading2,
    paymentHistoryData,
    analyticsSmsV1,
    analyticsMmsV1,
  } = useSelector(selectProfile);

  const [summaryObj, setSummaryObj] = useState<any>({});

  const [dateRepresentation, setDateRepresentation] = useState("");
  const [showPercentage, setShowPercentage] = useState(false);

  useEffect(() => {
    dispatch(getPaymentHistoryThunk(1, 20));
  }, []);

  const orderedKeys = ["Total", "SMS", "MMS", "Total Opt-Out"];

  useEffect(() => {
    if (Object.keys(usageSummaryObj).length > 0) {
      const temp = removeKeyFromObj(usageSummaryObj?.total, [
        "total",
        "transactions",
      ]);
      const reorderedObj: any = {};
      orderedKeys.forEach((key) => {
        if (temp[key]) {
          reorderedObj[key] = temp[key];
        }
      });
      setSummaryObj(reorderedObj);
    }
  }, [usageSummaryObj]);

  const smsTotal = useMemo(() => {
    if (analyticsSmsV1.data) {
      return Object.keys(analyticsSmsV1.data).reduce((acc: any, date: any) => {
        const dateData = analyticsSmsV1.data[date];
        Object.keys(dateData).map((phoneNumber) => {
          const phoneData = dateData[phoneNumber];
          Object.keys(phoneData).map((key) => {
            acc[key] = (acc[key] || 0) + phoneData[key];
          });
        });
        return acc;
      }, {});
    }
  }, [analyticsSmsV1]);

  const mmsTotal = useMemo(() => {
    if (analyticsMmsV1.data)
      return Object.keys(analyticsMmsV1.data).reduce((acc: any, date: any) => {
        const dateData = analyticsMmsV1.data[date];
        Object.keys(dateData).forEach((phoneNumber) => {
          const phoneData = dateData[phoneNumber];
          Object.keys(phoneData).forEach((key) => {
            acc[key] = (acc[key] || 0) + phoneData[key];
          });
        });
        return acc;
      }, {});
  }, [analyticsMmsV1]);

  const totalMessagingData = useMemo(() => {
    const combinedTotals: any = {};
    if (smsTotal) {
      Object.keys(smsTotal).map((key) => {
        combinedTotals[key] = (combinedTotals[key] || 0) + smsTotal[key];
      });
    }
    if (mmsTotal) {
      Object.keys(mmsTotal).map((key) => {
        combinedTotals[key] = (combinedTotals[key] || 0) + mmsTotal[key];
      });
    }
    return { ...combinedTotals };
  }, [smsTotal, mmsTotal]);

  const totalSummaryData = useMemo(() => {
    return {
      ...usageSummaryObj,
      totalMessagingData,
    };
  }, [usageSummaryObj, smsTotal, mmsTotal]);

  const handleToggleView = () => {
    setShowPercentage((prev) => !prev);
  };

  return (
    <React.Fragment>
      <Row>
        <Col xl={9} md={12} sm={6} className="mb-4">
          <Card className="card card-height-100 overflow-hidden mb-0 h-100">
            <Card.Header className="align-items-center d-flex pb-3">
              <h4 className="card-title mb-0 flex-grow-1">
                Messaging Insights
              </h4>

              {/* <Button
                variant={"secondary"}
                onClick={handleToggleView}
                className="ms-2"
              >
                {showPercentage ? (
                  <i className="ri-numbers-line" />
                ) : (
                  <i className="ri-percent-line" />
                )}
              </Button> */}

              <p className="mb-0 ms-2 text-end">
                <b>Period:</b> {dateRepresentation}
              </p>
            </Card.Header>
            {loading ? (
              <div style={{ height: 60 }}>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                />
              </div>
            ) : Object.keys(totalSummaryData)?.length > 0 ? (
              <Card.Body className="p-0 dashsummary">
                <Row className="g-0">
                  <Col md={4}>
                    <Card className={`shadow-none rounded-0 mb-0 border h-100`}>
                      <Card.Header className="pt-2">
                        <h6 className="mb-0">Messaging at a Glance</h6>
                      </Card.Header>
                      <Card.Body className="p-2 pt-0">
                        <div id="summary-total123" dir="ltr">
                          {(totalMessagingData?.delivered !== undefined ||
                            totalMessagingData?.failed !== undefined) && (
                            <SummaryChart
                              periodData={[
                                totalMessagingData?.delivered || 0,
                                totalMessagingData?.failed || 0,
                              ]}
                              dataColors='["--tb-secondary", "--tb-primary"]'
                              labels={["Delivered", "Failed"]}
                              trackBg={["#6868ab", "#e41cfd"]}
                            />
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card className={`shadow-none rounded-0 mb-0 border h-100`}>
                      <Card.Header className="pt-2">
                        <h6 className="mb-0">SMS Delivered</h6>
                      </Card.Header>
                      <Card.Body className="p-2 pt-0">
                        <div id="summary-total-1" dir="ltr">
                          {smsTotal?.delivered !== undefined &&
                            smsTotal?.failed !== undefined && (
                              <SummaryChart
                                periodData={[
                                  smsTotal?.delivered ?? 0,
                                  smsTotal?.failed ?? 0,
                                ]}
                                dataColors='["--tb-secondary", "--tb-primary"]'
                                labels={["Delivered", "Failed"]}
                                trackBg={["#6868ab", "#e41cfd"]}
                              />
                            )}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md={4}>
                    <Card className={`shadow-none rounded-0 mb-0 border h-100`}>
                      <Card.Header className="pt-2">
                        <h6 className="mb-0">MMS Delivered</h6>
                      </Card.Header>
                      <Card.Body className="p-2 pt-0">
                        <div id="summary-total-3" dir="ltr">
                          {mmsTotal?.delivered !== undefined &&
                          mmsTotal?.failed !== undefined ? (
                            <SummaryChart
                              periodData={[
                                mmsTotal?.delivered ?? 0,
                                mmsTotal?.failed ?? 0,
                              ]}
                              dataColors='["--tb-secondary", "--tb-primary"]'
                              labels={["Delivered", "Failed"]}
                              trackBg={["#6868ab", "#e41cfd"]}
                            />
                          ) : null}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>

                  {Object.keys(summaryObj)?.length > 0 &&
                    Object.keys(summaryObj).map(
                      (dt1, i1) =>
                        typeof summaryObj?.[dt1] === "object" &&
                        !Array.isArray(summaryObj?.[dt1]) &&
                        summaryObj?.[dt1] !== null && (
                          <Col md={4} key={i1}>
                            <Card
                              className={`shadow-none rounded-0 mb-0 border`}
                            >
                              <Card.Header className="pt-2">
                                <h6 className="mb-0">{dt1}</h6>
                              </Card.Header>
                              <Card.Body className="p-2 pt-0">
                                <div id={`summary-[${dt1}]`} dir="ltr">
                                  <SummaryChart
                                    periodData={
                                      Object.keys(summaryObj?.[dt1])?.length > 0
                                        ? Object.keys(summaryObj?.[dt1]).map(
                                            (dt2) =>
                                              usageSummaryObj?.total?.[dt1]?.[
                                                dt2
                                              ]?.transactions || 0
                                          )
                                        : [0]
                                    }
                                    labels={
                                      Object.keys(summaryObj?.[dt1])?.length > 0
                                        ? Object.keys(summaryObj?.[dt1]).map(
                                            (dt2) => capitalizeString(dt2)
                                          )
                                        : ["No Data"]
                                    }
                                    dataColors='["--tb-primary", "--tb-secondary"]'
                                    trackBg={["#e41cfd", "#6868ab"]}
                                  />
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                        )
                    )}

                  <Col md={4}>
                    <Card className={`shadow-none rounded-0 mb-0 border h-100`}>
                      <Card.Header className="pt-2">
                        <h6 className="mb-0">Opt-Out Rate</h6>
                      </Card.Header>
                      <Card.Body className="p-2 pt-0">
                        <div id="summary-total" dir="ltr">
                          {totalMessagingData?.totalOptOut !== undefined && (
                            <SummaryChart
                              periodData={[
                                totalMessagingData?.totalOptOut || 0,
                              ]}
                              dataColors='["--tb-secondary"]'
                              labels={["Opt-Out"]}
                              trackBg={["#6868ab"]}
                            />
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Card.Body>
            ) : (
              <Card.Body>
                <p className="mb-0">No Record Found</p>
              </Card.Body>
            )}
          </Card>
        </Col>
        <Col xl={3} className="mb-4">
          <Card className="overflow-hidden mb-0 h-100">
            <Card.Body>
              <div className="h-100 d-flex flex-column justify-content-between">
                <div>
                  <p className="text-muted mb-2">Balance (USD)</p>
                  <h4>
                    {authUser?.balance
                      ? `$${(+authUser?.balance)?.toFixed(2)}`
                      : "-"}{" "}
                  </h4>
                  <p className="text-muted text-truncate">Current Balance</p>
                </div>
                <div>
                  <div className="mx-3" style={{ height: 100 }}>
                    {loading2 ? (
                      <div className="position-relative h-100">
                        <img
                          src={Loader}
                          className={`position-absolute top-50 start-50 translate-middle`}
                        />
                      </div>
                    ) : (
                      <BudgetChart
                        periodData={
                          paymentHistoryData?.records?.length > 0
                            ? [
                                {
                                  data: paymentHistoryData?.records
                                    ?.filter(
                                      (dt: { status?: string }) =>
                                        dt?.status === "succeeded"
                                    )
                                    ?.slice(0, 10)
                                    ?.map((dt: any) => dt?.amount),
                                },
                              ]
                            : []
                        }
                        dataColors='["--tb-primary"]'
                      />
                    )}
                  </div>

                  <div className="mt-4">
                    <Link to={"/dashboard/add-funds"}>
                      <Button
                        variant="primary"
                        className="w-100"
                        data-bs-toggle="modal"
                        data-bs-target="#addAmount"
                      >
                        Add Funds
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UsageSummary;
