import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { removeEmptyAndNullValues } from "helpers/common";
import BreadCrumb from "Common/BreadCrumb";
import withRouter from "Common/withRouter";
import {
  createSubgroupThunk,
  getSubgroupDetailsThunk,
  openModal,
  updateSubgroupThunk,
} from "slices/thunk";
import { FocusError } from "focus-formik-error";
import Loader from "assets/images/spinner-dark.svg";

const CreateSubGroup = (props: any) => {
  const { groupId, subGroupId } = props?.router?.params;
  const dispatch = useDispatch<any>();

  document.title = `Signal House Portal ${
    subGroupId ? "Update" : "Create"
  } Sub Group`;

  const [tempStatus, setTempStatus] = useState<any>("");

  const selectAccount = createSelector(
    (state: any) => state.Groups,
    (groups) => ({
      loading4: groups.loading4,
      loading3: groups.loading3,
      subgroupDetails: groups.subgroupDetails,
      error: groups.error,
    })
  );

  const { loading4, loading3, subgroupDetails } = useSelector(selectAccount);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      groupId: groupId || "",
      subGroupName: "",
      subGroupDescription: "",
    },
    validationSchema: Yup.object({
      groupId: Yup.string().required("Group Id is Required"),
      subGroupName: Yup.string().required("Please Enter Group Name"),
      // subGroupDescription: Yup.string().required(
      //   "Please Enter Group Description"
      // ),
    }),
    onSubmit: (values: any) => {
      const cb = () => {
        handleCancel();
      };
      subGroupId
        ? dispatch(
            updateSubgroupThunk(
              removeEmptyAndNullValues({
                ...values,
                status: tempStatus,
                subGroupId,
              }),
              cb
            )
          )
        : dispatch(
            createSubgroupThunk(
              removeEmptyAndNullValues({
                ...values,
              }),
              cb
            )
          );
    },
  });

  useEffect(() => {
    if (subGroupId) {
      dispatch(getSubgroupDetailsThunk(subGroupId));
    }
  }, [subGroupId]);

  useEffect(() => {
    if (subGroupId && subgroupDetails) {
      const tempObj = subgroupDetails?.data?.[0];

      validation.setFieldValue("subGroupName", tempObj?.sub_group_name);
      validation.setFieldValue(
        "subGroupDescription",
        tempObj?.sub_group_description
      );
      setTempStatus(tempObj?.status || "Inactive");
    }
  }, [subgroupDetails]);

  const handleCancel = () => {
    validation.resetForm();
    props.router?.navigate(-1);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title={`${subGroupId ? "Edit" : "Add"} Sub Group`}
          isBack={true}
          backClick={handleCancel}
        />
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
          }}
        >
          <FocusError formik={validation} />
          <Card
            className={`${
              loading3 && subGroupId ? "portingwrapper position-relative" : ""
            }`}
          >
            <Card.Body>
              {loading3 && subGroupId ? (
                <div>
                  <img
                    src={Loader}
                    className={`position-absolute top-50 start-50 translate-middle`}
                    alt="loading"
                    height={50}
                  />
                </div>
              ) : (
                <Row>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      {validation.touched.groupId &&
                      validation.errors.groupId ? (
                        <p className="text-danger">
                          {validation.errors.groupId}
                        </p>
                      ) : null}
                    </div>
                    {subGroupId && (
                      <div className="d-flex flex-column align-items-end">
                        <Form.Label htmlFor="webhookUrl" className="">
                          Status
                        </Form.Label>
                        <div className="d-flex align-items-center">
                          <p className="mb-0 me-2">Inactive</p>
                          <div
                            className="form-check form-switch form-switch-md"
                            dir="ltr"
                          >
                            <Form.Check
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckChecked"
                              checked={tempStatus === "Active"}
                              onChange={() => {
                                dispatch(
                                  openModal({
                                    modalbody:
                                      "Are you sure you want to change the status?",
                                    data: {
                                      title: "Change Status",
                                      footer: true,
                                      cancelBtn: true,
                                      buttonText: {
                                        submit: "Yes",
                                      },
                                      onSubmit: ({ onClose }: any) => {
                                        setTempStatus((prev: any) =>
                                          prev === "Active"
                                            ? "Inactive"
                                            : "Active"
                                        );
                                        onClose();
                                      },
                                    },
                                  })
                                );
                              }}
                            />
                          </div>
                          <p className="mb-0 ms-1">Active</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <Col lg={4} className="mb-3">
                    <Form.Label htmlFor="subGroupName">
                      Sub Group Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="subGroupName"
                      name="subGroupName"
                      placeholder="Enter group name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.subGroupName || ""}
                      isInvalid={
                        validation.touched.subGroupName &&
                        validation.errors.subGroupName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.subGroupName &&
                    validation.errors.subGroupName ? (
                      <Form.Control.Feedback type="invalid">
                        {validation.errors.subGroupName}
                      </Form.Control.Feedback>
                    ) : null}
                  </Col>
                  <Col lg={8} className="mb-3">
                    <Form.Label htmlFor="subGroupDescription">
                      Sub Group Description
                    </Form.Label>
                    <textarea
                      id="subGroupDescription"
                      name="subGroupDescription"
                      placeholder="Enter group description"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.subGroupDescription || ""}
                      className={`form-control ${
                        validation.touched.subGroupDescription &&
                        validation.errors.subGroupDescription
                          ? "border-danger"
                          : ""
                      }`}
                      rows={3}
                    />
                    {validation.touched.subGroupDescription &&
                    validation.errors.subGroupDescription ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {validation.errors.subGroupDescription}
                      </Form.Control.Feedback>
                    ) : null}
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>

          <div className="hstack gap-2 justify-content-end mb-4">
            <Button type="submit" variant="primary" disabled={loading4}>
              {loading4 && <Spinner size="sm" animation="border" />}{" "}
              {subGroupId ? "Update" : "Add"}
            </Button>
            <Button className="btn btn-subtle-danger" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default withRouter(CreateSubGroup);
