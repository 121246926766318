import { Button, Form, InputGroup } from "react-bootstrap";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function Step1({ validation1 }: any) {
  const [passwordShow, setPasswordShow] = useState<any>(false);
  const [passwordShow2, setPasswordShow2] = useState<any>(false);

  return (
    <div className="p-5 p-lg-3 p-xxl-5">
      <div className="text-center">
        <h5 className="fs-3xl">Create your account</h5>
        <p className="text-muted">Get your Signal House account now</p>
      </div>
      <div className="mt-5">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation1.handleSubmit();
            return false;
          }}
        >
          <Form.Group className="mb-3">
            <Form.Label htmlFor="firstName">
              First Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              id="firstName"
              placeholder="Enter firstname"
              name="firstName"
              onChange={validation1.handleChange}
              onBlur={validation1.handleBlur}
              value={validation1.values.firstName || ""}
              isInvalid={
                validation1.touched.firstName && validation1.errors.firstName
                  ? true
                  : false
              }
            />
            {validation1.touched.firstName && validation1.errors.firstName ? (
              <Form.Control.Feedback type="invalid">
                <div>{validation1.errors.firstName}</div>
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label htmlFor="lastName">
              Last Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              id="lastName"
              placeholder="Enter lastname"
              name="lastName"
              onChange={validation1.handleChange}
              onBlur={validation1.handleBlur}
              value={validation1.values.lastName || ""}
              isInvalid={
                validation1.touched.lastName && validation1.errors.lastName
                  ? true
                  : false
              }
            />
            {validation1.touched.lastName && validation1.errors.lastName ? (
              <Form.Control.Feedback type="invalid">
                <div>{validation1.errors.lastName}</div>
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label htmlFor="emailId">
              Email <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              id="emailId"
              placeholder="Enter email address"
              name="emailId"
              className="form-control"
              onChange={validation1.handleChange}
              onBlur={validation1.handleBlur}
              value={validation1.values.emailId || ""}
              isInvalid={
                validation1.touched.emailId && validation1.errors.emailId
                  ? true
                  : false
              }
            />
            {validation1.touched.emailId && validation1.errors.emailId ? (
              <Form.Control.Feedback type="invalid">
                <div>{validation1.errors.emailId}</div>
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label htmlFor="password-input">
              Password <span className="text-danger">*</span>
            </Form.Label>
            <InputGroup className="position-relative auth-pass-inputgroup">
              <Form.Control
                placeholder="Enter password"
                id="password-input"
                type={!passwordShow ? "password" : "text"}
                name="password"
                onChange={validation1.handleChange}
                onBlur={validation1.handleBlur}
                value={validation1.values.password || ""}
                isInvalid={
                  validation1.touched.password && validation1.errors.password
                    ? true
                    : false
                }
              />
              <Button
                variant="link"
                className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                type="button"
                id="password-addon"
                onClick={() => setPasswordShow(!passwordShow)}
              >
                <i className="ri-eye-fill align-middle"></i>
              </Button>
              {validation1.touched.password && validation1.errors.password ? (
                <Form.Control.Feedback type="invalid">
                  <div>{validation1.errors.password}</div>
                </Form.Control.Feedback>
              ) : null}
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label htmlFor="confirm-password-input">
              Confirm Password <span className="text-danger">*</span>
            </Form.Label>
            <InputGroup className="position-relative auth-pass-inputgroup">
              <Form.Control
                placeholder="Confirm password"
                id="confirm-password-input"
                type={!passwordShow2 ? "password" : "text"}
                name="confPassword"
                onChange={validation1.handleChange}
                onBlur={validation1.handleBlur}
                value={validation1.values.confPassword || ""}
                isInvalid={
                  validation1.touched.confPassword &&
                  validation1.errors.confPassword
                    ? true
                    : false
                }
              />
              <Button
                variant="link"
                className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                type="button"
                id="password-addon"
                onClick={() => setPasswordShow2(!passwordShow2)}
              >
                <i className="ri-eye-fill align-middle"></i>
              </Button>
              {validation1.touched.confPassword &&
              validation1.errors.confPassword ? (
                <Form.Control.Feedback type="invalid">
                  <div>{validation1.errors.confPassword}</div>
                </Form.Control.Feedback>
              ) : null}
            </InputGroup>
          </Form.Group>

          {/* <Form.Group className="mb-4">
            <p className="mb-0 fs-xs text-muted fst-italic">
              By registering you agree to the Signal House{" "}
              <a
                href={process.env.PUBLIC_URL + "pages-term-conditions"}
                className="text-primary text-decoration-underline fst-normal fw-medium"
              >
                Terms of Use
              </a>
            </p>
          </Form.Group> */}
          <div className="d-flex align-items-start justify-content-center gap-3 mt-4">
            <button
              type="submit"
              className="btn btn-secondary btn-label right nexttab nexttab"
            >
              <i className="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>
              Next
            </button>
          </div>
        </Form>
        <div className="mt-4 text-center">
          <p className="mb-0">
            Already have an account ?{" "}
            <Link
              to={process.env.PUBLIC_URL + "/login"}
              className="fw-semibold text-primary text-decoration-underline"
            >
              Signin
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
